export const TronLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_z8.png" width="70" />
      </th>
      <td>{'Motorway (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway.png" width="70" />
      </th>
      <td>{'Motorway (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary.png" width="70" />
      </th>
      <td>{'Trunk road, primary road, secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary_ref.png" width="70" />
      </th>
      <td>{'Labelled primary road/trunk road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tertiary.png" width="70" />
      </th>
      <td>{'Tertiary road, minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/link.png" width="70" />
      </th>
      <td>{'Slip road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/service.png" width="70" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_tunnel_lower.png" width="70" />
      </th>
      <td>{'Motorway with tunnel (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_tunnel.png" width="70" />
      </th>
      <td>{'Motorway with tunnel (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary_tunnel.png" width="70" />
      </th>
      <td>{'Trunk road, primary road, secondary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tertiary_tunnel.png" width="70" />
      </th>
      <td>{'Tertiary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/minor_road_tunnel.png" width="70" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/footway.png" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/steps.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/footway_tunnel.png" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/runway_lower.png" width="70" />
      </th>
      <td>{'Runway (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/runway.png" width="70" />
      </th>
      <td>{'Runway (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/taxiway.png" width="70" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train.png" width="70" />
      </th>
      <td>{'Railway - train'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/subway.png" width="70" />
      </th>
      <td>{'Subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tram.png" width="70" />
      </th>
      <td>{'Tram, light rail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pier.png" width="70" />
      </th>
      <td>{'Pier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/platform.png" />
      </th>
      <td>{'Platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/boundary_country.png" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/boundary_regional.png" />
      </th>
      <td>{'Regional boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water.png" width="60" />
      </th>
      <td>{'Ocean, sea, lake (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_middle.png" width="60" />
      </th>
      <td>{'Ocean, sea, lake (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_higher.png" width="60" />
      </th>
      <td>{'Ocean, sea (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/lake.png" width="62" />
      </th>
      <td>{'Lake (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/swimming_pool.png" width="69" />
      </th>
      <td>{'Swimming pool'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/forest.png" width="60" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/nature_reserve.png" width="60" />
      </th>
      <td>{'Nature reserve'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/protected_area.png" width="60" />
      </th>
      <td>{'National park, protected area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/farmland.png" width="60" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/park.png" width="60" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/golf_course.png" width="60" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hospital.png" width="60" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/university.png" width="60" />
      </th>
      <td>{'University, college'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/parking.png" width="60" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/substation.png" width="60" />
      </th>
      <td>{'Substation, plant, generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/man_made.png" width="60" />
      </th>
      <td>{'Wastewater plant, works, waterworks, breakwater, pier area, bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pedestrian.png" width="60" />
      </th>
      <td>{'Pedestrian area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/river.png" width="70" />
      </th>
      <td>{'River'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/peak.png" width="18" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/volcano.png" width="18" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/spring.png" width="18" />
      </th>
      <td>{'Spring, hot spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/beach.png" width="18" />
      </th>
      <td>{'Beach'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/town_hall.png" width="20" />
      </th>
      <td>{'Town hall, government offices'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/police.png" width="20" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fire_station.png" width="20" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/court.png" width="20" />
      </th>
      <td>{'Court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/post_office.png" width="20" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bank.png" width="18" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/atm.png" width="18" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car_sharing.png" width="18" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bicycle_rental.png" width="18" />
      </th>
      <td>{'Bicycle rental, bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/university_icon.png" width="18" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/kindergarten.png" width="18" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/library.png" width="18" />
      </th>
      <td>{'Library'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/vet.png" width="18" />
      </th>
      <td>{'Vet, pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship.png" width="18" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_christian.png" width="18" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_jewish.png" width="18" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_muslim.png" width="18" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_buddhist.png" width="18" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pharmacy.png" width="18" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hospital_icon.png" width="18" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/nursing_home.png" width="18" />
      </th>
      <td>{'Nursing home'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hotel.png" width="18" />
      </th>
      <td>{'Hotel, hostel, motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/camping.png" width="18" />
      </th>
      <td>{'Camping, summer camp'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/museum.png" width="18" />
      </th>
      <td>{'Museum, observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/theatre.png" width="18" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cinema.png" width="18" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/gallery.png" width="18" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/national_park_icon.png" width="18" />
      </th>
      <td>{'National park, protected area, park, battlefield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/national_park_icon_z13.png" width="32" />
      </th>
      <td>{'National park, battlefield (up zoom 13)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/forest_icon.png" width="18" />
      </th>
      <td>{'Nature reserve, forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/garden.png" width="18" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/playground.png" width="18" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/recreation_ground.png" width="18" />
      </th>
      <td>{'Recreation ground, sports centre, outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch.png" width="18" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/golf_course_icon.png" width="18" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_soccer.png" width="18" />
      </th>
      <td>{'Football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_tennis.png" width="18" />
      </th>
      <td>{'Tennis court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_basketball.png" width="18" />
      </th>
      <td>{'Basketball court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_baseball.png" width="18" />
      </th>
      <td>{'Baseball field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_park.png" width="18" />
      </th>
      <td>{'Water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/viewpoint.png" width="18" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/information.png" width="6" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fort.png" width="18" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/monument.png" width="18" />
      </th>
      <td>{'Memorial, monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/archaeological_site.png" width="18" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/stadium.png" width="18" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/winter_sports.png" width="18" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/marina.png" width="18" />
      </th>
      <td>{'Marina'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/aquarium.png" width="18" />
      </th>
      <td>{'Aquarium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/zoo.png" width="18" />
      </th>
      <td>{'Zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/theme_park.png" width="18" />
      </th>
      <td>{'Theme park, amusement ride'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/slipway.png" width="18" />
      </th>
      <td>{'Slipway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/parking_icon.png" width="13" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bicycle_parking.png" width="13" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bus_station.png" width="16" />
      </th>
      <td>{'Bus station, bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train_station.png" width="18" />
      </th>
      <td>{'Train station, tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train_station_z18.png" width="32" />
      </th>
      <td>{'Train station (up zoom 18)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fuel.png" width="18" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/traffic_lights.png" width="19" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/airport.png" width="18" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/airport_z12.png" width="18" />
      </th>
      <td>{'Airport (up zoom 12)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/ferry_terminal.png" width="18" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/subway_entrance.png" width="14" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Offices'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/office.png" width="18" />
      </th>
      <td>{'Company, insurance, office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/estate_agent.png" width="18" />
      </th>
      <td>{'Estate agent'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bar.png" width="18" />
      </th>
      <td>{'Bar, pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cafe.png" width="18" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/biergarten.png" width="18" />
      </th>
      <td>{'Beer garden, brewery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fast_food.png" width="18" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/restaturant.png" width="18" />
      </th>
      <td>{'Restaturant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/ice_cream.png" width="18" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Craft'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/photographer.png" width="18" />
      </th>
      <td>{'Photographer, photographic laboratory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/confectionery.png" width="18" />
      </th>
      <td>{'Confectionery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/winery.png" width="18" />
      </th>
      <td>{'Winery, wine shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tailor.png" width="18" />
      </th>
      <td>{'Tailor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/shoemaker.png" width="18" />
      </th>
      <td>{'Shoemaker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/alcohol.png" width="18" />
      </th>
      <td>{'Alcohol shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bakery.png" width="18" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/books.png" width="18" />
      </th>
      <td>{'Bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/butcher.png" width="18" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car_repair.png" width="18" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car.png" width="18" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/clothes.png" width="18" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/computer.png" width="18" />
      </th>
      <td>{'Computer store, Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/convenience.png" width="18" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/department_store.png" width="18" />
      </th>
      <td>{'Department store, mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/diy.png" width="18" />
      </th>
      <td>{'Do-it-yourself store, hardware store, painter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/dry_cleaner.png" width="18" />
      </th>
      <td>{'Dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/florist.png" width="18" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/gift.png" width="18" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/greengrocer.png" width="18" />
      </th>
      <td>{'Greengrocery, supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hairdresser.png" width="18" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/jewellery.png" width="18" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/laundry.png" width="18" />
      </th>
      <td>{'Laundry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/mobile_phone.png" width="18" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/music.png" width="18" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/newspaper.png" width="18" />
      </th>
      <td>{'Newspaper'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/optician.png" width="18" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/sports.png" width="20" />
      </th>
      <td>{'Sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/toys.png" width="18" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/toilets.png" width="18" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/drinking_water.png" width="18" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/recycling.png" width="18" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bench.png" width="18" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/lighthouse.png" width="18" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/generator.png" width="18" />
      </th>
      <td>{'Generator, plant, substation, wastewater plant, waterworks, works'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cemetery.png" width="18" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/quarry.png" width="18" />
      </th>
      <td>{'Quarry, mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/generic.png" width="14" />
      </th>
      <td>{'Generic point of interest'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
