import { FormEvent, KeyboardEvent, useEffect, useState } from 'react'

import { Box, Text, TextInput, useMantineTheme } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'

import { Tooltip } from './Tooltip'

type Props = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}
export const InlineTextInput = ({ value, onChange, disabled }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const theme = useMantineTheme()
  const ref = useClickOutside(() => setIsEditMode(false))

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (inputValue.length === 0) {
      setErrorMessage('Cannot be empty')
    } else {
      setErrorMessage(null)
      setIsEditMode(false)
      if (value !== inputValue) onChange(inputValue)
    }
  }

  const handleClick = () => {
    if (!disabled) setIsEditMode(true)
  }

  useEffect(() => {
    setIsEditMode(false)
  }, [disabled])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setIsEditMode(false)
      setInputValue(value)
    }
  }

  return (
    <Box onClick={handleClick} sx={{ flexGrow: 1 }}>
      {isEditMode && !disabled ? (
        <form onSubmit={handleFormSubmit} ref={ref} onBlur={handleFormSubmit}>
          <Tooltip label={errorMessage} opened={!!errorMessage} withArrow color="red">
            <TextInput
              onKeyDown={handleKeyDown}
              onInput={(e) => setInputValue(e.currentTarget.value)}
              value={inputValue}
              autoFocus
              data-autofocus
              size="xs"
              styles={{ input: { fontSize: theme.fontSizes.sm, paddingLeft: 4, paddingRight: 4 } }}
            />
          </Tooltip>
        </form>
      ) : (
        <Text
          ml={4}
          lineClamp={1}
          size="sm"
          sx={{
            lineHeight: 1,
            cursor: disabled ? 'default' : 'text',
            lineBreak: 'anywhere',
          }}
          color={disabled ? 'dimmed' : ''}
        >
          {value}
        </Text>
      )}
    </Box>
  )
}
