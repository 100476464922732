import { useEffect, useRef } from 'react'

import { AUTOMATIC_SAVING } from 'config/constants'
import { debounce } from 'lodash'
import { useMap, useMapEvents } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { useAuth } from './useAuth'

export const useMapSettingsSaver = () => {
  const { update, user } = useAuth()
  const savingEnabled = useRef(false)

  useEffect(() => {
    if (!user) {
      savingEnabled.current = false
      return undefined
    }
    const timeout = setTimeout(() => {
      savingEnabled.current = true
    }, AUTOMATIC_SAVING.allowSavingAfter)
    return () => clearTimeout(timeout)
  }, [user])

  const {
    state: { mapColor, mapType: mapLayerID, mapOverlays: overlayType, language, units: unitType },
  } = useOptions()
  const map = useMap()

  const save = () => {
    const center = map.getCenter()
    const zoom = map.getZoom()
    update({
      viewCenter: [center.lat, center.lng],
      mapColor,
      mapType: mapLayerID,
      mapOverlays: overlayType,
      language,
      units: unitType,
      viewZoom: zoom,
    })
  }

  const debouncedSave = debounce(save, AUTOMATIC_SAVING.debounceLength)

  useMapEvents({
    moveend: () => {
      if (!user) return
      if (savingEnabled.current) debouncedSave()
    },
  })
}
