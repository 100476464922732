import { Group, Paper, Text } from '@mantine/core'
import { Marker, Tooltip } from 'react-leaflet'
import { useGenericState } from 'stores/genericStore/GenericContext'

import { chartTrackIcon } from './Icons'
import './ChartMarkerStyleReset.css'

export const ChartMarker = () => {
  const { chartTooltipData: data } = useGenericState()

  return data && data.isHovered ? (
    <Marker position={data.position} icon={chartTrackIcon} zIndexOffset={100} draggable={false}>
      <Tooltip permanent={true} direction="top" offset={[0, -34]} opacity={1} className="leaflet-tooltip-reset">
        <Paper
          py={4}
          px={10}
          sx={(theme) => ({
            boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
            '&:before': {
              position: 'absolute',
              border: '6px solid transparent',
              borderTopColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white',
              background: 'transparent',
              content: '""',
              bottom: 0,
              marginBottom: -12,
              left: '50%',
              marginLeft: -6,
            },
          })}
        >
          <Group spacing={0} sx={{ justifyContent: 'center' }}>
            <Text size="xs">{data.xAxisString}</Text>
            <Text size="xs">{data.yAxisString}</Text>
          </Group>
        </Paper>
      </Tooltip>
    </Marker>
  ) : null
}
