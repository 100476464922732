import { Coords, Track } from 'types/app'
import { getDistanceBetweenCoords } from 'utils/coords'

export type CustomChartDataPoint = {
  xAxis: {
    distance: number
    time: number | null
  }
  yAxis: {
    elevation: number | null
    heartRate: number | null
    cadence: number | null
    power: number | null
    speed: number | null
    temperature: number | null
  }
}

export const getPreparedChartData = ({
  trackpoints,
  elevations,
  trackPointsHeartRate,
  trackPointsCadence,
  trackPointsPower,
  trackPointsSpeed,
  trackPointsTemperature,
  trackPointsTimeDatapoints,
}: {
  trackPointsHeartRate: Track['trackPointsHeartRate']
  trackPointsCadence: Track['trackPointsCadence']
  trackPointsPower: Track['trackPointsPower']
  trackPointsSpeed: Track['trackPointsSpeed']
  trackPointsTemperature: Track['trackPointsTemperature']
  trackPointsTimeDatapoints: number[] | undefined
  elevations: Track['trackPointsEle']
  trackpoints: Coords[]
}) => {
  let minElevation = Number.MAX_VALUE
  let maxElevation = Number.MIN_VALUE
  let totalClimb = 0
  let totalDescend = 0
  let computedTotalDistance = 0 // km
  let distanceUphill = 0 // km
  let distanceDownhill = 0 // km
  let computedTotalTime = 0
  // Should be done differently, at the point where track data is processed, but it's a part of the tech debt
  const populatedDatasets = {
    elevation: false,
    heartRate: false,
    cadence: false,
    power: false,
    speed: false,
    temperature: false,
    time: false,
  }

  const prepareTrackStats: CustomChartDataPoint[] = trackpoints.map((point, index) => {
    const prevPoint = trackpoints[index - 1]
    const distanceBetweenCurrentPrevPoint = prevPoint ? getDistanceBetweenCoords(prevPoint, point) / 1000 : 0
    const currentDistanceFromStart = computedTotalDistance + distanceBetweenCurrentPrevPoint
    computedTotalDistance = currentDistanceFromStart
    computedTotalTime += trackPointsTimeDatapoints?.[index] ?? 0

    const prevElevation = elevations[index - 1] || 0
    const elevation = Number(elevations[index]) || 0
    if (elevation < minElevation) {
      minElevation = elevation
    }
    if (elevation > maxElevation) {
      maxElevation = elevation
    }

    if (elevation > prevElevation) {
      distanceUphill += distanceBetweenCurrentPrevPoint
    } else {
      distanceDownhill += distanceBetweenCurrentPrevPoint
    }

    if (index > 0) {
      const elevationDiff = elevation - prevElevation

      if (elevationDiff > 0) {
        totalClimb += elevationDiff
      } else if (elevationDiff < 0) {
        totalDescend += Math.abs(elevationDiff)
      }
    }

    const roundedElevation = Number(elevations[index]?.toFixed(1))
    const time = trackPointsTimeDatapoints?.[index] ?? null
    const currentHeartRate = trackPointsHeartRate?.[index] ?? null
    const currentCadence = trackPointsCadence?.[index] ?? null
    const currentPower = trackPointsPower?.[index] ?? null
    const currentSpeed = trackPointsSpeed?.[index] ?? null
    const currentTemperature = trackPointsTemperature?.[index] ?? null

    if (populatedDatasets.elevation === false && !isNaN(roundedElevation)) populatedDatasets.elevation = true
    if (populatedDatasets.heartRate === false && currentHeartRate !== null) populatedDatasets.heartRate = true
    if (populatedDatasets.cadence === false && currentCadence !== null) populatedDatasets.cadence = true
    if (populatedDatasets.power === false && currentPower !== null) populatedDatasets.power = true
    if (populatedDatasets.speed === false && currentSpeed !== null) populatedDatasets.speed = true
    if (populatedDatasets.temperature === false && currentTemperature !== null) populatedDatasets.temperature = true
    if (populatedDatasets.time === false && time !== null) populatedDatasets.time = true

    return {
      xAxis: {
        distance: index > 0 ? currentDistanceFromStart : 0,
        time,
      },
      yAxis: {
        elevation: roundedElevation,
        heartRate: currentHeartRate,
        cadence: currentCadence,
        power: currentPower,
        speed: currentSpeed,
        temperature: currentTemperature,
      },
    }
  })

  return {
    preparedChartData: prepareTrackStats,
    populatedDatasets,
    minElevation,
    maxElevation,
    totalClimb,
    totalDescend,
    computedTotalDistance,
    computedTotalTime,
    distanceUphill,
    distanceDownhill,
  }
}
