export type WaypointIcon = {
  key: string
  name: string
}

export const waypointIcons: WaypointIcon[] = [
  {
    key: 'Default',
    name: 'map_icon_default',
  },
  {
    key: 'ATV',
    name: 'map_icon_sports_atv',
  },
  {
    key: 'Airport',
    name: 'map_icon_transport_airport',
  },
  {
    key: 'Alert',
    name: 'map_icon_transport_caution',
  },
  {
    key: 'Amusement Park',
    name: 'map_icon_culture_amusement_park',
  },
  {
    key: 'Anchor Prohibited',
    name: 'map_icon_transport_anchorprohibited',
  },
  {
    key: 'Anchor',
    name: 'map_icon_transport_anchor',
  },
  {
    key: 'Animal Tracks',
    name: 'map_icon_nature_animal',
  },
  {
    key: 'Asian Food',
    name: 'map_icon_restaurantshotels_asian_food',
  },
  {
    key: 'Bait and Tackle',
    name: 'map_icon_stores_bait',
  },
  {
    key: 'Ball Park',
    name: 'map_icon_sports_ball',
  },
  {
    key: 'Bank',
    name: 'map_icon_offices_bank',
  },
  {
    key: 'Bar',
    name: 'map_icon_restaurantshotels_bar',
  },
  {
    key: 'Beach',
    name: 'map_icon_nature_beach',
  },
  {
    key: 'Beacon',
    name: 'map_icon_tourism_beacon',
  },
  {
    key: 'Bell',
    name: 'map_icon_people_bell',
  },
  {
    key: 'Big Game',
    name: 'map_icon_nature_animal',
  },
  {
    key: 'Bike Trail',
    name: 'map_icon_sports_bike',
  },
  {
    key: 'Binouculars',
    name: 'map_icon_stores_binoculars',
  },
  {
    key: 'Blind',
    name: 'map_icon_sports_blind',
  },
  {
    key: 'Block',
    name: 'map_shape_block_blue',
  },
  {
    key: 'Block, Blue',
    name: 'map_shape_block_blue',
  },
  {
    key: 'Block, Green',
    name: 'map_shape_block_green',
  },
  {
    key: 'Block, Red',
    name: 'map_shape_block_red',
  },
  {
    key: 'Blood Trail',
    name: 'map_icon_nature_zoo',
  },
  {
    key: 'Boat Ramp',
    name: 'map_icon_transport_boat_ramp',
  },
  {
    key: 'Border Crossing',
    name: 'map_icon_offices_customs',
  },
  {
    key: 'Bowling',
    name: 'map_icon_culture_bowling',
  },
  {
    key: 'Bridge',
    name: 'map_icon_tourism_bridge',
  },
  {
    key: 'Building',
    name: 'map_icon_offices_building',
  },
  {
    key: 'Buoy',
    name: 'map_icon_transport_buoy',
  },
  {
    key: 'Buoy: White',
    name: 'map_icon_transport_buoy',
  },
  {
    key: 'Camp Fire',
    name: 'map_icon_events_fire',
  },
  {
    key: 'Campground',
    name: 'map_icon_tourism_campground',
  },
  {
    key: 'Canoe',
    name: 'map_icon_stores_kayak',
  },
  {
    key: 'Car Rental',
    name: 'map_icon_offices_car_rental',
  },
  {
    key: 'Car Repair',
    name: 'map_icon_transport_car_repair',
  },
  {
    key: 'Car',
    name: 'map_icon_transport_car',
  },
  {
    key: 'Cemetery',
    name: 'map_icon_tourism_cemetery',
  },
  {
    key: 'Church',
    name: 'map_icon_tourism_church',
  },
  {
    key: 'Circle with X',
    name: 'map_shape_circlex',
  },
  {
    key: 'Circle',
    name: 'map_shape_circle_blue',
  },
  {
    key: 'Circle, Blue',
    name: 'map_shape_circle_blue',
  },
  {
    key: 'Circle, Green',
    name: 'map_shape_circle_green',
  },
  {
    key: 'Circle, Red',
    name: 'map_shape_circle_red',
  },
  {
    key: 'City (Capitol)',
    name: 'map_icon_tourism_city_large',
  },
  {
    key: 'City (Large)',
    name: 'map_icon_tourism_city_large',
  },
  {
    key: 'City (Medium)',
    name: 'map_icon_tourism_city_large',
  },
  {
    key: 'City (Small)',
    name: 'map_icon_tourism_city_small',
  },
  {
    key: 'City Hall',
    name: 'map_icon_offices_embassy',
  },
  {
    key: 'Civil',
    name: 'map_icon_offices_administration',
  },
  {
    key: 'Coast Guard',
    name: 'map_icon_tourism_coast_guard',
  },
  {
    key: 'Controlled Area',
    name: 'map_icon_tourism_tower',
  },
  {
    key: 'Convenience Store',
    name: 'map_icon_stores_convenience_store',
  },
  {
    key: 'Cover',
    name: 'map_icon_tourism_cabin',
  },
  {
    key: 'Covey',
    name: 'map_icon_nature_covey',
  },
  {
    key: 'Crossing',
    name: 'map_icon_transport_crossing',
  },
  {
    key: 'Dam',
    name: 'map_icon_industry_dam',
  },
  {
    key: 'Danger Area',
    name: 'map_icon_events_danger_skull',
  },
  {
    key: 'Deli',
    name: 'map_icon_restaurantshotels_fast_food',
  },
  {
    key: 'Department Store',
    name: 'map_icon_stores_department_store',
  },
  {
    key: 'Diamond',
    name: 'map_shape_diamond_blue',
  },
  {
    key: 'Diamond, Blue',
    name: 'map_shape_diamond_blue',
  },
  {
    key: 'Diamond, Green',
    name: 'map_shape_diamond_green',
  },
  {
    key: 'Diamond, Red',
    name: 'map_shape_diamond_red',
  },
  {
    key: 'Dock',
    name: 'map_icon_transport_anchor',
  },
  {
    key: 'Drinking Water',
    name: 'map_icon_nature_drinking_water',
  },
  {
    key: 'Dropoff',
    name: 'map_icon_transport_descent',
  },
  {
    key: 'Exit without services',
    name: 'map_icon_offices_exit',
  },
  {
    key: 'Exit',
    name: 'map_icon_offices_exit',
  },
  {
    key: 'Fast Food',
    name: 'map_icon_restaurantshotels_fast_food',
  },
  {
    key: 'Ferry',
    name: 'map_icon_transport_ferry',
  },
  {
    key: 'Fishing Area',
    name: 'map_icon_sports_fishing',
  },
  {
    key: 'Fishing Hot Spot Facility',
    name: 'map_icon_sports_fishing2',
  },
  {
    key: 'Fitness Center',
    name: 'map_icon_sports_fitness',
  },
  {
    key: 'Flag',
    name: 'map_marker_flag_5_azure',
  },
  {
    key: 'Flag, Blue',
    name: 'map_marker_flag_5_azure',
  },
  {
    key: 'Flag, Green',
    name: 'map_marker_flag_5_chartreuse',
  },
  {
    key: 'Flag, Red',
    name: 'map_marker_flag_5_red',
  },
  {
    key: 'Food Source',
    name: 'map_icon_restaurantshotels_restaurant_greek',
  },
  {
    key: 'Forest',
    name: 'map_icon_nature_forest',
  },
  {
    key: 'Furbearer',
    name: 'map_icon_nature_badger',
  },
  {
    key: 'Gambling/Casino',
    name: 'map_icon_culture_gambling',
  },
  {
    key: 'Gas Station',
    name: 'map_icon_transport_gas',
  },
  {
    key: 'Gas',
    name: 'map_icon_transport_gas',
  },
  {
    key: 'Geocache',
    name: 'map_icon_culture_geocache',
  },
  {
    key: 'Ghost Town',
    name: 'map_icon_tourism_ghost_town',
  },
  {
    key: 'Glider Area',
    name: 'map_icon_sports_hanggliding',
  },
  {
    key: 'Golf Course',
    name: 'map_icon_sports_golf',
  },
  {
    key: 'Ground Transportation',
    name: 'map_icon_transport_ground_transportation',
  },
  {
    key: 'Hazard',
    name: 'map_icon_events_danger_skull',
  },
  {
    key: 'Heliport',
    name: 'map_icon_transport_heliport',
  },
  {
    key: 'Horn',
    name: 'map_icon_transport_horn',
  },
  {
    key: 'Horse Trail',
    name: 'map_icon_sports_horseriding',
  },
  {
    key: 'Hotel',
    name: 'map_icon_restaurantshotels_hotel',
  },
  {
    key: 'House',
    name: 'map_icon_people_house',
  },
  {
    key: 'Hunting Area',
    name: 'map_icon_sports_hunting',
  },
  {
    key: 'Hunting',
    name: 'map_icon_sports_shooting',
  },
  {
    key: 'Ice Skating',
    name: 'map_icon_sports_ice_skating',
  },
  {
    key: 'Information',
    name: 'map_icon_offices_information',
  },
  {
    key: 'Intersection',
    name: 'map_icon_transport_intersection',
  },
  {
    key: 'Intl Freeway hwy',
    name: 'map_icon_transport_highway',
  },
  {
    key: 'Intl National hwy',
    name: 'map_icon_transport_highway',
  },
  {
    key: 'Italian Food',
    name: 'map_icon_restaurantshotels_italian_food',
  },
  {
    key: 'Kayak',
    name: 'map_icon_stores_kayak',
  },
  {
    key: 'Large Ramp intersection',
    name: 'map_icon_transport_intersection',
  },
  {
    key: 'Large exit without services',
    name: 'map_icon_offices_exit',
  },
  {
    key: 'Letter A, Blue',
    name: 'map_icon_letter_blue_a',
  },
  {
    key: 'Letter A, Green',
    name: 'map_icon_letter_green_a',
  },
  {
    key: 'Letter A, Red',
    name: 'map_icon_letter_red_a',
  },
  {
    key: 'Letter B, Blue',
    name: 'map_icon_letter_blue_b',
  },
  {
    key: 'Letter B, Green',
    name: 'map_icon_letter_green_b',
  },
  {
    key: 'Letter B, Red',
    name: 'map_icon_letter_red_b',
  },
  {
    key: 'Letter C, Blue',
    name: 'map_icon_letter_blue_c',
  },
  {
    key: 'Letter C, Green',
    name: 'map_icon_letter_green_c',
  },
  {
    key: 'Letter C, Red',
    name: 'map_icon_letter_red_c',
  },
  {
    key: 'Letter D, Blue',
    name: 'map_icon_letter_blue_d',
  },
  {
    key: 'Letter D, Green',
    name: 'map_icon_letter_green_d',
  },
  {
    key: 'Letter D, Red',
    name: 'map_icon_letter_red_d',
  },
  {
    key: 'Levee',
    name: 'map_icon_industry_dam',
  },
  {
    key: 'Library',
    name: 'map_icon_offices_library',
  },
  {
    key: 'Light',
    name: 'map_icon_people_light',
  },
  {
    key: 'Lighthouse',
    name: 'map_icon_tourism_lighthouse',
  },
  {
    key: 'Live Theater',
    name: 'map_icon_culture_theater',
  },
  {
    key: 'Lodge',
    name: 'map_icon_restaurantshotels_lodge',
  },
  {
    key: 'Lodging',
    name: 'map_icon_restaurantshotels_lodge',
  },
  {
    key: 'Man Overboard',
    name: 'map_icon_events_rescue',
  },
  {
    key: 'Marina',
    name: 'map_icon_tourism_marina',
  },
  {
    key: 'Medical Facility',
    name: 'map_icon_healthedu_hospital',
  },
  {
    key: 'Mile Marker',
    name: 'map_icon_transport_milemarker',
  },
  {
    key: 'Military',
    name: 'map_icon_offices_military',
  },
  {
    key: 'Mine',
    name: 'map_icon_industry_mine',
  },
  {
    key: 'Movie Theater',
    name: 'map_icon_culture_movie_theater',
  },
  {
    key: 'Museum',
    name: 'map_icon_culture_museum',
  },
  {
    key: 'Mushroom',
    name: 'map_icon_nature_mushroom',
  },
  {
    key: 'Non-directional Beacon',
    name: 'map_icon_tourism_beacon',
  },
  {
    key: 'Null',
    name: 'map_icon_number_blue_0',
  },
  {
    key: 'Number 0, Blue',
    name: 'map_icon_number_blue_0',
  },
  {
    key: 'Number 0, Green',
    name: 'map_icon_number_green_0',
  },
  {
    key: 'Number 0, Red',
    name: 'map_icon_number_red_0',
  },
  {
    key: 'Number 1, Blue',
    name: 'map_icon_number_blue_1',
  },
  {
    key: 'Number 1, Green',
    name: 'map_icon_number_green_1',
  },
  {
    key: 'Number 1, Red',
    name: 'map_icon_number_red_1',
  },
  {
    key: 'Number 2, Blue',
    name: 'map_icon_number_blue_2',
  },
  {
    key: 'Number 2, Green',
    name: 'map_icon_number_green_2',
  },
  {
    key: 'Number 2, Red',
    name: 'map_icon_number_red_2',
  },
  {
    key: 'Number 3, Blue',
    name: 'map_icon_number_blue_3',
  },
  {
    key: 'Number 3, Green',
    name: 'map_icon_number_green_3',
  },
  {
    key: 'Number 3, Red',
    name: 'map_icon_number_red_3',
  },
  {
    key: 'Number 4, Blue',
    name: 'map_icon_number_blue_4',
  },
  {
    key: 'Number 4, Green',
    name: 'map_icon_number_green_4',
  },
  {
    key: 'Number 4, Red',
    name: 'map_icon_number_red_4',
  },
  {
    key: 'Number 5, Blue',
    name: 'map_icon_number_blue_5',
  },
  {
    key: 'Number 5, Green',
    name: 'map_icon_number_green_5',
  },
  {
    key: 'Number 5, Red',
    name: 'map_icon_number_red_5',
  },
  {
    key: 'Number 6, Blue',
    name: 'map_icon_number_blue_6',
  },
  {
    key: 'Number 6, Green',
    name: 'map_icon_number_green_6',
  },
  {
    key: 'Number 6, Red',
    name: 'map_icon_number_red_6',
  },
  {
    key: 'Number 7, Blue',
    name: 'map_icon_number_blue_7',
  },
  {
    key: 'Number 7, Green',
    name: 'map_icon_number_green_7',
  },
  {
    key: 'Number 7, Red',
    name: 'map_icon_number_red_7',
  },
  {
    key: 'Number 8, Blue',
    name: 'map_icon_number_blue_8',
  },
  {
    key: 'Number 8, Green',
    name: 'map_icon_number_green_8',
  },
  {
    key: 'Number 8, Red',
    name: 'map_icon_number_red_8',
  },
  {
    key: 'Number 9, Blue',
    name: 'map_icon_number_blue_9',
  },
  {
    key: 'Number 9, Green',
    name: 'map_icon_number_green_9',
  },
  {
    key: 'Number 9, Red',
    name: 'map_icon_number_red_9',
  },
  {
    key: 'Oil Field',
    name: 'map_icon_industry_oil_field',
  },
  {
    key: 'Open 24 Hours',
    name: 'map_icon_stores_nontop',
  },
  {
    key: 'Oval',
    name: 'map_shape_oval_blue',
  },
  {
    key: 'Oval, Blue',
    name: 'map_shape_oval_blue',
  },
  {
    key: 'Oval, Green',
    name: 'map_shape_oval_green',
  },
  {
    key: 'Oval, Red',
    name: 'map_shape_oval_red',
  },
  {
    key: 'Parachute Area',
    name: 'map_icon_sports_parachute',
  },
  {
    key: 'Park',
    name: 'map_icon_tourism_park',
  },
  {
    key: 'Parking Area',
    name: 'map_icon_transport_parking',
  },
  {
    key: 'Pharmacy',
    name: 'map_icon_healthedu_pharmacy',
  },
  {
    key: 'Picnic Area',
    name: 'map_icon_tourism_picnic',
  },
  {
    key: 'Pin',
    name: 'map_marker_push_pin_2_straight_azure',
  },
  {
    key: 'Pin, Blue',
    name: 'map_marker_push_pin_2_straight_azure',
  },
  {
    key: 'Pin, Green',
    name: 'map_marker_push_pin_2_straight_chartreuse',
  },
  {
    key: 'Pin, Red',
    name: 'map_marker_push_pin_2_straight_red',
  },
  {
    key: 'Pizza',
    name: 'map_icon_restaurantshotels_pizza',
  },
  {
    key: 'Police Station',
    name: 'map_icon_offices_police',
  },
  {
    key: 'Post Office',
    name: 'map_icon_offices_post_office',
  },
  {
    key: 'Private Field',
    name: 'map_icon_transport_airport',
  },
  {
    key: 'RV Park',
    name: 'map_icon_tourism_rv_park',
  },
  {
    key: 'Radio Beacon',
    name: 'map_icon_industry_radio_station',
  },
  {
    key: 'Railway',
    name: 'map_icon_transport_train',
  },
  {
    key: 'Ramp intersection',
    name: 'map_icon_transport_intersection',
  },
  {
    key: 'Rectangle',
    name: 'map_shape_rectangle_blue',
  },
  {
    key: 'Rectangle, Blue',
    name: 'map_shape_rectangle_blue',
  },
  {
    key: 'Rectangle, Green',
    name: 'map_shape_rectangle_green',
  },
  {
    key: 'Rectangle, Red',
    name: 'map_shape_rectangle_red',
  },
  {
    key: 'Residence',
    name: 'map_icon_people_house',
  },
  {
    key: 'Restaurant',
    name: 'map_icon_restaurantshotels_restaurant',
  },
  {
    key: 'Restricted Area',
    name: 'map_icon_transport_accesdenied',
  },
  {
    key: 'Restroom',
    name: 'map_icon_offices_restroom',
  },
  {
    key: 'Scales',
    name: 'map_icon_offices_court',
  },
  {
    key: 'Scenic Area',
    name: 'map_icon_nature_scenic',
  },
  {
    key: 'School',
    name: 'map_icon_healthedu_school',
  },
  {
    key: 'Seafood',
    name: 'map_icon_restaurantshotels_seafood',
  },
  {
    key: 'Seaplane Base',
    name: 'map_icon_transport_plane',
  },
  {
    key: 'Shelter',
    name: 'map_icon_tourism_cabin',
  },
  {
    key: 'Shipwreck',
    name: 'map_icon_industry_shipwreck',
  },
  {
    key: 'Shopping Center',
    name: 'map_icon_stores_shopping_center',
  },
  {
    key: 'Short Tower',
    name: 'map_icon_industry_radio_station_short',
  },
  {
    key: 'Shower',
    name: 'map_icon_offices_shower',
  },
  {
    key: 'Ski Resort',
    name: 'map_icon_sports_ski',
  },
  {
    key: 'Skiing Area',
    name: 'map_icon_sports_ski',
  },
  {
    key: 'Skull and Crossbones',
    name: 'map_icon_events_danger_skull',
  },
  {
    key: 'Small City',
    name: 'map_icon_tourism_city_small',
  },
  {
    key: 'Small Game',
    name: 'map_icon_nature_rodent',
  },
  {
    key: 'Snowmobile',
    name: 'map_icon_sports_snowmobiling',
  },
  {
    key: 'Soft Field',
    name: 'map_icon_transport_airport',
  },
  {
    key: 'Square',
    name: 'map_shape_square_blue',
  },
  {
    key: 'Square, Blue',
    name: 'map_shape_square_blue',
  },
  {
    key: 'Square, Green',
    name: 'map_shape_square_green',
  },
  {
    key: 'Square, Red',
    name: 'map_shape_square_red',
  },
  {
    key: 'Stadium',
    name: 'map_icon_sports_stadium',
  },
  {
    key: 'Star',
    name: 'map_icon_tourism_star',
  },
  {
    key: 'State hwy',
    name: 'map_icon_transport_highway',
  },
  {
    key: 'Steak',
    name: 'map_icon_restaurantshotels_steak',
  },
  {
    key: 'Stop Sign',
    name: 'map_icon_transport_stop',
  },
  {
    key: 'Street Intersection',
    name: 'map_icon_transport_intersection',
  },
  {
    key: 'Stump',
    name: 'map_icon_nature_stump',
  },
  {
    key: 'Summit',
    name: 'map_icon_nature_summit',
  },
  {
    key: 'Swimming Area',
    name: 'map_icon_sports_swimming',
  },
  {
    key: 'Tall Tower',
    name: 'map_icon_industry_radio_station',
  },
  {
    key: 'Telephone',
    name: 'map_icon_offices_telephone',
  },
  {
    key: 'Theater',
    name: 'map_icon_culture_theater',
  },
  {
    key: 'Tide/Current Prediction Station',
    name: 'map_icon_industry_water',
  },
  {
    key: 'Toll Booth',
    name: 'map_icon_transport_toll_booth',
  },
  {
    key: 'TracBack Point',
    name: 'map_icon_tourism_footprint',
  },
  {
    key: 'Trail Head',
    name: 'map_icon_sports_trail_head',
  },
  {
    key: 'Trailhead',
    name: 'map_icon_sports_trail_head',
  },
  {
    key: 'Tree Stand',
    name: 'map_icon_nature_tree',
  },
  {
    key: 'Tree',
    name: 'map_icon_nature_tree',
  },
  {
    key: 'Treed Quarry',
    name: 'map_icon_nature_tree',
  },
  {
    key: 'Triangle',
    name: 'map_shape_triangle_blue',
  },
  {
    key: 'Triangle, Blue',
    name: 'map_shape_triangle_blue',
  },
  {
    key: 'Triangle, Green',
    name: 'map_shape_triangle_green',
  },
  {
    key: 'Triangle, Red',
    name: 'map_shape_triangle_red',
  },
  {
    key: 'Truck Stop',
    name: 'map_icon_transport_truck',
  },
  {
    key: 'Truck',
    name: 'map_icon_transport_truck',
  },
  {
    key: 'Tunnel',
    name: 'map_icon_transport_tunnel',
  },
  {
    key: 'U Marina',
    name: 'map_icon_tourism_marina',
  },
  {
    key: 'U Stump',
    name: 'map_icon_nature_stump',
  },
  {
    key: 'US hwy',
    name: 'map_icon_transport_highway',
  },
  {
    key: 'Ultralight Area',
    name: 'map_icon_transport_plane',
  },
  {
    key: 'Upland Game',
    name: 'map_icon_nature_grouse',
  },
  {
    key: 'Water Hydrant',
    name: 'map_icon_offices_fire_hydrant',
  },
  {
    key: 'Water Skiing',
    name: 'map_icon_sports_water_skiing',
  },
  {
    key: 'Water Source',
    name: 'map_icon_nature_water',
  },
  {
    key: 'Waterfowl',
    name: 'map_icon_nature_duck',
  },
  {
    key: 'Weed Bed',
    name: 'map_icon_nature_weed_bed',
  },
  {
    key: 'Wind Turbine',
    name: 'map_icon_industry_windturbine',
  },
  {
    key: 'Winery',
    name: 'map_icon_restaurantshotels_winebar',
  },
  {
    key: 'Wrecker',
    name: 'map_icon_transport_car_repair',
  },
  {
    key: 'XSki',
    name: 'map_icon_sports_ski',
  },
  {
    key: 'Zoo',
    name: 'map_icon_nature_zoo',
  },
]
