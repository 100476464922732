import { useEffect, useRef, useState } from 'react'

import { NON_ROUTING_CONTROL_SETTINGS } from 'config/constants'
import { Polyline as LPolyline } from 'leaflet'
import 'leaflet-arrowheads'
import { ArrowheadOptions } from 'leaflet-arrowheads'
import { Polyline, PolylineProps, useMap, useMapEvents } from 'react-leaflet'

interface PolylineWithArrowheadsProps extends PolylineProps {
  arrowheads?: ArrowheadOptions
  arrowheadsVisibleOverZoomLvl?: number
  disabledArrowheads: boolean
}

interface PolylineWithArrowheadsInterface extends LPolyline {
  deleteArrowheads: () => void
  _update: () => void
}

export const PolylineWithArrowheads = (props: PolylineWithArrowheadsProps) => {
  const map = useMap()
  const polyRef = useRef<PolylineWithArrowheadsInterface>(null)
  const shouldArrowheadsBeVisible = (currentZoomLvl: number) =>
    currentZoomLvl >= (props.arrowheadsVisibleOverZoomLvl || NON_ROUTING_CONTROL_SETTINGS.arrowheadsVisibleOverZoomLvl)
  const [isVisible, setIsVisible] = useState(shouldArrowheadsBeVisible(map.getZoom()))

  useMapEvents({
    zoomend: () => {
      const polyline = polyRef.current
      const shouldBeVisible = shouldArrowheadsBeVisible(map.getZoom())
      if (polyline && isVisible && !shouldBeVisible) {
        // done to run deleteArrowheads() before component rerender, otherwise it causes an ugly visual effect
        // where arrowheads redraw and are deleted right away
        // deleteArrowHeads() gets also called twice during zoom out
        // 1. here directly
        // 2. in a useEffect cleanup function
        // deleteArrowheads() does a check if arrowheads are present and returns from the deleteArrowheads right away if not
        polyline.deleteArrowheads()
      }
      setIsVisible(shouldBeVisible)
    },
  })

  useEffect(() => {
    const polyline = polyRef.current

    if (polyline && props.arrowheads && isVisible && !props.disabledArrowheads) {
      polyline.arrowheads(props.arrowheads)
       
      polyline._update()
    }

    return () => {
      if (polyline) {
        polyline.deleteArrowheads()
      }
    }
  }, [isVisible, props.arrowheads, props.disabledArrowheads])

  return (
    <Polyline {...props} ref={polyRef}>
      {props.children}
    </Polyline>
  )
}
