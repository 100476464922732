import { ActionType, OptionsActions } from "./actions";
import {
  initialOptionsState,
  NonSaveableOptions,
  SaveableOptions,
} from "./state";

export const optionsReducer = (
  state: SaveableOptions & NonSaveableOptions,
  action: OptionsActions
): SaveableOptions & NonSaveableOptions => {
  switch (action.type) {
    case ActionType.UPDATE_SAVEABLE:
      return { ...state, ...action.payload };
    case ActionType.UPDATE_NON_SAVEABLE:
      return { ...state, ...action.payload };
    case ActionType.RESET:
      return initialOptionsState;
    default:
      throw new Error("Invalid action at optionsReducer");
  }
};
