import { useCallback, useEffect, useState } from 'react'

import { getOverlayPreview } from 'assets/overlayPreviews'
import { MapLayerPickerPart } from 'components/LeafletMap/MapControls/MapLayerPicker'
import { getTangramLayerMeta, isTangramLayer } from 'components/LeafletMap/TangramTileLayer'
import { getGenericOverlayLabels, getOverlayLabels, Overlay } from 'config/enums/overlays'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'

type OverlayOptions = {
  label: string
  value: Overlay
}[]

export const MapOverlayPicker = () => {
  const [overlayOptions, setOverlayOptions] = useState<OverlayOptions>([])
  const { user } = useAuth()
  const { state, toggleOverlay } = useOptions()
  const { t } = useTranslation()

  useEffect(() => {
    if (isTangramLayer(state.mapType)) {
      const tangramLayerMeta = getTangramLayerMeta(state.mapType)
      if (tangramLayerMeta?.extraSupportedOverlays) {
        const extraLabels = getOverlayLabels(tangramLayerMeta.extraSupportedOverlays)
        setOverlayOptions([...getGenericOverlayLabels(), ...extraLabels])
        return
      }
      setOverlayOptions(getGenericOverlayLabels)
    }
  }, [state.mapType, state.mapOverlays, user])

  const handleOverlaySelect = useCallback(
    (value: Overlay) => {
      toggleOverlay(value)
    },
    [toggleOverlay]
  )

  return (
    <MapLayerPickerPart title={t('map_layer_picker.overlay_type_heading')}>
      <MapLayerPickerPart.Grid>
        {overlayOptions.map((option, index) => {
          const imageSrc = getOverlayPreview(option.value, state.mapType)

          return (
            <MapLayerPickerPart.Card
              key={index}
              label={option.label}
              value={option.value}
              imageSrc={imageSrc}
              onClick={handleOverlaySelect}
              selected={state?.mapOverlays?.includes(option.value)}
            />
          )
        })}
      </MapLayerPickerPart.Grid>
    </MapLayerPickerPart>
  )
}
