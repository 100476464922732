import { useState } from "react";

import { Button, NumberInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { useTranslation } from "react-i18next";
import { showErrorNotification } from "utils/notifications/customNotifications";

export type CoordsInputModalProps = {
  callback: () => void;
};

type FormValues = {
  lat: number | undefined;
  lng: number | undefined;
};

export const CoordsInputModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<CoordsInputModalProps>) => {
  const { createWaypoint } = useUserWaypoints();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    initialValues: { lat: undefined, lng: undefined },
    validateInputOnChange: true,
    validate: {
      lat: (value: number | undefined) => {
        if (!value) return null;
        if (value < -90) return t("coords_input_modal.errors.latitude_too_low");
        if (value > 90) return t("coords_input_modal.errors.latitude_too_high");
        return null;
      },
      lng: (value: number | undefined) => {
        if (!value) return null;
        if (value < -180)
          return t("coords_input_modal.errors.longitude_too_low");
        if (value > 180)
          return t("coords_input_modal.errors.longitude_too_high");
        return null;
      },
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      await createWaypoint(
        { point: [values.lat as number, values.lng as number] },
        { focus: true }
      );
      context.closeModal(id);
      innerProps.callback();
    } catch (error) {
      setLoading(false);
      showErrorNotification({ message: t("errors.cannot_create_waypoint") });
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Stack align="center">
        <NumberInput
          hideControls
          label="Latitude"
          placeholder="51.538231"
          precision={8}
          min={-90}
          max={90}
          required
          data-autofocus
          sx={{ width: "100%" }}
          {...form.getInputProps("lat")}
        />
        <NumberInput
          hideControls
          label="Longitude"
          placeholder="16.261123"
          precision={8}
          min={-180}
          max={180}
          sx={{ width: "100%" }}
          required
          {...form.getInputProps("lng")}
        />
        <Button fullWidth type="submit" loading={loading}>
          {t("coords_input_modal.place_waypoint_button_label")}
        </Button>
      </Stack>
    </form>
  );
};
