import { useCallback, useEffect, useMemo, useState } from 'react'

import { GuideMarkers, PointData } from 'components/LeafletMap/Measurement/GuideMarkers'
import { MeasurementGuidePolyline } from 'components/LeafletMap/Measurement/MeasurementGuidePolyline'
import { MeasurementPolyline } from 'components/LeafletMap/Measurement/MeasurementPolyline'
import { MouseLabelMarker } from 'components/LeafletMap/Measurement/MouseLabelMarker'
import { SegmentData, SegmentMarkers } from 'components/LeafletMap/Measurement/SegmentMarkers'
import { MarkerWithIndex } from 'components/LeafletMap/TrackpointControls/components/MarkerWithIndex'
import { useEditMode } from 'hooks/useEditMode'
import { LatLng, LeafletEventHandlerFnMap } from 'leaflet'
import * as L from 'leaflet'
import { useMapEvents, Pane, Marker, useMap } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { Coords } from 'types/app'
import { formatDistance } from 'utils/formatters/formatDistance'

type Guide = {
  start: Coords
  end: Coords
  length: number
}

const invisibleMarkerIcon = L.divIcon({
  iconAnchor: [100, 100],
  iconSize: [200, 200],
})

const draggableMarkerIcon = L.divIcon({
  html: '<div style="width:100%;height:100%"/>',
  className: '',
  iconSize: [18, 18],
  iconAnchor: [9, 9],
})

const MeasurementContainer = () => {
  const [points, setPoints] = useState<Coords[]>([])
  const [pointsData, setPointsData] = useState<PointData[]>([])
  const [segmentData, setSegmentData] = useState<SegmentData[]>([])
  const [isMarkerBeingDragged, setIsMarkerBeingDragged] = useState(false)
  const [guide, setGuide] = useState<Guide | null>(null)
  const {
    state: { units },
  } = useOptions()
  const map = useMap()

  const updatePoint = (index: number, value: Coords) => {
    setPoints((prevPoints) => [...prevPoints.slice(0, index), value, ...prevPoints.slice(index + 1)])
  }

  const removePoint = (index: number) => {
    setPoints((prevPoints) => [...prevPoints.slice(0, index), ...prevPoints.slice(index + 1)])
  }

  const addPoint = (coords: Coords) => {
    setPoints((prev) => [...prev, coords])
  }

  useMapEvents({
    click: (e) => {
      const coords: Coords = [e.latlng.lat, e.latlng.lng]
      setGuide(null)
      addPoint(coords)
    },
    mousemove: (e) => {
      const lastPoint = points.at(-1)
      if (lastPoint) {
        const length = L.latLng(lastPoint).distanceTo(e.latlng)
        setGuide({ start: lastPoint, end: [e.latlng.lat, e.latlng.lng], length })
      }
    },
  })

  useEffect(() => {
    let totalDistance = 0
    const pointsWithDistances = points.map((point, index, array) => {
      const previousPoint = array[index - 1] || undefined
      const distanceFromPrevious = previousPoint ? L.latLng(previousPoint).distanceTo(L.latLng(point)) : null
      if (distanceFromPrevious) {
        totalDistance += distanceFromPrevious
      }
      return { distanceFromPrevious, totalDistance, point }
    })
    setPointsData(pointsWithDistances)
  }, [points])

  useEffect(() => {
    const segments = points.reduce<[Coords, Coords][]>((accumulator, point, index, array) => {
      const prevPoint = array[index - 1]
      if (prevPoint) {
        return [...accumulator, [prevPoint, point]]
      }
      return accumulator
    }, [])

    const calculatedSegmentData: SegmentData[] = segments.map((segment) => {
      const start = segment[0]
      const end = segment[1]

      const centerLat = (start[0] + end[0]) / 2
      const centerLng = (start[1] + end[1]) / 2

      const startLatLng = L.latLng(start)
      const endLatLng = L.latLng(end)

      const centerLatLng = L.latLng([centerLat, centerLng])
      const distance = startLatLng.distanceTo(endLatLng)

      const angleFromNorth = L.GeometryUtil.angle(map, startLatLng, endLatLng)

      return {
        center: centerLatLng,
        distance,
        angleFromNorth,
      }
    })
    setSegmentData(calculatedSegmentData)
  }, [map, points])

  const eventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      dragstart: () => {
        setIsMarkerBeingDragged(true)
        setGuide(null)
      },
      drag: (e) => {
        const latlng: LatLng = e.target.getLatLng()
        const coords: Coords = [latlng.lat, latlng.lng]
        const index = e.target?.options?.markerIndex as number
        updatePoint(index, coords)
      },
      dragend: () => setIsMarkerBeingDragged(false),
      click: (e) => {
        const index = e.target?.options?.markerIndex as number
        removePoint(index)
      },
    }),
    []
  )
  // invisible marker is placed under cursor to prevent stutter
  return (
    <>
      <Pane name="guides" style={{ zIndex: 920 }}>
        <>
          {points.length > 1 && <MeasurementPolyline positions={points} />}
          {guide && !isMarkerBeingDragged && <MeasurementGuidePolyline positions={[guide.start, guide.end]} />}
        </>
      </Pane>

      <Pane name="guide-markers" style={{ zIndex: 940 }}>
        <GuideMarkers pointsData={pointsData} />
        <SegmentMarkers segmentData={segmentData} />
        {guide && !isMarkerBeingDragged && (
          <MouseLabelMarker position={guide.end} text={formatDistance(guide.length, units, 3)} zIndexOffset={1500} />
        )}
      </Pane>

      <Pane name="guide-invisible-marker" style={{ zIndex: 990 }}>
        {/* TODO (Peter): Disable autopanning once upgraded to leaflet 1.8 */}
        {guide && <Marker interactive={true} position={guide.end} icon={invisibleMarkerIcon} opacity={0} />}
      </Pane>

      <Pane name="draggable-invisible-markers" style={{ zIndex: 1000 }}>
        {pointsData.map((point, index) => (
          <MarkerWithIndex
            key={index}
            markerIndex={index}
            draggable
            eventHandlers={eventHandlers}
            position={point.point}
            icon={draggableMarkerIcon}
          />
        ))}
      </Pane>
    </>
  )
}

export const MeasurementWrapper = () => {
  const { editMode, setEditMode } = useEditMode()
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') setEditMode('default')
    },
    [setEditMode]
  )

  useEffect(() => {
    if (editMode === 'measurement') window.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [editMode, handleKeyPress])

  return editMode === 'measurement' ? <MeasurementContainer /> : null
}
