import { FormEvent, useState } from 'react'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Button, Center, Loader, Popover, Stack, TextInput } from '@mantine/core'
import { Tooltip } from 'components/UI/Tooltip'
import { Routing } from 'config/enums/routings'
import { useAuth } from 'hooks/useAuth'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { getErrorMsg } from 'utils/getErrorMsg/getErrorMsg'

export const SaveIndicatorIcon = () => {
  const { user } = useAuth()
  const [isSavePopoverOpen, setIsSavePopoverOpen] = useState(false)
  const { selectedTrack, createTrack, trackStatus } = useUserTracks()
  const [newTrackName, setNewTrackName] = useState('')
  const {
    state: { trackWaypoints, routing, trackColor, elevations, computedTrackpoints, trackMeta },
    sharedTrack,
  } = useRouting()
  const { openErrorModal } = useGenericModals()
  const { t } = useTranslation()
  const haveSaveableTrackwaypoints = trackWaypoints.length > 1

  const handleNewTrackSave = async () => {
    const isRoutable = routing !== Routing.none

    try {
      // TODO (Peter): handle elevations being loaded
      await createTrack({
        controlPoints: !isRoutable ? [] : trackWaypoints,
        trackPoints: computedTrackpoints,
        color: trackColor || undefined,
        name: newTrackName.length > 0 ? newTrackName : 'Track',
        trackPointsEle: elevations,
        trackPointsSpeed: trackMeta.trackPointsSpeed ?? sharedTrack?.trackPointsSpeed ?? undefined,
        trackPointsTime: trackMeta.trackPointsTime ?? sharedTrack?.trackPointsTime ?? undefined,
        ...(sharedTrack !== null && !isRoutable
          ? {
              trackPointsCadence: sharedTrack.trackPointsCadence,
              trackPointsHeartRate: sharedTrack.trackPointsHeartRate,
              trackPointsPower: sharedTrack.trackPointsPower,
              trackPointsTemperature: sharedTrack.trackPointsTemperature,
            }
          : {}),
        links: [],
        routing,
      })
    } catch (error) {
      const msg = getErrorMsg(error)
      openErrorModal({ title: 'Error saving new track', text: msg ?? '' })
    }
  }

  const handleSaveTrackSubmit = async (e: FormEvent) => {
    setIsSavePopoverOpen(false)
    e.preventDefault()
    await handleNewTrackSave()
    setNewTrackName('')
  }

  const getSaveTrackButtonLabel = () => {
    if (!user) return t('editmode.save_indicator.tooltips.login_to_enable_saving')
    if (trackStatus === 'saving') return t('editmode.save_indicator.tooltips.saving_track')
    if (trackStatus === 'saved' && !haveSaveableTrackwaypoints)
      return t('editmode.save_indicator.tooltips.not_enough_trackpoints')
    if (trackStatus === 'saved') return t('editmode.save_indicator.tooltips.saved')
    return t('editmode.save_indicator.tooltips.save')
  }

  return (
    <Popover
      opened={isSavePopoverOpen}
      onClose={() => setIsSavePopoverOpen(false)}
      position="bottom"
      transition="pop-top-left"
      styles={{ dropdown: { border: 'none' } }}
      withinPortal
      trapFocus
    >
      <Popover.Target>
        <ActionIcon
          mx={4}
          variant="light"
          radius={selectedTrack ? 100 : 'sm'}
          sx={{
            transition: 'border-radius 2s ease-in-out',
            ...(selectedTrack && { transform: 'none!important' }),
            cursor: selectedTrack ? 'default' : 'pointer',
          }}
          color={selectedTrack && trackStatus === 'saved' ? 'teal' : 'primaryColor'}
          onClick={() => {
            if (!selectedTrack) setIsSavePopoverOpen((prev) => !prev)
          }}
          disabled={!haveSaveableTrackwaypoints || !user}
        >
          <Tooltip label={getSaveTrackButtonLabel()}>
            <Center sx={{ width: '100%', height: '100%' }}>
              {trackStatus === 'saving' ? <Loader size="xs" /> : <FontAwesomeIcon icon={faSave} />}
            </Center>
          </Tooltip>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <div>
          <form onSubmit={handleSaveTrackSubmit}>
            <Stack spacing={4}>
              <TextInput
                data-autofocus
                onChange={(e) => setNewTrackName(e.currentTarget.value)}
                size="xs"
                placeholder={t('editmode.save_indicator.name_input.placeholder')}
              />
              <Button type="submit" color="teal" size="xs">
                {t('generic.save_button_label')}
              </Button>
            </Stack>
          </form>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
