import { useEffect, useState } from 'react'

import { getOS, OperatingSystem } from 'utils/detectOS/detectOS'

export const useDetectOS = () => {
  const [os, setOs] = useState<OperatingSystem | null>(null)
  useEffect(() => {
    const operatingSystem = getOS()
    setOs(operatingSystem)
  }, [])

  return { os }
}
