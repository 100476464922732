import { Polyline, PolylineProps } from 'react-leaflet'

export const MeasurementGuidePolyline = ({ positions, ...rest }: PolylineProps) => (
  <>
    <Polyline
      interactive={false}
      positions={positions}
      pathOptions={{ color: 'white', opacity: 1, weight: 5 }}
      dashArray={[4, 8]}
      {...rest}
    />
    <Polyline
      interactive={false}
      positions={positions}
      pathOptions={{ color: '#F06595', opacity: 1, weight: 3 }}
      dashArray={[4, 8]}
    />
  </>
)
