import { t } from "i18next";

export enum MapID {
  basic,
  bubbleWrap,
  cinnabar,
  refill,
  street,
  topo,
  tron,
  walkabout,
  openStreetMap,
  openTopoMap,
  cyclOSM,
  satellite,
}

export const premiumMapIds = new Map([
  [MapID.satellite, t("subscription_modal.satellite_map")],
]);
