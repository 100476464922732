import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

// Important to keep string references to prevent colision with original reducer, because the original reducer can use numbers as action.type
enum UndoAction {
  UNDO = '__undo__',
  REDO = '__redo__',
  CLEAR_HISTORY = '__CLEAR_HISTORY__',
}

interface Undo {
  value: { type: UndoAction.UNDO }
  createUndoSlice: boolean
}
interface Redo {
  value: { type: UndoAction.REDO }
  createUndoSlice: boolean
}

interface ClearHistory {
  value: { type: UndoAction.CLEAR_HISTORY }
  createUndoSlice: boolean
}

const undoAction = (): Undo => ({
  value: { type: UndoAction.UNDO },
  createUndoSlice: false,
})
const redoAction = (): Redo => ({
  value: { type: UndoAction.REDO },
  createUndoSlice: false,
})

const clearHistoryAction = (): ClearHistory => ({
  value: { type: UndoAction.CLEAR_HISTORY },
  createUndoSlice: false,
})

type UndoActions = Undo | Redo | ClearHistory

export interface UndoReducerAction {
  type: unknown
  payload?: unknown
  createUndoSlice?: boolean
}

export type StateHistoryType<S> = {
  past: S[]
  present: S
  future: S[]
}

type ExtendedAction<A> = {
  value: A | A[]
  createUndoSlice: boolean
}

type UseUndoReducer<S, A extends UndoReducerAction> = {
  state: StateHistoryType<S>
  dispatch: (value: DispatchActionValue<S, A>, createUndoSlice?: boolean) => void
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
  clearHistory: () => void
}

export type PresentStateGetter<S> = () => StateHistoryType<S>['present']

export type DispatchActionValue<S, A extends UndoReducerAction> =
  | A
  | A[]
  | ((getState: PresentStateGetter<S>) => A | A[])

export const useUndoReducer = <S, A extends UndoReducerAction>(
  reducer: (state: S, action: A) => S,
  initialState: S
): UseUndoReducer<S, A> => {
  const initialUndoState: StateHistoryType<S> = {
    past: [],
    present: initialState,
    future: [],
  }

  const undoReducer = useMemo(
    () => (state: StateHistoryType<S>, action: ExtendedAction<A> | UndoActions) => {
      const shouldCreateUndoSlice = action.createUndoSlice
      // undoreducer allows passing of arrays of actions
      // useful for creating just one history slice with multiple merged actions

      // handle array of actions
      if (Array.isArray(action.value)) {
        const newPresent = action.value.reduce((acc, currentAction) => reducer(acc, currentAction), state.present)

        if (shouldCreateUndoSlice) {
          return {
            past: [state.present, ...state.past],
            present: newPresent,
            future: [],
          }
        }
        return {
          past: state.past,
          present: newPresent,
          future: state.future,
        }
      }

      const canUndo = state.past.length !== 0
      const canRedo = state.future.length !== 0

      // handle single action except UNDO and REDO
      if (
        action.value.type !== UndoAction.UNDO &&
        action.value.type !== UndoAction.REDO &&
        action.value.type !== UndoAction.CLEAR_HISTORY
      ) {
        // action casted as A, because the Undo action type should never appear in this block
        const newPresent = reducer(state.present, action.value as A)
        if (shouldCreateUndoSlice) {
          return {
            past: [state.present, ...state.past],
            present: newPresent,
            future: [],
          }
        }
        return {
          past: state.past,
          present: newPresent,
          future: state.future,
        }
      }

      if (action.value.type === UndoAction.UNDO && canUndo) {
        const [newPresentFromPast, ...past] = state.past
        return {
          past,
          present: newPresentFromPast,
          future: [state.present, ...state.future],
        }
      }
      if (action.value.type === UndoAction.REDO && canRedo) {
        const [newPresentFromFuture, ...future] = state.future
        return {
          past: [state.present, ...state.past],
          present: newPresentFromFuture,
          future,
        }
      }
      if (action.value.type === UndoAction.CLEAR_HISTORY) {
        return {
          past: [],
          present: state.present,
          future: [],
        }
      }

      // return the previous state as if nothing happened if cannot undo or redo
      return state
    },
    [reducer]
  )

  const [state, dispatch] = useReducer(undoReducer, initialUndoState)
  const presentState = useRef<StateHistoryType<S>['present']>(state.present)

  useEffect(() => {
    presentState.current = state.present
  }, [state])

  const getState = useCallback(() => presentState.current, [presentState])

  // A callback parameter was added later to this function so we can get
  // a copy of our state into an action creator (similar to what Redux does)
  const customDispatch = useCallback(
    (value: DispatchActionValue<StateHistoryType<S>['present'], A>, createUndoSlice = true) => {
      if (typeof value === 'function') {
        dispatch({ value: value(getState), createUndoSlice })
      } else {
        dispatch({
          value,
          createUndoSlice,
        })
      }
    },
    [dispatch, getState]
  )

  const canUndo = state.past.length > 0
  const canRedo = state.future.length > 0
  const undo = useCallback(() => dispatch(undoAction()), [])
  const redo = useCallback(() => dispatch(redoAction()), [])
  const clearHistory = useCallback(() => dispatch(clearHistoryAction()), [])
  return { state, dispatch: customDispatch, undo, redo, canUndo, canRedo, clearHistory }
}
