import { forwardRef } from 'react'

import { ActionIcon, ActionIconProps } from '@mantine/core'
import { CONTROL_BUTTON_SETTINGS } from 'config/constants'
import { useMantineTextColorIndex } from 'hooks/useMantineTextColor'

export const ControlActionIcon = forwardRef<
  HTMLButtonElement,
  ActionIconProps & React.ComponentPropsWithoutRef<'button'>
>(({ children, ...rest }, ref) => {
  const textColor = useMantineTextColorIndex()

  return (
    <ActionIcon ref={ref} variant="transparent" color={textColor} size={CONTROL_BUTTON_SETTINGS.size} {...rest}>
      {children}
    </ActionIcon>
  )
})
ControlActionIcon.displayName = 'ControlActionIcon'
