import { Polyline, PolylineProps } from 'react-leaflet'

type Props = {
  positions: PolylineProps['positions']
  color: string
}
/**
 * Polyline used to display non routable tracks
 */
export const RoutablePolylinePlaceholder = ({ positions, color }: Props) => (
  <>
    <Polyline pathOptions={{ color: 'black', weight: 10, opacity: 0.2 }} positions={positions} />
    <Polyline pathOptions={{ color: 'white', weight: 8, opacity: 0.8 }} positions={positions} />
    <Polyline pathOptions={{ color, weight: 4, opacity: 1 }} positions={positions} />
  </>
)
