import { useCallback } from 'react'

import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Rectangle } from 'cesium'
import { Tooltip } from 'components/UI/Tooltip'
import { useCesiumContext } from 'hooks/useCesiumContext'
import { useEditMode } from 'hooks/useEditMode'
import { useMapContext } from 'hooks/useMapContext'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { expandRectangle } from 'utils/cesium/expandRectangle'
import { getCartesianPointsFromCoordsArray } from 'utils/cesium/getCartesianPointsFromCoordsArray'
import { fitTrackToScreen, fitWaypointToScreen } from 'utils/map/fitToScreen'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

export const FitToScreenIcon = ({ isOpen }: { isOpen: boolean }) => {
  const { map } = useMapContext()
  const {
    state: { computedTrackpoints },
  } = useRouting()
  const { editMode } = useEditMode()
  const { selectedWaypoint } = useUserWaypoints()
  const { t } = useTranslation()
  const { cesium } = useCesiumContext()

  const fitToScreen = useCallback(() => {
    if (map) {
      if (editMode === 'track') {
        fitTrackToScreen(computedTrackpoints, map)
      }
      if (editMode === 'waypoint' && selectedWaypoint) {
        fitWaypointToScreen(selectedWaypoint.point, map)
      }
    } else if (cesium) {
      const cartesianPoints = getCartesianPointsFromCoordsArray(computedTrackpoints)
      const rectangle = cartesianPoints ? expandRectangle(Rectangle.fromCartesianArray(cartesianPoints), 0.5) : null
      if (rectangle) cesium.camera.flyTo({ destination: rectangle })
    }
  }, [cesium, computedTrackpoints, editMode, map, selectedWaypoint])

  return (
    <Tooltip
      label={
        selectedWaypoint
          ? t('editmode.fit_to_screen_button.fit_waypoint_to_view_tooltip')
          : t('editmode.fit_to_screen_button.fit_track_to_view_tooltip')
      }
    >
      <div>
        <CollapsibleTopMenuActionIcon isOpen={isOpen} onClick={fitToScreen}>
          <FontAwesomeIcon icon={faExpand} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  )
}
