import { Waypoint, WaypointRaw } from 'types/app'
import { decodePolyline, encodePolyline } from 'utils/polyline'

import { parseLinksArrayToString, parseLinksStringToArray } from '../linksParser/linksParser'

export interface WaypointSave extends Omit<WaypointRaw, 'userId' | 'ele' | 'createdAt' | 'updatedAt' | 'localTime'> {}

export const parseWaypointForAPI = ({
  links,
  id,
  name,
  desc,
  cmt,
  src,
  type,

  icon,
  point,
}: Partial<Waypoint>): WaypointSave => ({
  links: links && links.length > 0 ? parseLinksArrayToString(links) : '',
  id: id ?? -1,
  name: name ?? '',
  desc: desc ?? '',
  cmt: cmt ?? '',
  src: src ?? '',
  type: type ?? '',
  point: point ? encodePolyline([point], 6) : '',
  icon: icon ?? '',
})
export const parseWaypointFromAPI = ({
  id,
  name,
  desc,
  links,
  userId,
  cmt,
  src,
  type,
  createdAt,
  ele,
  icon,
  localTime,
  point,
  updatedAt,
}: WaypointRaw): Waypoint => ({
  links: parseLinksStringToArray(links),
  id,
  name,
  desc,
  userId,
  cmt,
  src,
  type,
  point: decodePolyline(point)[0]!,
  ele: ele ? Number(ele) : 0,
  localTime,
  createdAt,
  icon,
  updatedAt,
})
