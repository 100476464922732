import { Rectangle } from 'cesium'

/**
 *
 * @param rectangle
 * @param ratio 0 --> no change, 1.3 --> expand by 130%, -0.2 --> shrink by 20%
 * @returns
 */
export const expandRectangle = (rectangle: Rectangle, ratio: number) => {
  const expandedRectangle = rectangle.clone()
  const { width, height } = expandedRectangle
  expandedRectangle.west -= width * ratio
  expandedRectangle.east += width * ratio
  expandedRectangle.north += height * ratio
  expandedRectangle.south -= height * ratio
  return expandedRectangle
}
