export type OperatingSystem = 'windows' | 'mac' | 'linux' | 'ios' | 'android'

// source https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
export const getOS = (): OperatingSystem | null => {
  const { userAgent, platform } = window.navigator
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.indexOf(platform) !== -1) return 'mac'
  if (iosPlatforms.indexOf(platform) !== -1) return 'ios'
  if (windowsPlatforms.indexOf(platform) !== -1) return 'windows'
  if (/Android/.test(userAgent)) return 'android'
  if (/Linux/.test(platform)) return 'linux'
  return null
}
