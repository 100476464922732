import { ReactNode } from 'react'

import { Paper } from '@mantine/core'
import { CONTROL_BUTTON_SETTINGS } from 'config/constants'

export const MapControlPaper = ({ children }: { children: ReactNode }) => (
  <Paper
    sx={(theme) => ({
      boxShadow: theme.other.controlButtonShadow,
      fontSize: CONTROL_BUTTON_SETTINGS.iconFontSize,
      minWidth: CONTROL_BUTTON_SETTINGS.size,
      minHeight: CONTROL_BUTTON_SETTINGS.size,
    })}
    shadow="xs"
  >
    {children}
  </Paper>
)
