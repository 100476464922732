import { useEffect } from 'react'

import { useDebouncedValue, useElementSize } from '@mantine/hooks'
import { MapViewContainer } from 'containers/Map/MapViewContainer'
import { useMapContext } from 'hooks/useMapContext'

export const MapWrapper = () => {
  const { ref, height, width } = useElementSize()
  const [debouncedHeight] = useDebouncedValue(height, 50)
  const [debouncedWidth] = useDebouncedValue(width, 50)
  const { map } = useMapContext()

  useEffect(() => {
    if (map) map.invalidateSize({ pan: false })
  }, [debouncedHeight, debouncedWidth, map])

  return (
    <div ref={ref} style={{ width: '100%', flexGrow: 1 }}>
      <MapViewContainer />
    </div>
  )
}
