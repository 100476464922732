import { SVGProps } from 'react'

import { omit } from 'lodash'

export const BicycleCrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', ...props.style }}
    {...omit(props, 'style')}
  >
    <g id="Vrstva-1" transform="matrix(1.24363,0,0,1.24363,-62.3942,-73.7987)">
      <g id="g4352">
        <path
          id="path4790"
          d="M51.258,438.029C65.593,438.073 79.928,438.117 92.149,436.238C104.369,434.358 114.475,430.555 123.956,430.795C133.436,431.035 142.29,435.318 149.683,435.797C157.076,436.275 163.009,432.951 169.836,431.512C176.662,430.074 184.381,430.521 190.17,432.086C195.959,433.651 199.819,436.333 208.064,439.015C216.309,441.697 228.94,444.379 237.886,443.485C246.832,442.591 252.095,438.12 257.441,434.696C262.787,431.271 268.215,428.893 275.759,428.669C283.303,428.446 292.961,430.377 300.772,432.461C308.584,434.544 314.548,436.78 323.144,439.553C331.74,442.327 342.967,445.639 351.212,444.298C359.456,442.956 364.719,436.962 373.667,433.741C382.614,430.521 395.244,430.074 405.419,431.862C415.594,433.651 423.313,437.674 432.116,439.71C440.92,441.746 450.807,441.796 460.694,441.846"
          fill="currentColor"
          stroke="currentColor"
          style={{ fillRule: 'nonzero', strokeWidth: '50px' }}
        />
        <g id="g1761-3" transform="matrix(0.169333,0,0,0.169333,47.8775,-286.479)">
          <path
            id="path1016-6"
            d="M1918.58,3015.69C1667.51,3034.51 1470.96,3246.35 1470.96,3498.12C1470.96,3763.52 1689.35,3981.9 1954.74,3981.9C2220.13,3981.9 2438.52,3763.52 2438.52,3498.12C2438.52,3498.12 2438.52,3498.12 2438.52,3498.12C2438.52,3232.73 2220.13,3014.34 1954.74,3014.34C1942.67,3014.34 1930.61,3014.79 1918.58,3015.69ZM1934.4,3226.76C1941.17,3226.25 1947.95,3226 1954.74,3226C2104.02,3226 2226.87,3348.84 2226.87,3498.12C2226.87,3498.12 2226.87,3498.12 2226.87,3498.12C2226.87,3647.41 2104.02,3770.25 1954.74,3770.25C1805.45,3770.25 1682.61,3647.41 1682.61,3498.12C1682.61,3356.5 1793.17,3237.34 1934.4,3226.76Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1020-7"
            d="M467.244,3015.69C216.171,3034.51 19.62,3246.35 19.621,3498.12C19.621,3763.52 238.007,3981.9 503.401,3981.9C768.794,3981.9 987.18,3763.52 987.18,3498.12C987.18,3498.12 987.18,3498.12 987.18,3498.12C987.18,3232.73 768.794,3014.34 503.401,3014.34C491.336,3014.34 479.275,3014.79 467.244,3015.69ZM483.064,3226.76C489.831,3226.25 496.614,3226 503.401,3226C652.685,3226 775.527,3348.84 775.527,3498.12C775.527,3498.12 775.527,3498.12 775.527,3498.12C775.527,3647.41 652.685,3770.25 503.401,3770.25C354.117,3770.25 231.275,3647.41 231.275,3498.12C231.274,3356.5 341.835,3237.34 483.064,3226.76Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1024-5"
            d="M1634.23,2651.51L1400.66,2467.06C1335.27,2415.66 1243.05,2414.91 1177.29,2465.93L863.208,2708.58C747.177,2798.53 755.492,2976.17 879.46,3054.78L1108.12,3199.91L1108.12,3619.06C1108.12,3685.96 1162.17,3740.01 1229.07,3740.01C1295.96,3740.01 1350.01,3685.96 1350.01,3619.06L1350.01,3135.28C1350.01,3094.84 1329.98,3057.05 1296.34,3034.75L1134.58,2927.03L1362.48,2743.73L1516.31,2866.94C1537.85,2883.95 1564.31,2893.39 1591.9,2893.39L1833.79,2893.39C1900.69,2893.39 1954.74,2839.35 1954.74,2772.45C1954.74,2705.55 1900.69,2651.51 1833.79,2651.51L1634.23,2651.51Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1026-3"
            d="M1531.43,2409.62C1630.95,2409.62 1712.85,2327.72 1712.85,2228.2C1712.85,2128.68 1630.95,2046.78 1531.43,2046.78C1431.91,2046.78 1350.01,2128.68 1350.01,2228.2C1350.01,2327.72 1431.91,2409.62 1531.43,2409.62Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </g>
  </svg>
)
