import React, { useCallback, useEffect } from 'react'

import { Box, createStyles, Paper, ScrollArea, Stack, Table, Title, CloseButton } from '@mantine/core'
import { PANEL_TOGGLE_ANIMATION_DURATION } from 'config/constants'
import { LEGENDS } from 'config/legends'
import { useTranslation } from 'react-i18next'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'

const PANEL_WIDTH = 250

const useStyles = createStyles(() => ({
  table: {
    '&>tbody>tr>td': {
      borderBottom: 'none',
    },
  },
}))

// const getLegendPanelColor = () => {

// }

export const LegendPanel = () => {
  const { state, dispatch } = useOptions()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const CurrentLegendBody = LEGENDS[state.mapType]

  const closeLegendPanel = useCallback(() => {
    dispatch(updateNonSaveableOptions({ isLegendOpen: false }))
  }, [dispatch])

  useEffect(() => {
    if (!CurrentLegendBody) closeLegendPanel()
  }, [CurrentLegendBody, closeLegendPanel, state.mapType])

  return CurrentLegendBody ? (
    <Stack
      spacing={4}
      sx={{
        position: 'relative',
        width: PANEL_WIDTH,
        height: '100%',
        marginRight: state.isLegendOpen ? 0 : -PANEL_WIDTH,
        transition: `margin ${PANEL_TOGGLE_ANIMATION_DURATION}s`,
        flexShrink: 0,
        zIndex: 100,
      }}
    >
      <Paper
        shadow="lg"
        p={0}
        radius={0}
        sx={{
          height: '100%',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 10,
          background: state.mapType === 6 ? '#364D6C' : 'transparent',
          color: state.mapType === 6 ? 'white' : 'inherit',
        }}
      >
        <ScrollArea>
          <Box p="sm">
            <Title order={3} pb="sm">
              {t('map_controls.legend_button_tooltip')}
            </Title>
            <Table className={classes.table} sx={{ color: state.mapType === 6 ? 'white' : 'inherit' }}>
              <CurrentLegendBody />
            </Table>
          </Box>
        </ScrollArea>
        <CloseButton sx={{ position: 'absolute', top: 6, right: 6 }} onClick={closeLegendPanel} variant="transparent" />
      </Paper>
    </Stack>
  ) : null
}
