import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import { Routing } from 'config/enums/routings'
import { useGenericModals } from 'hooks/useGenericModals'
import { useTrackStats } from 'hooks/useTrackStats'
import { DispatchActionValue, useUndoReducer } from 'hooks/useUndoReducer'
import { t } from 'i18next'
import { Map } from 'leaflet'
import { omit } from 'lodash'
import { Coords, DeepPartial, RoutingCostingOptions, Track } from 'types/app'
import { fitTrackToScreen } from 'utils/map/fitToScreen'
import { showErrorNotification } from 'utils/notifications/customNotifications'

import {
  changeRoutingType,
  changeShowPlaceholderTrack,
  changeTrackColor,
  clearElevations,
  clearTrackWaypoints,
  rewriteComputedTrackpoints,
  rewriteElevations,
  rewriteTrackmeta,
  rewriteTrackWaypoints,
  RoutingActions,
  setAreElevationsLoading,
  updateNonRoutableTrackMetadata,
  updateRoutingCostingOptions,
} from './actions'
import { RoutingReducer } from './reducer'
import { initialRoutingState, RoutingState } from './state'

const { VITE_ELEVATION_API_URL } = import.meta.env

type RoutingContextType = {
  state: RoutingState
  // RoutingActions is bad naming since there is only one action at a time (as opposed to it being an array, for example)
  dispatch: (action: DispatchActionValue<RoutingState, RoutingActions>, createUndoSlice?: boolean) => void
  /**
   * Contains functions regarding history (undo, redo).
   * Can be upgraded to return number of undo/redo steps or
   * a list with names of previous actions.
   */
  history: {
    /**
     * Undo to the previous state if available
     */
    undo: () => void
    /**
     * Redo to the next state if available
     */
    redo: () => void
    canUndo: boolean
    canRedo: boolean
    /**
     * Clears both future and historical undo slices
     */
    clearHistory: () => void
  }
  changeRouting: (type: Routing, costingOptions?: RoutingCostingOptions, callback?: () => void) => void
  updateCostingOptions: (updatedOptions: DeepPartial<RoutingCostingOptions>) => void
  /**
   * Fetches elevations if provided trackpoints in parameter, otherwise clears elevation data
   */
  fetchElevations: (trackPoints?: Coords[]) => Promise<number[] | void>
  setEditedTrack: (track: Track | null, map?: Map | null, isSharedTrack?: boolean) => void
  clear: () => void
  sharedTrack: Track | null
}

const initialContextState: RoutingContextType = {
  state: initialRoutingState,
  history: { undo: () => null, redo: () => null, canUndo: false, canRedo: false, clearHistory: () => null },
  dispatch: () => null,
  changeRouting: () => null,
  updateCostingOptions: () => null,
  fetchElevations: () => new Promise(() => {}),
  setEditedTrack: () => null,
  clear: () => null,
  sharedTrack: null,
}

export const RoutingContext = React.createContext<RoutingContextType>(initialContextState)
export const useRouting = () => useContext(RoutingContext)

export const RoutingProvider = (props: { children: React.ReactNode }) => {
  const { openConfirmModal } = useGenericModals()
  const { clearTrackStats, setTrackStats } = useTrackStats()
  const { state, dispatch, undo, redo, canUndo, canRedo, clearHistory } = useUndoReducer<RoutingState, RoutingActions>(
    RoutingReducer,
    initialContextState.state
  )
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])
  // This sharedTrack thing is unfortunately a real hackfix but was needed
  // since support for saving data for a shared track was not there - the
  // track's data wasn't being saved anywhere. This shouldn't be here but at the
  // time of implemenation putting it here was a path of least resistance. In the
  // future all track data should be united into one provider and we should just
  // differentiate between the tracks by using a type attribute (public, private, ...).
  // At the time of writing this there is a provider for each type and shared track
  // data is not being saved anywhere.
  const [sharedTrack, setSharedTrack] = useState<Track | null>(null)

  const history = useMemo(
    () => ({
      undo,
      redo,
      canUndo,
      canRedo,
      clearHistory,
    }),
    [canRedo, canUndo, clearHistory, redo, undo]
  )

  useEffect(() => {
    const { computedTrackpoints } = state.present

    if (computedTrackpoints.length < 2) {
      clearTrackStats()
    }
  }, [clearTrackStats, state.present])

  const fetchElevations = useCallback(
    async (trackpoints?: Coords[]): Promise<number[] | void> => {
      if (trackpoints && trackpoints?.length > 1) {
        dispatch(setAreElevationsLoading(true), false)
        try {
          const res = await axios.post(VITE_ELEVATION_API_URL, JSON.stringify(trackpoints))
          const elevationPoints: number[] = res.data
          const trimmed = elevationPoints.map((point) => Number(Math.max(Number(point.toFixed(2)), 0.0)))
          dispatch([rewriteElevations(trimmed), setAreElevationsLoading(false)], false)
          return trimmed
        } catch (error) {
          dispatch([rewriteElevations([]), setAreElevationsLoading(false)], false)
          showErrorNotification({
            title: t('generic.error_title'),
            message: t('errors.unable_to_fetch_elevations.text'),
          })
        }
      } else {
        dispatch([rewriteElevations([]), setAreElevationsLoading(false)], false)
      }
    },
    [dispatch]
  )
  const setEditedTrack = useCallback(
    (track: Track | null, map?: Map | null, isSharedTrack: boolean = false) => {
      if (!track) {
        dispatch(
          [rewriteTrackWaypoints([]), rewriteComputedTrackpoints([]), rewriteElevations([]), changeTrackColor(null)],
          false
        )
        return
      }

      if (isSharedTrack === true && sharedTrack === null) {
        setSharedTrack(track)
      } else if (isSharedTrack === false && sharedTrack !== null) {
        setSharedTrack(null)
      }

      const { trackPoints, controlPoints } = track
      const isRoutable = track.routing !== Routing.none
      dispatch(
        [
          changeRoutingType(track.routing),
          rewriteComputedTrackpoints(trackPoints),
          ...(!isRoutable
            ? [
                updateNonRoutableTrackMetadata({
                  trackPointsEle: track.trackPointsEle,
                  trackPointsCadence: track.trackPointsCadence,
                  trackPointsHeartRate: track.trackPointsHeartRate,
                  trackPointsPower: track.trackPointsPower,
                  trackPointsSpeed: track.trackPointsSpeed,
                  trackPointsTemperature: track.trackPointsTemperature,
                  trackPointsTimeDatapoints: track.trackPointsTime?.datapoints,
                }),
              ]
            : []),
          rewriteTrackWaypoints(isRoutable ? controlPoints : trackPoints),
          rewriteElevations(track.trackPointsEle),
          changeShowPlaceholderTrack(isRoutable),
          changeTrackColor(track.color),
        ],
        false
      )
      if (map && trackPoints.length > 0) {
        fitTrackToScreen(trackPoints, map)
      }

      setTrackStats({
        track: { ...omit(track, 'trackPointsTime'), trackPointsTimeDatapoints: track.trackPointsTime?.datapoints },
        trackpoints: trackPoints,
        // It first sets the stats for elevations with track data, while (in other place) elevations are fetched
        // and then stats are updated with that elevations data
        elevations: track.trackPointsEle,
      })
    },
    [dispatch, sharedTrack, setTrackStats]
  )

  const changeRouting = useCallback(
    (type: Routing, costingOptions?: RoutingCostingOptions, callback?: () => void) => {
      const dispatchConverted = () => {
        dispatch([
          rewriteTrackWaypoints(stateRef.current.present.computedTrackpoints),
          changeRoutingType(type),
          ...(costingOptions ? [updateRoutingCostingOptions(costingOptions)] : []),
          rewriteTrackmeta({
            totalDistance: undefined,
            trackPointsSpeed: undefined,
            trackPointsTime: undefined,
            totalTime: undefined,
          }),
        ])
        callback?.()
      }
      const dispatchClear = () => {
        dispatch([
          rewriteTrackWaypoints([]),
          rewriteComputedTrackpoints([]),
          changeRoutingType(type),
          ...(costingOptions ? [updateRoutingCostingOptions(costingOptions)] : []),
          rewriteTrackmeta({
            totalDistance: undefined,
            trackPointsSpeed: undefined,
            trackPointsTime: undefined,
            totalTime: undefined,
          }),
        ])
        callback?.()
      }

      if (stateRef.current.present.trackWaypoints.length === 1) {
        dispatch([changeRoutingType(type), ...(costingOptions ? [updateRoutingCostingOptions(costingOptions)] : [])])
        callback?.()
        return
      }

      // convert trackpoints if changing routing to none
      if (stateRef.current.present.routing !== Routing.none && type === Routing.none) {
        if (stateRef.current.present.trackWaypoints.length === 0) {
          dispatchConverted()
          return
        }
        openConfirmModal({
          title: t('change_routing_to_free_destroy_modal.title'),
          text: t('change_routing_to_free_destroy_modal.text'),
          labels: {
            confirm: t('generic.yes'),
            cancel: t('generic.dismiss'),
          },
          onConfirm: dispatchConverted,
        })
        return
      }
      // clear trackpoints if changing routing from none
      if (stateRef.current.present.routing === Routing.none && type !== Routing.none) {
        if (stateRef.current.present.trackWaypoints.length === 0) {
          dispatchClear()
          return
        }
        openConfirmModal({
          title: t('change_routing_clear_track_modal.title'),
          text: t('change_routing_clear_track_modal.text'),
          labels: {
            confirm: t('generic.yes'),
            cancel: t('generic.dismiss'),
          },
          onConfirm: dispatchClear,
        })
        return
      }
      dispatch([
        changeRoutingType(type),
        ...(costingOptions ? [updateRoutingCostingOptions(costingOptions)] : []),
        rewriteTrackmeta({
          totalDistance: undefined,
          trackPointsSpeed: undefined,
          trackPointsTime: undefined,
          totalTime: undefined,
        }),
      ])
      callback?.()
    },
    [dispatch, openConfirmModal]
  )

  const updateCostingOptions = useCallback(
    (costingOptions: DeepPartial<RoutingCostingOptions>) => {
      dispatch(updateRoutingCostingOptions(costingOptions))
    },
    [dispatch]
  )

  const clear = useCallback(() => {
    dispatch([clearTrackWaypoints(), clearElevations(), rewriteComputedTrackpoints([])], false)
  }, [dispatch])

  const memoizedValue = React.useMemo(
    () => ({
      state: state.present,
      dispatch,
      history,
      changeRouting,
      updateCostingOptions,
      fetchElevations,
      setEditedTrack,
      clear,
      sharedTrack,
    }),
    [
      state.present,
      dispatch,
      history,
      changeRouting,
      updateCostingOptions,
      fetchElevations,
      setEditedTrack,
      clear,
      sharedTrack,
    ]
  )

  return <RoutingContext.Provider value={memoizedValue}>{props.children}</RoutingContext.Provider>
}
