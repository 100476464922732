import { useCallback } from 'react'

import { LegendIcon } from 'assets/icons/LegendIcon'
import { Tooltip } from 'components/UI/Tooltip'
import { hasLegend } from 'config/legends'
import { useGenericModals } from 'hooks/useGenericModals'
import { useTranslation } from 'react-i18next'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { ControlActionIcon } from '../ControlActionIcon'

export const LegendControl = () => {
  const { openInfoModal } = useGenericModals()
  const { state, dispatch } = useOptions()
  const { t } = useTranslation()

  const handleLegendToggle = useCallback(() => {
    if (hasLegend(state.mapType)) {
      dispatch(updateNonSaveableOptions({ isLegendOpen: !state.isLegendOpen }))
    } else {
      openInfoModal(t('legend_modal.title'), t('legend_modal.text'))
    }
  }, [t, dispatch, openInfoModal, state.isLegendOpen, state.mapType])

  return (
    <Tooltip position="left" label={t('map_controls.legend_button_tooltip')}>
      <ControlActionIcon onClick={handleLegendToggle}>
        <LegendIcon />
      </ControlActionIcon>
    </Tooltip>
  )
}
