import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useCallback } from "react";
import { useModals } from "@mantine/modals";
import { Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/UI/Tooltip";
import { useOptions } from "stores/optionsStore/OptionsContext";

import "./PremiumFeatureWrapper.css";

interface PremiumFeatureWrapperProps {
  featureName: string;
  hasTooltip?: boolean;
  isVisible?: boolean;
  lockIconStyles?: React.CSSProperties;
}

const TooltipContainer = ({
  children,
  featureName,
  hasTooltip,
}: {
  children: ReactNode;
  featureName: string;
  hasTooltip: boolean;
}) => {
  const { t } = useTranslation();

  return hasTooltip ? (
    <Tooltip label={`${featureName} (${t("subscription_modal.title")})`}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export const PremiumFeatureWrapper = (
  props: React.PropsWithChildren<PremiumFeatureWrapperProps>
) => {
  const {
    children,
    featureName,
    isVisible = true,
    lockIconStyles,
    hasTooltip = true,
  } = props;
  const {
    state: { allowPremiumFeatures },
  } = useOptions();
  const { t } = useTranslation();
  const modals = useModals();
  const theme = useMantineTheme();

  const handleClick = useCallback(() => {
    modals.openContextModal("premiumFeatureModal", {
      title: (
        <Text>{`${t("subscription_modal.title")} 🚡 (${featureName})`}</Text>
      ),
      innerProps: { featureName },
      padding: 0,
      styles: {
        header: { padding: 20, marginBottom: 0 },
      },
      size: "xl",
    });
  }, [featureName]);

  return allowPremiumFeatures ? (
    <>{children}</>
  ) : (
    <TooltipContainer featureName={featureName} hasTooltip={hasTooltip}>
      <div
        style={{
          float: "left",
          position: "relative",
          display: isVisible ? "block" : "none",
        }}
        onClick={handleClick}
      >
        <div
          className="trkbk-locked-wrapper-overlay"
          style={{
            backgroundColor: theme.colorScheme === "dark" ? "#000" : "#eee",
          }}
        ></div>
        <FontAwesomeIcon
          className="trkbk-locked-wrapper-icon"
          style={lockIconStyles}
          icon={faLock}
        />
        {children}
      </div>
    </TooltipContainer>
  );
};
