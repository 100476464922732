import { Coords } from 'types/app'

/**
 *
 * @param polyline encoded polyline
 * @param defaultPrecision default = 5
 * @returns Array of Coords arrays [[lat, lng], [lat, lng], ...]
 */
export const decodePolyline = (polyline: string, defaultPrecision = 5): Coords[] => {
  let index = 0
  const coordinates: Coords[] = []
  let result = 0
  let byte: null | number = null
  let precision = defaultPrecision

  if (polyline.length > 2 && polyline.charCodeAt(0) === 254) {
    precision = Number(polyline[1])
    index = 2
  }

  const factor = 10 ** precision

  let shift = 0
  let lat = 0
  let lng = 0
  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < polyline.length) {
    // Reset shift, result, and byte
    byte = null
    shift = 0
    result = 0

    do {
      byte = polyline.charCodeAt(index) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
      index += 1
    } while (byte >= 0x20)

    const latitudeChange = result & 1 ? ~(result >> 1) : result >> 1

    shift = 0
    result = 0

    do {
      byte = polyline.charCodeAt(index) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
      index += 1
    } while (byte >= 0x20)

    const longitudeChange = result & 1 ? ~(result >> 1) : result >> 1

    lat += latitudeChange
    lng += longitudeChange

    const decodedCoords: Coords = [lat / factor, lng / factor]
    coordinates.push(decodedCoords)
  }

  return coordinates
}
