import { MapID } from 'config/enums/mapIDs'
import { Overlay } from 'config/enums/overlays'

import Bike from './bike.png'
import { BIKETRAILS_OVERLAY_PREVIEWS } from './bikeTrails'
import { HIKE_WAYMARKED_OVERLAY_PREVIEWS } from './hikeWaymarked'
import Path from './path.png'
import { TRANSIT_OVERLAY_PREVIEWS } from './transit'

export const OVERLAY_PREVIEWS = {
  [Overlay.bikePaths]: Bike,
  [Overlay.walkPaths]: Path,
  [Overlay.transit]: TRANSIT_OVERLAY_PREVIEWS,
  [Overlay.bikeTrails]: BIKETRAILS_OVERLAY_PREVIEWS,
  [Overlay.hikeWaymarked]: HIKE_WAYMARKED_OVERLAY_PREVIEWS,
}

export const getOverlayPreview = (overlay: Overlay, mapType: MapID) => {
  if (overlay === Overlay.walkPaths || overlay === Overlay.bikePaths) return OVERLAY_PREVIEWS[overlay]
  const previews = OVERLAY_PREVIEWS[overlay]
  return previews[mapType as keyof typeof previews]
}
