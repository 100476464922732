import { useEffect, useMemo, useRef, useState } from 'react'

import { Box } from '@mantine/core'
import {
  Cartesian3,
  CesiumTerrainProvider,
  UrlTemplateImageryProvider,
  Rectangle,
  Viewer as CesiumViewer,
  Credit,
} from 'cesium'
import { CesiumMarker } from 'components/CesiumMap/CesiumMarker'
import { ChartMarkerCesium } from 'components/CesiumMap/ChartMarkerCesium'
import { TrackPolyline } from 'components/CesiumMap/TrackPolyline'
import { TRACK_COLORS } from 'config/constants'
import { useCesiumContext } from 'hooks/useCesiumContext'
import { CameraFlyTo, CesiumComponentRef, Viewer } from 'resium'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { expandRectangle } from 'utils/cesium/expandRectangle'
import { getCartesianPointsFromCoordsArray } from 'utils/cesium/getCartesianPointsFromCoordsArray'
import './CesiumMap.css'

const terrainProvider = new CesiumTerrainProvider({
  url: 'https://terra.vecturagames.com/tiles?minZoom=1&maxZoom=13&cog=./ctod/files/cog/raw_cog.tif',
  requestVertexNormals: true,
})

const imageryProvider = new UrlTemplateImageryProvider({
  url: 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=pk.eyJ1IjoidmVjdHVyYWdhbWVzIiwiYSI6ImNsdDh0cThndTB5NW8yam05amI3ejcxaXEifQ.nHL7oXGDMSmPLOZjDCwhDg',
  credit: new Credit(
    '<div id="cesium-map-mapbox-logo-container"><img src="/img/mapbox-logo-white.png" width="100" height="22.5" title="Mapbox"/><span>© <a href="https://mapbox.com/" target="_blank">Mapbox</a></span><span>© <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors</span></div>',
    true
  ),
})

export const CesiumMap = () => {
  const { setCesium } = useCesiumContext()
  const ref = useRef<CesiumComponentRef<CesiumViewer>>(null)
  const {
    state: { computedTrackpoints, trackColor },
  } = useRouting()
  const [cartesianPoints, setCartesianPoints] = useState<Cartesian3[] | null>(
    getCartesianPointsFromCoordsArray(computedTrackpoints)
  )

  useEffect(() => {
    setCartesianPoints(getCartesianPointsFromCoordsArray(computedTrackpoints))
  }, [computedTrackpoints])

  useEffect(() => {
    if (ref.current?.cesiumElement) {
      const scene = ref.current?.cesiumElement?.scene
      if (scene) {
        scene.postProcessStages.fxaa.enabled = true
      }
      setCesium(ref.current.cesiumElement)
    }
    return () => setCesium(null)
  }, [setCesium])

  const viewExpandedRectangle = useMemo(
    () =>
      cartesianPoints && cartesianPoints?.length > 1
        ? expandRectangle(Rectangle.fromCartesianArray(cartesianPoints), 0.5)
        : null,
    [cartesianPoints]
  )

  const CameraFly = useMemo(
    () => (viewExpandedRectangle ? <CameraFlyTo destination={viewExpandedRectangle} duration={0} /> : null),
    [viewExpandedRectangle]
  )

  const firstPoint = cartesianPoints?.[0]
  const lastPoint = cartesianPoints?.at(-1)

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Viewer
        baseLayerPicker={false}
        full
        imageryProvider={imageryProvider}
        terrainProvider={terrainProvider}
        infoBox={false}
        selectionIndicator={false}
        geocoder={false}
        fullscreenButton={false}
        homeButton={false}
        vrButton={false}
        timeline={false}
        animation={false}
        sceneModePicker={false}
        scene3DOnly
        ref={ref}
      >
        {viewExpandedRectangle && CameraFly}
        {cartesianPoints && <TrackPolyline positions={cartesianPoints} color={trackColor || TRACK_COLORS.routable} />}
        {firstPoint && <CesiumMarker type="start" position={firstPoint} />}
        {lastPoint && <CesiumMarker type="end" position={lastPoint} />}
        <ChartMarkerCesium />
      </Viewer>
    </Box>
  )
}
