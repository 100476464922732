import { MapContainerProps } from 'react-leaflet'
import { Coords } from 'types/app'

import { MapID } from './enums/mapIDs'

export const BASE64_CHAR_SET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

// Map Markers Colors
export const MARKER_COLORS = {
  start: '#05CB25',
  route: '#682BCB',
  end: '#EF9227',
}

export const TRACK_COLORS = {
  routable: '#682BCB',
  nonRoutable: '#26B096',
  public: '#007BFF',
}

interface ExtendedMapContainerProps extends MapContainerProps {
  center: Coords
}
export const MAP_SETTINGS: ExtendedMapContainerProps = {
  minZoom: 3,
  center: [25.09, 2.64],
  zoom: 3,
  scrollWheelZoom: true,
  doubleClickZoom: false,
  zoomSnap: 0,
  zoomControl: false,
  preferCanvas: true,
}

export const MAX_ZOOMS = {
  /** Maximum zoom level for tangram (vector) maps */
  tangram: 20,
  /** Maximum zoom level for raster maps */
  raster: 18,
}

export const DEFAULT_MAP_ID = MapID.walkabout

export const INTERACTION_SETTINGS = {
  markerActionTimeout: 200, // miliseconds
}

export const NON_ROUTING_CONTROL_SETTINGS = {
  markersVisibleOverZoomLvl: 14,
  arrowheadsVisibleOverZoomLvl: 11, // including
}

export const TANGRAM_ATTRIBUTION =
  '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a>, &copy; OSM contributors | <a href="https://vecturagames.com/privacy_trackbook" target="_blank">Privacy Policy</a>'

export const PANEL_TOGGLE_ANIMATION_DURATION = 0.4 // seconds

export const FEEDBACK_EMAIL = 'trackbook@vecturagames.com'

export const CONTROL_BUTTON_SETTINGS = {
  size: 30,
  iconFontSize: 14,
}

export const TOP_MENU_ITEMS_MARGINS = 2

export const AUTOMATIC_SAVING = {
  allowSavingAfter: 5000, // milliseconds after app load
  debounceLength: 10_000, // miliseconds after last map move action
}
export const TOOLTIP_SHOW_TIMEOUT = 500 // ms

export const SECONDARY_COLOR = '#6c757d'
