export const TopoLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/motorway.png" width="70" />
      </th>
      <td>{'Motorway, primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/major_road.png" width="70" />
      </th>
      <td>{'Secondary road, tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/minor_road.png" width="70" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/service.png" width="70" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/pedestrian.png" width="70" />
      </th>
      <td>{'Pedestrian street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/raceway.png" height="5.5" width="70" />
      </th>
      <td>{'Raceway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/oneway.png" height="9" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/footway.png" width="70" />
      </th>
      <td>{'Footway, path, bridleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cycleway.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_2.png" width="70" />
      </th>
      <td>{'Track (without grade)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_1.png" width="70" />
      </th>
      <td>{'Track (grade 1)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_2.png" width="70" />
      </th>
      <td>{'Track (grade 2)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_3-4.png" width="70" />
      </th>
      <td>{'Track (grade 3)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_3-4.png" width="70" />
      </th>
      <td>{'Track (grade 4)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_5.png" width="70" />
      </th>
      <td>{'Track (grade 5)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/steps.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/casing_tunnel.png" width="70" />
      </th>
      <td>{'Dashed casing = tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/casing_bridge.png" width="70" />
      </th>
      <td>{'Black casing = bridge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aeroway.png" height="7" width="70" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aeroway.png" height="4" width="70" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_tram.png" width="70" />
      </th>
      <td>{'Tram'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway.png" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_tunnel.png" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_disused.png" width="70" />
      </th>
      <td>{'Disused railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_service.png" width="70" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_funicular.png" width="70" />
      </th>
      <td>{'Subway, funicular, narrow gauge, light rail, miniature railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_monorail.png" width="70" />
      </th>
      <td>{'Monorail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aerialway.png" height="6" width="70" />
      </th>
      <td>
        {'Chairlift, drag lift, t-bar lift, j-bar lift, platter lift, rope tow, magic carpet, zipline, mixed lift'}
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/gondola.png" width="70" />
      </th>
      <td>{'Cable car, gondola lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/goods.png" width="70" />
      </th>
      <td>{'Goods cable lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ferry.png" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_lower.png" />
      </th>
      <td>{'Country boundary (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_middle.png" />
      </th>
      <td>{'Country boundary (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_higher.png" />
      </th>
      <td>{'Country boundary (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_regional_lower.png" />
      </th>
      <td>{'Regional boundary (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_regional_higher.png" />
      </th>
      <td>{'Regional boundary (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/protected_area.png" />
      </th>
      <td>{'National park, protected area, nature reserve boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/military.png" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/water.png" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/glacier.png" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/lake_intermittent.png" />
      </th>
      <td>{'Intermittent lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest_deciduous.png" />
      </th>
      <td>{'Broadleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest_coniferous.png" />
      </th>
      <td>{'Needleleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest.png" />
      </th>
      <td>{'Mixed forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bare_rock.png" />
      </th>
      <td>{'Bare rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/scree.png" />
      </th>
      <td>{'Scree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/scrub.png" />
      </th>
      <td>{'Scrub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wetland.png" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/reedbed.png" />
      </th>
      <td>{'Reed bed'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bog.png" />
      </th>
      <td>{'Bog'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/swamp.png" />
      </th>
      <td>{'Swamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tidalflat_z9.png" />
      </th>
      <td>{'Tidal flat (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tidalflat.png" />
      </th>
      <td>{'Tidal flat (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/sand.png" />
      </th>
      <td>{'Beach, sand'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/meadow.png" />
      </th>
      <td>{'Grass, meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/vineyard.png" />
      </th>
      <td>{'Vineyard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/orchard.png" />
      </th>
      <td>{'Orchard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/allotments.png" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/grave_yard.png" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/quarry.png" />
      </th>
      <td>{'Quarry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/park.png" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/pitch.png" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/recreation_track.png" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/apron.png" />
      </th>
      <td>{'Airport apron'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/industrial.png" />
      </th>
      <td>{'Hospital, university, school, residential area, industrial area, substation, plant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/generator.png" />
      </th>
      <td>{'Generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hedge.png" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cliff.png" />
      </th>
      <td>{'Cliff, embankment, cutting'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powerline.png" height="5" width="70" />
      </th>
      <td>{'Powerline'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powertower.png" height="5" width="70" />
      </th>
      <td>{'Powerline with powertower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powerpole.png" height="5" width="70" />
      </th>
      <td>{'Powerline with powerpole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/fence.png" height="5" width="70" />
      </th>
      <td>{'Fence, wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wall.png" height="6" width="70" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch_trench.png" height="3" width="70" />
      </th>
      <td>{'Trench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/river.png" width="70" />
      </th>
      <td>{'River'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/river_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent river'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream.png" width="70" />
      </th>
      <td>{'Stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream_tunnel.png" width="70" />
      </th>
      <td>{'Stream with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/canal.png" width="70" />
      </th>
      <td>{'Canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch.png" width="70" />
      </th>
      <td>{'Ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/canal_tunnel.png" width="70" />
      </th>
      <td>{'Canal, ditch or drain with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_new_with_station.png" width="70" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_new_with_request_station.png" width="70" />
      </th>
      <td>{'Train request station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/peak.png" />
      </th>
      <td>{'Peak, volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/saddle-15.png" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cave.png" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/spring_z15.png" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/sinkhole.png" />
      </th>
      <td>{'Sinkhole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/broadleaf_tree.png" />
      </th>
      <td>{'Tree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/church_z13.png" />
      </th>
      <td>{'Church, chapel, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hospital.png" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hut_alpine.png" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hut_wilderness.png" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/campsite.png" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/shelter.png" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/golf.png" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/swimming.png" />
      </th>
      <td>{'Swimming pool, swimming area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/viewpoint.png" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/castle_z13.png" />
      </th>
      <td>{'Castle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/monument.png" />
      </th>
      <td>{'Monument, plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tumulus.png" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/parking.png" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bus_stop.png" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/station_subway.png" />
      </th>
      <td>{'Subway station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_crossing.png" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/communications_mast.png" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/communications_tower_z13.png" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/water_tower.png" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/watermill.png" />
      </th>
      <td>{'Watermill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/lighthouse.png" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wayside_cross.png" />
      </th>
      <td>{'Wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/power_station.png" />
      </th>
      <td>{'Power station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/photovoltaic.png" />
      </th>
      <td>{'Solar generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wind_turbine.png" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/mineshaft.png" />
      </th>
      <td>{'Mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bunker.png" />
      </th>
      <td>{'Bunker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
