import { Tooltip as TooltipMantine, TooltipProps, useMantineTheme } from '@mantine/core'
import { TOOLTIP_SHOW_TIMEOUT } from 'config/constants'

export const Tooltip = ({ children, ...rest }: TooltipProps) => {
  const theme = useMantineTheme()
  return (
    <TooltipMantine
      openDelay={TOOLTIP_SHOW_TIMEOUT}
      {...rest}
      withArrow
      withinPortal
      color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
    >
      {children}
    </TooltipMantine>
  )
}
