import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components/UI/Tooltip'
import { useGenericModals } from 'hooks/useGenericModals'
import { useTranslation } from 'react-i18next'

import { ControlActionIcon } from '../ControlActionIcon'

export const HelpControl = () => {
  const { openHelpModal } = useGenericModals()
  const { t } = useTranslation()
  return (
    <Tooltip position="left" label={t('map_controls.help_button_tooltip')}>
      <ControlActionIcon onClick={openHelpModal}>
        <FontAwesomeIcon icon={faQuestion} />
      </ControlActionIcon>
    </Tooltip>
  )
}
