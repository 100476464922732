import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClipboard } from '@mantine/hooks'
import { Tooltip } from 'components/UI/Tooltip'
import { usePublicTracks } from 'hooks/usePublicTracks'
import { useUserTracks } from 'hooks/useUserTracks'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { showSuccessNotification } from 'utils/notifications/customNotifications'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

export const CopyToClipboardIcon = ({ isOpen }: { isOpen: boolean }) => {
  const { selectedTrack } = useUserTracks()
  const { selectedTrack: selectedPublicTrack } = usePublicTracks()
  const { state } = useOptions()
  const clipboard = useClipboard()
  const { t } = useTranslation()

  const copyTrackToClipboard = () => {
    const link = `${window.location.origin}/?basemap=${state.mapType}&trkid=${
      selectedTrack?.uuid ?? selectedPublicTrack?.uuid
    }`
    clipboard.copy(link)

    showSuccessNotification({
      message: t('editmode.copy_link_to_clipboard.success_notification_message'),
    })
  }

  // TODO (Peter): add tooltip explaining why button is disabled
  return (
    <Tooltip label={t('editmode.copy_link_to_clipboard.tooltip')}>
      <div>
        <CollapsibleTopMenuActionIcon
          isOpen={isOpen}
          disabled={!selectedTrack && !selectedPublicTrack}
          onClick={copyTrackToClipboard}
        >
          <FontAwesomeIcon icon={faLink} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  )
}
