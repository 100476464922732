import { EventEmitter } from 'events'

import ky from 'ky'
import { concatUint8Arrays, uint8ArrayToBase64 } from 'uint8array-extras'

import { getListOfTilesInBBox, IBBox, ITileCoordinates } from '../tile-utils'
import {
  DEFAULT_CRAWL_DELAY,
  DEFAULT_DATABASE_NAME,
  DEFAULT_DATABASE_VERSION,
  DEFAULT_MAX_AGE,
  DEFAULT_PROVIDER_NAME,
  DEFAULT_TILE_URL,
  DEFAULT_TILE_URL_SUB_DOMAINS,
  TILES_OBJECT_STORE_NAME,
  METADATA_OBJECT_STORE_NAME,
} from './consts'

/**
 * Interface for the options parameter of the constructor of the IndexedDbTileCache class
 */
export interface IIndexedDbTileCacheOptions {
  /**
   * Name of the database
   *
   * The default value is equal to the constance DEFAULT_DATABASE_NAME
   * @default "tile-cache-data"
   */
  databaseName: string
  /**
   * Version of the IndexedDB store. Should not be changed normally! But can provide an "upgradeneeded" event from
   * IndexedDB.
   *
   * The default value is equal to the constance DEFAULT_DATABASE_VERSION
   * @default 1
   */
  databaseVersion: number
  /**
   * Name of the current tile provider. Should correspond with the name of the tile server
   *
   * The default value is equal to the constance DEFAULT_OBJECT_STORE_NAME
   * @default "OpenStreetMap";
   */
  provider: string
  /**
   * URL template of the tile server.
   *
   * The default value is equal to the constance DEFAULT_TILE_URL
   * @default "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
   */
  tileUrl: string
  /**
   * A list of all available sub domains for the URL template.
   *
   * The default value is equal to the constance DEFAULT_TILE_URL_SUB_DOMAINS
   * @default ["a", "b", "c"]
   */
  tileUrlSubDomains: string[]
  /**
   * The delay in milliseconds used for not stressing the tile server while seeding.
   *
   * The default value is equal to the constance DEFAULT_CRAWL_DELAY
   * @default 500
   */
  crawlDelay: number
  /**
   * The maximum age in milliseconds of a stored tile.
   *
   * The default value is equal to the constance DEFAULT_MAX_AGE
   * @default 1000 * 60 * 60 * 24 * 7
   */
  maxAge: number
}

/**
 * Interface for an internal IIndexedDbTileCacheTile
 */
export interface IIndexedDbTileCacheTile {
  /**
   * Unique identifier for the record - can be a number or a string (e.g. tile server name, like "OpenStreetMap")
   * Should match the id for the tile metadata
   */
  id: string
  /**
   * URL of the tile excepts its sub-domain value that is still stored as placeholder.
   */
  data: Uint8Array
  /**
   * The content-type from the response header.
   */
  contentType: string
}

/**
 * Interface for an internal IIndexedDbTileCacheTileMetadata
 */
export interface IIndexedDbTileCacheTileMetadata {
  /**
   * Id for the tile
   */
  tileId: string | number
  /**
   * Tile server provider (e.g. "OpenStreetMap")
   */
  provider: string
  /**
   * URL of the tile excepts its sub-domain value that is still stored as placeholder.
   */
  url: string
  /**
   * Timestamp of the creation date of the entry
   */
  timestamp: number
}

export interface IIndexedDbTileCacheEntry {
  /**
   * Id for the tile
   */
  tileId: string | number
  /**
   * URL of the tile excepts its sub-domain value that is still stored as placeholder.
   */
  data: Uint8Array
  /**
   * The content-type from the response header.
   */
  contentType: string
  /**
   * Tile server provider (e.g. "OpenStreetMap")
   */
  provider: string
  /**
   * URL of the tile excepts its sub-domain value that is still stored as placeholder.
   */
  url: string
  /**
   * Timestamp of the creation date of the entry
   */
  timestamp: number
}

/**
 * Interface for the "seed-progress" event
 */
export interface IIndexedDbTileCacheSeedProgress {
  total: number
  remains: number
}

export const getCacheSizePercentage = async () => {
  const estimate = await navigator.storage.estimate()

  if (estimate.usage !== undefined && estimate.quota !== undefined) {
    return Number(((estimate.usage / estimate.quota) * 100).toFixed(2))
  }
  return null
}

export const purgeCache = (databaseName: string, databaseVersion: number): Promise<true> => {
  const dbRequest = indexedDB.open(databaseName, databaseVersion)

  return new Promise((resolve, reject) => {
    dbRequest.onsuccess = (dbEvent) => {
      const dbEventTarget = dbEvent.target as IDBOpenDBRequest
      const database = dbEventTarget.result
      const tx = database.transaction([TILES_OBJECT_STORE_NAME, METADATA_OBJECT_STORE_NAME], 'readwrite')

      const tileClearResult = tx.objectStore(TILES_OBJECT_STORE_NAME).clear()
      const metadataClearResult = tx.objectStore(METADATA_OBJECT_STORE_NAME).clear()

      tileClearResult.onsuccess = () => {
        // console.log('tiles cleared successfully!')
      }
      metadataClearResult.onsuccess = () => {
        // console.log('tiles metadata cleared successfully!')
      }

      tx.oncomplete = () => {
        resolve(true)
      }
      tx.onerror = (event) => {
        const eventTarget = event.target as IDBRequest
        reject(eventTarget.error)
      }
    }

    dbRequest.onerror = (dbEvent) => {
      const dbEventTarget = dbEvent.target as IDBOpenDBRequest
      reject(dbEventTarget.error)
    }
  })
}
/**
 * Class for a spatial-tile-cache that stores its data in the browsers IndexedDB
 */
export class IndexedDbTileCache extends EventEmitter {
  public options: IIndexedDbTileCacheOptions
  constructor(options?: IIndexedDbTileCacheOptions) {
    super()

    this.options = {
      databaseName: options?.databaseName ?? DEFAULT_DATABASE_NAME,
      databaseVersion: options?.databaseVersion ?? DEFAULT_DATABASE_VERSION,
      provider: options?.provider ?? DEFAULT_PROVIDER_NAME,
      tileUrl: options?.tileUrl ?? DEFAULT_TILE_URL,
      tileUrlSubDomains: options?.tileUrlSubDomains ?? DEFAULT_TILE_URL_SUB_DOMAINS,
      crawlDelay: options?.crawlDelay ?? DEFAULT_CRAWL_DELAY,
      maxAge: options?.maxAge ?? DEFAULT_MAX_AGE,
    }

    const dbOpenRequest = indexedDB.open(this.options.databaseName, this.options.databaseVersion)

    dbOpenRequest.onupgradeneeded = (event) => {
      this.emit('upgradeneeded', event)

      const eventTarget = event.target as IDBOpenDBRequest
      const database = eventTarget.result

      database.createObjectStore(TILES_OBJECT_STORE_NAME, {
        keyPath: 'id',
      })
      database.createObjectStore(METADATA_OBJECT_STORE_NAME, {
        keyPath: 'tileId',
      })
    }

    dbOpenRequest.onerror = (event) => {
      const dbEventTarget = event.target as IDBOpenDBRequest
      this.emit('error', dbEventTarget.error)
    }
  }
  /**
   * Get the internal tile entry from the database with all its additional meta information.
   *
   * If the tile is marked as outdated by the `IIndexedDbTileCacheOptions.maxAge` property, it tries to download it
   * again. On any error it will provide the cached version.
   *
   * If you pass `true` as parameter for the `downloadIfUnavaiable` argument, it tries to dowenload a tile if it is
   * not stored already.
   */
  public getTileEntry(
    tileCoordinates: ITileCoordinates,
    downloadIfUnavaiable?: boolean
  ): Promise<IIndexedDbTileCacheEntry> {
    const dbRequest = indexedDB.open(this.options.databaseName, this.options.databaseVersion)

    return new Promise((resolve, reject) => {
      dbRequest.onsuccess = (dbEvent) => {
        const dbEventTarget = dbEvent.target as IDBOpenDBRequest
        const database = dbEventTarget.result

        const tx = database.transaction([TILES_OBJECT_STORE_NAME, METADATA_OBJECT_STORE_NAME])

        const tileId = `${this.options.provider}_x${tileCoordinates.x}-y${tileCoordinates.y}-z${tileCoordinates.z}`

        const tileGetResult = tx.objectStore(TILES_OBJECT_STORE_NAME).get(tileId)
        const metadataGetResult = tx.objectStore(METADATA_OBJECT_STORE_NAME).get(tileId)

        let tile: IIndexedDbTileCacheTile
        let metadata: IIndexedDbTileCacheTileMetadata

        tileGetResult.onsuccess = (event) => {
          const eventTarget = event.target as IDBRequest
          tile = eventTarget.result as IIndexedDbTileCacheTile
        }
        metadataGetResult.onsuccess = (event) => {
          const eventTarget = event.target as IDBRequest
          metadata = eventTarget.result as IIndexedDbTileCacheTileMetadata
        }

        tx.oncomplete = () => {
          if (!(tile && metadata)) {
            if (downloadIfUnavaiable) {
              return this.downloadTile(tileCoordinates).then(resolve, reject)
            }
            return reject(new Error('Unable to find entry'))
          }
          const tileEntry = { ...tile, ...metadata } as IIndexedDbTileCacheEntry

          if (tileEntry.timestamp < Date.now() - this.options.maxAge) {
            // Cached tile entry exceeds maxAge, downloading...
            return this.downloadTile(tileCoordinates)
              .catch(() =>
                // Not available so keep cached version...
                resolve(tileEntry)
              )
              .then((freshTileEntry) => {
                if (freshTileEntry) {
                  return resolve(freshTileEntry)
                }
                return resolve(tileEntry)
              })
          }
          return resolve(tileEntry)
        }

        tx.onerror = (event) => {
          const eventTarget = event.target as IDBRequest

          this.emit('error', eventTarget.error)
          reject(eventTarget.error)
        }
      }

      dbRequest.onerror = (dbEvent) => {
        const dbEventTarget = dbEvent.target as IDBOpenDBRequest

        this.emit('error', dbEventTarget.error)
        reject(dbEventTarget.error)
      }
    })
  }
  /**
   * Creates an internal tile url from the url template from IIndexedDbTileCacheOptions
   *
   * It keeps the sub-domain placeholder to provide unique database entries while seeding from multiple sub-domains.
   */
  public createInternalTileUrl(tileCoordinates: ITileCoordinates): string {
    return this.options.tileUrl
      .split(/{x}/)
      .join(tileCoordinates.x.toString())
      .split(/{y}/)
      .join(tileCoordinates.y.toString())
      .split(/{z}/)
      .join(tileCoordinates.z.toString())
  }
  /**
   * Creates a real tile url from the url template from IIndexedDbTileCacheOptions
   */
  public createTileUrl(tileCoordinates: ITileCoordinates): string {
    const randomSubDomain: string =
      this.options.tileUrlSubDomains[Math.floor(Math.random() * this.options.tileUrlSubDomains.length)]

    return this.createInternalTileUrl(tileCoordinates).split(/{s}/).join(randomSubDomain)
  }
  /**
   * Receive a tile as an Uint8Array / Buffer
   */
  public getTileAsBuffer(tileCoordinates: ITileCoordinates): Promise<ArrayBuffer> {
    return this.getTileEntry(tileCoordinates, true).then((tileEntry: IIndexedDbTileCacheEntry) =>
      Promise.resolve(tileEntry.data)
    )
  }
  /**
   * Receives a tile as its base64 encoded data url.
   */
  public getTileAsDataUrl(tileCoordinates: ITileCoordinates): Promise<string> {
    return this.getTileEntry(tileCoordinates, true).then((tileEntry: IIndexedDbTileCacheEntry) =>
      Promise.resolve(`data:${tileEntry.contentType};base64,${uint8ArrayToBase64(tileEntry.data, { urlSafe: false })}`)
    )
  }

  public async fetchStream(url: string) {
    let contentType: string
    const buffers: Uint8Array[] = []

    try {
      const response = await ky.get(url)
      const stream = response.body

      if (!stream) throw new Error('Stream is nil!')

      const reader = stream.getReader()

      const processText = ({
        done,
        value,
      }: ReadableStreamReadResult<Uint8Array>): Promise<{ data: Uint8Array; contentType: string }> => {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
          contentType = response.headers.get('content-type') ?? 'image/png'
          return new Promise((resolve) => {
            resolve({ data: concatUint8Arrays(buffers), contentType })
          })
        }

        buffers.push(value)

        return reader.read().then(processText)
      }

      const result = await reader.read().then(processText)
      return result
    } catch (error: unknown) {
      throw new Error(`Failed to fetch tile buffer: ${error?.toString()}`)
    }
  }
  /**
   * Download a specific tile by its coordinates and store it within the indexed-db
   */
  public async downloadTile(tileCoordinates: ITileCoordinates): Promise<IIndexedDbTileCacheEntry> {
    try {
      const { contentType, data } = await this.fetchStream(this.createTileUrl(tileCoordinates))

      if (!data) throw new Error('Data is nil!')

      const dbRequest = indexedDB.open(this.options.databaseName, this.options.databaseVersion)
      const tileId = `${this.options.provider}_x${tileCoordinates.x}-y${tileCoordinates.y}-z${tileCoordinates.z}`

      const tile: IIndexedDbTileCacheTile = {
        id: tileId,
        contentType,
        data,
      }

      const metadata: IIndexedDbTileCacheTileMetadata = {
        provider: this.options.provider,
        tileId,
        timestamp: Date.now(),
        url: this.createInternalTileUrl(tileCoordinates),
      }

      return await new Promise((resolve, reject) => {
        dbRequest.onsuccess = (dbEvent) => {
          const dbEventTarget = dbEvent.target as IDBOpenDBRequest
          const database = dbEventTarget.result

          const tx = database.transaction([TILES_OBJECT_STORE_NAME, METADATA_OBJECT_STORE_NAME], 'readwrite')

          const tilePutResult = tx.objectStore(TILES_OBJECT_STORE_NAME).put(tile)
          const metadataPutResult = tx.objectStore(METADATA_OBJECT_STORE_NAME).put(metadata)

          tilePutResult.onsuccess = () => {
            // console.log('tile saved successfully!')
          }
          metadataPutResult.onsuccess = () => {
            // console.log('metadata saved successfully!')
          }

          tx.oncomplete = () => {
            this.emit('tile-cached')
            resolve({
              ...tile,
              ...metadata,
            })
          }
          tx.onerror = (event) => {
            const eventTarget = event.target as IDBRequest

            this.emit('error', eventTarget.error)
            reject(eventTarget.error)
          }
        }

        dbRequest.onerror = (dbEvent) => {
          const dbEventTarget = dbEvent.target as IDBOpenDBRequest

          this.emit('error', dbEventTarget.error)
          reject(dbEventTarget.error)
        }
      })
    } catch (error) {
      throw new Error(`Error writing tile entry: ${error?.toString() ?? 'Unknown error'}`)
    }
  }
  /**
   * Seeds an area of tiles by the given bounding box, the maximal z value and the optional minimal z value.
   *
   * The returned number in the promise is equal to the duration of the operation in milliseconds.
   */
  public seedBBox(bbox: IBBox, maxZ: number, minZ: number = 0): Promise<number> {
    const start = Date.now()
    const list: ITileCoordinates[] = getListOfTilesInBBox(bbox, maxZ, minZ)
    const total: number = list.length
    return new Promise((resolve, reject) => {
      const fn = () => {
        /**
         * @event IndexedDbTileCache#seed-progess
         * @type IIndexedDbTileCacheSeedProgress
         */
        this.emit('seed-progress', {
          total,
          remains: list.length,
        } as IIndexedDbTileCacheSeedProgress)
        const val = list.shift()
        if (val) {
          this.downloadTile(val).then(() => {
            setTimeout(fn, this.options.crawlDelay)
          }, reject)
          return
        }
        resolve(Date.now() - start)
      }
      fn()
    })
  }
}
