import { createStyles } from '@mantine/core'
import * as L from 'leaflet'
import { Marker, MarkerProps } from 'react-leaflet'

type Props = {
  text: string | number
}

const useStyles = createStyles((theme) => ({
  textMarker: {
    textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.colors.gray[9],
  },
}))

export const LabelMarker = ({ text, ...rest }: Props & MarkerProps) => {
  const { classes } = useStyles()

  const distanceIcon = L.divIcon({
    html: `<div>${text}</div>`,
    className: classes.textMarker,
    iconSize: [100, 20],
    iconAnchor: [50, 30],
  })

  return <Marker interactive={false} icon={distanceIcon} {...rest} />
}
