import { ReactNode } from 'react'

import { Box, SimpleGrid, Text } from '@mantine/core'

import { MapLayerPickerCard } from './MapLayerPickerCard'

type MapLayerPickerPartProps = {
  title: string
  children: ReactNode
}
export const MapLayerPickerPart = ({ title, children }: MapLayerPickerPartProps) => (
  <Box mb="md">
    <Text
      mb="xs"
      weight="bold"
      sx={(theme) => ({
        color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[9],
        textShadow: theme.colorScheme === 'dark' ? '0 0 6px #00000085' : '0 0 6px #ffffff85',
        fontSize: 22,
      })}
    >
      {title}
    </Text>
    {children}
  </Box>
)

export const Grid = ({ children }: { children: ReactNode }) => (
  <SimpleGrid
    cols={6}
    spacing="xs"
    sx={{ maxWidth: 900 }}
    breakpoints={[
      { maxWidth: 1100, cols: 4 },
      { maxWidth: 750, cols: 3 },
      { maxWidth: 470, cols: 2 },
    ]}
  >
    {children}
  </SimpleGrid>
)

MapLayerPickerPart.Card = MapLayerPickerCard
MapLayerPickerPart.Grid = Grid
