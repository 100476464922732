import { useState } from 'react'

import { Group } from '@mantine/core'
import { InlineTextInput } from 'components/UI/InlineTextInput'
import { useUserTracks } from 'hooks/useUserTracks'
import { useTranslation } from 'react-i18next'
import { SavingStatus } from 'types/app'

import { CircleColorPicker } from './ColorPicker'

type Props = {
  trackName: string | undefined
  status: SavingStatus
}

export const TrackMeta = ({ trackName, status }: Props) => {
  const { updateTrack, selectedTrack } = useUserTracks()
  const [nameBeingSaved, setNameBeingSaved] = useState<string | undefined>()
  const { t } = useTranslation()

  const handleNameChange = async (name: string) => {
    setNameBeingSaved(name)
    if (!selectedTrack) return
    await updateTrack(selectedTrack?.id, { name })
    setNameBeingSaved(undefined)
  }

  return (
    <Group spacing={6} sx={{ flexGrow: 1, height: 28, flexWrap: 'nowrap', flexShrink: 1, minWidth: 0 }}>
      <CircleColorPicker />
      <InlineTextInput
        disabled={!selectedTrack || status === 'saving'}
        value={nameBeingSaved || trackName || t('editmode.unsaved_track_name_placeholder')}
        onChange={handleNameChange}
      />
    </Group>
  )
}
