import { Box, Button, Group, Stack, Text } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { Routing } from 'config/enums/routings'
import { useAuth } from 'hooks/useAuth'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'

export type ConfirmLosingTrackModalProps = {
  onConfirm?: () => void
}

export const ConfirmLosingTrackModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<ConfirmLosingTrackModalProps>) => {
  const {
    clear,
    state: { trackColor, routing, trackWaypoints, elevations, trackMeta },
    sharedTrack,
  } = useRouting()
  const { createTrack, trackStatus } = useUserTracks()
  const { openInfoModal } = useGenericModals()
  const { user } = useAuth()
  const { t } = useTranslation()
  const close = () => {
    context.closeModal(id)
  }

  const handleClear = () => {
    clear()
    close()
    innerProps.onConfirm?.()
  }

  const handleSave = async () => {
    if (!user) {
      close()
      openInfoModal(t('confirm_losing_track_modal.login_title'), t('confirm_losing_track_modal.login_text'))
      return
    }

    const isRoutable = routing !== Routing.none

    await createTrack({
      controlPoints: !isRoutable ? [] : trackWaypoints,
      trackPoints: trackWaypoints,
      color: trackColor || undefined,
      name: 'Track',
      trackPointsEle: elevations,
      trackPointsSpeed: trackMeta.trackPointsSpeed ?? sharedTrack?.trackPointsSpeed ?? undefined,
      trackPointsTime: trackMeta.trackPointsTime ?? sharedTrack?.trackPointsTime ?? undefined,
      ...(sharedTrack !== null && !isRoutable
        ? {
            trackPointsCadence: sharedTrack.trackPointsCadence,
            trackPointsHeartRate: sharedTrack.trackPointsHeartRate,
            trackPointsPower: sharedTrack.trackPointsPower,
            trackPointsTemperature: sharedTrack.trackPointsTemperature,
          }
        : {}),
      links: [],
      routing,
    })
    close()
    innerProps.onConfirm?.()
  }

  const isSaving = trackStatus === 'saving'

  return (
    <Stack>
      <Text>{t('confirm_losing_track_modal.text')}</Text>
      <Group spacing="xs" noWrap>
        <Box sx={{ flexGrow: 1 }}>
          <Button color="gray" variant="outline" onClick={close} disabled={isSaving}>
            {t('generic.cancel_button_label')}
          </Button>
        </Box>
        <Button color="red" variant="filled" onClick={handleClear} disabled={isSaving}>
          {t('confirm_losing_track_modal.discard_track_button')}
        </Button>
        <Button color="primaryColor" onClick={handleSave} disabled={isSaving} loading={isSaving}>
          {t('confirm_losing_track_modal.save_track_button')}
        </Button>
      </Group>
    </Stack>
  )
}
