import { useCallback, useEffect, useRef } from 'react'

import { useLeafletContext } from '@react-leaflet/core'
import { MAX_ZOOMS, TANGRAM_ATTRIBUTION } from 'config/constants'
import { Language } from 'config/enums/languages'
import { MapID } from 'config/enums/mapIDs'
import { Overlay } from 'config/enums/overlays'
import { Layer } from 'leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import Tangram from 'tangram'

const baseURL = import.meta.env.BASE_URL

type SceneMeta = {
  id: number
  name: string
  label: string
  isAnimated: boolean
  hasLabels: boolean
  colors?: { text: string; name: string }[]
  extraSupportedOverlays?: Overlay[]
}

const TANGRAM_LAYERS_META: SceneMeta[] = [
  { id: MapID.basic, name: 'basic', label: 'Basic', isAnimated: false, hasLabels: false },
  { id: MapID.bubbleWrap, name: 'bubble-wrap', label: 'Bubble-wrap', isAnimated: false, hasLabels: true },
  { id: MapID.cinnabar, name: 'cinnabar', label: 'Cinnabar', isAnimated: false, hasLabels: true },
  {
    id: MapID.refill,
    name: 'refill',
    label: 'Refill',
    isAnimated: false,
    hasLabels: true,
    colors: [
      { text: 'Black', name: 'black' },
      { text: 'Blue', name: 'blue' },
      { text: 'Blue-gray', name: 'blue-gray' },
      { text: 'Brown-orange', name: 'brown-orange' },
      { text: 'High contrast', name: 'high-contrast' },
      { text: 'Gray', name: 'gray' },
      { text: 'Gray-gold', name: 'gray-gold' },
      { text: 'Inverted', name: 'inverted' },
      { text: 'Inverted-dark', name: 'inverted-dark' },
      { text: 'Pink', name: 'pink' },
      { text: 'Pink-yellow', name: 'pink-yellow' },
      { text: 'Purple-green', name: 'purple-green' },
      { text: 'Sepia', name: 'sepia' },
      { text: 'Zinc', name: 'zinc' },
    ],
  },
  { id: MapID.street, name: 'street', label: 'Street', isAnimated: false, hasLabels: false },
  { id: MapID.topo, name: 'topo', label: 'Topo', isAnimated: false, hasLabels: false },
  { id: MapID.tron, name: 'tron', label: 'Tron', isAnimated: true, hasLabels: true },
  {
    id: MapID.walkabout,
    name: 'walkabout',
    label: 'Walkabout',
    isAnimated: false,
    hasLabels: true,
    extraSupportedOverlays: [Overlay.bikePaths, Overlay.walkPaths],
  },
]

const DEFAULT_TANGRAM_SETTINGS = {
  sdk_bike_network_overlay: false,
  sdk_bike_overlay: false,
  sdk_path_overlay: true,
  sdk_path_shields: true,
  sdk_road_shields: true,
  sdk_transit_overlay: false,
  sdk_building_extrude: false,
  ux_language: Language.en,
}

type MapLabelsCount = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

/**
 * Calculates tangrams label count from percentage (0%-100%) ---> (0 - 11 integers)
 */
const calculateLabelCountValueFromPercentage = (percentage: number) => {
  const recalculated = Math.floor(11 * 0.01 * percentage)
  if (recalculated > 11) return 11
  if (recalculated < 0) return 0
  return recalculated as MapLabelsCount
}

type TangramLayerProps = {
  sceneMeta: SceneMeta
  buildingExtrude: boolean
}
export const TangramLayer = ({ sceneMeta, buildingExtrude }: TangramLayerProps) => {
  // It would be awesome to have this typed but according to Tangram's GitHub,
  // it's a Leaflet Layer extended with bunch of stuff and it would take lot of time to type.
  // And since Tangram lib is most probably abandoned, that time would be pretty much wasted.
  /* eslint-disable @typescript-eslint/no-explicit-any */ 
  const layerRef = useRef<any | null>(null)
  const context = useLeafletContext()
  const {
    state: { mapColor, mapOverlays: overlayType, language, mapLabels },
  } = useOptions()

  const updateConfigRef = useRef<() => void>()

  const updateConfig = useCallback(() => {
    if (!layerRef.current?.scene?.config?.global) return
    const { scene } = layerRef.current
    const { global } = scene.config
    global.sdk_transit_overlay = overlayType.includes(Overlay.transit)
    global.sdk_road_shields = !overlayType.includes(Overlay.transit)
    global.sdk_bike_overlay = overlayType.includes(Overlay.bikePaths)
    global.sdk_bike_network_overlay = overlayType.includes(Overlay.bikePaths)
    global.sdk_path_overlay = overlayType.includes(Overlay.walkPaths)
    global.sdk_path_shields = overlayType.includes(Overlay.walkPaths)
    global.sdk_biking_lines = overlayType.includes(Overlay.bikeTrails)
    global.sdk_walking_lines = overlayType.includes(Overlay.hikeWaymarked)
    global.sdk_animated = sceneMeta.isAnimated
    global.sdk_building_extrude = buildingExtrude
    global.ux_language = language
    scene.updateConfig()
  }, [buildingExtrude, language, overlayType, sceneMeta.isAnimated])

  useEffect(() => {
    updateConfigRef.current = updateConfig
  }, [updateConfig])

  const constructImportArray = useCallback(() => {
    const mapLabelsCount = calculateLabelCountValueFromPercentage(mapLabels)
    const pathBase = `${baseURL}map/scenes/${sceneMeta.name}/`
    const scenePath = `${pathBase + sceneMeta.name}-style.yaml`
    const labelsPath = `${pathBase}themes/label-${mapLabelsCount}.yaml`
    const dataToImport = [scenePath]
    if (sceneMeta?.hasLabels) {
      dataToImport.push(labelsPath)
    }
    const colorChoices = sceneMeta?.colors
    if (colorChoices && colorChoices.length > 0 && mapColor) {
      const colors = `${pathBase}themes/color-${mapColor}.yaml`
      dataToImport.push(colors)
    }
    return dataToImport
  }, [mapColor, mapLabels, sceneMeta?.colors, sceneMeta?.hasLabels, sceneMeta.name])

  const pathBase = `/map/scenes/${sceneMeta.name}/`
  const scenePath = `${pathBase + sceneMeta.name}-style.yaml`
  const labelsPath = `${pathBase}themes/label-11.yaml`
  const dataToImport = [scenePath]

  if (sceneMeta?.hasLabels) {
    dataToImport.push(labelsPath)
  }

  const colorChoices = sceneMeta?.colors
  if (colorChoices && colorChoices.length > 0 && mapColor) {
    const colors = `${pathBase}themes/color-${mapColor}.yaml`
    dataToImport.push(colors)
  }

  useEffect(() => {
    layerRef.current = Tangram.leafletLayer({
      scene: { import: constructImportArray(), global: DEFAULT_TANGRAM_SETTINGS },
      attribution: TANGRAM_ATTRIBUTION,
      maxZoom: MAX_ZOOMS.tangram,
    })
    if (layerRef.current) {
      layerRef.current.scene.subscribe({
        load: () => {
          updateConfigRef.current?.()
        },
      })
    }

    const container = context.layerContainer || context.map
    container.addLayer(layerRef.current as Layer)
    return () => {
      if (layerRef.current) {
        container.removeLayer(layerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!layerRef.current) return
    const { scene } = layerRef.current
    scene.load({ import: constructImportArray() })
  }, [constructImportArray, sceneMeta.colors, mapLabels])

  useEffect(() => {
    updateConfig()
  }, [overlayType, language, buildingExtrude, sceneMeta.isAnimated, updateConfig])

  return null
}

export const TANGRAM_LAYERS_LABELS = TANGRAM_LAYERS_META.map((layer) => ({
  label: layer.label,
  value: layer.id,
}))

export const isTangramLayer = (id: number) => TANGRAM_LAYERS_META.some((layer) => layer.id === id)

export const getTangramLayerMeta = (id: number) => TANGRAM_LAYERS_META.find((layer) => layer.id === id)

export const getTangramLayer = (id: number) => {
  const meta = getTangramLayerMeta(id)
  if (!meta) return null
  return <TangramLayer key={meta.name} buildingExtrude={false} sceneMeta={meta} />
}
