import { forwardRef, MouseEventHandler } from 'react'

import { Box } from '@mantine/core'

type Props = {
  size?: number
  onClick?: MouseEventHandler
  color: string
}
export const ColorCircle = forwardRef<HTMLDivElement, Props>(({ size = 17, onClick, color }, ref) => (
  <Box ref={ref}>
    <Box
      onClick={onClick}
      sx={(theme) => ({
        backgroundColor: color,
        width: size,
        height: size,
        borderRadius: '100%',
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[2],
        transition: 'all 0.1s ease-in-out',
        boxShadow: '1px 1px 5px 0px rgb(0 0 0 / 40%)',
        '&:hover': {
          transform: 'scale(1.1)',
          boxShadow: '1px 1px 7px 0px rgb(0 0 0 / 40%)',
        },
      })}
    ></Box>
  </Box>
))

ColorCircle.displayName = 'ColorCircle'
