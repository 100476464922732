import { MouseEventHandler } from 'react'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ActionIcon, Box, Menu, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Tooltip } from './Tooltip'

export type SortDirection = 'asc' | 'desc'

export type SortingOptions<T> = {
  value: T
  label: string
  icon: FontAwesomeIconProps['icon']
  direction: SortDirection
}

type SortByPickerProps<T> = {
  data: SortingOptions<T>[]
  value: T
  sortDirection: SortDirection
  onItemChange: (value: T, direction: SortDirection) => void
}

interface CustomMenuItemProps {
  icon: FontAwesomeIconProps['icon']
  text: string
  onClick: MouseEventHandler
  selected: boolean
}
const CustomMenuItem = ({ icon, text, onClick, selected }: CustomMenuItemProps) => {
  const theme = useMantineTheme()
  const isDark = theme.colorScheme === 'dark'

  const getBackgroundColor = () => {
    if (selected) return isDark ? theme.colors.violet[8] : theme.colors.violet[1]
    return ''
  }
  const getHoverBackgroundColor = () => {
    if (selected) return isDark ? theme.colors.violet[7] : theme.colors.violet[0]
    return ''
  }

  return (
    <Menu.Item
      sx={{ backgroundColor: getBackgroundColor(), '&:hover': { backgroundColor: getHoverBackgroundColor() } }}
      rightSection={
        <FontAwesomeIcon fontSize={16} color={isDark ? theme.colors.dark[1] : theme.colors.gray[6]} icon={icon} />
      }
      onClick={onClick}
    >
      {text}
    </Menu.Item>
  )
}

export const SortByPicker = <T extends string | number>({ data, onItemChange, value, sortDirection }: SortByPickerProps<T>) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const currentItem = data.find((item) => item.value === value && item.direction === sortDirection)
  if (!currentItem) throw new Error('Incorrect data sent to sortByPicker')
  const { icon } = currentItem

  return (
    <>
      <Menu withinPortal styles={{ itemRightSection: { marginLeft: theme.spacing.md } }}>
        <Menu.Target>
          <ActionIcon color="dark">
            {/* tooltips don't work in menu.target directly, but might in the future */}
            <Tooltip label={t('itemlist.sort_button_tooltip')}>
              <Box
                sx={{ height: '100%', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
              >
                <FontAwesomeIcon icon={icon} />
              </Box>
            </Tooltip>
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {data.map((item, i) => (
            <CustomMenuItem
              key={i}
              text={item.label}
              selected={item.value === value && item.direction === sortDirection}
              onClick={() => onItemChange(item.value, item.direction)}
              icon={item.icon}
            />
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  )
}
