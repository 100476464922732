import { faCheck, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationProps, showNotification } from '@mantine/notifications'

export const showSuccessNotification = (notification: NotificationProps) => {
  showNotification({
    ...notification,
    color: 'teal',
    icon: <FontAwesomeIcon icon={faCheck} />,
  })
}

export const showDeleteSuccessNotification = (notification: NotificationProps) => {
  showNotification({
    ...notification,
    color: 'red',
    icon: <FontAwesomeIcon icon={faTrash} />,
  })
}

export const showErrorNotification = (notification: NotificationProps) => {
  showNotification({
    ...notification,
    color: 'red',
    icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
  })
}
