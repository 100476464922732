import axios from 'axios'
import { Routing } from 'config/enums/routings'
import { Coords, RoutingAPI, RoutingCostingOptions } from 'types/app'
import { decodePolyline } from 'utils/polyline'

const ROUTING_API_URL = import.meta.env.VITE_ROUTING_API_URL ?? ''

const parseRoutingData = (data: RoutingAPI) => {
  if (data?.trip?.legs && data.trip.legs.length > 0) {
    const { legs } = data.trip

    const newTrackpoints = legs.reduce((trackpoints, leg) => {
      if (leg?.maneuvers && leg?.shape) {
        const polyline = decodePolyline(leg.shape, 6)
        return [...trackpoints, ...polyline]
      }
      return trackpoints
    }, [] as Coords[])

    return newTrackpoints.length > 0 ? newTrackpoints : null
  }
  return null
}

export const routeToPoint = async (
  from: Coords,
  to: Coords,
  routing: Routing,
  costingOptions?: RoutingCostingOptions
) => {
  const getCostingOptionsPayload = () => {
    switch (routing) {
      case Routing.bicycle: {
        return costingOptions !== undefined && costingOptions[Routing.bicycle]
          ? {
              [Routing.bicycle]: {
                bicycle_type: costingOptions[Routing.bicycle].bicycleType,
                use_hills: costingOptions[Routing.bicycle].useHills,
              },
            }
          : undefined
      }
      case Routing.auto:
      case Routing.pedestrian:
      case Routing.none:
      default:
        return undefined
    }
  }

  const res = await axios.get(
    `${ROUTING_API_URL}?json=${JSON.stringify({
      locations: [
        {
          lat: from[0],
          lon: from[1],
          type: 'break',
        },
        {
          lat: to[0],
          lon: to[1],
          type: 'break',
        },
      ],
      costing: routing,
      costing_options: getCostingOptionsPayload(),
    })}`
  )
  const { data } = res
  return parseRoutingData(data)
}
