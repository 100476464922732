import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'

import axios from 'axios'
import { setCesiumMode } from 'stores/genericStore/actions'
import { useGenericDispatch } from 'stores/genericStore/GenericContext'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { TrackRaw, TrackWithMetadata } from 'types/app'
import { parseTrackFromAPI } from 'utils/helpers/trackParser/trackParser'
import { getTracksWithMetadata } from 'utils/tracks/getTracksWithMetadata'

import { useAuth } from './useAuth'
import { useEditMode } from './useEditMode'
import { useGenericModals } from './useGenericModals'
import { useMapContext } from './useMapContext'

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL

interface PublicTrackData {
  tracks: TrackWithMetadata[] | null
  selectedTrack: TrackWithMetadata | null
  selectTrack: (id: number | null) => void
}

const PublicTrackContext = React.createContext<PublicTrackData>({
  tracks: null,
  selectedTrack: null,
  selectTrack: () => null,
})

export const useProvidePublicTrackData = (): PublicTrackData => {
  const [tracks, setTracks] = useState<TrackWithMetadata[] | null>(null)
  const [filteredTracks, setFilteredTracks] = useState<TrackWithMetadata[] | null>(null)
  const [selectedTrackId, setSelectedTrackId] = useState<number | null>(null)
  const { editMode, setEditMode } = useEditMode()
  const { openConfirmLosingTrackModal } = useGenericModals()
  const genericDispatch = useGenericDispatch()
  const { user } = useAuth()
  const { setEditedTrack } = useRouting()
  const {
    state: { language },
  } = useOptions()
  const { map } = useMapContext()
  const tracksRef = useRef(tracks)
  const selectedTrackIdRef = useRef(selectedTrackId)
  const editModeRef = useRef(editMode)
  useEffect(() => {
    tracksRef.current = tracks
  }, [tracks])
  useEffect(() => {
    selectedTrackIdRef.current = selectedTrackId
  }, [selectedTrackId])
  useEffect(() => {
    editModeRef.current = editMode
  }, [editMode])

  const {
    state: { showPublicTracks },
  } = useOptions()

  useEffect(() => {
    if (!tracks) return
    // Filtering tracks to not show user's public tracks twice
    const filtered = tracks.filter((track) => (user ? track.userId !== user.id : true))
    setFilteredTracks(filtered)
  }, [tracks, user])

  const fetchTracks = useCallback(async () => {
    try {
      const { data } = await axios.get(`${BASE_API_URL}/get-public-tracks?agent=Trackbook&lng=${language}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const rawTracks = data.tracks as TrackRaw[]
      const parsedTracks = rawTracks.map((rawTrack) => parseTrackFromAPI(rawTrack))
      const tracksWithMeta = getTracksWithMetadata(parsedTracks)
      setTracks(tracksWithMeta)
      return data.tracks
    } catch (error) {
      setTracks(null)
      return console.error('error getting public tracks')
    }
  }, [language])

  const selectTrack = useCallback(
    (id: number | null) => {
      genericDispatch(setCesiumMode(false))
      if (id === null) {
        setSelectedTrackId(null)
        setEditedTrack(null)
        return
      }
      if (!tracksRef.current) throw new Error('Tracks empty')
      const foundTrack = tracksRef.current.find((track) => track.id === id)
      if (!foundTrack) throw new Error('Track not found')

      const select = () => {
        setSelectedTrackId(foundTrack.id)
        setEditedTrack(foundTrack, map)
        setEditMode('track')
      }

      if (editModeRef.current === 'track' && !selectedTrackIdRef.current) {
        openConfirmLosingTrackModal({ onConfirm: select })
        return
      }
      select()
    },
    [genericDispatch, map, openConfirmLosingTrackModal, setEditMode, setEditedTrack]
  )

  useEffect(() => {
    if (showPublicTracks && (!tracksRef.current || tracksRef.current?.length === 0)) {
      fetchTracks()
    }
    if (!showPublicTracks) setTracks([])
  }, [fetchTracks, showPublicTracks])

  const selectedTrack = useMemo(() => {
    const foundTrack = tracks?.find((value) => value.id === selectedTrackId)
    // return a new copy to prevent accident original array mutation
    return foundTrack ? { ...foundTrack } : null
  }, [selectedTrackId, tracks])

  return {
    tracks: filteredTracks,
    selectedTrack,
    selectTrack,
  }
}

export const usePublicTracks = () => useContext(PublicTrackContext)

export const PublicTracksDataProvider = ({ children }: { children: ReactNode }) => {
  const userTrackData = useProvidePublicTrackData()
  return <PublicTrackContext.Provider value={userTrackData}>{children}</PublicTrackContext.Provider>
}
