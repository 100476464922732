/**
 * Normalizing function
 * source: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @param text text to be normalized
 * @returns string with removed accents/diacritics
 */
const normalizeString = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

// CSS "display: none" works way faster than truly filtering the data (and unmounting/remounting components)
// that's why "hidden: true" property is added to the data instead of filtering them

/**
 * Adds { hidden: true } to filtered out data
 * @param data Object with data to be filtered
 * @param accessor Accessor to one of the object properties (property value must be string)
 * @param keyword Filtering keyword
 * @returns
 */
export const filterDataByString = <T>(data: T[] | undefined, accessor: keyof T, keyword: string) => {
  if (!data) return undefined
  return data.map((datapoint) => {
    const filterableValue = datapoint[accessor] as unknown as string
    const normalizedFilterableValue = normalizeString(filterableValue)
    const normalizedKeyword = normalizeString(keyword)
    if (typeof filterableValue !== 'string') throw new Error('Accessor must lead to a string property')
    if (normalizedFilterableValue.toLowerCase().includes(normalizedKeyword.toLowerCase())) {
      return datapoint
    }
    return { ...datapoint, hidden: true }
  })
}
