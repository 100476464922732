import { useCallback, useEffect, useRef, useState } from 'react'

import './WaypointAddingIndicator.css'
import { Paper, Text } from '@mantine/core'
import { useEditMode } from 'hooks/useEditMode'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { LeafletMouseEvent } from 'leaflet'
import * as L from 'leaflet'
import { useTranslation } from 'react-i18next'
import { useMap } from 'react-leaflet'
import { Coords } from 'types/app'

const WaypointAddingIndicator = () => {
  const [pos, setPos] = useState({ x: 0, y: 0 })
  const { isAddingWaypoint, setIsAddingWaypoint, createWaypoint } = useUserWaypoints()
  const { editMode } = useEditMode()
  const [isMapHovered, setIsMapHovered] = useState(false)
  const { openCoordsInputModal } = useGenericModals()
  const map = useMap()
  const { t } = useTranslation()

  const isAddingWaypointRef = useRef(isAddingWaypoint)
  useEffect(() => {
    isAddingWaypointRef.current = isAddingWaypoint
  }, [isAddingWaypoint])

  useEffect(() => {
    if (editMode !== 'default') setIsAddingWaypoint(false)
  }, [editMode, setIsAddingWaypoint])

  const addWaypoint = useCallback(
    (e: LeafletMouseEvent) => {
      // adding of waypoint can be canceled at multiple places
      // that's why we need to check if the mode is still active here
      if (!isAddingWaypointRef.current) return
      const coords: Coords = [e.latlng.lat, e.latlng.lng]
      createWaypoint({ point: coords })
      setIsAddingWaypoint(false)
    },
    [createWaypoint, setIsAddingWaypoint]
  )

  const handleContextOpen = useCallback(() => {
    openCoordsInputModal(() => setIsAddingWaypoint(false))
  }, [openCoordsInputModal, setIsAddingWaypoint])

  useEffect(() => {
    const wrapper = map.getContainer()
    wrapper?.addEventListener('mouseenter', () => setIsMapHovered(true))
    wrapper?.addEventListener('mouseleave', () => setIsMapHovered(false))
  }, [map])

  useEffect(() => {
    if (!map) return undefined

    if (isAddingWaypoint) {
      L.DomUtil.addClass(map.getContainer(), 'cursor-override')
      map.on('contextmenu', handleContextOpen, 'addWaypointRightClick')
      map.once('click', addWaypoint, 'addSingleWaypointContext')
    }
    return () => {
      L.DomUtil.removeClass(map.getContainer(), 'cursor-override')
      map.off('contextmenu', handleContextOpen, 'addWaypointRightClick')
      map.off('click', addWaypoint, 'addSingleWaypointContext')
    }
  }, [map, isAddingWaypoint, handleContextOpen, addWaypoint])

  const handleMouseMove = (e: MouseEvent) => {
    setPos({ x: e.pageX, y: e.pageY })
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    return () => document.removeEventListener('mousemove', handleMouseMove)
  }, [])

  return isMapHovered ? (
    <Paper
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1000,
        transform: `translate3d(${pos.x + 18}px, ${pos.y + 22}px, 0px)`,
        pointerEvents: 'none',
      }}
      shadow="sm"
      py={2}
      px={6}
    >
      <Text size="xs">{t('adding_waypoint_indicator_label.left_click')}</Text>
      <Text size="xs">{t('adding_waypoint_indicator_label.right_click')}</Text>
    </Paper>
  ) : null
}

export const WaypointAddingIndicatorWrapper = () => {
  const { isAddingWaypoint } = useUserWaypoints()
  return isAddingWaypoint ? <WaypointAddingIndicator /> : null
}
