import { MAX_ZOOMS } from 'config/constants'
import { MapID } from 'config/enums/mapIDs'
import { CachedReactLeafletTileLayer } from 'lib/leaflet-cached-tile-layer'

type RasterLayerMeta = {
  id: RasterMapID
  label: string
  provider: string
  url: string
  attribution: string
}

export type RasterMapID = MapID.openStreetMap | MapID.openTopoMap | MapID.cyclOSM | MapID.satellite

export const RASTER_LAYERS_META: RasterLayerMeta[] = [
  {
    id: MapID.openStreetMap,
    label: 'OpenStreetMap',
    provider: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  {
    id: MapID.openTopoMap,
    label: 'OpenTopoMap',
    provider: 'OpenTopoMap',
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://opentopomap.org/about">OpenTopoMap</a>',
  },
  {
    id: MapID.cyclOSM,
    label: 'CyclOSM',
    provider: 'CyclOSM',
    url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://www.cyclosm.org/legend.html">CyclOSM</a>',
  },
  {
    id: MapID.satellite,
    label: 'Satellite',
    provider: 'DigitalGlobe',
    url: 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/jpg?apiKey=5vMCWygbMZGe1nO78iLqtzweBG_0KtzlbtqZaKrMaOs',
    attribution: '&copy; HERE, DigitalGlobe',
  },
]

export const RASTER_LAYERS_LABELS = RASTER_LAYERS_META.map((layer) => ({ label: layer.label, value: layer.id }))

export const getCachedRasterTileLayer = (mapID: RasterMapID) => {
  const meta = RASTER_LAYERS_META.find((layer) => layer.id === mapID) as RasterLayerMeta

  return (
    <CachedReactLeafletTileLayer
      key={meta.label}
      url={meta.url}
      provider={meta.provider}
      attribution={meta.attribution}
      maxZoom={MAX_ZOOMS.raster}
      maxNativeZoom={18}
      databaseName={import.meta.env.VITE_CACHE_DB_NAME}
      databaseVersion={import.meta.env.VITE_CACHE_DB_VERSION}
    />
  )
}
