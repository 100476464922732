import { useEffect } from 'react'

export const useImagePreload = (srcs: string[]) => {
  useEffect(() => {
    const imagesToPreload = srcs.map((src) => {
      const img = new Image()
      img.src = src
      return img
    })
    window.preloadedImages = imagesToPreload
  }, [srcs])
}
