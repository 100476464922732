import { ChartTooltipData } from './state'

export enum ActionType {
  REWRITE_CHART_TOOLTIP_DATA,
  TOGGLE_CESIUM,
  SET_CESIUM_MODE,
}

interface RewriteChartTooltipData {
  type: ActionType.REWRITE_CHART_TOOLTIP_DATA
  payload: ChartTooltipData
}

interface ToggleCesium {
  type: ActionType.TOGGLE_CESIUM
}

interface SetCesiumMode {
  type: ActionType.SET_CESIUM_MODE
  payload: boolean
}

export type GenericActions = RewriteChartTooltipData | ToggleCesium | SetCesiumMode

export const rewriteChartTooltipData = (data: ChartTooltipData): RewriteChartTooltipData => ({
  type: ActionType.REWRITE_CHART_TOOLTIP_DATA,
  payload: data,
})

export const toggleCesium = (): ToggleCesium => ({
  type: ActionType.TOGGLE_CESIUM,
})

export const setCesiumMode = (mode: boolean): SetCesiumMode => ({
  type: ActionType.SET_CESIUM_MODE,
  payload: mode,
})
