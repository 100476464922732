import { MouseEventHandler, ReactNode } from 'react'

import { ActionIcon, MantineColor, useMantineTheme } from '@mantine/core'

import { CollapsibleWidthComponent, TransitionableComponentProps } from './CollapsibleComponent'

interface CollapsibleActionIconProps {
  isOpen: TransitionableComponentProps['isOpen']
  children: ReactNode
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  color?: MantineColor
  active?: boolean
}

const DEFAULT_ACTIVE_ICON_COLOR: MantineColor = 'teal'

export const CollapsibleTopMenuActionIcon = ({
  children,
  isOpen,
  disabled,
  onClick,
  color,
  active,
}: CollapsibleActionIconProps) => {
  const theme = useMantineTheme()

  return (
    <CollapsibleWidthComponent height={28} width={28} isOpen={isOpen}>
      <ActionIcon
        variant="light"
        color={color ?? (active ? DEFAULT_ACTIVE_ICON_COLOR : theme.primaryColor)}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </ActionIcon>
    </CollapsibleWidthComponent>
  )
}
