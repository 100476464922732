import { MouseEvent } from 'react'

import { PUBicon } from 'assets/icons/PUBicon'
import { PUBiconDisabled } from 'assets/icons/PUBiconDisabled'
import { Tooltip } from 'components/UI/Tooltip'
import { useTranslation } from 'react-i18next'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { ControlActionIcon } from '../ControlActionIcon'

export const PublicTracksControl = () => {
  const {
    dispatch,
    state: { showPublicTracks },
  } = useOptions()
  const { t } = useTranslation()

  const toggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dispatch(updateNonSaveableOptions({ showPublicTracks: !showPublicTracks }))
  }

  return (
    <Tooltip position="left" label={t('map_controls.public_tracks_button_tooltip')}>
      <ControlActionIcon onClick={toggle}>{showPublicTracks ? <PUBicon /> : <PUBiconDisabled />}</ControlActionIcon>
    </Tooltip>
  )
}
