import { LeafletEventHandlerFnMap } from 'leaflet'
import { Polyline, PolylineProps } from 'react-leaflet'

import { PolylineWithArrowheads } from './PolylineWithArrowheads'

type Props = {
  positions: PolylineProps['positions']
  color: string
  eventHandlers?: LeafletEventHandlerFnMap
  disabledArrowheads?: boolean
}
/**
 * Polyline used to display non routable tracks
 */
export const NonRoutablePolyline = ({ positions, color, eventHandlers, disabledArrowheads }: Props) => (
  <>
    <Polyline pathOptions={{ color: 'black', weight: 10, opacity: 0.2 }} positions={positions} interactive={false} />
    <Polyline
      pathOptions={{ color: 'white', weight: 8, opacity: 0.8 }}
      positions={positions}
      eventHandlers={eventHandlers}
    />
    <PolylineWithArrowheads
      disabledArrowheads={disabledArrowheads ?? false}
      interactive={false}
      pathOptions={{ color, weight: 4, opacity: 0.9 }}
      positions={positions}
      arrowheads={{
        size: '4px',
        color: 'black',
        yawn: 90,
        frequency: '1000m',
      }}
    />
  </>
)
