import { MantineThemeOverride } from '@mantine/core'

declare module '@mantine/core' {
  export interface MantineThemeOther {
    controlButtonShadow: string
  }
}

export const MANTINE_THEME: MantineThemeOverride = {
  fontSizes: {
    xs: 13,
  },
  shadows: {
    xs: '0 1px 2px 0 rgb(0 0 0 / 0.1)',
    sm: '0 1px 3px 0 rgb(0 0 0 / 0.2), 0 1px 2px -1px rgb(0 0 0 / 0.2)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.2), 0 4px 6px -4px rgb(0 0 0 / 0.2)',
    xl: '0 20px 25px -5px rgb(0 0 0 / 0.2), 0 8px 10px -6px rgb(0 0 0 / 0.2)',
  },
  other: {
    // shadow used for map controls
    controlButtonShadow: '0px 1px 4px rgb(0 0 0 / 20%), 0 1px 2px rgb(0 0 0 / 20%)',
  },
  defaultRadius: 'sm',
  cursorType: 'pointer',
  primaryColor: 'violet',
}

export const COLOR_SWATCHES = [
  '#25262B',
  '#FA5252',
  '#E64980',
  '#BE4BDB',
  '#682BCB', // default track color
  '#4C6EF5',
  '#228BE6',
  '#15AABF',
  '#12B886',
  '#40C057',
  '#FAB005',
  '#FD7E14',
]
