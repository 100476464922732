import chartPositionmarker from 'assets/cesium/chartPositionMarker.svg'
import endMarker from 'assets/cesium/endMarkerWhiteDot.svg'
import startMarker from 'assets/cesium/startMarkerWhiteDot.svg'
import { Cartesian3, HeightReference, VerticalOrigin } from 'cesium'
import { BillboardGraphics, Entity } from 'resium'

type CesiumMarkerType = 'start' | 'end' | 'chartPosition'

type Props = {
  position: Cartesian3
  type: CesiumMarkerType
}

const getMarkerByType = (type: CesiumMarkerType) => {
  if (type === 'start') return startMarker
  if (type === 'end') return endMarker
  return chartPositionmarker
}

export const CesiumMarker = ({ position, type }: Props) => (
  <Entity position={position}>
    <BillboardGraphics
      image={getMarkerByType(type)}
      width={28}
      height={39}
      verticalOrigin={VerticalOrigin.BOTTOM}
      heightReference={HeightReference.CLAMP_TO_GROUND}
    />
  </Entity>
)
