import { ReactNode } from 'react'

import { TOP_MENU_ITEMS_MARGINS } from 'config/constants'
import { useTransition, animated } from 'react-spring'

export interface TransitionableComponentProps {
  isOpen: boolean
  children: ReactNode
  height: number
  width: number
}

export const CollapsibleWidthComponent = ({ children, isOpen, width, height }: TransitionableComponentProps) => {
  const transitions = useTransition(isOpen, {
    from: { width: 0, marginLeft: 0, marginRight: 0, scale: 0, opacity: 0 },
    enter: { width, marginLeft: TOP_MENU_ITEMS_MARGINS, marginRight: TOP_MENU_ITEMS_MARGINS, scale: 1, opacity: 1 },
    leave: { width: 0, marginLeft: 0, marginRight: 0, scale: 0, opacity: 0 },
  })
  return transitions((styles, item) => item && <animated.div style={{ ...styles, height }}>{children}</animated.div>)
}
