import { MapID } from 'config/enums/mapIDs'

import Basic from './basic.jpg'
import BubbleWrap from './bubbleWrap.jpg'
import Cinnabar from './cinnabar.jpg'
import CycloOSM from './cycloOSM.jpg'
import Satellite from './heresattelite.jpg'
import OpenStreetMap from './openstreetmap.jpg'
import OpenTopoMap from './opentopomap.jpg'
import Refill from './refill.jpg'
import Street from './street.jpg'
import Topo from './topo.jpg'
import Tron from './tron.jpg'
import Walkabout from './walkabout.jpg'

export const MAP_TYPE_PREVIEWS = {
  [MapID.basic]: Basic,
  [MapID.bubbleWrap]: BubbleWrap,
  [MapID.cinnabar]: Cinnabar,
  [MapID.cyclOSM]: CycloOSM,
  [MapID.satellite]: Satellite,
  [MapID.openStreetMap]: OpenStreetMap,
  [MapID.openTopoMap]: OpenTopoMap,
  [MapID.refill]: Refill,
  [MapID.street]: Street,
  [MapID.topo]: Topo,
  [MapID.tron]: Tron,
  [MapID.walkabout]: Walkabout,
}
