import { forwardRef } from 'react'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Group, Text, UnstyledButton, createStyles, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.gray[6],
  },
}))

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  email: string
}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ email, ...rest }: UserButtonProps, ref) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const theme = useMantineTheme()

  return (
    <UnstyledButton
      ref={ref}
      sx={{
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        borderRadius: theme.radius.sm,
        '@media(hover: hover)': {
          '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        },
      }}
      {...rest}
    >
      <Group spacing="xs" sx={{ flexWrap: 'nowrap' }}>
        <Avatar radius="xl" color={theme.primaryColor} />
        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {t('generic.your_account_label')}
          </Text>

          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>

        <FontAwesomeIcon icon={faChevronDown} className={classes.icon} />
      </Group>
    </UnstyledButton>
  )
})

UserButton.displayName = 'UserButton'
