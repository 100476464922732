import { ReactNode, useEffect, useState } from 'react'

import { Group, Paper, Stack, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useGenericState } from 'stores/genericStore/GenericContext'

const Row = ({ icon, text }: { icon: ReactNode; text: string | undefined }) => (
  <Group spacing={6} sx={{ flexWrap: 'nowrap' }}>
    <Stack
      sx={(theme) => ({ width: 18, color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7] })}
    >
      {icon}
    </Stack>
    <Text size="xs" sx={{ whiteSpace: 'nowrap' }}>
      {text}
    </Text>
  </Group>
)

export type ChartTooltipProps = {
  xAxisIcon?: ReactNode
  yAxisIcon?: ReactNode
}

export const ChartTooltip = ({ xAxisIcon, yAxisIcon }: ChartTooltipProps) => {
  const [isTooltipLeft, setIsTooltipLeft] = useState(false)
  const { chartTooltipData } = useGenericState()
  const { ref, width } = useElementSize()

  useEffect(() => {
    if (!chartTooltipData) return
    setIsTooltipLeft(chartTooltipData.left > width * 0.5)
  }, [chartTooltipData?.left, width, chartTooltipData])

  return chartTooltipData?.isHovered ? (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: chartTooltipData.left,
          top: 0,
          bottom: 0,
          pointerEvents: 'none',
          zIndex: 100,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: chartTooltipData.top - 22,
            right: isTooltipLeft ? 10 : undefined,
            left: isTooltipLeft ? undefined : 10,
          }}
        >
          <Paper
            withBorder={true}
            py={4}
            px={10}
            shadow="xs"
            sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white' })}
          >
            <Row icon={xAxisIcon} text={chartTooltipData.xAxisString} />
            <Row icon={yAxisIcon} text={chartTooltipData.yAxisString} />
          </Paper>
        </div>
      </div>
    </div>
  ) : null
}
