import { Slider, SliderProps, Stack, Text, useMantineTheme } from '@mantine/core'

type SliderWithLabelProps = {
  label: string
  labelFontSize?: string | number
  markLabelFontSize?: string | number
  markTooltip?: SliderProps['label']
} & Omit<SliderProps, 'label'>

export const SliderWithLabel = (props: SliderWithLabelProps) => {
  const { label, labelFontSize, markLabelFontSize, markTooltip = null, ...rest } = props
  const theme = useMantineTheme()

  return (
    <Stack spacing="xs">
      <Text
        style={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#495057',
          fontSize: labelFontSize ?? '0.8em',
        }}
      >
        {label}
      </Text>
      <Slider
        mb="lg"
        thumbSize={18}
        size="xs"
        radius="sm"
        label={markTooltip}
        styles={(theme_) => ({
          track: {
            '&:before': {
              backgroundColor: theme_.colorScheme === 'dark' ? theme_.colors.dark[1] : theme_.colors.gray[5],
            },
          },
          mark: {
            border: theme_.colorScheme === 'light' ? '2px solid #7950f2' : 'none',
            width: '12px',
            height: '12px',
            position: 'relative',
            left: '-3px',
            top: '-3px',
          },
          markLabel: {
            fontSize: markLabelFontSize ?? '0.7em',
            color: theme_.colorScheme === 'dark' ? theme_.colors.dark[0] : '#495057',
          },
        })}
        {...rest}
      />
    </Stack>
  )
}
