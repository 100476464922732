import landscape1 from './landscape1.jpg'
import landscape10 from './landscape10.jpg'
import landscape11 from './landscape11.jpg'
import landscape12 from './landscape12.jpg'
import landscape2 from './landscape2.jpg'
import landscape3 from './landscape3.jpg'
import landscape4 from './landscape4.jpg'
import landscape5 from './landscape5.jpg'
import landscape6 from './landscape6.jpg'
import landscape7 from './landscape7.jpg'
import landscape8 from './landscape8.jpg'
import landscape9 from './landscape9.jpg'

export const landscapes = [
  landscape1,
  landscape2,
  landscape3,
  landscape4,
  landscape5,
  landscape6,
  landscape7,
  landscape8,
  landscape9,
  landscape10,
  landscape11,
  landscape12,
]
