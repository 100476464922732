import { WaypointIcon } from '../icons/icons'

export const getIconsWithUniqueNames = (data: WaypointIcon[]) => {
  const usedWaypointIconNames: string[] = []
  // The Mantine Select component cannot handle multiple items with the same value
  // Therefore we first have to make sure the names are unique, and then strip the extra unique part
  // when displaying the image
  const waypointIconsUniqueNames = data.map((icon) => {
    if (usedWaypointIconNames.includes(icon.name)) {
      const countOfPreviousUses = usedWaypointIconNames.filter((name) => name === icon.name).length
      usedWaypointIconNames.push(icon.name)
      return { key: icon.key, name: `${icon.name}-UNIQUE-${countOfPreviousUses + 1}` }
    }
    usedWaypointIconNames.push(icon.name)
    return icon
  })

  return waypointIconsUniqueNames
}
