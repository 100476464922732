import { Routing } from 'config/enums/routings'
import { PresentStateGetter, UndoReducerAction } from 'hooks/useUndoReducer'
import { isEmpty } from 'lodash'
import { Coords, DeepPartial, RoutingCostingOptions } from 'types/app'
import { getDistanceBetweenCoords } from 'utils/coords'

import { ComputedTrackPointsWithAttributes, NonRoutableTrackMetadata, RoutingState, TrackMeta } from './state'

export enum ActionType {
  ADD_TRACK_WAYPOINT = 'add_track_waypoint',
  ADD_TRACK_WAYPOINTS = 'add_track_waypoints',
  DELETE_TRACK_WAYPOINT = 'delete_track_waypoint',
  UPDATE_TRACK_WAYPOINT = 'update_track_waypoint',
  CLEAR_TRACK_WAYPOINTS = 'clear_track_waypoint',
  REWRITE_TRACK_WAYPOINTS = 'rewrite_track_waypoints',
  CHANGE_ROUTING_TYPE = 'change_routing_type',
  REWRITE_COMPUTED_TRACKPOINTS = 'rewrite_computed_trackpoints',
  REWRITE_COMPUTED_TRACKPOINTS_WITH_ATTRIBUTES = 'rewrite_computed_trackpoints_with_attributes',
  REWRITE_ELEVATIONS = 'rewrite_elevations',
  CLEAR_ELEVATIONS = 'clear_elevations',
  REWRITE_TRACK_META = 'rewrite_track_meta',
  SHOW_PLACEHOLDER_TRACK = 'show_placeholder_track',
  CHANGE_TRACK_COLOR = 'change_track_color',
  SET_ARE_ELEVATIONS_LOADING = 'set_are_elevations_loading',
  SET_IS_ROUTING = 'set_is_routing',
  UPDATE_NON_ROUTABLE_TRACK_METADATA = 'update_non_routable_track_metadata',
  UPDATE_ROUTING_COSTING_OPTIONS = 'update_routing_costing_options',
}

interface AddTrackWaypoint {
  type: ActionType.ADD_TRACK_WAYPOINT
  payload: {
    coords: Coords
    index?: number
  }
}

interface AddTrackWaypoints {
  type: ActionType.ADD_TRACK_WAYPOINTS
  payload: Coords[]
}

interface DeleteTrackWaypoint {
  type: ActionType.DELETE_TRACK_WAYPOINT
  payload: number
}

interface UpdateTrackWaypoint {
  type: ActionType.UPDATE_TRACK_WAYPOINT
  payload: {
    index: number
    coords: Coords
  }
}

interface ClearTrackWaypoints {
  type: ActionType.CLEAR_TRACK_WAYPOINTS
}

interface RewriteTrackWaypoints {
  type: ActionType.REWRITE_TRACK_WAYPOINTS
  payload: Coords[]
}

interface UpdateNonRoutableTrackMetadata extends UndoReducerAction {
  type: ActionType.UPDATE_NON_ROUTABLE_TRACK_METADATA
  payload: NonRoutableTrackMetadata
}

interface ChangeRoutingType {
  type: ActionType.CHANGE_ROUTING_TYPE
  payload: Routing
}

interface UpdateRoutingCostingOptions {
  type: ActionType.UPDATE_ROUTING_COSTING_OPTIONS
  payload: DeepPartial<RoutingCostingOptions>
}

interface RewriteComputedTrackpoints extends UndoReducerAction {
  type: ActionType.REWRITE_COMPUTED_TRACKPOINTS
  payload: Coords[]
}

interface RewriteComputedTrackpointsWithAttributes {
  type: ActionType.REWRITE_COMPUTED_TRACKPOINTS_WITH_ATTRIBUTES
  payload: ComputedTrackPointsWithAttributes[]
}

interface RewriteElevations extends UndoReducerAction {
  type: ActionType.REWRITE_ELEVATIONS
  payload: number[]
}

interface ClearElevations extends UndoReducerAction {
  type: ActionType.CLEAR_ELEVATIONS
}

interface RewriteTrackMeta extends UndoReducerAction {
  type: ActionType.REWRITE_TRACK_META
  payload: TrackMeta
}

interface ChangeShowPlaceholderTrack extends UndoReducerAction {
  type: ActionType.SHOW_PLACEHOLDER_TRACK
  payload: boolean
}

interface ChangeTrackColor extends UndoReducerAction {
  type: ActionType.CHANGE_TRACK_COLOR
  payload: string | null
}

interface SetAreElevationsLoading {
  type: ActionType.SET_ARE_ELEVATIONS_LOADING
  payload: boolean
}

interface SetIsRouting {
  type: ActionType.SET_IS_ROUTING
  payload: boolean
}

// Inserts a new item into an array
const insertAtIndex = <T>(arr: T[], item: T, index: number) => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
]

const removeAtIndex = <T>(arr: T[], index: number) => [
  ...arr.slice(0, index),
  ...(index + 1 === arr.length ? [] : arr.slice(index + 1)),
]

export const addTrackWaypoint = (coords: Coords, index?: number): AddTrackWaypoint => ({
  type: ActionType.ADD_TRACK_WAYPOINT,
  payload: { coords, index },
})
export const addTrackWaypoints = (coords: Coords[]): AddTrackWaypoints => ({
  type: ActionType.ADD_TRACK_WAYPOINTS,
  payload: coords,
})
export const deleteTrackWaypoint = (index: number): DeleteTrackWaypoint => ({
  type: ActionType.DELETE_TRACK_WAYPOINT,
  payload: index,
})
export const updateTrackWaypoint = (index: number, coords: Coords): UpdateTrackWaypoint => ({
  type: ActionType.UPDATE_TRACK_WAYPOINT,
  payload: { index, coords },
})
export const clearTrackWaypoints = (): ClearTrackWaypoints => ({ type: ActionType.CLEAR_TRACK_WAYPOINTS })

export const rewriteTrackWaypoints = (trackWaypoints: Coords[]): RewriteTrackWaypoints => ({
  type: ActionType.REWRITE_TRACK_WAYPOINTS,
  payload: trackWaypoints,
})

export const updateNonRoutableTrackMetadata = (metadata: NonRoutableTrackMetadata): UpdateNonRoutableTrackMetadata => ({
  type: ActionType.UPDATE_NON_ROUTABLE_TRACK_METADATA,
  payload: metadata,
})

export const addWaypointForNonRoutableTrack =
  (coords: Coords, pointIndex?: number) => (getState: PresentStateGetter<RoutingState>) => {
    const currentState = getState()
    let updatedTrackMetadata: Partial<{ [key: string]: number[] }> = {}
    // This method assumes that the track waypoint wasn't added yet (using a different method/redux action), so pointIndex
    // can be equal to the length of the trackWaypoints array since it hasn't been inserted there yet
    const isMidPoint = pointIndex !== undefined && pointIndex !== 0 && pointIndex < currentState.trackWaypoints.length

    // When isMidPoint is false, we assume the new point is a new final (ending) point
    if (!isMidPoint) {
      // For the new final point we just copy values from the previous final point
      updatedTrackMetadata = Object.entries(currentState.nonRoutableTrackMetadata).reduce((acc, [key, value]) => {
        if (isEmpty(value)) return acc

        return {
          ...acc,
          [key]: [...value, value[value.length - 1]],
        }
      }, {})
    } else {
      // If this new point is in the middle, we interpolate new values
      const previousCoords = currentState.trackWaypoints[pointIndex - 1]
      const nextCoords = currentState.trackWaypoints[pointIndex]
      const distanceBetweenPreviousAndNewCoords = getDistanceBetweenCoords(previousCoords, coords)
      const distanceBetweenNewAndNextCoords = getDistanceBetweenCoords(coords, nextCoords)
      const totalDistance = distanceBetweenPreviousAndNewCoords + distanceBetweenNewAndNextCoords

      updatedTrackMetadata = Object.entries(currentState.nonRoutableTrackMetadata).reduce((acc, [key, value]) => {
        if (isEmpty(value)) return acc

        const interpolatedValue =
          value[pointIndex - 1] +
          distanceBetweenPreviousAndNewCoords * ((value[pointIndex] - value[pointIndex - 1]) / totalDistance)
        return {
          ...acc,
          [key]: insertAtIndex<number>(value as number[], Number(interpolatedValue.toFixed(2)), pointIndex),
        }
      }, {})
    }

    return [
      updateNonRoutableTrackMetadata({ ...currentState.nonRoutableTrackMetadata, ...updatedTrackMetadata }),
      addTrackWaypoint(coords, pointIndex),
    ]
  }

export const deleteWaypointForNonRoutableTrack =
  (pointIndex: number) => (getState: PresentStateGetter<RoutingState>) => {
    const currentState = getState()

    const updatedTrackMetadata: Partial<{ [key: string]: number[] }> = Object.entries(
      currentState.nonRoutableTrackMetadata
    ).reduce((acc, [key, value]) => {
      if (isEmpty(value)) return acc

      return {
        ...acc,
        [key]: removeAtIndex<number>(value as number[], pointIndex),
      }
    }, {})

    return [
      updateNonRoutableTrackMetadata({ ...currentState.nonRoutableTrackMetadata, ...updatedTrackMetadata }),
      deleteTrackWaypoint(pointIndex),
    ]
  }

export const changeRoutingType = (routing: Routing): ChangeRoutingType => ({
  type: ActionType.CHANGE_ROUTING_TYPE,
  payload: routing,
})

export const updateRoutingCostingOptions = (
  costingOptions: DeepPartial<RoutingCostingOptions>
): UpdateRoutingCostingOptions => ({
  type: ActionType.UPDATE_ROUTING_COSTING_OPTIONS,
  payload: costingOptions,
})

export const rewriteComputedTrackpoints = (trackpoints: Coords[]): RewriteComputedTrackpoints => ({
  type: ActionType.REWRITE_COMPUTED_TRACKPOINTS,
  payload: trackpoints,
})
export const rewriteComputedTrackpointsWithAttributes = (
  trackpointsWithAttributes: ComputedTrackPointsWithAttributes[]
): RewriteComputedTrackpointsWithAttributes => ({
  type: ActionType.REWRITE_COMPUTED_TRACKPOINTS_WITH_ATTRIBUTES,
  payload: trackpointsWithAttributes,
})

export const rewriteElevations = (elevations: number[]): RewriteElevations => ({
  type: ActionType.REWRITE_ELEVATIONS,
  payload: elevations,
})

export const clearElevations = (): ClearElevations => ({
  type: ActionType.CLEAR_ELEVATIONS,
})

export const rewriteTrackmeta = (metaData: TrackMeta): RewriteTrackMeta => ({
  type: ActionType.REWRITE_TRACK_META,
  payload: metaData,
})

export const changeShowPlaceholderTrack = (show: boolean): ChangeShowPlaceholderTrack => ({
  type: ActionType.SHOW_PLACEHOLDER_TRACK,
  payload: show,
})

export const changeTrackColor = (color: string | null): ChangeTrackColor => ({
  type: ActionType.CHANGE_TRACK_COLOR,
  payload: color,
})

export const setAreElevationsLoading = (bool: boolean): SetAreElevationsLoading => ({
  type: ActionType.SET_ARE_ELEVATIONS_LOADING,
  payload: bool,
})

export const setIsRouting = (bool: boolean): SetIsRouting => ({
  type: ActionType.SET_IS_ROUTING,
  payload: bool,
})

export type RoutingActions =
  | AddTrackWaypoint
  | AddTrackWaypoints
  | DeleteTrackWaypoint
  | UpdateTrackWaypoint
  | ClearTrackWaypoints
  | RewriteTrackWaypoints
  | ChangeRoutingType
  | RewriteComputedTrackpoints
  | RewriteElevations
  | ClearElevations
  | RewriteTrackMeta
  | ChangeShowPlaceholderTrack
  | ChangeTrackColor
  | SetAreElevationsLoading
  | SetIsRouting
  | RewriteComputedTrackpointsWithAttributes
  | UpdateNonRoutableTrackMetadata
  | UpdateRoutingCostingOptions
