import { faCar, faPersonBiking, faWalking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BicycleCityIcon } from 'assets/icons/BicycleCityIcon'
import { BicycleCrossIcon } from 'assets/icons/BicycleCrossIcon'
import { BicycleMTBIcon } from 'assets/icons/BicycleMTBIcon'
import { BicycleRoadIcon } from 'assets/icons/BicycleRoadIcon'
import { RoutingNoneIcon } from 'assets/icons/RoutingNoneIcon'

import { BicycleType } from './enums/routings'

export const ROUTING_ICONS = {
  bicycle: <FontAwesomeIcon icon={faPersonBiking} />,
  auto: <FontAwesomeIcon icon={faCar} />,
  pedestrian: <FontAwesomeIcon icon={faWalking} />,
  none: <RoutingNoneIcon />,
}

export const BICYCLE_ICONS = {
  [BicycleType.hybrid]: <BicycleCityIcon style={{ height: '1em' }} />,
  [BicycleType.road]: <BicycleRoadIcon style={{ height: '1em' }} />,
  [BicycleType.crossCountry]: <BicycleCrossIcon style={{ height: '1em' }} />,
  [BicycleType.mountain]: <BicycleMTBIcon style={{ height: '1em' }} />,
}
