import { BasicLegendBody } from 'components/LeafletMap/Legend/BasicLegendBody'
import { BubbleWrapLegendBody } from 'components/LeafletMap/Legend/BubbleWrapLegendBody'
import { CinnabarLegendBody } from 'components/LeafletMap/Legend/CinnabarLegendBody'
import { RefillLegendBody } from 'components/LeafletMap/Legend/RefillLegendBody'
import { StreetLegendBody } from 'components/LeafletMap/Legend/StreetLegendBody'
import { TopoLegendBody } from 'components/LeafletMap/Legend/TopoLegendBody'
import { TronLegendBody } from 'components/LeafletMap/Legend/TronLegendBody'
import { WalkaboutLegendBody } from 'components/LeafletMap/Legend/WalkaboutLegendBody'

import { MapID } from './enums/mapIDs'

export const LEGENDS = {
  [MapID.basic]: BasicLegendBody,
  [MapID.bubbleWrap]: BubbleWrapLegendBody,
  [MapID.cinnabar]: CinnabarLegendBody,
  [MapID.cyclOSM]: null,
  [MapID.satellite]: null,
  [MapID.openStreetMap]: null,
  [MapID.openTopoMap]: null,
  [MapID.refill]: RefillLegendBody,
  [MapID.street]: StreetLegendBody,
  [MapID.topo]: TopoLegendBody,
  [MapID.tron]: TronLegendBody,
  [MapID.walkabout]: WalkaboutLegendBody,
}

export const hasLegend = (mapType: MapID) => !!LEGENDS[mapType]
