import axios from 'axios'
import { Routing } from 'config/enums/routings'
import { Coords, RoutingAPI } from 'types/app'
import { decodePolyline, encodePolyline } from 'utils/polyline'

const NAV_BASE_API_URL = import.meta.env.VITE_NAV_API_BASE

export const convertTrackToRoutable = async (selectedRouting: Routing, trackpoints: Coords[]) => {
  if (!NAV_BASE_API_URL) throw new Error('Missing VITE_NAV_API_BASE env variable')
  const encoded = encodePolyline(trackpoints, 6).substring(2)

  const res = await axios.post(
    `${NAV_BASE_API_URL}/trace_route`,
    {
      encoded_polyline: encoded,
      shape_match: 'map_snap',
      costing: selectedRouting,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  const data = res.data as RoutingAPI
  const { legs } = data.trip
  if (!legs || legs.length === 0) {
    throw new Error('No legs created during routing')
  }
  const controlPoints: Coords[] = []
  legs.forEach((leg) => {
    if (leg.maneuvers && leg.shape) {
      const polyline = decodePolyline(leg.shape, 6)
      if (polyline[0]) controlPoints.push(polyline[0])

      leg.maneuvers.forEach((maneuver) => {
        if (maneuver.end_shape_index) {
          const controlPoint = polyline[maneuver.end_shape_index]
          if (controlPoint) controlPoints.push(controlPoint)
        }
      })
    }
  })

  return controlPoints
}
