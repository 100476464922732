import { ReactNode } from 'react'

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Box, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import { CoordsDisplay } from 'components/UI/OptionsPanel/CoordsDisplay'
import { MarkerWithNumber } from 'components/UI/OptionsPanel/MarkerWithNumber'
import { Routing } from 'config/enums/routings'
import { useTranslation } from 'react-i18next'
import { deleteTrackWaypoint } from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { Coords } from 'types/app'

const VERTICAL_GUTTER = 10
const MARKER_WIDTH = 27

const LeftSideWrap = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: 'flex', flexShrink: 0, width: MARKER_WIDTH }}>{children}</Box>
)

type RightSideProps = {
  coords: Coords
  onDeleteClick?: () => void
}
const RightSide = ({ coords, onDeleteClick }: RightSideProps) => (
  <Group sx={{ flexGrow: 1 }} noWrap spacing="xs">
    <CoordsDisplay coords={coords} />
    <ActionIcon onClick={onDeleteClick}>
      <FontAwesomeIcon icon={faClose} />
    </ActionIcon>
  </Group>
)

const formatEndMarker = (points: Coords[], routing: Routing) => {
  if (routing === 'none') return 100
  if (points.length > 1) return points.length
  return 2
}

export const TrackpointsList = () => {
  const {
    state: { trackWaypoints, routing },
    dispatch,
  } = useRouting()
  const theme = useMantineTheme()
  const firstTrackWaypoint = trackWaypoints?.[0]
  const finalTrackWapoint = trackWaypoints.length > 1 && trackWaypoints.at(-1)
  const { t } = useTranslation()

  return (
    <Box p="xs">
      <Stack spacing={0} sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: MARKER_WIDTH,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            my={10}
            sx={{
              borderLeftStyle: 'solid',
              borderLeftColor: theme.colors.gray[5],
              borderLeftWidth: 1,
            }}
          />
        </Box>
        <Group sx={{ flexWrap: 'nowrap' }} mb={VERTICAL_GUTTER}>
          <LeftSideWrap>
            <MarkerWithNumber type="start" numericLabel={routing === 'none' ? 100 : 1} />
          </LeftSideWrap>
          {firstTrackWaypoint ? (
            <RightSide
              coords={firstTrackWaypoint}
              onDeleteClick={() => {
                dispatch(deleteTrackWaypoint(0))
              }}
            />
          ) : (
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.2 }} pr="xs">
              {t('trackpoints_display.add_start_point_cta_label')}
            </Text>
          )}
        </Group>

        <Group sx={{ flexWrap: 'nowrap' }}>
          <LeftSideWrap>
            <MarkerWithNumber type="end" numericLabel={formatEndMarker(trackWaypoints, routing)} />
          </LeftSideWrap>
          {finalTrackWapoint && (
            <RightSide
              coords={finalTrackWapoint}
              onDeleteClick={() => {
                dispatch(deleteTrackWaypoint(trackWaypoints.length - 1))
              }}
            />
          )}

          {!finalTrackWapoint && (
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.2 }}>
              {firstTrackWaypoint
                ? t('trackpoints_display.add_end_point_point_cta_label')
                : t('trackpoints_display.destination_label')}
            </Text>
          )}
        </Group>
      </Stack>
    </Box>
  )
}
