import { useMediaQuery } from '@mantine/hooks'

type UseResponsive = () => {
  /** min-width: 640px */
  isSm: boolean
  /** min-width: 768px */
  isMd: boolean
  /** min-width: 1024px */
  isLg: boolean
}
export const useResponsive: UseResponsive = () => {
  const isSm = useMediaQuery('(min-width: 640px)')
  const isMd = useMediaQuery('(min-width: 768px)')
  const isLg = useMediaQuery('(min-width: 1024px)')
  return { isSm, isMd, isLg }
}
