import { TrackPointsTime } from 'types/app'
import { decodeBase64string, encodeBase64string } from 'utils/base64'

export const decodeTrackPointsTime = (data: string): TrackPointsTime => {
  const dataArray = data.split(',')
  if (dataArray.length !== 3) throw new Error('invalid data length')

  const startTimestamp = Number(dataArray[0])
  const startPointIndex = Number(dataArray[1])
  const datapoints = dataArray[2] ? decodeBase64string(dataArray[2]) : []

  return {
    startTimestamp,
    startPointIndex,
    datapoints,
  }
}

export const encodeTrackPointsTime = (trackPointsTime: TrackPointsTime): string => {
  const datapoints = trackPointsTime.datapoints ? encodeBase64string(trackPointsTime.datapoints) : ''
  const encodedString = [trackPointsTime.startTimestamp, trackPointsTime.startPointIndex, datapoints].join(',')
  return encodedString
}
