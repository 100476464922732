import { useRef } from 'react'

import { Anchor, Box, CloseButton, Divider, Stack, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Popup } from 'react-leaflet'

import './WaypointInfoPopup.css'

type WaypointInfoPopupProps = {
  heading: string
  description?: string
  links?: string[]
  onRemove?: () => void
  onPopupCloseClick: () => void
}

export const WaypointInfoPopup = ({ heading, description, links, onPopupCloseClick }: WaypointInfoPopupProps) => {
  const { t } = useTranslation()
  const popupRef = useRef<L.Popup>(null)

  return (
    <Popup closeButton={false} ref={popupRef}>
      <Box>
        <CloseButton
          onClick={onPopupCloseClick}
          size="sm"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          variant="transparent"
        />
        <Stack spacing={4}>
          <Text className="trkbk-waypoint-popup-title" weight={700} size="sm" my={4}>
            {heading}
          </Text>
          {description && (
            <Text className="trkbk-waypoint-popup-description" size="xs">
              {description}
            </Text>
          )}
          {links && links.length > 0 && (
            <>
              {description && <Divider label={t('waypoint_popup.links_divider_label')} labelPosition="center" />}
              <Stack spacing={0} align="flex-start">
                {links.map((link, index) => (
                  <Anchor
                    href={link}
                    key={index}
                    target="_blank"
                    size="xs"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    {link}
                  </Anchor>
                ))}
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </Popup>
  )
}
