import { useEffect, useLayoutEffect, useState } from 'react'

import L from 'leaflet'
import ReactDOM from 'react-dom'
import { useMap } from 'react-leaflet'

export type LeafletControlWrapperProps = {
  children: React.ReactNode
  position: L.ControlPosition
  useDefaultStyle?: boolean
}

/**
 * Use as a wrapper for a react element to be placed in a leaflet-control div
 * using the native leaflet positioning.
 */
export const LeafletControlWrapper = ({ children, position, useDefaultStyle = false }: LeafletControlWrapperProps) => {
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null)
  const map = useMap()

  useLayoutEffect(() => {
    const control = new L.Control({ position })

    control.onAdd = () => {
      const classNames = useDefaultStyle ? 'leaflet-bar' : ''
      const div = L.DomUtil.create('div', `${classNames} custom-control`)
      setWrapper(div)
      return div
    }
    control.addTo(map)

    return () => {
      control.remove()
    }
  }, [map, position, useDefaultStyle])

  useEffect(() => {
    if (wrapper) {
      L.DomEvent.disableClickPropagation(wrapper)
    }
  }, [wrapper])

  return wrapper ? ReactDOM.createPortal(children, wrapper) : null
}
