import { useEffect, useState } from 'react'

import { Group, Text } from '@mantine/core'
import { InlineTextInput } from 'components/UI/InlineTextInput'
import { Tooltip } from 'components/UI/Tooltip'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { formatElevation } from 'utils/formatters/formatElevation'

export const WaypointMeta = () => {
  const { selectedWaypoint, updateWaypoint, waypointStatus } = useUserWaypoints()
  const [nameBeingSaved, setNameBeingSaved] = useState<string | undefined>()
  const { t } = useTranslation()
  const {
    state: { units },
  } = useOptions()

  const handleNameChange = async (name: string) => {
    if (!selectedWaypoint) return
    setNameBeingSaved(name)
    try {
      await updateWaypoint({ name, id: selectedWaypoint.id })
    } catch (error) {
      setNameBeingSaved(undefined)
    }
  }

  useEffect(() => {
    setNameBeingSaved(undefined)
  }, [selectedWaypoint?.name])

  const getWaypointElevation = () => {
    if (!selectedWaypoint) return null
    return selectedWaypoint.ele ? Number(selectedWaypoint.ele) : null
  }

  const elevation = getWaypointElevation()

  return (
    <Group spacing={6} sx={{ flexGrow: 1, height: 28, flexWrap: 'nowrap', flexShrink: 1, minWidth: 0 }}>
      <InlineTextInput
        value={nameBeingSaved || selectedWaypoint?.name || ''}
        onChange={handleNameChange}
        disabled={waypointStatus === 'saving'}
      />
      {elevation && (
        <Tooltip label={t('editmode.elevation_tooltip')} position="bottom">
          <Text
            size="sm"
            sx={{
              lineHeight: 1,
              whiteSpace: 'nowrap',
            }}
            color="dimmed"
          >
            {formatElevation(elevation, units, 1)}
          </Text>
        </Tooltip>
      )}
    </Group>
  )
}
