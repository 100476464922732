import { useEffect, useState } from 'react'

import { Paper } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { useAuth } from 'hooks/useAuth'
import { useEditMode } from 'hooks/useEditMode'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'

import { TopEditMenuItems } from './TopEditMenuItems'

const HIDE_TOPMENU_IF_LEFTMENU_OPEN_VIEWPORT_WIDTH = 768

export const TopEditMenu = () => {
  const { editMode, setEditMode } = useEditMode()
  const { selectedTrack, selectTrack } = useUserTracks()
  const { selectedWaypoint, selectWaypoint } = useUserWaypoints()
  const [isVisible, setIsVisible] = useState(false)
  const { user } = useAuth()
  const { width } = useViewportSize()
  const {
    state: { isLeftMenuOpen },
  } = useOptions()
  const {
    state: { trackWaypoints },
    history: { clearHistory },
  } = useRouting()

  /** Handles clearing of track/waypoint depending on selected edit mode */
  useEffect(() => {
    if (editMode === 'track') {
      clearHistory()
      selectWaypoint(null)
    }
    if (editMode === 'waypoint') {
      selectTrack(null)
    }
  }, [clearHistory, editMode, selectTrack, selectWaypoint])

  /** Handles defaulting to editmode default */
  useEffect(() => {
    if (trackWaypoints.length === 0 && !selectedTrack && !selectedWaypoint && editMode !== 'measurement') {
      setEditMode('default')
      clearHistory()
    }
  }, [clearHistory, editMode, selectWaypoint, selectedTrack, selectedWaypoint, setEditMode, trackWaypoints.length])

  /** Handles automatic track edit mode engaging if there are trackwaypoints */
  useEffect(() => {
    if (trackWaypoints.length > 0) setEditMode('track')
  }, [setEditMode, trackWaypoints])

  useEffect(() => {
    const hideDefaultNoAuth = editMode === 'default' && !user
    const shouldBeOpenBySpaceAvailable = !(width <= HIDE_TOPMENU_IF_LEFTMENU_OPEN_VIEWPORT_WIDTH && isLeftMenuOpen)
    setIsVisible(!hideDefaultNoAuth && shouldBeOpenBySpaceAvailable)
  }, [editMode, isLeftMenuOpen, user, width])

  return (
    <Paper
      shadow="sm"
      radius="md"
      sx={{
        position: 'absolute',
        top: 0,
        left: '50%',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        pointerEvents: 'all',
        transform: isVisible ? 'translate3d(-50%, 0px, 0px)' : 'translate3d(-50%, -80px, 0px)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <TopEditMenuItems />
    </Paper>
  )
}
