import { SliderWithLabel } from 'containers/UI/PlanMenu/SliderWithLabel'
import './styles.css'

interface SliderItem<T> {
  label: string
  value: T
}

export const Slider = <T extends number>({
  items,
  label,
  value,
  onChange,
}: {
  items: SliderItem<T>[]
  label: string
  value: T
  onChange: (value: T) => void
}) => (
  <div className="trkbk-context-menu sub-menu-item-slider">
    <SliderWithLabel
      label={label}
      labelFontSize="0.8em"
      marks={items}
      markLabelFontSize="0.7em"
      min={items[0].value}
      max={items[items.length - 1].value}
      step={items[1].value - items[0].value}
      value={value}
      thumbSize={14}
      onChange={onChange}
    />
  </div>
)
