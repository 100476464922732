import { useEffect, useMemo } from 'react'

import { useMantineTheme } from '@mantine/core'
import { Polyline } from 'leaflet'
import * as L from 'leaflet'

import './TooltipColors.css'

type Props = {
  polyline: Polyline
  html: string
  maxWidth?: number
}

export const CustomTooltip = ({ polyline, html }: Props) => {
  const theme = useMantineTheme()

  const tooltip = useMemo(
    () =>
      L.tooltip({
        direction: 'top',
        opacity: 1,
        permanent: true,
        interactive: true,
        className: theme.colorScheme === 'dark' ? 'trkbk-map-dark-tooltip' : 'trkbk-map-light-tooltip',
      }),
    [theme]
  )

  useEffect(() => {
    // tento blok sa pravdepodobne nikdy nespusti, element bude (takmer?) vzdy undefined,
    // ale nechavam to tu, kedze tu mal podobny kod aj predosly developer (ktory mozno poznal edge cases)
    const element = tooltip.getElement()

    if (element !== undefined) {
      if (theme.colorScheme === 'dark') {
        if (element.classList.contains('trkbk-map-light-tooltip')) {
          element.classList.replace('trkbk-map-light-tooltip', 'trkbk-map-dark-tooltip')
        } else {
          element.classList.add('trkbk-map-dark-tooltip')
        }
      } else if (theme.colorScheme === 'light') {
        if (element.classList.contains('trkbk-map-dark-tooltip')) {
          element.classList.replace('trkbk-map-dark-tooltip', 'trkbk-map-light-tooltip')
        } else {
          element.classList.add('trkbk-map-light-tooltip')
        }
      }
    }

    tooltip.setContent(html)
    polyline.bindTooltip(tooltip)

    return () => {
      if (polyline && tooltip) {
        try {
          polyline.unbindTooltip()
        } catch (error) {
          // ignore if polyline is not binded anymore or other errors from the method
        }
      }
    }
  }, [html, polyline, theme.colorScheme, tooltip])

  return null
}
