import React, { useContext, useReducer } from 'react'

import { GenericActions } from './actions'
import { genericReducer } from './reducer'
import { GenericState, initialGenericState } from './state'

export const GenericStateContext = React.createContext<GenericState>(initialGenericState)
export const GenericDispatchContext = React.createContext<React.Dispatch<GenericActions>>(() => undefined)

export const useGenericDispatch = () => useContext(GenericDispatchContext)
export const useGenericState = () => useContext(GenericStateContext)

export const GenericContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(genericReducer, initialGenericState)

  return (
    <GenericStateContext.Provider value={state}>
      <GenericDispatchContext.Provider value={dispatch}>{children}</GenericDispatchContext.Provider>
    </GenericStateContext.Provider>
  )
}
