import { Coords } from 'types/app'

export type ChartTooltipData = {
  /** Coords of the place that is hovered with mouse over the chart */
  position: Coords
  /** Formatted x axis string */
  xAxisString?: string
  /** Formatted y axis string */
  yAxisString: string
  /** ChartTooltip distance from the top */
  top: number
  /** ChartTooltip distance from the left */
  left: number
  /** Controls whether a chart is hovered or not */
  isHovered: boolean
} | null

export interface GenericState {
  chartTooltipData: ChartTooltipData
  /** Controls whether cesium 3d map should be displayed (true). If false, leaflet maps are displayed */
  showCesiumMap: boolean
}

export const initialGenericState: GenericState = {
  chartTooltipData: null,
  showCesiumMap: false,
}
