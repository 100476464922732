import { MouseEventHandler, ReactNode } from 'react'

import { Paper } from '@mantine/core'
import { TooltipBaseProps } from '@mantine/core/lib/Tooltip/Tooltip.types'
import { LeafletControlWrapper, LeafletControlWrapperProps } from 'components/LeafletMap/Utils/LeafletControlWrapper'
import { Tooltip } from 'components/UI/Tooltip'
import { CONTROL_BUTTON_SETTINGS } from 'config/constants'

import { ControlActionIcon } from '../MapControls/ControlActionIcon'

type Props = {
  position: LeafletControlWrapperProps['position']
  icon: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  tooltip?: {
    label: string
    position: TooltipBaseProps['position']
  }
}

export const LeafletSingleIconControlWrapper = ({ position, icon, onClick, tooltip }: Props) => (
  <LeafletControlWrapper position={position}>
    <Paper
      sx={(theme) => ({ boxShadow: theme.other.controlButtonShadow, fontSize: CONTROL_BUTTON_SETTINGS.iconFontSize })}
      shadow="xs"
    >
      {tooltip ? (
        <Tooltip label={tooltip.label} position={tooltip.position}>
          <ControlActionIcon onClick={onClick}>{icon}</ControlActionIcon>
        </Tooltip>
      ) : (
        <ControlActionIcon onClick={onClick}>{icon}</ControlActionIcon>
      )}
    </Paper>
  </LeafletControlWrapper>
)

LeafletSingleIconControlWrapper.displayName = 'LeafletSingleIconControlWrapper'
