export enum BicycleType {
  hybrid = 'Hybrid',
  road = 'Road',
  crossCountry = 'Cross',
  mountain = 'Mountain',
}

export enum Routing {
  pedestrian = 'pedestrian',
  auto = 'auto',
  bicycle = 'bicycle',
  none = 'none',
}
