import { Suspense, useCallback, useEffect, useRef, useState } from 'react'

import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Affix, Box, Portal, ScrollArea, Stack, Space } from '@mantine/core'
import { ControlActionIcon } from 'components/LeafletMap/MapControls/ControlActionIcon'
import { getTangramLayerMeta, isTangramLayer } from 'components/LeafletMap/TangramTileLayer'
import { Tooltip } from 'components/UI/Tooltip'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { LabelCountSlider } from './parts/LabelCountSlider'
import { MapColorPicker } from './parts/MapColorPicker'
import { MapOverlayPicker } from './parts/MapOverlayPicker'
import { MapTypePicker } from './parts/MapTypePicker'

export const MapLayersPickerOverlay = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { state } = useOptions()
  const { t } = useTranslation()
  const [isLayerTypeTangram, setIsLayerTypeTangram] = useState(isTangramLayer(state.mapType))
  const ignoreClicks = useRef(false)

  const setIgnoreClicks = useCallback((value: boolean) => {
    ignoreClicks.current = value
  }, [])

  useEffect(() => {
    setIsLayerTypeTangram(isTangramLayer(state.mapType))
  }, [state.mapType])

  const currentTangramLayerMeta = getTangramLayerMeta(state.mapType)

  const handleAffixClick = () => {
    if (ignoreClicks.current) return
    setIsOpen(false)
  }

  return (
    <div>
      <Tooltip label={t('map_controls.maps_button_tooltip')} position="left">
        <ControlActionIcon onClick={() => setIsOpen((prev) => !prev)}>
          <FontAwesomeIcon icon={faLayerGroup} />
        </ControlActionIcon>
      </Tooltip>

      {isOpen && (
        <Portal target={document.body}>
          <Affix
            onClick={handleAffixClick}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? '#1a1a1aa1' : '#e4e4e4a1',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              overflow: 'auto',
              zIndex: 1000,
              backdropFilter: 'blur(2px)',
            })}
          >
            <ScrollArea
              type="scroll"
              scrollHideDelay={20000}
              styles={{
                root: { height: '100%' },
                thumb: {
                  '&:before': { minWidth: 0 },
                },
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} p="xs" mr={40}>
                <Stack spacing={0}>
                  <Stack>
                    <MapTypePicker />
                    <Space h="xs" />
                  </Stack>
                  {isLayerTypeTangram && (
                    <Stack>
                      <MapOverlayPicker />
                      <Space h="xs" />
                    </Stack>
                  )}
                  {isLayerTypeTangram && currentTangramLayerMeta?.colors && (
                    <Stack>
                      <Suspense fallback={null}>
                        <MapColorPicker />
                      </Suspense>
                      <Space h="xs" />
                    </Stack>
                  )}
                  {currentTangramLayerMeta?.hasLabels && <LabelCountSlider setIgnoreClicks={setIgnoreClicks} />}
                </Stack>
              </Box>
            </ScrollArea>
          </Affix>
        </Portal>
      )}
    </div>
  )
}
