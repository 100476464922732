import { TFunction } from 'react-i18next'

export enum Unit {
  metric = 'metric',
  imperial = 'imperial',
}

export const getUnits = (t: TFunction) => [
  { label: t('general.unit.metric'), value: Unit.metric },
  { label: t('general.unit.imperial'), value: Unit.imperial },
]
