import * as React from 'react'
import { SVGProps } from 'react'

export const RoutingNoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fillRule="evenodd"
    clipRule="evenodd"
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.799 7.038A3.001 3.001 0 1 1 24 5a3.001 3.001 0 0 1-3.431 2.969l-3.333 6.032A3.001 3.001 0 1 1 12 16c0-.583.167-1.127.455-1.587L9.89 10.866c-.281.087-.58.134-.89.134l-.368-.022-3.355 6.069A3.001 3.001 0 1 1 0 19a3.001 3.001 0 0 1 3.543-2.951l3.298-5.967A3.001 3.001 0 1 1 12 8a2.99 2.99 0 0 1-.507 1.669l2.527 3.495a2.999 2.999 0 0 1 1.462-.125zM15 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM9 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM2.932 18a1 1 0 1 1-.001 2 1 1 0 0 1 0-2zM21.016 4.026a1 1 0 1 1-.001 2.001 1 1 0 0 1 0-2z"
    />
  </svg>
)
