import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Text, ThemeIcon } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { OpenContextModal } from '@mantine/modals/lib/context'
import { useTranslation } from 'react-i18next'

export const ResetEmailSentModal = ({ context, id }: ContextModalProps) => {
  const { t } = useTranslation()
  const text = (context.modals[0]?.props as OpenContextModal<{ text: string }>)?.innerProps?.text
  return (
    <Stack align="center">
      <ThemeIcon size={70} variant="light" radius={100} sx={{ fontSize: 35 }}>
        <FontAwesomeIcon icon={faEnvelope} />
      </ThemeIcon>
      <Text size="xl" weight={700}>
        {t('reset_password_sent_modal.title')}
      </Text>
      <Text align="center">{text}</Text>
      <Button fullWidth onClick={() => context.closeModal(id)}>
        {t('generic.ok')}
      </Button>
    </Stack>
  )
}
