import { ReactNode, useCallback } from "react";

import {
  faInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Image, Text, ThemeIcon } from "@mantine/core";
import TrackpointAddGif from "assets/help/help_trackpoint_add.gif";
import TrackpointDeleteGif from "assets/help/help_trackpoint_delete.gif";
import TrackpointHybridGif from "assets/help/help_trackpoint_hybrid.gif";
import TrackpointInsertGif from "assets/help/help_trackpoint_insert.gif";
import TrackpointMoveGif from "assets/help/help_trackpoint_move.gif";
import { ConfirmLosingTrackModalProps } from "containers/UI/Modals/ConfirmLosingTrackModal";
import { CoordsInputModalProps } from "containers/UI/Modals/CoordsInputModal";
import { Trans, useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { TrackWithMetadata, Waypoint } from "types/app";

import style from "./markdown-styles.module.css";
import { useLiftedModalContext } from "./useLiftedModalContext";

export const useGenericModals = () => {
  const { modals } = useLiftedModalContext();
  const { t } = useTranslation();

  const welcomeTitle = t("welcome_modal.welcome_paragraph");

  type ConfirmModalProps = {
    title: string;
    text?: string;
    children?: ReactNode;
    onCancel?: () => void;
    onConfirm: () => void;
    labels?: {
      confirm?: string;
      cancel?: string;
    };
  };
  const openConfirmModal = useCallback(
    ({
      title,
      text,
      onCancel,
      onConfirm,
      children,
      labels,
    }: ConfirmModalProps) => {
      if (!modals) return;
      modals.openConfirmModal({
        title: <Text size="lg">{title}</Text>,
        children: children || <Text size="sm">{text}</Text>,
        labels: {
          confirm: labels?.confirm ?? t("generic.confirm_button_label"),
          cancel: labels?.cancel ?? t("generic.cancel_button_label"),
        },
        centered: true,
        onCancel,
        onConfirm,
        confirmProps: { color: "red" },
      });
    },
    [modals, t]
  );

  type ConfirmTrackDeleteModalProps = {
    count: number;
    onConfirm: () => void;
    onCancel?: () => void;
  };
  const openConfirmTrackDeleteModal = ({
    count,
    onConfirm,
    onCancel,
  }: ConfirmTrackDeleteModalProps) => {
    openConfirmModal({
      title: t("delete_tracks_modal.title", { count }),
      text: t("delete_tracks_modal.text", { count }),
      onConfirm,
      onCancel,
      labels: {
        confirm: t("generic.yes"),
        cancel: t("generic.no"),
      },
    });
  };

  type ConfirmWaypointDeleteModalProps = {
    count: number;
    onConfirm: () => void;
    onCancel?: () => void;
  };
  const openConfirmWaypointDeleteModal = ({
    count,
    onConfirm,
    onCancel,
  }: ConfirmWaypointDeleteModalProps) => {
    openConfirmModal({
      title: t("delete_waypoints_modal.title", { count }),
      text: t("delete_waypoints_modal.text", { count }),
      onConfirm,
      onCancel,
      labels: {
        confirm: t("generic.yes"),
        cancel: t("generic.no"),
      },
    });
  };

  type ErrorModalProps = {
    title?: string;
    text?: string | null;
  };
  const openErrorModal = useCallback(
    ({ title, text }: ErrorModalProps) => {
      if (!modals) return;
      modals.openContextModal("errorModal", {
        centered: true,
        withCloseButton: false,
        title: null,
        size: "xs",
        innerProps: {
          icon: (
            <ThemeIcon
              size={60}
              variant="light"
              color="red"
              radius={100}
              sx={{ fontSize: 30 }}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </ThemeIcon>
          ),
          title: title ?? t("generic.error_title"),
          text: text ?? t("error_modal.generic_text"),
          buttonText: t("generic.dismiss"),
        },
      });
    },
    [modals, t]
  );

  const openWaypointEditModal = useCallback(
    (waypoint: Waypoint) => {
      if (!modals) return;
      modals.openContextModal("editWaypoint", {
        title: <Text weight={700}>{waypoint.name}</Text>,
        centered: true,
        innerProps: {
          waypoint,
        },
      });
    },
    [modals]
  );

  const openTrackEditModal = useCallback(
    (track: TrackWithMetadata) => {
      if (!modals) return;
      modals.openContextModal("editTrack", {
        title: <Text weight={700}>{track.name}</Text>,
        centered: true,
        innerProps: {
          track,
        },
      });
    },
    [modals]
  );

  const openInfoModal = useCallback(
    (heading?: string, text?: string) => {
      if (!modals) return;
      modals.openContextModal("topIconModal", {
        centered: true,
        withCloseButton: false,
        title: null,
        size: "xs",
        innerProps: {
          icon: (
            <ThemeIcon
              size={60}
              variant="light"
              color="violet"
              radius={100}
              sx={{ fontSize: 30 }}
            >
              <FontAwesomeIcon icon={faInfo} />
            </ThemeIcon>
          ),
          title: heading,
          text,
          buttonText: t("generic.ok"),
          buttonColor: "primaryColor",
        },
      });
    },
    [modals, t]
  );

  const openImportGPXmodal = useCallback(() => {
    if (!modals) return;
    modals.openContextModal("importGPX", {
      title: <Text weight={700}>{t("import_GPX_modal.title")}</Text>,
      centered: true,
      innerProps: {},
    });
  }, [modals, t]);

  const openHelpModal = useCallback(() => {
    if (!modals) return;
    const id = modals.openModal({
      title: t("help_modal.title"),
      size: "lg",
      children: (
        <>
          <Trans i18nKey="help_modal.body">
            <Text weight="bold" size="lg">
              {"Routing: Bike, Car, Walk"}
            </Text>
            <Text>{"Left click on map to add track point"}</Text>
            <Image src={TrackpointAddGif} mt="xs" mb="lg" />
            <Text>{"Left click on track point and drag mouse to move it"}</Text>
            <Image src={TrackpointMoveGif} mt="xs" mb="lg" />
            <Text>
              {
                "Left click on track line to insert track point between track points and drag mouse to move it"
              }
            </Text>
            <Image src={TrackpointInsertGif} mt="xs" mb="lg" />
            <Text>{"Left click on track point to delete it"}</Text>
            <Image src={TrackpointDeleteGif} mt="xs" mb="lg" />
            <Text weight="bold" size="lg">
              {"Routing: None"}
            </Text>
            <Text>
              {
                "Left click on map to add track point, right click on map to select routing to point"
              }
            </Text>
            <Image src={TrackpointHybridGif} mt="xs" mb="lg" />
          </Trans>
          <Button fullWidth onClick={() => modals.closeModal(id)} mt="md">
            {t("generic.ok")}
          </Button>
        </>
      ),
    });
  }, [modals, t]);

  const openWelcomeModal = useCallback(() => {
    if (!modals) return;
    modals.openContextModal("welcome", {
      title: (
        <Text>
          <ReactMarkdown className={style.reactMarkDown} skipHtml>
            {welcomeTitle}
          </ReactMarkdown>
        </Text>
      ),
      innerProps: {},
      padding: 0,
      styles: {
        header: { padding: 20, marginBottom: 0 },
      },
      size: "xl",
    });
  }, [modals, welcomeTitle]);

  const openConfirmLosingTrackModal = useCallback(
    (props?: ConfirmLosingTrackModalProps) => {
      if (!modals) return;
      modals.openContextModal("confirmLosingTrack", {
        title: t("confirm_losing_track_modal.title"),
        innerProps: {
          onConfirm: props?.onConfirm,
        },
      });
    },
    [modals, t]
  );

  const openCoordsInputModal = useCallback(
    (callback: CoordsInputModalProps["callback"]) => {
      if (!modals) return;
      modals.openContextModal("coordsInputModal", {
        title: t("coords_input_modal.title"),
        size: "auto",
        innerProps: {
          callback,
        },
      });
    },
    [modals, t]
  );

  const openSettingsModal = useCallback(() => {
    if (!modals) return;
    modals.openContextModal("settingsModal", {
      title: t("account_settings_modal.title"),
      innerProps: {},
      size: "md",
    });
  }, [modals, t]);

  return {
    openErrorModal,
    openConfirmModal,
    openWaypointEditModal,
    openTrackEditModal,
    openHelpModal,
    openImportGPXmodal,
    openInfoModal,
    openConfirmTrackDeleteModal,
    openConfirmWaypointDeleteModal,
    openConfirmLosingTrackModal,
    openWelcomeModal,
    openCoordsInputModal,
    openSettingsModal,
    // openPremiumFeatureModal,
  };
};
