import { PropsWithChildren } from "react";

import {
  createPathComponent,
  LeafletContextInterface,
  PathProps,
} from "@react-leaflet/core";
import L from "leaflet";
import Hotline from "leaflet-hotline";
import { Coords } from "types/app";
// initialize UMD module
Hotline(L);

// It's pretty time consuming to type all of this 3rd party Leaflet UMD stuff, but this will get us at least somewhere
type LeafletWithHotline = typeof L & {
  Hotline: L.Polyline & {
    renderer: (options: {pane: string}) => L.Canvas | null
  }
};

type HotlineDataPoint = [...Coords, number | null];

interface HotlineProps {
  positions: HotlineDataPoint[];
  weight: number;
  outlineWidth: number;
  interactive: boolean;
  min: number | null;
  max: number | null;
}

type PathPropshWithChildren = PropsWithChildren<HotlineProps> & PathProps;

const renderer = L.Hotline.renderer({ pane: "hotlinePane" });
const createPolyline = (
  { positions, ...options }: PathPropshWithChildren,
  context: LeafletContextInterface
) => {
  // @ts-expect-error TS rightfully complains here since 'positions' is not a tuple but the previous developer
  // did it this way and since I don't have the same domain knowledge that he has I'll leave it here for now
  // Also, typing Hotline througly would really require bunch of time that we don't have right now :)
  const instance = L.hotline(positions, { ...options, renderer }); 
  return { instance, context: { ...context, overlayContainer: instance } };
};

const updatePolyline = (layer: LeafletWithHotline["Hotline"], props: PathPropshWithChildren, prevProps: PathPropshWithChildren) => {
  if (props.positions !== prevProps.positions) {
    // @ts-expect-error See my previous comment
    layer.setLatLngs(props.positions);
  }

  if (props.max !== prevProps.max || props.min !== prevProps.min) {
    // @ts-expect-error See my previous comment
    layer.options.min = props.min;
    // @ts-expect-error See my previous comment
    layer.options.max = props.max;
    // @ts-expect-error See my previous comment
    layer._renderer._update();
  }
};

export const HotlinePolyline = createPathComponent<
  typeof Hotline,
  PathPropshWithChildren
  // @ts-expect-error See my previous comment
>(createPolyline, updatePolyline);
