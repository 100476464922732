import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { NON_ROUTING_CONTROL_SETTINGS, TRACK_COLORS } from 'config/constants'
import { ActionTimeout } from 'hooks/useActionTimeout'
import { useTrackStats } from 'hooks/useTrackStats'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { latLng, LatLng, LeafletEventHandlerFnMap } from 'leaflet'
import * as L from 'leaflet'
import { Marker, useMap, useMapEvents } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import {
  addWaypointForNonRoutableTrack,
  deleteWaypointForNonRoutableTrack,
  rewriteComputedTrackpoints,
  rewriteComputedTrackpointsWithAttributes,
  updateTrackWaypoint,
} from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { Coords } from 'types/app'

import { ContextMenu } from '../ContextMenu/ContextMenu'
import { nonRoutableEndIcon, nonRoutableStartIcon, trackPointIcon } from '../Icons'
import { DragStylePolyline } from './components/DragStylePolyline'
import { MarkerWithIndex } from './components/MarkerWithIndex'
import { NonRoutablePolyline } from './components/NonRoutablePolyline'

type DraggedWaypoint = {
  coords: LatLng
  prevCoords: LatLng | null
  nextCoords: LatLng | null
}

/**
 * Handles displaying and control of non-routable trackpoints
 */
export const NonRoutingControl = ({ actionTimeout }: { actionTimeout: ActionTimeout }) => {
  const {
    state: { nonRoutableTrackMetadata, trackWaypoints, trackColor },
    dispatch,
    fetchElevations,
  } = useRouting()
  const { setTrackStats } = useTrackStats()
  const {
    state: { hotlineType },
  } = useOptions()
  const map = useMap()
  const { isAddingWaypoint } = useUserWaypoints()
  const shouldDisplayRouteMarkers = () => map.getZoom() >= NON_ROUTING_CONTROL_SETTINGS.markersVisibleOverZoomLvl
  const [isRouteMarkerVisible, setIsRouteMarkerVisible] = useState(() => shouldDisplayRouteMarkers())
  const isMidpointDragged = useRef(false)
  const [midpointLatLng, setMidpointLatLng] = useState<LatLng | null>(null)
  const [nextIndex, setNextIndex] = useState<number | null>(null)
  const [draggedWaypoint, setDraggedWaypoint] = useState<DraggedWaypoint | null>(null)
  const trackWaypointsRef = useRef(trackWaypoints)
  useEffect(() => {
    trackWaypointsRef.current = trackWaypoints
  }, [trackWaypoints])

  type VisibleTrackWaypoint = { point: Coords; originalIndex: number }
  const [trackWaypointInViewport, setTrackWaypointsInViewport] = useState<VisibleTrackWaypoint[]>([])

  const setVisibleWaypoints = useCallback(() => {
    const trackWaypointsWithIndexes = trackWaypoints.map((point, i) => ({ point, originalIndex: i }))
    const pointsInViewport = trackWaypointsWithIndexes.filter((data) => map.getBounds().pad(0.5).contains(data.point))
    setTrackWaypointsInViewport(pointsInViewport)
  }, [map, trackWaypoints])

  useMapEvents({
    click: (e) => {
      if (isMidpointDragged.current) {
        // when dragging midpoint, click event is fired at the end (when releasing the mouse button)
        // that's why this is the place to set isMidpointDragged to false (mouseUp event fires first, click event later)
        isMidpointDragged.current = false
        return
      }
      if (!actionTimeout.timeoutHasPassed() || isAddingWaypoint) return

      const coords: Coords = [e.latlng.lat, e.latlng.lng]
      // When adding a new point in the middle of the track
      // this code finally adds the point into the trackWaypoints array and
      // also updates track's data by copying data from the previous point
      dispatch(addWaypointForNonRoutableTrack(coords))
    },
    zoomend: () => {
      setIsRouteMarkerVisible(shouldDisplayRouteMarkers)
      setVisibleWaypoints()
    },
    moveend: setVisibleWaypoints,
    resize: setVisibleWaypoints,
    mouseup: (e) => {
      if (isMidpointDragged.current && nextIndex) {
        actionTimeout.setTime()
        const coords: Coords = [e.latlng.lat, e.latlng.lng]
        // When adding a new point in the middle of the track
        // this code finally adds the point into the trackWaypoints array and
        // also updates (interpolates) track's data
        dispatch(addWaypointForNonRoutableTrack(coords, nextIndex))
      }
      if (isMidpointDragged.current) {
        map.dragging.enable()
      }
    },
    mousemove: (e) => {
      if (!isMidpointDragged.current) return
      setMidpointLatLng(e.latlng)
    },
  })

  const findNextIndex = (latlng: LatLng) => {
    for (let i = 1; i < trackWaypoints.length; i += 1) {
      const prevPoint = L.latLng(trackWaypoints[i - 1]!)
      const nextPoint = L.latLng(trackWaypoints[i]!)
      const belongsToSegment = L.GeometryUtil.belongsSegment(latlng, prevPoint, nextPoint, 0.005)
      if (belongsToSegment) return i
    }
    throw new Error('Point does not belong to any segment')
  }

  const polylineEventHandlers: LeafletEventHandlerFnMap = {
    mousedown: (e) => {
      if (!isRouteMarkerVisible) return
      const nextTrackpointIndex = findNextIndex(e.latlng)
      if (nextTrackpointIndex) {
        // When adding a new point in the "middle" of the track
        // it will first run this code
        setMidpointLatLng(e.latlng)
        map.dragging.disable()
        setNextIndex(nextTrackpointIndex)
        isMidpointDragged.current = true
      }
    },
    click: (e) => {
      L.DomEvent.stopPropagation(e)
    },
  }

  useEffect(() => {
    setVisibleWaypoints()
  }, [map, setVisibleWaypoints])

  /** Keeps final/computed trackpoints in sync with user managed waypoints in non-routing mode */
  useEffect(() => {
    dispatch([rewriteComputedTrackpoints(trackWaypoints), rewriteComputedTrackpointsWithAttributes([])], false)
    fetchElevations(trackWaypoints).then((elevations) => {
      if (elevations) {
        setTrackStats({
          track: nonRoutableTrackMetadata,
          trackpoints: trackWaypoints,
          elevations,
        })
      }
    })
  }, [dispatch, fetchElevations, nonRoutableTrackMetadata, setTrackStats, trackWaypoints])

  const getCoordsByIndex = useCallback((index: number) => trackWaypointsRef.current[index] ?? null, [])

  const markerEventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      dragend: (e) => {
        const { lat, lng } = e.target.getLatLng()
        const coords: Coords = [lat, lng]
        const { markerIndex }: { markerIndex: number } = e.target.options
        dispatch(updateTrackWaypoint(markerIndex, coords))
        setDraggedWaypoint(null)
        actionTimeout.setTime()
      },
      drag: (e) => {
        const index = e.target?.options?.markerIndex
        const latlng: LatLng = e.target.getLatLng()
        const prevCoords = getCoordsByIndex(index - 1)
        const nextCoords = getCoordsByIndex(index + 1)
        setDraggedWaypoint({
          coords: latlng,
          prevCoords: prevCoords ? latLng(prevCoords) : null,
          nextCoords: nextCoords ? latLng(nextCoords) : null,
        })
      },
      click: (e) => {
        if (!actionTimeout.timeoutHasPassed()) return
        const { markerIndex }: { markerIndex: number } = e.target.options
        // We need to delete track data on that point index if there are any
        // (this method also deletes the actual track waypoint from trackWaypoints array)
        dispatch(deleteWaypointForNonRoutableTrack(markerIndex))
      },
    }),
    [actionTimeout, dispatch, getCoordsByIndex]
  )

  const getTrackColor = () => trackColor || TRACK_COLORS.nonRoutable

  const prevPointCoords = nextIndex ? trackWaypoints[nextIndex - 1] : undefined
  const nextPointCoords = nextIndex ? trackWaypoints[nextIndex] : undefined

  return (
    <>
      <ContextMenu />
      {trackWaypoints.length > 1 && (
        <NonRoutablePolyline
          disabledArrowheads={hotlineType !== 'none'}
          eventHandlers={polylineEventHandlers}
          positions={trackWaypoints}
          color={getTrackColor()}
        />
      )}

      {!!prevPointCoords && !!nextPointCoords && !!midpointLatLng && isMidpointDragged.current && isRouteMarkerVisible && (
        <>
          <DragStylePolyline positions={[prevPointCoords, midpointLatLng]} />
          <DragStylePolyline positions={[midpointLatLng, nextPointCoords]} />
          <Marker position={midpointLatLng} icon={trackPointIcon} />
        </>
      )}

      {draggedWaypoint?.coords && draggedWaypoint.nextCoords && (
        <DragStylePolyline positions={[draggedWaypoint.coords, draggedWaypoint.nextCoords]} />
      )}
      {draggedWaypoint?.coords && draggedWaypoint.prevCoords && (
        <DragStylePolyline positions={[draggedWaypoint.coords, draggedWaypoint.prevCoords]} />
      )}

      {trackWaypointInViewport.map(({ originalIndex, point }) => {
        if (originalIndex === 0) {
          return (
            <MarkerWithIndex
              key={originalIndex}
              draggable
              markerIndex={0}
              eventHandlers={markerEventHandlers}
              position={point}
              icon={nonRoutableStartIcon}
            />
          )
        }

        if (originalIndex === trackWaypoints.length - 1) {
          return (
            <MarkerWithIndex
              key={originalIndex}
              draggable
              markerIndex={trackWaypoints.length - 1}
              eventHandlers={markerEventHandlers}
              position={point}
              icon={nonRoutableEndIcon}
            />
          )
        }

        return (
          hotlineType === 'none' &&
          isRouteMarkerVisible && (
            <MarkerWithIndex
              key={originalIndex}
              draggable
              markerIndex={originalIndex}
              eventHandlers={markerEventHandlers}
              position={point}
              icon={trackPointIcon}
            />
          )
        )
      })}
    </>
  )
}
