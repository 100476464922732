import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { SavingStatus, Waypoint, WaypointRaw } from 'types/app'
import { getUrlsIfAreValidImages } from 'utils/api/checkIfUrlIsImage'
import { parseWaypointForAPI, parseWaypointFromAPI } from 'utils/helpers/waypointParser/waypointParser'
import { fitCoordsToScreen, fitWaypointToScreen } from 'utils/map/fitToScreen'
import { showWaypointDeletionSuccessNotification } from 'utils/notifications/waypointNotifications'

import { useAuth } from './useAuth'
import { useEditMode } from './useEditMode'
import { useGenericModals } from './useGenericModals'
import { useMapContext } from './useMapContext'
import { useUserTracks } from './useUserTracks'

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL

type SelectWaypointOptions = {
  focus?: boolean
}

type CreateWaypointOptions = {
  focus?: boolean
}

export interface UpdateWaypointData extends Partial<Omit<Waypoint, ''>> {
  id: number
}

export interface CreateWaypointData extends Omit<UpdateWaypointData, 'id'> {}

interface UserWaypoints {
  waypoints: Waypoint[] | null
  fetchWaypoints: () => Promise<Record<string, unknown>>
  selectedWaypoint: Waypoint | null
  selectWaypoint: (id: number | null, options?: SelectWaypointOptions) => Waypoint | null
  updateWaypoint: (updatedData: UpdateWaypointData) => Promise<unknown>
  deleteWaypoints: (id: number | number[]) => Promise<unknown>
  createWaypoint: (data: CreateWaypointData, options?: CreateWaypointOptions) => Promise<unknown>
  isAddingWaypoint: boolean
  setIsAddingWaypoint: (value: React.SetStateAction<boolean>) => void
  isFetching: boolean
  waypointStatus: SavingStatus
  getImagesFromLinks: () => Promise<string[]>
  getSelectedWaypointImages: () => Promise<string[]>
}

const UserWaypointsContext = React.createContext<UserWaypoints>({
  waypoints: null,
  fetchWaypoints: async () => ({}),
  selectedWaypoint: null,
  selectWaypoint: () => null,
  updateWaypoint: async () => null,
  deleteWaypoints: async () => null,
  createWaypoint: async () => null,
  isAddingWaypoint: false,
  setIsAddingWaypoint: () => null,
  isFetching: false,
  waypointStatus: 'unsaved',
  getImagesFromLinks: async () => [],
  getSelectedWaypointImages: async () => [],
})

export const useProvideUserWaypoints = (): UserWaypoints => {
  const { map } = useMapContext()
  const [waypoints, setWaypoints] = useState<Waypoint[] | null>(null)
  const [selectedWaypointId, setSelectedWaypointId] = useState<number | null>(null)
  const [isAddingWaypoint, setIsAddingWaypoint] = useState(false)
  const [waypointStatus, setWaypointStatus] = useState<SavingStatus>('unsaved')
  const [isFetching, setIsFetching] = useState(false)
  const { selectedTrack } = useUserTracks()
  const { setEditMode, editMode } = useEditMode()
  const { openConfirmLosingTrackModal, openErrorModal } = useGenericModals()
  const { t } = useTranslation()
  const {
    state: { language },
  } = useOptions()
  const { user } = useAuth()
  const waypointsRef = useRef(waypoints)
  const selectedWaypointIdRef = useRef(selectedWaypointId)
  const editModeRef = useRef(editMode)
  const isTrackSavedRef = useRef(!!selectedTrack)
  useEffect(() => {
    waypointsRef.current = waypoints
  }, [waypoints])
  useEffect(() => {
    selectedWaypointIdRef.current = selectedWaypointId
  }, [selectedWaypointId])
  useEffect(() => {
    editModeRef.current = editMode
  }, [editMode])
  useEffect(() => {
    isTrackSavedRef.current = !!selectedTrack
  }, [selectedTrack])

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') setIsAddingWaypoint(false)
  }

  useEffect(() => {
    if (isAddingWaypoint) window.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [isAddingWaypoint])

  const reset = () => {
    setWaypointStatus('unsaved')
    setWaypoints(null)
    setSelectedWaypointId(null)
    setIsAddingWaypoint(false)
    setIsFetching(false)
  }

  useEffect(() => {
    if (!user) {
      reset()
    }
  }, [user])

  const fetchWaypoints = useCallback(async () => {
    if (!user) return Promise.reject(new Error('not_signed_in'))
    try {
      const { data } = await axios.post(
        `${BASE_API_URL}/get-waypoints`,
        {
          agent: 'Trackbook',
          lng: language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      const rawWaypoints: WaypointRaw[] = data.waypoints
      const parsedWaypoints = rawWaypoints.map((waypoint) => parseWaypointFromAPI(waypoint))
      setWaypoints(parsedWaypoints)
      return data.waypoints
    } catch (error) {
      setWaypoints(null)
      return console.error('error getting waypoints')
    }
  }, [language, user])

  const updateWaypoint = useCallback(
    async (newData: UpdateWaypointData) => {
      const originalWaypointData = newData.id ? waypointsRef.current?.find((value) => value.id === newData.id) : {}
      const updatedWaypointData = { ...originalWaypointData, ...newData }
      const parsedData = parseWaypointForAPI(updatedWaypointData)

      if (!user) return Promise.reject(new Error('not_signed_in'))
      setWaypointStatus('saving')
      try {
        await axios.post(
          `${BASE_API_URL}/update-waypoint`,
          {
            ...parsedData,
            agent: 'Trackbook',
            lng: language,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        )
        fetchWaypoints()
        setWaypointStatus('saved')
        return null
      } catch (error) {
        setWaypointStatus('unsaved')
        openErrorModal({
          title: t('generic.error_title'),
          text: t('errors.updating_waypoint_failed.text'),
        })
        throw error
      }
    },
    [fetchWaypoints, language, openErrorModal, t, user]
  )

  const createWaypoint = useCallback(
    async (data: CreateWaypointData, options?: CreateWaypointOptions) => {
      await updateWaypoint({ name: 'Waypoint', src: 'Trackbook', desc: '', icon: '', links: [], id: -1, ...data })
      if (options?.focus === true && data.point && map) fitCoordsToScreen(data.point, map, 14)
    },
    [map, updateWaypoint]
  )

  const deleteWaypoints = useCallback(
    async (id: number | number[]) => {
      try {
        let singleDeletedWaypointName
        if (typeof id === 'number' || id.length === 1) {
          const currentId = typeof id === 'number' ? id : id[0]
          const waypointToBeDeleted = waypointsRef.current?.find((waypoint) => waypoint.id === currentId)
          singleDeletedWaypointName = waypointToBeDeleted?.name
        }

        await axios.post(
          `${BASE_API_URL}/delete-waypoint`,
          {
            id: typeof id === 'number' ? id : undefined,
            ids: Array.isArray(id) ? id : undefined,
            agent: 'Trackbook',
            lng: language,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        )
        if (singleDeletedWaypointName) {
          showWaypointDeletionSuccessNotification({ name: singleDeletedWaypointName })
        } else if (Array.isArray(id)) {
          showWaypointDeletionSuccessNotification({ count: id.length })
        }

        fetchWaypoints()
      } catch (error) {
        openErrorModal({
          title: t('generic.error_title'),
          text: t('errors.error_deleting_waypoint.text'),
        })
      }
    },
    [fetchWaypoints, language, openErrorModal, t]
  )

  const selectWaypoint = useCallback(
    (id: number | null, options?: SelectWaypointOptions) => {
      if (id === null) {
        setSelectedWaypointId(null)
        return null
      }
      if (!waypointsRef.current) throw new Error('Waypoints empty')
      const waypoint = waypointsRef.current?.find((point) => point.id === id)
      if (!waypoint) throw new Error('Waypoint not found')

      const select = () => {
        setWaypointStatus('saved')
        setSelectedWaypointId(id)
        setEditMode('waypoint')
        if (map && options?.focus) fitWaypointToScreen(waypoint.point, map)
      }

      if (editModeRef.current === 'track' && !isTrackSavedRef.current) {
        openConfirmLosingTrackModal({ onConfirm: select })
        return null
      }
      select()

      return waypoint
    },
    [map, openConfirmLosingTrackModal, setEditMode]
  )

  useEffect(() => {
    if (!user) return
    const fetch = async () => {
      setIsFetching(true)
      await fetchWaypoints()
      setIsFetching(false)
    }
    fetch()
  }, [fetchWaypoints, user])

  const selectedWaypoint = useMemo(() => {
    const foundWaypoint = waypoints?.find((waypoint) => waypoint.id === selectedWaypointId)
    // return a new copy to prevent accident original array mutation
    return foundWaypoint ? { ...foundWaypoint } : null
  }, [selectedWaypointId, waypoints])

  /**
   * Returns a list of images that are present in links in all waypoints
   */
  const getImagesFromLinks = async () => {
    const allLinks = waypoints?.reduce<string[]>((links, waypoint) => [...links, ...waypoint.links], [])
    if (!allLinks || allLinks.length === 0) return []
    const imageUrls = await getUrlsIfAreValidImages(allLinks)
    return imageUrls
  }

  const getSelectedWaypointImages = useCallback(async () => {
    if (!selectedWaypoint || selectedWaypoint.links.length < 1) return []
    const images = await getUrlsIfAreValidImages(selectedWaypoint.links)
    return images
  }, [selectedWaypoint])

  return {
    waypoints,
    fetchWaypoints,
    selectedWaypoint,
    selectWaypoint,
    updateWaypoint,
    deleteWaypoints,
    createWaypoint,
    isAddingWaypoint,
    setIsAddingWaypoint,
    isFetching,
    waypointStatus,
    getImagesFromLinks,
    getSelectedWaypointImages,
  }
}

export const useUserWaypoints = () => useContext(UserWaypointsContext)

export const UserWaypointDataProvider = ({ children }: { children: ReactNode }) => {
  const userWaypointData = useProvideUserWaypoints()
  return <UserWaypointsContext.Provider value={userWaypointData}>{children}</UserWaypointsContext.Provider>
}
