import { ActionType, GenericActions } from './actions'
import { GenericState } from './state'

export const genericReducer = (state: GenericState, action: GenericActions): GenericState => {
  switch (action.type) {
    case ActionType.REWRITE_CHART_TOOLTIP_DATA:
      return { ...state, chartTooltipData: action.payload }
    case ActionType.TOGGLE_CESIUM:
      return { ...state, showCesiumMap: !state.showCesiumMap }
    case ActionType.SET_CESIUM_MODE:
      return { ...state, showCesiumMap: action.payload }
    default:
      throw new Error('Invalid action at genericReducer')
  }
}
