import { useCallback, useEffect, useRef, useState } from 'react'

type Data = {
  id: number
  selected: boolean
}

export const useMultiSelect = (data: Data[]) => {
  const [selectedData, setSelectedData] = useState<Data[]>(data)
  const [previousIndex, setPreviousIndex] = useState<number | null>(null)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const previousIndexRef = useRef(previousIndex)
  const selectedDataRef = useRef(selectedData)
  const selectedIdsRef = useRef(selectedIds)
  useEffect(() => {
    previousIndexRef.current = previousIndex
  }, [previousIndex])
  useEffect(() => {
    selectedDataRef.current = selectedData
  }, [selectedData])
  useEffect(() => {
    selectedIdsRef.current = selectedIds
  }, [selectedIds])

  useEffect(() => {
    setSelectedIds(selectedData.filter((value) => value.selected).map((value) => value.id))
  }, [selectedData])

  const getIndexesToChange = useCallback((toIndex: number) => {
    if (previousIndexRef.current !== null) {
      const min = Math.min(toIndex, previousIndexRef.current)
      const max = Math.max(toIndex, previousIndexRef.current)
      const range = [...Array(max - min + 1)].map((_, index) => min + index)
      return range
    }
    return []
  }, [])

  const onMultiselectClick = useCallback(
    (index: number, wasShiftPressed: boolean) => {
      if (wasShiftPressed) {
        const indexesToChange = getIndexesToChange(index)
        setSelectedData((prev) =>
          prev.map((value, i) => {
            if (indexesToChange.includes(i))
              return { id: value.id, selected: !selectedDataRef.current[index]?.selected }
            return value
          })
        )

        setPreviousIndex(index)
      } else {
        setPreviousIndex(index)
        setSelectedData((prev) => {
          const cloned = [...prev]
          cloned[index] = { id: cloned[index]!.id, selected: !cloned[index]!.selected }
          return cloned
        })
      }
    },
    [getIndexesToChange]
  )

  const reset = useCallback(() => {
    setSelectedData((prev) => prev.map((value) => ({ ...value, selected: false })))
    setSelectedIds([])
  }, [])

  const setNewData = useCallback((newData: { id: number }[]) => {
    setSelectedData(newData.map((value) => ({ ...value, selected: selectedIdsRef.current.includes(value.id) })))
  }, [])

  return { selectedIds, onMultiselectClick, reset, setNewData }
}
