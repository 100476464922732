import { MAP_SETTINGS } from 'config/constants'
import { Coords } from 'types/app'

import { getClosestValidZoom } from './mapSettings/getClosestValidZoom'
import { isValidLat, isValidLng } from './validators'

export const getQueryParam = (name: string) => new URLSearchParams(window.location.search).get(name)

export const getQueryLatLng = (): Coords | null => {
  const rawLat = getQueryParam('lat')
  const rawLng = getQueryParam('lng')

  if (!rawLat || !rawLng) return null

  const lat = isValidLat(Number(rawLat)) ? Number(rawLat) : null
  const lng = isValidLng(Number(rawLng)) ? Number(rawLng) : null

  if (lat !== null && lng !== null) {
    return [lat, lng]
  }
  return null
}

export const getQueryZoom = () => {
  const rawZoom = getQueryParam('z')
  if (!rawZoom) return null
  return getClosestValidZoom(Number(rawZoom), MAP_SETTINGS.minZoom ?? 1, MAP_SETTINGS.maxZoom ?? 20)
}

export const getQueryTrackID = () => getQueryParam('trkid')
