export const WalkaboutLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway.png" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/primary.png" />
      </th>
      <td>{'Primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/secondary.png" />
      </th>
      <td>{'Secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tertiary.png" />
      </th>
      <td>{'Tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/minor_road.png" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/service.png" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway_tunnel.png" />
      </th>
      <td>{'Motorway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/primary_tunnel.png" />
      </th>
      <td>{'Primary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/secondary_tunnel.png" />
      </th>
      <td>{'Secondary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tertiary_tunnel.png" />
      </th>
      <td>{'Tertiary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/minor_road_tunnel.png" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/oneway.png" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bridleway.png" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/steps.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/racetrack.png" width="70" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/runway.png" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taxiway.png" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rail.png" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rail_service.png" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tram.png" />
      </th>
      <td>{'Tram, light rail, subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste.png" />
      </th>
      <td>{'Piste'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_easy.png" />
      </th>
      <td>{'Piste (difficulty: easy)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_intermediate.png" />
      </th>
      <td>{'Piste (difficulty: intermediate)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_advanced.png" />
      </th>
      <td>{'Piste (difficulty: advanced, expert)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gondola.png" />
      </th>
      <td>{'Chairlift, gondola lift, cable car'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aerialway.png" />
      </th>
      <td>
        {
          'Goods cable lift, mixed lift, drag lift, t-bar lift, j-bar lift, platter lift, magic carpet, rope tow, zipline'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pier.png" />
      </th>
      <td>{'Pier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ferry.png" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water.png" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/glacier.png" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/intermittent.png" width="70" height="40" />
      </th>
      <td>{'Intermittent water areas'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/forest.png" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/scree.png" />
      </th>
      <td>{'Scree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/scrub.png" />
      </th>
      <td>{'Scrub, pier area, bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wetland.png" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach.png" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/grass.png" />
      </th>
      <td>{'Grass, meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/allotments.png" />
      </th>
      <td>{'Garden, allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cemetery.png" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/farmland.png" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/park.png" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch.png" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/playground.png" />
      </th>
      <td>{'Playground, village green'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recreation_ground.png" />
      </th>
      <td>{'Recreation ground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/golf_course.png" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hospital.png" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/university.png" />
      </th>
      <td>
        {
          'Stadium, university, college, school, airport, military area, place of worship, parking, industrial area, substation, plant, generator, railway area, wastewater plant, works, waterworks, campsite, theme park, maze'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pedestrian.png" />
      </th>
      <td>{'Pedestrian area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/zoo.png" />
      </th>
      <td>{'Zoo, wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tree_row.png" />
      </th>
      <td>{'Tree row'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hedge.png" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fence.png" />
      </th>
      <td>{'Fence'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/snow_fence.png" />
      </th>
      <td>{'Snow fence, dam'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/retaining_wall.png" />
      </th>
      <td>{'Retaining wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/city_wall.png" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/river.png" />
      </th>
      <td>{'River, stream, canal, ditch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/river_intermittent.png" width="70" />
      </th>
      <td>{'Drain + intermittent: river, stream, canal, ditch, drain'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/peak.png" width="20" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/volcano.png" width="20" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/saddle.png" width="20" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cave_entrance.png" width="20" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/spring.png" width="20" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hot_spring.png" width="20" />
      </th>
      <td>{'Hot spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/waterfall.png" width="20" />
      </th>
      <td>{'Waterfall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/geyser.png" width="20" />
      </th>
      <td>{'Geyser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rock.png" width="20" />
      </th>
      <td>{'Rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stone.png" width="20" />
      </th>
      <td>{'Stone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sinkhole.png" width="20" />
      </th>
      <td>{'Sinkhole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tree.png" width="16" />
      </th>
      <td>{'Tree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wetland_icon.png" width="20" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach_icon.png" width="20" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dune.png" width="20" />
      </th>
      <td>{'Dune'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/town_hall.png" width="20" />
      </th>
      <td>{'Town hall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/police.png" width="20" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fire_station.png" width="20" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/courthouse.png" width="20" />
      </th>
      <td>{'Court, lawyer office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/post.png" width="20" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/post_box.png" width="20" />
      </th>
      <td>{'Postbox'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bank.png" width="16" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/atm.png" width="20" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bureau_de_change.png" width="20" />
      </th>
      <td>{'Bureau de change'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/money_transfer.png" width="20" />
      </th>
      <td>{'Money transfer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/prison.png" width="20" />
      </th>
      <td>{'Prison'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ranger_station.png" width="20" />
      </th>
      <td>{'Ranger station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_wash.png" width="20" />
      </th>
      <td>{'Car wash'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_repair_station.png" width="20" />
      </th>
      <td>{'Bicycle repair station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_rental.png" width="20" />
      </th>
      <td>{'Car rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_sharing.png" width="20" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_rental.png" width="20" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/boat_rental.png" width="20" />
      </th>
      <td>{'Boat rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/boat_storage.png" width="20" />
      </th>
      <td>{'Boat storage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/university_icon.png" width="20" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/school.png" width="20" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/library.png" width="20" />
      </th>
      <td>{'Library, bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/childcare.png" width="20" />
      </th>
      <td>{'Childcare, day care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/veterinary.png" width="20" />
      </th>
      <td>{'Vet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/place_of_worship.png" width="20" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/christian.png" width="20" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/jewish.png" width="20" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/muslim.png" width="20" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hindu.png" width="20" />
      </th>
      <td>{'Hindu place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sikh.png" width="20" />
      </th>
      <td>{'Sikh place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shinto.png" width="20" />
      </th>
      <td>{'Shinto place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/buddhist.png" width="20" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taoist.png" width="20" />
      </th>
      <td>{'Taoist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pharmacy.png" width="20" />
      </th>
      <td>{'Pharmacy, chemist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hospital_icon.png" width="20" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/health_centre.png" width="20" />
      </th>
      <td>{'Health centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/doctors.png" width="20" />
      </th>
      <td>{'Doctor, clinic'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dentist.png" width="20" />
      </th>
      <td>{'Dentist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/blood_bank.png" width="20" />
      </th>
      <td>{'Blood bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/midwife.png" width="20" />
      </th>
      <td>{'Midwife'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/social_facility.png" width="20" />
      </th>
      <td>{'Social facility'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ambulatory_care.png" width="20" />
      </th>
      <td>{'Ambulatory care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/assisted_living.png" width="20" />
      </th>
      <td>{'Assisted living'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/food_bank.png" width="20" />
      </th>
      <td>{'Food bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/group_home.png" width="20" />
      </th>
      <td>{'Group home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nursing_home.png" width="20" />
      </th>
      <td>{'Nursing home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/outreach.png" width="20" />
      </th>
      <td>{'Outreach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/soup_kitchen.png" width="20" />
      </th>
      <td>{'Soup kitchen'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/workshop.png" width="20" />
      </th>
      <td>{'Workshop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hotel.png" width="20" />
      </th>
      <td>{'Hotel, hostel, motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/guest_house.png" width="20" />
      </th>
      <td>{'Guest house'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/chalet.png" width="20" />
      </th>
      <td>{'Chalet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/alpine_hut.png" width="20" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wilderness_hut.png" width="20" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/campsite.png" width="20" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/caravan_site.png" width="20" />
      </th>
      <td>{'Caravan park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shelter.png" width="20" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hunting_stand.png" width="20" />
      </th>
      <td>{'Hunting stand'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/museum.png" width="20" />
      </th>
      <td>{'Museum'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/theatre.png" width="20" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cinema.png" width="20" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gallery.png" width="20" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/arts_centre.png" width="20" />
      </th>
      <td>{'Arts centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/artwork.png" width="20" />
      </th>
      <td>{'Artwork'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/community_centre.png" width="20" />
      </th>
      <td>{'Community centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gambling.png" width="20" />
      </th>
      <td>{'Gambling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nightclub.png" width="20" />
      </th>
      <td>{'Nightclub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/studio.png" width="20" />
      </th>
      <td>{'Studio'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/park_icon.png" width="20" />
      </th>
      <td>{'National park, protected area, park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nature_reserve_icon.png" width="20" />
      </th>
      <td>{'Nature reserve, forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/playground_icon.png" width="20" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sports.png" width="18" />
      </th>
      <td>{'Recreation ground, sports centre, sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/garden.png" width="20" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fitness.png" width="20" />
      </th>
      <td>{'Outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recreation_track.png" width="20" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dog_park.png" width="20" />
      </th>
      <td>{'Dog park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/golf_course_icon.png" width="20" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/miniature_golf.png" width="20" />
      </th>
      <td>{'Minigolf'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_soccer.png" width="20" />
      </th>
      <td>{'Football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_american_football.png" width="20" />
      </th>
      <td>{'American football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_tennis.png" width="20" />
      </th>
      <td>{'Tennis court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_basketball.png" width="20" />
      </th>
      <td>{'Basketball court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_baseball.png" width="20" />
      </th>
      <td>{'Baseball field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_cricket.png" width="20" />
      </th>
      <td>{'Cricket field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_golf.png" width="20" />
      </th>
      <td>{'Green, golf shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/swimming_area.png" width="20" />
      </th>
      <td>{'Swimming area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_park.png" width="20" />
      </th>
      <td>{'Water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach_resort.png" width="20" />
      </th>
      <td>{'Beach resort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fishing.png" width="20" />
      </th>
      <td>{'Fishing area, fishing shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/picnic_site.png" width="20" />
      </th>
      <td>{'Picnic site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/firepit.png" width="20" />
      </th>
      <td>{'Fire pit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/viewpoint.png" width="20" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/information.png" width="20" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fort.png" width="20" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/memorial.png" width="20" />
      </th>
      <td>{'Memorial, monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/obelisk.png" width="20" />
      </th>
      <td>{'Obelisk'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/plaque.png" width="20" />
      </th>
      <td>{'Plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/archaeological_site.png" width="20" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/historical.png" width="20" />
      </th>
      <td>{'Historical'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/battlefield.png" width="20" />
      </th>
      <td>{'Battlefield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stadium.png" width="20" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/winter_sports.png" width="20" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ski_rental.png" width="20" />
      </th>
      <td>{'Ski rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ski_school.png" width="20" />
      </th>
      <td>{'Ski school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/marina.png" width="20" />
      </th>
      <td>{'Marina'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dive_centre.png" width="20" />
      </th>
      <td>{'Dive centre, scuba diving equipment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aquarium.png" width="20" />
      </th>
      <td>{'Aquarium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/horse_range.png" width="20" />
      </th>
      <td>{'Horse riding'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/zoo_icon.png" width="20" />
      </th>
      <td>{'Zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aviary.png" width="20" />
      </th>
      <td>{'Aviary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/petting_zoo.png" width="20" />
      </th>
      <td>{'Petting zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wildlife_park.png" width="20" />
      </th>
      <td>{'Wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/theme_park.png" width="20" />
      </th>
      <td>{'Theme park, amusement ride'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/attraction.png" width="20" />
      </th>
      <td>{'Attraction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/carousel.png" width="20" />
      </th>
      <td>{'Carousel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/roller_coaster.png" width="20" />
      </th>
      <td>{'Roller coaster'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/summer_toboggan.png" width="20" />
      </th>
      <td>{'Summer toboggan'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/maze.png" width="20" />
      </th>
      <td>{'Maze'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_slide.png" width="20" />
      </th>
      <td>{'Water slide'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/slipway.png" width="20" />
      </th>
      <td>{'Slipway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_put_in.png" width="20" />
      </th>
      <td>{'Put in (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_egress.png" width="20" />
      </th>
      <td>{'Egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_put_in_egress.png" width="20" />
      </th>
      <td>{'Put in, egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_rapid.png" width="20" />
      </th>
      <td>{'Rapid (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_hazard.png" width="20" />
      </th>
      <td>{'Hazard (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/summer_camp.png" width="20" />
      </th>
      <td>{'Summer camp'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/parking.png" width="16" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/parking_garage.png" width="20" />
      </th>
      <td>{'Parking garage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_parking.png" width="20" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorcycle_parking.png" width="20" />
      </th>
      <td>{'Motorcycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bus_stop.png" width="20" />
      </th>
      <td>{'Bus station, bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/station.png" width="20" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/halt.png" width="20" />
      </th>
      <td>{'Train request station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tram_stop.png" width="20" />
      </th>
      <td>{'Tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taxi.png" width="20" />
      </th>
      <td>{'Taxi'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fuel.png" width="20" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/charging_station.png" width="20" />
      </th>
      <td>{'Charging station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/traffic_lights.png" width="20" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/level_crossing.png" width="14" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mini_roundabout.png" width="20" />
      </th>
      <td>{'Mini roundabout'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/airport.png" width="20" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/helipad.png" width="20" />
      </th>
      <td>{'Helipad'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/heliport.png" width="20" />
      </th>
      <td>{'Heliport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ferry_terminal.png" width="20" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aeroway_gate.png" width="20" />
      </th>
      <td>{'Aeroway gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/subway_entrance.png" width="20" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/elevator.png" width="20" />
      </th>
      <td>{'Elevator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toll_both.png" width="20" />
      </th>
      <td>{'Tollboth'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway_junction.png" width="20" />
      </th>
      <td>{'Motorway junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rest_area.png" width="20" />
      </th>
      <td>{'Rest area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/service_area.png" width="20" />
      </th>
      <td>{'Service area'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Offices'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/company.png" width="20" />
      </th>
      <td>{'Company, insurance, office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/association.png" width="20" />
      </th>
      <td>{'Association, foundation'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/government.png" width="20" />
      </th>
      <td>{'Government offices'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/employment_agency.png" width="20" />
      </th>
      <td>{'Employment agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/estate_agent.png" width="20" />
      </th>
      <td>{'Estate agent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/notary.png" width="20" />
      </th>
      <td>{'Notary public'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ngo.png" width="20" />
      </th>
      <td>{'NGO'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/consulting.png" width="20" />
      </th>
      <td>{'Consulting'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/it.png" width="20" />
      </th>
      <td>{'It office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/financial.png" width="20" />
      </th>
      <td>{'Financial company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/political_party.png" width="20" />
      </th>
      <td>{'Political party'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telecommunication.png" width="20" />
      </th>
      <td>{'Telecommunication company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/religion.png" width="20" />
      </th>
      <td>{'Religious community'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/research.png" width="20" />
      </th>
      <td>{'Research'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/architect.png" width="20" />
      </th>
      <td>{'Architect'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/accountant.png" width="20" />
      </th>
      <td>{'Accountant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tax_advisor.png" width="20" />
      </th>
      <td>{'Tax advisor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/therapist.png" width="20" />
      </th>
      <td>{'Therapist'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bar.png" width="20" />
      </th>
      <td>{'Bar, pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cafe.png" width="20" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/biergarten.png" width="20" />
      </th>
      <td>{'Beer garden, brewery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fast_food.png" width="20" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/restaurant.png" width="20" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ice_cream.png" width="20" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Craft'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/craft.png" width="20" />
      </th>
      <td>{'Unspecified craft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/carpenter.png" width="20" />
      </th>
      <td>{'Carpenter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/electrician.png" width="20" />
      </th>
      <td>{'Electrician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hvac.png" width="20" />
      </th>
      <td>{'HVAC'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/photographer.png" width="20" />
      </th>
      <td>{'Photographer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/confectionery.png" width="20" />
      </th>
      <td>{'Confectionery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/winery.png" width="20" />
      </th>
      <td>{'Winery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dressmaker.png" width="20" />
      </th>
      <td>{'Dressmaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tailor.png" width="20" />
      </th>
      <td>{'Tailor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gardener.png" width="20" />
      </th>
      <td>{'Gardener'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stonemason.png" width="20" />
      </th>
      <td>{'Stonemason'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/plumber.png" width="20" />
      </th>
      <td>{'Plumber'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/handicraft.png" width="20" />
      </th>
      <td>{'Handicraft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shoemaker.png" width="20" />
      </th>
      <td>{'Shoemaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pottery.png" width="20" />
      </th>
      <td>{'Pottery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/metal_construction.png" width="20" />
      </th>
      <td>{'Metal construction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sawmill.png" width="20" />
      </th>
      <td>{'Sawmill'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shop.png" width="20" />
      </th>
      <td>{'Shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/closed.png" width="16" />
      </th>
      <td>{'Closed shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/alcohol.png" width="20" />
      </th>
      <td>{'Alcohol shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/art.png" width="20" />
      </th>
      <td>{'Art shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bakery.png" width="20" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beauty.png" width="20" />
      </th>
      <td>{'Beauty salon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle.png" width="20" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gas_canister.png" width="20" />
      </th>
      <td>{'Bottled gas shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/butcher.png" width="20" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_parts.png" width="20" />
      </th>
      <td>{'Car parts shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_repair.png" width="20" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car.png" width="20" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/clothes.png" width="20" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/coffee.png" width="20" />
      </th>
      <td>{'Coffee shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/computer.png" width="20" />
      </th>
      <td>{'Computer store, Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/convenience.png" width="20" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/copyshop.png" width="20" />
      </th>
      <td>{'Copy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cosmetics.png" width="20" />
      </th>
      <td>{'Cosmetics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/deli.png" width="20" />
      </th>
      <td>{'Deli'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mall.png" width="20" />
      </th>
      <td>{'Department store, mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hardware.png" width="20" />
      </th>
      <td>{'Do-it-yourself store, hardware store, painter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dry_cleaning.png" width="20" />
      </th>
      <td>{'Dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fishmonger.png" width="20" />
      </th>
      <td>{'Fishmonger'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/florist.png" width="20" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/funeral_directors.png" width="20" />
      </th>
      <td>{'Funeral home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/furniture.png" width="20" />
      </th>
      <td>{'Furniture shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/garden_centre.png" width="20" />
      </th>
      <td>{'Garden centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gift.png" width="20" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/greengrocer.png" width="20" />
      </th>
      <td>{'Greengrocery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hairdresser.png" width="20" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hifi.png" width="20" />
      </th>
      <td>{'Hi-fi shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trade.png" width="20" />
      </th>
      <td>{'Home improvement store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hunting.png" width="20" />
      </th>
      <td>{'Hunting shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/jewellry.png" width="20" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/laundry.png" width="20" />
      </th>
      <td>{'Laundry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/marketplace.png" width="20" />
      </th>
      <td>{'Marketplace'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mobile_phone.png" width="20" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorcycle.png" width="20" />
      </th>
      <td>{'Motorcycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/music.png" width="20" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/newsagent.png" width="20" />
      </th>
      <td>{'Newsagent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/newspaper.png" width="20" />
      </th>
      <td>{'Newspaper'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/optician.png" width="20" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/outdoor.png" width="20" />
      </th>
      <td>{'Outdoor shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/perfumery.png" width="20" />
      </th>
      <td>{'Perfumery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pet.png" width="20" />
      </th>
      <td>{'Pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/photo.png" width="20" />
      </th>
      <td>{'Photography shop, photographic laboratory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shoes.png" width="20" />
      </th>
      <td>{'Shoe shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stationery.png" width="20" />
      </th>
      <td>{'Stationery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/supermarket.png" width="20" />
      </th>
      <td>{'Supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tobacco.png" width="20" />
      </th>
      <td>{'Tobacconist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toy.png" width="20" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/travel_agency.png" width="20" />
      </th>
      <td>{'Travel agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tyres.png" width="20" />
      </th>
      <td>{'Tyre shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/variety_store.png" width="20" />
      </th>
      <td>{'Variety store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wine.png" width="20" />
      </th>
      <td>{'Wine shop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toilets.png" width="20" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/drinking_water.png" width="20" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_point.png" width="20" />
      </th>
      <td>{'Water point'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/watering_place.png" width="20" />
      </th>
      <td>{'Watering place'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bbq.png" width="20" />
      </th>
      <td>{'Barbecue'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shower.png" width="20" />
      </th>
      <td>{'Shower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telephone.png" width="20" />
      </th>
      <td>{'Telephone, emergency phone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recycling.png" width="20" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wastebasket.png" width="20" />
      </th>
      <td>{'Wastebasket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/waste_disposal.png" width="20" />
      </th>
      <td>{'Waste container'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bench.png" width="20" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lamp.png" width="20" />
      </th>
      <td>{'Street lamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/defibrilator.png" width="20" />
      </th>
      <td>{'Defibrillator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fire_hydrant.png" width="20" />
      </th>
      <td>{'Fire hydrant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gate.png" width="20" />
      </th>
      <td>{'Gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bollard.png" width="20" />
      </th>
      <td>{'Bollard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cycle_barrier.png" width="20" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/border_control.png" width="20" />
      </th>
      <td>{'Border control'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_well.png" width="20" />
      </th>
      <td>{'Water well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mast.png" width="20" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/communications_tower.png" width="20" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telescope.png" width="20" />
      </th>
      <td>{'Radio telescope'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/observatory.png" width="20" />
      </th>
      <td>{'Observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_tower.png" width="20" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/watermill.png" width="20" />
      </th>
      <td>{'Watermill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/windmill.png" width="20" />
      </th>
      <td>{'Windmill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lighthouse.png" width="20" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cross.png" width="16" />
      </th>
      <td>{'Cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wayside_cross.png" width="20" />
      </th>
      <td>{'Wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/substation.png" width="20" />
      </th>
      <td>{'Generator, plant, substation, wastewater plant, waterworks, works'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_nuclear.png" width="20" />
      </th>
      <td>{'Nuclear generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_solar.png" width="20" />
      </th>
      <td>{'Solar generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_wind.png" width="20" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/power_pole.png" width="20" />
      </th>
      <td>{'Power pole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/power_tower.png" width="20" />
      </th>
      <td>{'Transmission tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pylon.png" width="20" />
      </th>
      <td>{'Pylon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/petroleum_well.png" width="20" />
      </th>
      <td>{'Petroleum well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lifeguard_tower.png" width="20" />
      </th>
      <td>{'Lifeguard tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/offshore_platform.png" width="20" />
      </th>
      <td>{'Offshore platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dam.png" width="20" />
      </th>
      <td>{'Dam'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lock.png" width="20" />
      </th>
      <td>{'Lock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cemetery_icon.png" width="20" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/quarry.png" width="20" />
      </th>
      <td>{'Quarry, mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/allotments_icon.png" width="20" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/farm.png" width="20" />
      </th>
      <td>{'Farm'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/slaughterhouse.png" width="20" />
      </th>
      <td>{'Slaughterhouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bunker.png" width="20" />
      </th>
      <td>{'Bunker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/airfield.png" width="20" />
      </th>
      <td>{'Airfield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/military.png" width="20" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/danger_area.png" width="20" />
      </th>
      <td>{'Danger area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trail_riding_station.png" width="20" />
      </th>
      <td>{'Trail riding station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trailhead.png" width="20" />
      </th>
      <td>{'Trailhead'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/walking_junction.png" width="20" />
      </th>
      <td>{'Walking junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generic.png" width="9" />
      </th>
      <td>{'Generic symbol'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike paths'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_path_off-road.png" width="70" />
      </th>
      <td>{'Bike path - off-roads paths (off-road trail)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_minor_road_neighborhood_greenway.png" width="70" />
      </th>
      <td>{'Minor road - residential and living streets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_minor_road_bike-friendly_minor_road.png" width="70" />
      </th>
      <td>{'Minor road - bike-friendly minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_cycle_track.png" width="70" />
      </th>
      <td>{'Major road - ride your bike separated from cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_bike_lanes.png" width="70" />
      </th>
      <td>{'Major road - ride your bike next to cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_bike_route.png" width="70" />
      </th>
      <td>{'Major road - ride your bike with cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_off-road_dirt_track.png" width="70" />
      </th>
      <td>{'Other - off-road dirt track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_footway.png" width="70" />
      </th>
      <td>{'Other - ride your bike with walkers'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_bycicle=no.png" width="70" />
      </th>
      <td>{'Other - walk your bike'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_bike-friendly_minor_road.png" width="70" />
      </th>
      <td>{'Other - bike-friendly minor road (with buses)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle_parking.png" width="20" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle_rental.png" width="20" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle.png" width="20" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_drinking_water.png" width="20" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_toilets.png" width="20" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_cycle_barrier.png" width="20" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_cafe.png" width="20" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_pub.png" width="20" />
      </th>
      <td>{'Pub'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike paths'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/footway.png" width="70" />
      </th>
      <td>{'Footway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/path.png" width="70" />
      </th>
      <td>{'Path, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/track.png" width="70" />
      </th>
      <td>{'Track, no motor vehicles'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/steps_hike.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/walking_network.png" width="70" />
      </th>
      <td>{'Walking network'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
