import { useMatomo } from '@datapunt/matomo-tracker-react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, useMantineTheme } from '@mantine/core'
import { useDetectOS } from 'hooks/useDetectOS'
import { useTranslation } from 'react-i18next'

const TRACKBOOK_DOWNLOAD_LINKS = {
  windows: 'https://dl.vecturagames.com/trackbook-win.zip',
  linux: 'https://dl.vecturagames.com/trackbook-linux.zip',
  mac: 'https://dl.vecturagames.com/trackbook-mac.dmg',
}

export const DownloadTrackbook = ({ iconWidth }: { iconWidth: number }) => {
  const { os } = useDetectOS()
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const { trackEvent } = useMatomo()

  const getHref = () => {
    if (os === 'linux') return TRACKBOOK_DOWNLOAD_LINKS.linux
    if (os === 'mac') return TRACKBOOK_DOWNLOAD_LINKS.mac
    return TRACKBOOK_DOWNLOAD_LINKS.windows
  }

  const getFileName = () => {
    if (os === 'linux') return 'trackbook-linux.zip'
    if (os === 'mac') return 'trackbook-mac.dmg'
    return 'trackbook-windows.zip'
  }

  const getOSLabel = () => {
    if (os === 'linux') return 'Linux'
    if (os === 'mac') return 'macOS'
    return 'Windows'
  }
  const downloadAppLabel = t('dropdown_menu.download_app_label')

  const handleClick = () => {
    trackEvent({ category: 'Download Trackbook', action: 'Download Trackbook', name: 'Download Trackbook' })
  }

  return (
    <Menu.Item
      component="a"
      onClick={handleClick}
      href={getHref()}
      download={getFileName()}
      icon={<FontAwesomeIcon icon={faDownload} color={theme.colors.gray[6]} width={iconWidth} />}
    >
      {`${downloadAppLabel} (${getOSLabel()})`}
    </Menu.Item>
  )
}
