import { useCallback, useEffect, useRef } from 'react'

import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { Tooltip } from 'components/UI/Tooltip'
import { CollapsibleWidthComponent } from 'components/UI/TopMenu/CollapsibleComponent'
import { useEditMode } from 'hooks/useEditMode'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'

export const EditIcon = ({ isOpen }: { isOpen: boolean }) => {
  const { selectedTrack } = useUserTracks()
  const { selectedWaypoint } = useUserWaypoints()
  const { openTrackEditModal, openWaypointEditModal } = useGenericModals()
  const { editMode } = useEditMode()
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const selectedTrackRef = useRef(selectedTrack)
  const selectedWaypointRef = useRef(selectedWaypoint)
  useEffect(() => {
    selectedTrackRef.current = selectedTrack
  }, [selectedTrack])
  useEffect(() => {
    selectedWaypointRef.current = selectedWaypoint
  }, [selectedWaypoint])

  const handleClick = useCallback(() => {
    if (selectedTrackRef.current) openTrackEditModal(selectedTrackRef.current)
    if (selectedWaypointRef.current) openWaypointEditModal(selectedWaypointRef.current)
  }, [openTrackEditModal, openWaypointEditModal])

  return (
    <Tooltip
      label={
        editMode === 'track' ? t('editmode.edit_button_tooltip.track') : t('editmode.edit_button_tooltip.waypoint')
      }
    >
      <div>
        <CollapsibleWidthComponent isOpen={isOpen} width={28} height={28}>
          <ActionIcon
            variant="light"
            color={theme.primaryColor}
            disabled={!selectedTrack && !selectedWaypoint}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </ActionIcon>
        </CollapsibleWidthComponent>
      </div>
    </Tooltip>
  )
}
