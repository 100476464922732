import { SVGProps } from 'react'

import { omit } from 'lodash'

export const BicycleRoadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2, ...props.style }}
    {...omit(props, 'style')}
  >
    <g transform="matrix(1.25,0,0,1.25,-64,-81.8207)">
      <g id="Vrstva-1">
        <g id="g709">
          <rect id="rect291" x="51.201" y="403.645" width="409.599" height="50" fill="currentColor" />
          <path
            id="path1016-6"
            d="M372.757,224.178C330.242,227.365 296.96,263.235 296.96,305.869C296.977,350.798 333.952,387.759 378.88,387.759C423.808,387.759 460.783,350.798 460.8,305.869C460.8,305.869 460.8,305.869 460.8,305.869C460.8,260.929 423.82,223.949 378.88,223.949C376.837,223.949 374.795,224.026 372.757,224.178ZM375.436,259.918C376.582,259.832 377.731,259.789 378.88,259.789C404.159,259.789 424.96,280.591 424.96,305.869C424.96,305.869 424.96,305.869 424.96,305.869C424.96,331.148 404.159,351.949 378.88,351.949C353.601,351.949 332.8,331.148 332.8,305.869C332.8,281.888 351.521,261.711 375.436,259.918Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1020-7"
            d="M126.998,224.178C84.482,227.365 51.2,263.235 51.2,305.869C51.2,350.809 88.18,387.789 133.12,387.789C178.06,387.789 215.04,350.809 215.04,305.869C215.04,305.869 215.04,305.869 215.04,305.869C215.04,260.929 178.06,223.949 133.12,223.949C131.077,223.949 129.035,224.026 126.998,224.178ZM129.676,259.918C130.822,259.832 131.971,259.789 133.12,259.789C158.399,259.789 179.2,280.591 179.2,305.869C179.2,305.869 179.2,305.869 179.2,305.869C179.193,331.143 158.394,351.936 133.12,351.936C107.846,351.936 87.047,331.143 87.04,305.869C87.04,281.888 105.762,261.711 129.676,259.918Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1024-5"
            d="M309.393,179.654C309.231,174.627 308.698,159.596 307.922,152.694C306.983,144.335 305.815,132.512 300.118,126.444C291.117,116.856 273.431,114.856 259.31,115.059C233.102,115.435 205.211,122.675 184.206,138.353C166.595,151.498 144.609,171.392 147.574,193.167C149.694,208.733 162.294,210.61 183.286,223.922L235.519,255.373L235.519,326.349C235.519,337.677 244.671,346.829 255.999,346.829C267.327,346.829 276.479,337.677 276.479,326.349L276.479,244.429C276.479,237.901 273.397,231.781 268.176,227.952C267.75,227.64 266.994,227.245 266.506,227.044C259.229,224.059 209.529,203.276 208.122,191.909C207.159,183.805 224.585,174.637 233.871,170.953C243.157,167.27 259.788,164.117 268.278,169.305C276.769,174.494 276.331,185.368 276.331,185.368L275.752,205.593C275.609,210.586 278.783,216.401 283.098,216.424C283.44,216.426 283.796,216.428 284.169,216.432C293.527,216.528 314.748,216.39 317.084,216.39L358.044,216.39C369.372,216.39 378.879,210.32 378.879,198.992C378.879,187.664 369.727,181.33 358.399,181.33L311.122,181.33C310.196,181.33 309.423,180.579 309.393,179.654L309.393,179.654Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
          <path
            id="path1026-3"
            d="M349.05,148.308C365.903,148.308 379.77,134.441 379.77,117.588C379.77,100.736 365.903,86.868 349.05,86.868C332.198,86.868 318.33,100.736 318.33,117.588C318.33,134.441 332.198,148.308 349.05,148.308Z"
            fill="currentColor"
            style={{ fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </g>
  </svg>
)
