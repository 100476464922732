import { MouseEvent, ReactNode, useState } from 'react'

import { faAndroid, faApple, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Checkbox, Group, Image, Stack, TypographyStylesProvider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { ContextModalProps } from '@mantine/modals'
import { landscapes } from 'assets/welcomeModal'
import { useGenericModals } from 'hooks/useGenericModals'
import { useResponsive } from 'hooks/useResponsive'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import './WelcomeModal.css'

const getRandomLandscapeImage = () => landscapes[Math.floor(Math.random() * landscapes.length)]

type IconWithTextProps = {
  icon: ReactNode
  children: ReactNode
}
const WrapperWithIconOnLeft = ({ icon, children }: IconWithTextProps) => (
  <Group spacing={4}>
    <Box sx={{ minWidth: 22 }}>{icon}</Box>
    <div>{children}</div>
  </Group>
)

const GpxViewerLink = () => (
  <a
    href="https://play.google.com/store/apps/details?id=com.vecturagames.android.app.gpxviewer"
    target="_blank"
    rel="noreferrer"
  >
    {'GPX Viewer'}
  </a>
)

const GpxViewerProAndroidLink = () => (
  <a
    href="https://play.google.com/store/apps/details?id=com.vecturagames.android.app.gpxviewer.pro"
    target="_blank"
    rel="noreferrer"
  >
    {'GPX Viewer PRO'}
  </a>
)

const GpxViewerProiOSLink = () => (
  <a href="https://apps.apple.com/us/app/gpx-viewer-pro/id1497379822" target="_blank" rel="noreferrer">
    {'GPX Viewer PRO'}
  </a>
)

export const WelcomeModal = ({ context, id }: ContextModalProps) => {
  const { openHelpModal } = useGenericModals()
  const [featuredImage] = useState(getRandomLandscapeImage())
  const { isMd } = useResponsive()
  const [, setIsWelcomeModalHidden] = useLocalStorage<boolean>({ key: 'hideWelcomeModal' })
  const { t } = useTranslation()

  const screenSizeParagraph = t('welcome_modal.screen_size_paragraph')
  const tracksParagraph = t('welcome_modal.tracks_paragraph')
  const createTracksLink = t('welcome_modal.create_tracks_link')
  const accountParagraph = t('welcome_modal.account_paragraph')
  const syncParagraph = t('welcome_modal.sync_paragraph')
  const youTubeParagraph = t('welcome_modal.youtube_paragraph')
  const youtubeLink = t('welcome_modal.youtube_link')
  const dontShowAgainCheckbox = t('welcome_modal.dont_show_again_checkbox')
  const okButton = t('welcome_modal.ok_button')

  const handleHelpLinkClick = (e: MouseEvent) => {
    e.preventDefault()
    context.closeModal(id)
    openHelpModal()
  }

  const handleClose = () => {
    context.closeModal(id)
  }

  return (
    <>
      <Image src={featuredImage} fit="contain" />
      <TypographyStylesProvider>
        <Stack p="lg">
          <div className="welcomeModalHeaderContent">
            {!isMd && <ReactMarkdown skipHtml>{screenSizeParagraph}</ReactMarkdown>}
            <div>
              <ReactMarkdown skipHtml>{tracksParagraph}</ReactMarkdown>
              <div style={{ marginLeft: 20 }}>
                <a href="" onClick={handleHelpLinkClick}>
                  <span style={{ paddingRight: 7 }}>{'🚵‍♀️'}</span>
                  {createTracksLink}
                </a>
              </div>
            </div>
          </div>
          {isMd && (
            <div className="welcomeModalmainContent">
              <ReactMarkdown skipHtml>{accountParagraph}</ReactMarkdown>
              <ReactMarkdown skipHtml>{syncParagraph}</ReactMarkdown>
              <ul style={{ listStyleType: 'none', paddingLeft: 22 }}>
                <li>
                  <WrapperWithIconOnLeft icon={<FontAwesomeIcon icon={faAndroid} color="#78C257" />}>
                    {'Android: '}
                    <GpxViewerLink />
                    {', '}
                    <GpxViewerProAndroidLink />
                  </WrapperWithIconOnLeft>
                </li>
                <li>
                  <WrapperWithIconOnLeft icon={<FontAwesomeIcon icon={faApple} color="#555555" fontSize={20} />}>
                    {'iOS: '}
                    <GpxViewerProiOSLink />
                  </WrapperWithIconOnLeft>
                </li>
              </ul>
              <ReactMarkdown skipHtml>{youTubeParagraph}</ReactMarkdown>
              <div style={{ marginLeft: 15 }}>
                <a href="https://www.youtube.com/watch?v=uh2ioW_Xyxg" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} color="#FF0000" style={{ paddingLeft: 6, paddingRight: 4 }} />
                  {youtubeLink}
                </a>
              </div>
            </div>
          )}
          <Group sx={{ justifyContent: 'space-between' }}>
            <Checkbox
              label={dontShowAgainCheckbox}
              onChange={(e) => setIsWelcomeModalHidden(e.currentTarget.checked)}
            />
            <Button onClick={handleClose} data-autofocus>
              {okButton}
            </Button>
          </Group>
        </Stack>
      </TypographyStylesProvider>
    </>
  )
}
