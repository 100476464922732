import { useCallback, useEffect, useRef, useState } from 'react'

import { ColorPicker, Popover } from '@mantine/core'
import { ColorCircle } from 'components/UI/ColorCircle'
import { TRACK_COLORS } from 'config/constants'
import { Routing } from 'config/enums/routings'
import { COLOR_SWATCHES } from 'config/mantine'
import { useUserTracks } from 'hooks/useUserTracks'
import { debounce } from 'lodash'
import { changeTrackColor } from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'

export const CircleColorPicker = () => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const {
    dispatch,
    state: { trackColor, routing },
  } = useRouting()
  const { selectedTrack, updateTrack } = useUserTracks()
  const selectedTrackRef = useRef(selectedTrack)

  useEffect(() => {
    selectedTrackRef.current = selectedTrack
  }, [selectedTrack])

  const updateTrackColor = useCallback(
    (color: string) => {
      if (selectedTrackRef.current?.id) {
        updateTrack(selectedTrackRef.current?.id, { color: color ?? undefined })
      }
    },
    [updateTrack]
  )

  const debouncedUpdateTrack = debounce(updateTrackColor, 1000)

  const handleColorChange = (color: string) => {
    dispatch(changeTrackColor(color), false)
    debouncedUpdateTrack(color)
  }

  const defaultColorCircleColor = routing === Routing.none ? TRACK_COLORS.nonRoutable : TRACK_COLORS.routable

  return (
    <Popover
      opened={isColorPickerOpen}
      onClose={() => setIsColorPickerOpen(false)}
      position="bottom-end"
      closeOnClickOutside={true}
    >
      <Popover.Target>
        <ColorCircle
          color={trackColor || defaultColorCircleColor}
          onClick={() => setIsColorPickerOpen((prev) => !prev)}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          format="hex"
          swatches={COLOR_SWATCHES}
          swatchesPerRow={6}
          onChange={handleColorChange}
          value={trackColor || TRACK_COLORS.routable}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
