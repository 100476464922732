import { TFunction } from 'react-i18next'
import { UnitType } from 'types/app'
import { Distance, DistanceUnits } from 'utils/units/Distance'

export const formatDistance = (meters: number, unitType: UnitType, decimals: number) => {
  const unitName: DistanceUnits = unitType === 'metric' ? 'km' : 'mi'
  const distance = new Distance(meters).get(unitName)
  return `${distance.toFixed(decimals)} ${unitName}`
}

export const formatDistanceLabel = (meters: number, unitType: UnitType, decimals: number, t: TFunction) =>
  `${t('track_stats.length_label')}${':'} ${formatDistance(meters, unitType, decimals)}`
