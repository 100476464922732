import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, PasswordInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { showSuccessNotification } from "utils/notifications/customNotifications";

type Props = {
  key: string;
};

export const EnterNewPasswordModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<Props>) => {
  const { loading, setPassword } = useAuth();
  const { key } = innerProps;
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      password: "",
      passwordrepeat: "",
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { success, error } = await setPassword(
      form.values.password,
      form.values.passwordrepeat,
      key
    );
    if (error) {
      form.setErrors({ passwordrepeat: error });
    }
    if (success) {
      context.closeModal(id);
      showSuccessNotification({ message: success });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="sm">
        <PasswordInput
          disabled={loading}
          label={t("signup_modal.password.label")}
          placeholder={t("signup_modal.password.placeholder")}
          icon={<FontAwesomeIcon icon={faLock} />}
          required
          {...form.getInputProps("password")}
        />
        <PasswordInput
          disabled={loading}
          label={t("signup_modal.confirm_password.label")}
          placeholder={t("signup_modal.confirm_password.placeholder")}
          icon={<FontAwesomeIcon icon={faLock} />}
          required
          {...form.getInputProps("passwordrepeat")}
        />
        <Button type="submit" loading={loading} fullWidth>
          {t("new_password_modal.submit_button_text")}
        </Button>
      </Stack>
    </form>
  );
};
