export type DistanceUnits = 'm' | 'km' | 'ft' | 'mi'

export class Distance {
  m: number
  km: number
  ft: number
  mi: number
  /**
   * @param length Distance in meters
   */
  constructor(length: number) {
    this.m = length
    this.km = length / 1000
    this.mi = length * 0.000621371
    this.ft = length * 3.28084
  }
  get(unit: DistanceUnits) {
    return this[unit]
  }
}
