import { useEffect, useMemo } from 'react'

import { useModals } from '@mantine/modals'
import { useLiftedModalContext } from 'hooks/useLiftedModalContext'
import { useMapContext } from 'hooks/useMapContext'
import { useMap } from 'react-leaflet'
/**
 * A component whose sole purpose is to lift some values to custom contexts
 * For example, the map variable (const map = useMap()) can only be obtained if you are within context of
 * a leaflet map. However, we have to work with the context elsewhere in the app as well. That's why
 * the context is lifted to a custom one (useMapContext() hook)
 */
export const ContextLifter = () => {
  const map = useMap()
  const { setMap: setLiftedContextMap } = useMapContext()
  const modals = useModals()
  const { setModals: setLiftedContextModals, modals: liftedModals } = useLiftedModalContext()

  const memoizedModals = useMemo(() => modals, [])

  useEffect(() => {
    if (map) setLiftedContextMap(map)
    return () => setLiftedContextMap(null)
  }, [map, setLiftedContextMap])

  useEffect(() => {
    if (liftedModals) return
    if (memoizedModals) setLiftedContextModals(memoizedModals)
  }, [memoizedModals, liftedModals, setLiftedContextModals])

  return null
}
