import { useMemo } from 'react'

import { faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Group, useMantineTheme } from '@mantine/core'
import { Tooltip } from 'components/UI/Tooltip'
import { CollapsibleWidthComponent } from 'components/UI/TopMenu/CollapsibleComponent'
import { useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'

export const UndoRedoButtons = ({ isOpen }: { isOpen: boolean }) => {
  const {
    history: { canUndo, canRedo, undo, redo },
  } = useRouting()
  const { t } = useTranslation()
  const theme = useMantineTheme()

  return useMemo(
    () => (
      <CollapsibleWidthComponent height={28} width={57} isOpen={isOpen}>
        <Group
          noWrap
          spacing={1}
          sx={{
            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
            borderRadius: theme.radius.sm,
          }}
        >
          <Tooltip label={t('editmode.undo_tooltip')}>
            <div>
              <ActionIcon
                variant="light"
                color={theme.primaryColor}
                disabled={!canUndo}
                onClick={undo}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </ActionIcon>
            </div>
          </Tooltip>
          <Tooltip label={t('editmode.redo_tooltip')}>
            <div>
              <ActionIcon
                variant="light"
                color={theme.primaryColor}
                disabled={!canRedo}
                onClick={redo}
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </ActionIcon>
            </div>
          </Tooltip>
        </Group>
      </CollapsibleWidthComponent>
    ),
    [
      canRedo,
      canUndo,
      isOpen,
      redo,
      t,
      theme.colorScheme,
      theme.colors.dark,
      theme.colors.gray,
      theme.primaryColor,
      theme.radius.sm,
      undo,
    ]
  )
}
