import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Overlay, Text } from '@mantine/core'
import { EndMarker, MidMarker, StartMarker } from 'components/UI/OptionsPanel/Markers'

type MarkerType = 'start' | 'middle' | 'end'

const Marker = ({ type }: { type: MarkerType }) => {
  if (type === 'start') return <StartMarker />
  if (type === 'end') return <EndMarker />
  return <MidMarker />
}

export const MarkerWithNumber = ({ type, numericLabel }: { type: MarkerType; numericLabel?: number }) => (
  <Box sx={{ display: 'flex', position: 'relative', width: '100%' }}>
    <Marker type={type} />
    {numericLabel !== undefined && (
      <Overlay opacity={1} color="transparent" sx={{ justifyContent: 'center', display: 'flex' }}>
        {numericLabel > 99 ? (
          <Box pt="6%">
            <FontAwesomeIcon icon={faCircle} color="white" fontSize={14} />
          </Box>
        ) : (
          <Text color="white" weight="bold" size="sm" pt="15%">
            {numericLabel}
          </Text>
        )}
      </Overlay>
    )}
  </Box>
)
