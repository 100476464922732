import axios from 'axios'
import { TrackRaw } from 'types/app'

const API_BASE = import.meta.env.VITE_BASE_API_URL ?? ''

export const getTrackByUUID = async (uuid: string): Promise<TrackRaw> => {
  const res = await axios.post(`${API_BASE}/get-track`, {
    uuid,
  })
  const { data } = res
  if (data.tracks.length === 0) throw new Error('Unable to get track')
  return data.tracks[0]
}
