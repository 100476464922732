import { useCallback, useState } from 'react'

import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LightboxGallery } from 'components/UI/Gallery/LightboxGallery'
import { Tooltip } from 'components/UI/Tooltip'
import { useAuth } from 'hooks/useAuth'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'
import { EditMode } from 'types/app'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

type Props = {
  isOpen: boolean
  editMode: EditMode
}

export const GalleryIcon = ({ isOpen, editMode }: Props) => {
  const { t } = useTranslation()
  const { getSelectedWaypointImages } = useUserWaypoints()
  const { selectedTrack, getSelectedTrackImages } = useUserTracks()
  const [images, setImages] = useState<string[]>([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const { user } = useAuth()

  const openGallery = async () => {
    const imagesSrcs = editMode === 'track' ? await getSelectedTrackImages() : await getSelectedWaypointImages()
    setImages(imagesSrcs)
    setIsLightboxOpen(true)
  }

  const handleGalleryClose = useCallback(() => {
    setIsLightboxOpen(false)
  }, [setIsLightboxOpen])

  return (
    <>
      <Tooltip label={t('editmode.open_gallery_button_tooltip')}>
        <div>
          <CollapsibleTopMenuActionIcon
            isOpen={isOpen}
            onClick={openGallery}
            disabled={!user || selectedTrack === null || selectedTrack === undefined}
          >
            <FontAwesomeIcon icon={faImage} />
          </CollapsibleTopMenuActionIcon>
        </div>
      </Tooltip>
      {isLightboxOpen && (
        <LightboxGallery srcs={images} isOpen={isLightboxOpen} onClose={handleGalleryClose} editMode={editMode} />
      )}
    </>
  )
}
