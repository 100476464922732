import { useCallback, useEffect, useState } from 'react'

import { BicycleType, Routing } from 'config/enums/routings'
import { BICYCLE_ICONS } from 'config/icons'
import { useGenericModals } from 'hooks/useGenericModals'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMapEvents } from 'react-leaflet'
import { addTrackWaypoints } from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { BicycleCostingOptions, Coords } from 'types/app'
import { routeToPoint } from 'utils/api/routeToPoint'
import { getErrorMsg } from 'utils/getErrorMsg/getErrorMsg'

import { BicycleOptionsSubMenu } from './BicycleOptionsSubMenu'
import { MainMenu } from './MainMenu'
import './styles.css'

// This width is set in styles.css, if changed here it needs to be changed there also
const mainMenuWidth = 120

export const ContextMenu = () => {
  const {
    state: { routing, trackWaypoints },
    dispatch,
  } = useRouting()
  const { t } = useTranslation()
  const { openErrorModal } = useGenericModals()
  const [costingOptions, setCostingOptions] = useState({
    [Routing.bicycle]: {
      bicycleType: BicycleType.hybrid,
      useHills: '0' as BicycleCostingOptions['useHills'],
    },
    [Routing.auto]: undefined,
    [Routing.pedestrian]: undefined,
    [Routing.none]: undefined,
  })
  const [isMainMenuVisible, setIsMainMenuVisible] = useState(false)
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false)
  const [menuCoords, setMenuCoords] = useState<Coords>([0, 0])
  const [menuCSSPosition, setMenuCSSPosition] = useState({ left: 0, top: 0 })

  useMapEvents({
    contextmenu: (e) => {
      // The context menu only shows up if we are in non-routing mode and there is at least one waypoint present
      if (trackWaypoints.length > 0 && routing === Routing.none) {
        setMenuCoords([e.latlng.lat, e.latlng.lng])
        setMenuCSSPosition({ left: e.containerPoint.x, top: e.containerPoint.y })
        setIsMainMenuVisible(true)
      }
    },
    mousedown: () => {
      setIsMainMenuVisible(false)
      setIsSubMenuVisible(false)
    },
  })

  useEffect(() => {
    if (!(trackWaypoints.length > 0 && routing === Routing.none)) {
      setIsMainMenuVisible(false)
      setIsSubMenuVisible(false)
    }
  }, [routing, trackWaypoints])

  const handleRouteToPoint = useCallback(
    async (costing: Routing) => {
      const from = trackWaypoints.at(-1)
      if (!from) throw new Error('Cannot route to point without a starting point')

      try {
        const newTrackpoints = await routeToPoint(from, menuCoords, costing, costingOptions)
        if (newTrackpoints) {
          dispatch(addTrackWaypoints(newTrackpoints))
        } else {
          throw new Error('')
        }
      } catch (error) {
        const msg = getErrorMsg(error)
        openErrorModal({
          title: t('errors.route_to_point'),
          text: msg ?? '',
        })
      }
    },
    [costingOptions, dispatch, menuCoords, openErrorModal, t, trackWaypoints]
  )

  const updateCostingOptions = useCallback((costing: Routing, options: Partial<BicycleCostingOptions>) => {
    setCostingOptions((prevState) => ({
      ...prevState,
      [costing]: {
        ...prevState[costing],
        ...options,
      },
    }))
  }, [])

  const wrapper = document.getElementById('mapchartwrapper')

  return wrapper && isMainMenuVisible
    ? createPortal(
        <>
          <MainMenu
            bicycleIcon={BICYCLE_ICONS[costingOptions[Routing.bicycle].bicycleType]}
            onItemClick={(value) => {
              handleRouteToPoint(value)
              setIsMainMenuVisible(false)
              setIsSubMenuVisible(false)
            }}
            onItemMouseEnter={(value) => {
              if (value === Routing.bicycle) {
                setIsSubMenuVisible(true)
              } else if (isSubMenuVisible) {
                setIsSubMenuVisible(false)
              }
            }}
            position={menuCSSPosition}
            value={isSubMenuVisible ? Routing.bicycle : undefined}
            visible={isMainMenuVisible}
          />
          <BicycleOptionsSubMenu
            handlers={{
              bicycleType: (value) => {
                updateCostingOptions(Routing.bicycle, { bicycleType: value })
              },
              useHills: (value) => {
                updateCostingOptions(Routing.bicycle, { useHills: String(value) as BicycleCostingOptions['useHills'] })
              },
            }}
            onButtonClick={() => {
              handleRouteToPoint(Routing.bicycle)
              setIsMainMenuVisible(false)
              setIsSubMenuVisible(false)
            }}
            position={{ left: menuCSSPosition.left + mainMenuWidth, top: menuCSSPosition.top }}
            values={costingOptions[Routing.bicycle]}
            visible={isSubMenuVisible}
          />
        </>,
        wrapper
      )
    : null
}
