const code = {
  ' ': '&nbsp;',
  '¢': '&cent;',
  '£': '&pound;',
  '¥': '&yen;',
  '€': '&euro;',
  '©': '&copy;',
  '®': '&reg;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '&': '&amp;',
  "'": '&apos;',
}
export const encodeHTML = (str: string) => str.replace(/[\u00A0-\u9999<>&''""]/gm, (i) => code[i as keyof typeof code])
