import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { useAuth } from "hooks/useAuth";
import { useAuthModals } from "hooks/useAuthModals";
import { useTranslation } from "react-i18next";

export const ResetPasswordModal = ({ context, id }: ContextModalProps) => {
  const { resetPassword, loading } = useAuth();
  const { openResetEmailSentModal } = useAuthModals();
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { success, error } = await resetPassword(form.values.email);

    if (error) {
      form.setErrors({ email: error });
    }
    if (success) {
      context.closeModal(id);
      openResetEmailSentModal(success);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="sm">
        <TextInput
          data-autofocus
          disabled={loading}
          label={t("reset_password_modal.email.label")}
          placeholder={t("reset_password_modal.email.placeholder")}
          icon={<FontAwesomeIcon icon={faEnvelope} />}
          required
          {...form.getInputProps("email")}
        />

        <Button type="submit" loading={loading} fullWidth>
          {t("reset_password_modal.submit_button_text")}
        </Button>
      </Stack>
    </form>
  );
};
