import { ComputedTrackPointsWithAttributes } from 'stores/routingStore/state'

// Ported from Java code

const computeGauss1DEquation = (i: number, o: number) =>
  (1.0 / Math.sqrt(2 * Math.PI * o ** 2)) * Math.E ** -((i * i) / (2 * o * o))

const computeGauss1D = (size: number, deviation: number) => {
  let sizee = size
  if (size % 2 === 0) {
    sizee += 1
  }
  const result = []

  const small = 1.0 / computeGauss1DEquation(-sizee / 2, deviation)

  for (let i = -sizee / 2, di = 0, d = 0, dr = 0; i <= sizee / 2; i += 1, di += 1) {
    d = computeGauss1DEquation(i, deviation) * small
    dr = Math.round(d)
    result[di] = dr
  }

  return result
}

export const smoothDataGauss = (
  trackpoints: ComputedTrackPointsWithAttributes[],
  attribute: string,
  kernelSize: number
): number[] | undefined => {
  if (kernelSize < 1) {
    return
  }

  let kernelSize1 = kernelSize
  let kernelSize2 = kernelSize * 2 + 1

  if (trackpoints.length > 2 && trackpoints.length < kernelSize2) {
    kernelSize2 = trackpoints.length % 2 === 0 ? trackpoints.length - 1 : trackpoints.length
    kernelSize1 = kernelSize2 / 2
  } else if (trackpoints.length <= 2) {
    return
  }

  const deviation = kernelSize2 / 4
  const gaussWeights = computeGauss1D(kernelSize2, deviation > 0 ? deviation : kernelSize2)

  const data: number[] = []
  let value = 0
  let sum: number
  let div: number

  for (let i = 0; i < trackpoints.length; i += 1) {
    sum = 0
    div = 0
    for (let j = -kernelSize1; j < kernelSize1; j += 1) {
      if (i + j >= 0 && i + j < trackpoints.length) {
        const trackpoint = trackpoints[i + Math.round(j)]
        if (trackpoint) {
          // Only numeric attributes will work for obvious reasons
          sum +=
            (trackpoint[attribute as keyof ComputedTrackPointsWithAttributes] as number) * gaussWeights[j + kernelSize1]
          div += gaussWeights[j + kernelSize1]
        }
      }
    }

    value = sum / div
    data.push(value)
  }

  return data
}
