import { useMemo } from 'react'

import { ScrollArea, Space, Title, Text, Anchor, Image, useMantineColorScheme, useMantineTheme } from '@mantine/core'
import { useTrackStats } from 'hooks/useTrackStats'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'

import logo_dark from '../../../assets/main/trackbook_icon_dark.png'
import logo_light from '../../../assets/main/trackbook_icon_light.png'
import { TrackStatsDisplay } from '../../../components/UI/OptionsPanel/TrackStatsDisplay'
import { RoutingPicker } from './RoutingPicker'
import { TrackpointsList } from './TrackpointsDisplay'

import './PlanMenu.css'

const styles = {
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '.4rem',
    textDecoration: 'none',
    marginTop: 'auto',
    padding: '10px',
    width: 'max-content',
  },
  image: { width: '1.7rem' },
  title: { fontSize: '1.1rem', position: 'relative' as const, top: '-1.5px' },
}

export const PlanMenu = () => {
  const { stats } = useTrackStats()
  const { t } = useTranslation()
  const {
    state: { trackMeta },
  } = useRouting()
  const {
    state: { units },
  } = useOptions()
  const { colorScheme } = useMantineColorScheme()
  const theme = useMantineTheme()
  return useMemo(
    () => (
      <>
        <ScrollArea
          p="xs"
          style={{ flexGrow: 1, marginBottom: '-25px' }}
          styles={{
            thumb: {
              '&:before': { minWidth: 0 },
            },
            viewport: {
              '& > div': {
                display: 'flex!important',
                flexDirection: 'column',
                height: '100%',
              },
            },
          }}
        >
          <RoutingPicker />
          <Space h="md" />
          <TrackpointsList />
          <Space h="lg" />
          <Text px="xs" py={4} color="dimmed">
            {t('plan_menu.stats_label')}
          </Text>
          <TrackStatsDisplay stats={{ ...stats, totalTime: trackMeta.totalTime }} unitType={units} />
        </ScrollArea>
        <Anchor href="/" style={styles.div}>
          <Image
            style={styles.image}
            radius="md"
            src={colorScheme === 'light' ? logo_light : logo_dark}
            alt="Trackbook logo"
          />
          <Title
            order={1}
            style={styles.title}
            weight="600"
            color={colorScheme === 'light' ? theme.primaryColor : '#a68cf4'}
          >
            {'Trackbook'}
          </Title>
        </Anchor>
      </>
    ),
    [stats, t, trackMeta.totalTime, units, colorScheme, theme]
  )
}
