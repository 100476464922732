import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'

export const useLanguage = () => {
  const { i18n } = useTranslation()
  const {
    updateSaveable,
    state: { language },
  } = useOptions()

  const changeLanguage = useCallback(
    (value: string) => {
      i18n.changeLanguage(value)
      localStorage.setItem('language', value)
      updateSaveable({ language: value })
    },
    [i18n, updateSaveable]
  )

  return { changeLanguage, language }
}
