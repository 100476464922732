import { useEffect, useState, useMemo } from 'react'

import { Stack, Tabs, TabsValue } from '@mantine/core'
import { useEditMode } from 'hooks/useEditMode'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'

import { PlanMenu } from '../PlanMenu/PlanMenu'
import { TracksMenu } from './TracksMenu'
import { WaypointsMenu } from './WaypointsMenu'

enum TabIndexes {
  options = 'options',
  waypoints = 'waypoints',
  tracks = 'tracks',
}

export const TabMenu = () => {
  const [activeTab, setActiveTab] = useState<TabsValue>(TabIndexes.options)
  const { selectedWaypoint } = useUserWaypoints()
  const { selectedTrack } = useUserTracks()
  const { editMode } = useEditMode()
  const { t } = useTranslation()

  const onChange = (active: TabsValue) => {
    setActiveTab(active)
  }

  useEffect(() => {
    if (selectedWaypoint?.id && editMode === 'waypoint') setActiveTab(TabIndexes.waypoints)
  }, [editMode, selectedWaypoint?.id])

  useEffect(() => {
    if (selectedTrack?.id && editMode === 'track') setActiveTab(TabIndexes.tracks)
  }, [editMode, selectedTrack?.id])

  return useMemo(
    () => (
      <Stack style={{ width: '100%', flexGrow: 1, maxHeight: '100%', minHeight: 0 }}>
        <Tabs
          value={activeTab}
          onTabChange={onChange}
          variant="default"
          styles={{
            root: {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              maxHeight: '100%',
              minHeight: 0,
            },
            panel: {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              maxHeight: '100%',
              minHeight: 0,
            },
          }}
        >
          <Tabs.List grow>
            <Tabs.Tab value={TabIndexes.options}>{t('tabs.plan')}</Tabs.Tab>
            <Tabs.Tab value={TabIndexes.tracks}>{t('tabs.tracks')}</Tabs.Tab>
            <Tabs.Tab value={TabIndexes.waypoints}>{t('tabs.waypoints')}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={TabIndexes.options}>
            <PlanMenu />
          </Tabs.Panel>
          <Tabs.Panel value={TabIndexes.tracks}>
            <TracksMenu />
          </Tabs.Panel>
          <Tabs.Panel value={TabIndexes.waypoints}>
            <WaypointsMenu />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    ),
    [activeTab, t]
  )
}
