import { TFunction } from 'react-i18next'

export enum MapColor {
  'black' = 'black',
  'blue' = 'blue',
  'blueGray' = 'blue-gray',
  'brownOrange' = 'brown-orange',
  'highContrast' = 'high-contrast',
  'gray' = 'gray',
  'grayGold' = 'gray-gold',
  'inverted' = 'inverted',
  'invertedDark' = 'inverted-dark',
  'pink' = 'pink',
  'pinkYellow' = 'pink-yellow',
  'purpleGreen' = 'purple-green',
  'sepia' = 'sepia',
  'zinc' = 'zinc',
}

export const getMapColors = (t: TFunction) => [
  { label: t('general.color.black'), value: MapColor.black },
  { label: t('general.color.blue'), value: MapColor.blue },
  { label: t('general.color.blue_gray'), value: MapColor.blueGray },
  { label: t('general.color.brown_orange'), value: MapColor.brownOrange },
  { label: t('general.color.high_contrast'), value: MapColor.highContrast },
  { label: t('general.color.gray'), value: MapColor.gray },
  { label: t('general.color.gray-gold'), value: MapColor.grayGold },
  { label: t('general.color.inverted'), value: MapColor.inverted },
  { label: t('general.color.inverted_dark'), value: MapColor.invertedDark },
  { label: t('general.color.pink'), value: MapColor.pink },
  { label: t('general.color.pink_yellow'), value: MapColor.pinkYellow },
  { label: t('general.color.purple_green'), value: MapColor.purpleGreen },
  { label: t('general.color.sepia'), value: MapColor.sepia },
  { label: t('general.color.zinc'), value: MapColor.zinc },
]
