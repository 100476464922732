import { useCallback } from 'react'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components/UI/Tooltip'
import { useEditMode } from 'hooks/useEditMode'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

type Props = {
  isOpen: boolean
}

export const DeleteIcon = ({ isOpen }: Props) => {
  const { selectedTrack, deleteTrack } = useUserTracks()
  const { selectedWaypoint, deleteWaypoints } = useUserWaypoints()
  const { openConfirmTrackDeleteModal, openConfirmWaypointDeleteModal } = useGenericModals()
  const { editMode } = useEditMode()
  const { t } = useTranslation()

  const deleteCurrentTrack = useCallback(() => {
    if (!selectedTrack?.id) return
    deleteTrack(selectedTrack?.id)
  }, [deleteTrack, selectedTrack?.id])

  const deleteCurrentWaypoint = useCallback(() => {
    if (!selectedWaypoint?.id) return
    deleteWaypoints(selectedWaypoint?.id)
  }, [deleteWaypoints, selectedWaypoint?.id])

  const handleDeleteClick = useCallback(() => {
    if (selectedTrack) {
      if (!selectedTrack?.id) return
      openConfirmTrackDeleteModal({
        count: 1,
        onConfirm: deleteCurrentTrack,
      })
    }
    if (selectedWaypoint) {
      if (!selectedWaypoint.id) return
      openConfirmWaypointDeleteModal({
        count: 1,
        onConfirm: deleteCurrentWaypoint,
      })
    }
  }, [
    deleteCurrentTrack,
    deleteCurrentWaypoint,
    openConfirmTrackDeleteModal,
    openConfirmWaypointDeleteModal,
    selectedTrack,
    selectedWaypoint,
  ])

  return (
    <Tooltip
      label={
        editMode === 'track' ? t('editmode.delete_button.track_tooltip') : t('editmode.delete_button.waypoint_tooltip')
      }
    >
      <div>
        <CollapsibleTopMenuActionIcon
          isOpen={isOpen}
          disabled={!selectedTrack && !selectedWaypoint}
          onClick={handleDeleteClick}
        >
          <FontAwesomeIcon icon={faTrash} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  )
}
