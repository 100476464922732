import { createStyles } from '@mantine/core'
import * as L from 'leaflet'
import { Marker, MarkerProps } from 'react-leaflet'

type Props = {
  text: string | number
}

const useStyles = createStyles((theme) => ({
  textMarker: {
    fontWeight: 700,
    textAlign: 'center',
    '&&': { display: 'flex', justifyContent: 'center' },
  },
  innerWrapper: {
    padding: '3px 8px',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
    borderRadius: theme.radius.md,
  },
}))

export const MouseLabelMarker = ({ text, ...rest }: Props & MarkerProps) => {
  const { classes } = useStyles()

  const distanceIcon = L.divIcon({
    html: `<div><div class="${classes.innerWrapper}">${text}</div></div>`,
    className: classes.textMarker,
    iconSize: [100, 20],
    iconAnchor: [50, 30],
  })

  return <Marker interactive={false} icon={distanceIcon} {...rest} />
}
