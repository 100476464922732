import { useCallback, useEffect, useRef, useState } from 'react'

import { faLocation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LatLng, LocationEventHandlerFn } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, CircleMarker, useMap } from 'react-leaflet'
import { showErrorNotification } from 'utils/notifications/customNotifications'

import { LeafletControlWrapperProps } from '../Utils/LeafletControlWrapper'
import { LeafletSingleIconControlWrapper } from '../Utils/LeafletSingleIconControlWrapper'

const MAIN_GEOLOCATION_CIRCLES_COLOR = '#4287f5'
type LocationData = {
  radius: number
  coords: LatLng
}

type GeolocationControlProps = {
  position: LeafletControlWrapperProps['position']
}

export const GeolocationControl = ({ position }: GeolocationControlProps) => {
  const [locationData, setLocationData] = useState<LocationData | null>(null)
  const [isActive, setIsActive] = useState(false)
  const hasViewBeenSet = useRef(false)
  const { t } = useTranslation()
  const map = useMap()

  const activate = useCallback(() => {
    map.locate({ watch: true, enableHighAccuracy: true })
    setIsActive(true)
  }, [map])

  const deactivate = useCallback(() => {
    map.stopLocate()
    setLocationData(null)
    setIsActive(false)
    hasViewBeenSet.current = false
  }, [map])

  const handleClick = useCallback(() => (isActive ? deactivate() : activate()), [activate, deactivate, isActive])

  useEffect(() => {
    const onLocationFound: LocationEventHandlerFn = (e) => {
      const radius = e.accuracy
      const coords = e.latlng

      if (!hasViewBeenSet.current) {
        map.setView(coords, 18)
        hasViewBeenSet.current = true
      }

      setLocationData({ radius, coords })
    }

    const onLocationError = () => {
      showErrorNotification({
        autoClose: 7000,
        message: t('geolocation_control.error_message'),
      })
      setIsActive(false)
    }

    map.on('locationfound', onLocationFound)
    map.on('locationerror', onLocationError)

    return () => {
      map.stopLocate()
      map.off('locationfound', onLocationFound)
      map.off('locationerror', onLocationError)
    }
  }, [map, t])

  return (
    <>
      <LeafletSingleIconControlWrapper
        position={position}
        onClick={handleClick}
        icon={<FontAwesomeIcon icon={faLocation} color={isActive ? MAIN_GEOLOCATION_CIRCLES_COLOR : undefined} />}
        tooltip={{ label: t('map_controls.geolocation_button_tooltip'), position: 'left' }}
      />
      {locationData && (
        <>
          <Circle
            center={locationData.coords}
            radius={locationData.radius}
            pathOptions={{ stroke: false, fillColor: MAIN_GEOLOCATION_CIRCLES_COLOR }}
          />
          <CircleMarker
            center={locationData.coords}
            radius={6}
            pathOptions={{
              stroke: true,
              weight: 2,
              color: 'white',
              fillColor: MAIN_GEOLOCATION_CIRCLES_COLOR,
              fillOpacity: 1,
            }}
          />
        </>
      )}
    </>
  )
}
