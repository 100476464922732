import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { faArrowDownAZ, faArrowDownShortWide, faArrowUpAZ, faArrowUpShortWide } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@mantine/hooks'
import { ItemMenu, ItemMenuData, MultiSelectAction } from 'components/UI/ItemMenu/ItemMenu'
import { SortByPicker, SortingOptions } from 'components/UI/SortByPicker'
import { Routing } from 'config/enums/routings'
import { saveAs } from 'file-saver'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { TFunction, useTranslation } from 'react-i18next'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { formatDistanceLabel } from 'utils/formatters/formatDistance'
import { encodeGPX } from 'utils/gpx/encodeGPX'
import { getSafeName } from 'utils/helpers/exportGPX/getExportSafeName'
import { filterDataByString } from 'utils/helpers/filterData'
import { showTrackExportSuccessNotification } from 'utils/notifications/trackNotifications'

interface ExtendedItemMenuData extends ItemMenuData {
  length: number
}

export type TracksSortByOptions = 'createdDate' | 'name' | 'length'

const getSortingOptions = (t: TFunction): SortingOptions<TracksSortByOptions>[] => [
  { value: 'name', label: t('sorting.name.AZ'), icon: faArrowDownAZ, direction: 'asc' },
  { value: 'name', label: t('sorting.name.ZA'), icon: faArrowUpAZ, direction: 'desc' },
  {
    value: 'createdDate',
    label: t('sorting.date.from_latest'),
    icon: faArrowDownShortWide,
    direction: 'desc',
  },
  {
    value: 'createdDate',
    label: t('sorting.date.from_oldest'),
    icon: faArrowUpShortWide,
    direction: 'asc',
  },
  { value: 'length', label: t('sorting.length.from_shortest'), icon: faArrowDownShortWide, direction: 'asc' },
  { value: 'length', label: t('sorting.length.from_longest'), icon: faArrowUpShortWide, direction: 'desc' },
]

type SortDataOptions = {
  sortBy: TracksSortByOptions
  data: ExtendedItemMenuData[] | undefined
  ascending: boolean
}

const sortData = ({ sortBy, data, ascending }: SortDataOptions) => {
  if (!data) return undefined
  let sorted = data
  if (sortBy === 'name') {
    sorted = [...data].sort((a, b) => a.name.localeCompare(b.name))
  }
  if (sortBy === 'length') {
    sorted = [...data].sort((a, b) => a.length - b.length)
  }
  return ascending ? sorted : sorted.reverse()
}

export const TracksMenu = () => {
  const { tracks, selectedTrack, selectTrack, updateTrack, isFetching, deleteTrack } = useUserTracks()
  const [filterKeyword, setFilterKeyword] = useState('')
  const { t } = useTranslation()
  const {
    dispatch,
    state: { isLeftMenuOpen, units, sortTracks },
    updateSaveable,
  } = useOptions()
  const isFirstSelect = useRef(true)
  const {
    state: { routing, computedTrackpoints, nonRoutableTrackMetadata, trackWaypoints, elevations, trackMeta },
  } = useRouting()
  const { openTrackEditModal, openErrorModal, openConfirmTrackDeleteModal } = useGenericModals()
  const selectedTrackRef = useRef(selectedTrack)
  const tracksRef = useRef(tracks)
  useEffect(() => {
    selectedTrackRef.current = selectedTrack
  }, [selectedTrack])
  useEffect(() => {
    tracksRef.current = tracks
  }, [tracks])
  const isSmallViewport = useMediaQuery('(max-width: 768px)')

  const getTrackById = (id: number) => tracksRef.current?.find((value) => value.id === id)

  useEffect(() => {
    if (
      selectedTrackRef.current &&
      trackWaypoints.length > 1 &&
      computedTrackpoints &&
      elevations &&
      !isFirstSelect.current
    ) {
      const isRoutable = routing !== Routing.none

      const track = selectedTrackRef.current

      try {
        updateTrack(selectedTrackRef.current.id, {
          controlPoints: !isRoutable ? [] : trackWaypoints,
          trackPoints: !isRoutable ? trackWaypoints : computedTrackpoints,
          routing,
          ...(!isRoutable
            ? {
                trackPointsCadence: nonRoutableTrackMetadata.trackPointsCadence,
                trackPointsHeartRate: nonRoutableTrackMetadata.trackPointsHeartRate,
                trackPointsPower: nonRoutableTrackMetadata.trackPointsPower,
                trackPointsSpeed: nonRoutableTrackMetadata.trackPointsSpeed,
                trackPointsTemperature: nonRoutableTrackMetadata.trackPointsTemperature,
                trackPointsTime:
                  track.trackPointsTime && nonRoutableTrackMetadata.trackPointsTimeDatapoints
                    ? {
                        ...track.trackPointsTime,
                        datapoints: nonRoutableTrackMetadata.trackPointsTimeDatapoints,
                      }
                    : undefined,
              }
            : { trackPointsSpeed: trackMeta.trackPointsSpeed, trackPointsTime: trackMeta.trackPointsTime }),
          trackPointsEle: elevations,
        })
      } catch (error) {
        console.error(error)
      }
    }
    if (selectedTrack) {
      isFirstSelect.current = false
    }
  }, [elevations])

  const handleMenuItemSelect = useCallback(
    (id: number) => {
      isFirstSelect.current = true
      if (id !== selectedTrackRef.current?.id) {
        if (isLeftMenuOpen && isSmallViewport) {
          dispatch(updateNonSaveableOptions({ isLeftMenuOpen: false }))
        }
        selectTrack(id)
      } else {
        selectTrack(null)
      }
    },
    [dispatch, isLeftMenuOpen, isSmallViewport, selectTrack]
  )

  const handleEditButtonClick = useCallback(
    (id: number) => {
      if (id !== selectedTrackRef.current?.id) selectTrack(id)
      const track = getTrackById(id)
      if (!track) throw new Error('cant find track to be edited')
      openTrackEditModal(track)
    },
    [selectTrack, openTrackEditModal]
  )

  const deleteTracks: MultiSelectAction = useCallback(
    (ids, reset) => {
      openConfirmTrackDeleteModal({
        count: ids.length,
        onConfirm: async () => {
          await deleteTrack(ids)
          reset()
        },
      })
    },
    [deleteTrack, openConfirmTrackDeleteModal]
  )

  const exportToGPX: MultiSelectAction = useCallback(
    (ids) => {
      if (!tracksRef.current) return
      const selectedTracks = tracksRef.current.filter((item) => ids.includes(item.id))
      const encoded = encodeGPX({
        tracks: selectedTracks.map((track) => ({
          description: track.desc,
          name: track.name,
          trackPoints: track.trackPoints,
          trackPointsEle: track.trackPointsEle,
          links: track.links,
        })),
      })
      try {
        if (!encoded) throw new Error()
        const blob = new Blob([encoded], { type: 'application/gpx+xml' })
        if (selectedTracks.length > 1) {
          saveAs(blob, 'export.gpx')
        } else {
          saveAs(blob, `${getSafeName(selectedTracks[0].name)}.gpx`)
        }
        showTrackExportSuccessNotification({ count: selectedTracks.length })
      } catch (error) {
        openErrorModal({ title: t('generic.error_title'), text: t('errors.encoding_GPX_failed.text') })
      }
    },
    [openErrorModal, t]
  )

  const data = tracks?.map((track) => {
    const formattedDistance = track.meta?.length
      ? formatDistanceLabel(track.meta.length * 1000, units, 1, t)
      : undefined

    const itemData: ExtendedItemMenuData = {
      ...track,
      bottomInfo: formattedDistance,
      length: track.meta?.length || 0,
      color: track.color,
    }
    return itemData
  })

  const filteredData = filterDataByString(data, 'name', filterKeyword)

  const sortedData = sortData({
    data: filteredData,
    sortBy: sortTracks.type,
    ascending: sortTracks.direction === 'asc',
  })

  const SortComponent = useMemo(
    () => (
      <SortByPicker
        data={getSortingOptions(t)}
        value={sortTracks.type}
        sortDirection={sortTracks.direction}
        onItemChange={(value, direction) => {
          updateSaveable({ sortTracks: { type: value, direction } })
        }}
      />
    ),
    [sortTracks.direction, sortTracks.type, t, updateSaveable]
  )

  return useMemo(
    () => (
      <ItemMenu
        data={sortedData}
        onDeleteClick={deleteTracks}
        onExportToGPXclick={exportToGPX}
        onMultiSelectModeOn={() => selectTrack(null)}
        onFilterInput={setFilterKeyword}
        selectedItemId={selectedTrack?.id}
        onItemClick={handleMenuItemSelect}
        onEditButtonClick={handleEditButtonClick}
        loading={isFetching}
        emptyMessage={t('tracks_menu.empty_label', 'No saved tracks')}
        topSubmenuComponent={SortComponent}
      />
    ),
    [
      SortComponent,
      deleteTracks,
      exportToGPX,
      handleEditButtonClick,
      handleMenuItemSelect,
      isFetching,
      selectTrack,
      selectedTrack?.id,
      sortedData,
      t,
    ]
  )
}
