import { useEffect, useRef } from 'react'

import { useMapQueryParams } from 'hooks/useMapQueryParams'
import { LatLng } from 'leaflet'
import { useMapEvent } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'

export const UrlParamsHandler = () => {
  const { lat, lng, zoom, updateParams } = useMapQueryParams()
  const {
    state: { mapType: mapLayerID },
  } = useOptions()
  const firstMoveendFiring = useRef(false)

  const map = useMapEvent('moveend', () => {
    if (firstMoveendFiring.current) {
      updateParams()
    } else {
      firstMoveendFiring.current = true
    }
  })

  useEffect(() => {
    updateParams()
  }, [mapLayerID, updateParams])

  useEffect(() => {
    if (lat && lng && zoom) {
      map.setView(new LatLng(lat, lng), zoom)
    }
    updateParams()
  }, [])

  return null
}
