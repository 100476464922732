import { Unit } from 'config/enums/units'
import { ScaleControl, ScaleControlProps } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'

interface Props {
  position: ScaleControlProps['position']
}

export const ScaleControlAutoUnit = ({ position }: Props) => {
  const {
    state: { units: unitType },
  } = useOptions()

  return unitType === Unit.metric ? (
    <ScaleControl key="metric" metric={true} imperial={false} position={position} />
  ) : (
    <ScaleControl key="imperial" metric={false} imperial={true} position={position} />
  )
}
