import { Text } from '@mantine/core'
import { Coords } from 'types/app'

export const CoordsDisplay = ({ coords }: { coords: Coords }) => (
  <Text size="sm" sx={{ lineHeight: 1.1, flexGrow: 1 }}>
    <span style={{ whiteSpace: 'nowrap' }}>{`Lat: ${coords[0].toFixed(6)}`}</span>
    {', '}
    <span style={{ whiteSpace: 'nowrap' }}>{`Lng: ${coords[1].toFixed(6)}`}</span>
  </Text>
)
