import { BASE64_CHAR_SET } from 'config/constants'

/**
 *
 * @param base64 Base64 encoded string
 * @returns Number with a precision to 2 decimal points
 */
export const base64ToNum = (base64: string): number => {
  let multiplier = 1
  let num = 0

  for (let i = 3; i >= 0; i -= 1) {
    const index = BASE64_CHAR_SET.indexOf(base64.charAt(i))

    num += index * multiplier
    multiplier *= 64
  }

  num = (num - 2000000) / 100.0

  return num
}
