export const BasicLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/motorway.png" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/major_road.png" />
      </th>
      <td>{'Major road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/minor_road.png" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/motorway_tunnel.png" />
      </th>
      <td>{'Motorway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/major_road_tunnel.png" />
      </th>
      <td>{'Major road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/minor_road_tunnel.png" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/oneway.png" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/path.png" width="70" />
      </th>
      <td>{'Footway, path, cycleway, bridleway, track, steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/runway.png" width="70" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/railway.png" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/railway_tunnel.png" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/aerialway.png" />
      </th>
      <td>
        {
          'Chairlift, gondola lift, cable car, goods cable lift, mixed lift, drag lift, t-bar lift, j-bar lift, platter lift, magic carpet, rope tow, zipline'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/ferry.png" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/boundary_country.png" width="62" height="32" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/boundary_regional.png" width="61" height="31" />
      </th>
      <td>{'Regional boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/military.png" width="61" height="31" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/water.png" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/park.png" />
      </th>
      <td>{'Forest, park, national park, protected area, nature reserve, cemetery, recreation ground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/pitch.png" />
      </th>
      <td>{'Natural wood, grass, garden, allotments, farmland, pitch, playground, golf course, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/urban.png" />
      </th>
      <td>
        {
          'Stadium, hospital, university, school, urban area, retail area, commercial area, industrial area, pier area, bridge area'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/peak.png" width="16" />
      </th>
      <td>{'Peak, volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/police.png" width="16" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bank.png" width="13" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/atm.png" width="16" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/car_sharing.png" width="16" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle_rental.png" width="16" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/university.png" width="16" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/kindergarten.png" width="16" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/chapel.png" width="16" />
      </th>
      <td>{'Church, chapel, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/pharmacy.png" width="16" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hospital.png" width="15" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/workshop.png" width="16" />
      </th>
      <td>{'Workshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hotel.png" width="16" />
      </th>
      <td>{'Hotel, hostel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/theatre.png" width="16" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/cinema.png" width="16" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/forest.png" width="16" />
      </th>
      <td>{'Forest, nature reserve'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/park_icon.png" width="16" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/sports_centre.png" width="16" />
      </th>
      <td>{'Sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/winter_sports.png" width="16" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/viewpoint.png" width="16" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/information.png" width="12" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/parking.png" width="13" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle_parking.png" width="16" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bus_stop.png" width="16" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/train.png" width="16" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/tram_stop.png" width="16" />
      </th>
      <td>{'Tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/fuel.png" width="16" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/traffic_signals.png" width="16" height="7" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/ferry_terminal.png" width="16" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/subway_entrance.png" width="16" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bar.png" width="16" />
      </th>
      <td>{'Bar, pub, beer garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/cafe.png" width="16" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/fast_food.png" width="16" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/restaurant.png" width="16" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle.png" width="16" />
      </th>
      <td>{'Bicycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/convenience.png" width="16" />
      </th>
      <td>{'Convenience shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/mall.png" width="16" />
      </th>
      <td>{'Mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/toilet.png" width="16" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/drinking_water.png" width="16" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/military_icon.png" width="16" />
      </th>
      <td>{'Military area'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
