import { Image, useMantineTheme } from "@mantine/core";
import trackbookIcon from "assets/main/trackbook_icon_dark.png";
import { useOptions } from "stores/optionsStore/OptionsContext";

export const LoadingScreen = () => {
  const theme = useMantineTheme();
  const {
    state: { colorScheme },
  } = useOptions();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
        backgroundColor: colorScheme === "dark" ? theme.colors.dark[4] : "#fff",
      }}
    >
      <Image src={trackbookIcon} style={{ width: "8%" }} />
    </div>
  );
};
