import { createTileLayerComponent, LayerProps, updateGridLayer, withPane } from '@react-leaflet/core'
import { TileLayer as LeafletTileLayer } from 'leaflet'

import { CachedLeafletTileLayer, ICachedTileLayerOptions } from './cached-tile-layer'

export interface CachedReactLeafletTileLayerProps extends ICachedTileLayerOptions, LayerProps {
  url: string
}

export const CachedReactLeafletTileLayer = createTileLayerComponent<LeafletTileLayer, CachedReactLeafletTileLayerProps>(
  function createTileLayer(options, context) {
    return {
      /* @ts-expect-error Leaflet's API is sometimes not very TS friendy, so we just assume that these options are of type ICachedTileLayerOptions */
      instance: new CachedLeafletTileLayer(withPane(options, context)),
      context,
    }
  },
  updateGridLayer
)
