import { Track } from 'types/app'
import { getTrackPointsDistance } from 'utils/coords'

export const getTracksWithMetadata = (rawTracks: Track[] | null) => {
  if (!rawTracks) return null
  return rawTracks?.map((track) => {
    const { trackPoints } = track
    if (!trackPoints || trackPoints.length === 0) return track
    const length = Number((getTrackPointsDistance(trackPoints) / 1000).toFixed(1))
    if (!length) return track
    return { ...track, meta: { length } }
  })
}
