import { Coords } from 'types/app'

import { encodeSingleCoord } from './encodeSingleCoord/encodeSingleCoord'

/**
 *
 * @param coordinates Array of Coords [[Lat, Lng], [Lat, Lng], ...]
 * @param precision Default 5
 * @returns
 */
export const encodePolyline = (coordinates: Coords[], precision = 5): string => {
  if (!coordinates[0]) return ''

  const factor = 10 ** precision

  const firstLat: number = coordinates[0][0]
  const firstLng: number = coordinates[0][1]

  let output =
    String.fromCharCode(254) + precision + encodeSingleCoord(firstLat, factor) + encodeSingleCoord(firstLng, factor)

  for (let i = 1; i < coordinates.length; i += 1) {
    const currentCoords: Coords = coordinates[i]!
    const prevCoords: Coords = coordinates[i - 1]!
    output += encodeSingleCoord(currentCoords[0] - prevCoords[0], factor)
    output += encodeSingleCoord(currentCoords[1] - prevCoords[1], factor)
  }

  return output
}
