import { useState } from 'react'

import { faFile, faUpload, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Button, Group, MantineTheme, Stack, Text, useMantineTheme } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { ContextModalProps } from '@mantine/modals'
import { useGenericModals } from 'hooks/useGenericModals'
import { useGPXupload } from 'hooks/useGPXupload'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'

const UPLOAD_FILE_SIZE_LIMIT = 10 // MB

type DropStatus = 'accepted' | 'rejected' | 'idle'

const getIconColor = (status: DropStatus, theme: MantineTheme) => {
  if (status === 'accepted') {
    return theme.colors[theme.primaryColor]![theme.colorScheme === 'dark' ? 4 : 6]
  }
  if (status === 'rejected') {
    return theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
  }
  return theme.colors.dark[0]
}

const FileUploadIcon = ({ status, ...props }: Partial<FontAwesomeIconProps> & { status: DropStatus }) => {
  if (status === 'accepted') return <FontAwesomeIcon {...props} icon={faUpload} />
  if (status === 'rejected') return <FontAwesomeIcon {...props} icon={faX} />
  return <FontAwesomeIcon {...props} icon={faFile} />
}

type DropzoneChildrenProps = {
  status: DropStatus
  theme: MantineTheme
  fileName?: string
}
export const DropzoneChildren = ({ status, theme, fileName }: DropzoneChildrenProps) => {
  const { t } = useTranslation()
  return (
    <Group position="center" spacing="xl" style={{ minHeight: 100, pointerEvents: 'none', flexWrap: 'nowrap' }}>
      <FileUploadIcon status={status} style={{ color: getIconColor(status, theme) }} fontSize={30} />
      <div style={{ flexShrink: 1 }}>
        {fileName ? (
          <Text size="xl" inline>
            {fileName}
          </Text>
        ) : (
          <>
            <Text size="xl" inline>
              {t('import_gpx_modal.dropzone.title')}
            </Text>
            <Text size="sm" color="dimmed" mt={7}>
              {`${t('import_gpx_modal.dropzone.label')} ${UPLOAD_FILE_SIZE_LIMIT}MB`}
            </Text>
          </>
        )}
      </div>
    </Group>
  )
}

export const ImportGPXModal = ({ context, id }: ContextModalProps) => {
  const theme = useMantineTheme()
  const [file, setFile] = useState<File | undefined>()
  const [error, setError] = useState<string | null>(null)
  const { fetchTracks } = useUserTracks()
  const { fetchWaypoints } = useUserWaypoints()
  const { openErrorModal, openInfoModal } = useGenericModals()
  const { upload, isUploading } = useGPXupload()
  const { t } = useTranslation()

  const handleUpload = async () => {
    if (!file) return
    try {
      const res = await upload(file)
      if (res) {
        context.closeModal(id)
        openInfoModal(t('import_gpx_modal.import_stats_title'), res)
        fetchTracks()
        fetchWaypoints()
      }
    } catch (err) {
      context.closeModal(id)
      openErrorModal({ title: t('generic.error_title'), text: t('import_gpx_modal.error_modal.body') })
    }
  }

  const handleAccept = (files: File[]) => {
    setError(null)
    if (files.length > 0) setFile(files[0])
  }

  const handleReject = () => {
    setFile(undefined)
    setError(`${t('import_gpx_modal.dropzone.error_rejected')} ${UPLOAD_FILE_SIZE_LIMIT}MB`)
  }

  return (
    <Stack align="center">
      <Dropzone
        sx={{
          width: '100%',
          borderColor: file && theme.colors.green[5],
          backgroundColor: file && theme.colors.green[0],
        }}
        onDrop={handleAccept}
        onReject={handleReject}
        maxSize={UPLOAD_FILE_SIZE_LIMIT * 1024 ** 2}
        multiple={false}
        loading={isUploading}
      >
        <Dropzone.Accept>
          <DropzoneChildren status="accepted" theme={theme} fileName={file?.name} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <DropzoneChildren status="rejected" theme={theme} fileName={file?.name} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <DropzoneChildren status="idle" theme={theme} fileName={file?.name} />
        </Dropzone.Idle>
      </Dropzone>
      {error && (
        <Text size="sm" color="red">
          {error}
        </Text>
      )}
      <Button fullWidth onClick={handleUpload} disabled={!file} loading={isUploading}>
        {t('import_gpx_modal.upload_button_label')}
      </Button>
    </Stack>
  )
}
