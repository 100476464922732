import { ActionIcon, MantineTheme, Text, useMantineTheme } from '@mantine/core'
import { useMantineTextColorIndex } from 'hooks/useMantineTextColor'
import './styles.css'

interface ActionIconSelectItem<T> {
  icon: JSX.Element
  value: T
}

const getHoveredButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors.gray[1] : theme.colors.dark[4]

const getHoveredSelectedButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors[theme.primaryColor]?.[8] : theme.colors[theme.primaryColor]?.[7]

export const ActionIconSelect = <T extends string>({
  items,
  label,
  onItemClick,
  value: selectedValue,
}: {
  items: ActionIconSelectItem<T>[]
  label: string
  onItemClick: (value: T) => void
  value: T
}) => {
  const theme = useMantineTheme()
  const textColor = useMantineTextColorIndex()

  return (
    <div className="trkbk-context-menu sub-menu-item-action-icon-select">
      <>
        <Text className="label" style={{ color: theme.colorScheme === 'light' ? '#495057' : 'inherit' }}>
          {label}
        </Text>
        {items.map(({ icon, value }) => {
          const isSelected = value === selectedValue

          return (
            <ActionIcon
              className="action-icon"
              key={value}
              onClick={() => {
                onItemClick(value)
              }}
              size={28}
              variant={isSelected ? 'filled' : 'transparent'}
              color={isSelected ? theme.primaryColor : textColor}
              sx={{
                '&:hover': {
                  backgroundColor: isSelected
                    ? getHoveredSelectedButtonBackground(theme)
                    : getHoveredButtonBackground(theme),
                },
              }}
            >
              <div className="icon">{icon}</div>
            </ActionIcon>
          )
        })}
      </>
    </div>
  )
}
