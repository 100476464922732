export const StreetLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorway.png" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/trunk.png" />
      </th>
      <td>{'Trunk road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/primary.png" />
      </th>
      <td>{'Primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/secondary.png" />
      </th>
      <td>{'Secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tertiary.png" />
      </th>
      <td>{'Tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/residential.png" />
      </th>
      <td>{'Residential road, unclassified road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/living_street.png" />
      </th>
      <td>{'Living street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/service.png" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pedestrian.png" />
      </th>
      <td>{'Pedestrian street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/raceway.png" />
      </th>
      <td>{'Raceway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/road.png" />
      </th>
      <td>{'Road with unknown classification'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/oneway.png" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/footway.png" width="70" />
      </th>
      <td>{'Footway, path'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cycleway.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridleway.png" width="70" />
      </th>
      <td>{'Bridleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_nograde.png" width="70" />
      </th>
      <td>{'Track (without grade)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade1.png" width="70" />
      </th>
      <td>{'Track (grade 1)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade2.png" width="70" />
      </th>
      <td>{'Track (grade 2)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade3.png" width="70" />
      </th>
      <td>{'Track (grade 3)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade4.png" width="70" />
      </th>
      <td>{'Track (grade 4)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade5.png" width="70" />
      </th>
      <td>{'Track (grade 5)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/steps.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recreation_track.png" width="70" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridge_casing.png" />
      </th>
      <td>{'Black casing = bridge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tunnel_casing.png" />
      </th>
      <td>{'Dashed casing = tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/runway.png" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taxiway.png" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway.png" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_tunnel.png" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_disused.png" width="70" />
      </th>
      <td>{'Disused railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_service.png" width="70" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_funicular.png" width="70" />
      </th>
      <td>{'Funicular, narrow gauge, light rail, tram'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_subway.png" width="70" />
      </th>
      <td>{'Subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_monorail.png" width="70" />
      </th>
      <td>{'Monorail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_preserved.png" width="70" />
      </th>
      <td>{'Preserved railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_miniature.png" width="70" />
      </th>
      <td>{'Miniature railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/aerialway.png" width="70" />
      </th>
      <td>{'Chairlift, drag lift, t-bar lift, j-bar lift, platter lift, rope tow, zipline'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gondola.png" width="70" />
      </th>
      <td>{'Cable car, gondola lift, mixed lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/goods.png" width="70" />
      </th>
      <td>{'Goods cable lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ferry_route.png" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/platform.png" width="70" />
      </th>
      <td>{'Platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_country_low.png" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_regional_low.png" />
      </th>
      <td>{'Regional boundary (lower and higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_regional_middle.png" />
      </th>
      <td>{'Regional boundary (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_aboriginal.png" width="63" height="33" />
      </th>
      <td>{'Reservation boundary (native peoples)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/protected_area.png" />
      </th>
      <td>{'National park, protected area, nature reserve boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/military.png" width="61" height="31" />
      </th>
      <td>{'Military, danger area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/prison.png" width="61" height="31" />
      </th>
      <td>{'Prison area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/zoo.png" />
      </th>
      <td>{'Zoo, theme park boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water.png" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/reef.png" width="60" height="30" />
      </th>
      <td>{'Reef'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/glacier.png" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/intermittent.png" width="60" height="30" />
      </th>
      <td>{'Intermittent lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/forest.png" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/broadleaved.png" width="60" height="30" />
      </th>
      <td>{'Broadleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/coniferous.png" width="60" height="30" />
      </th>
      <td>{'Needleleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mixed.png" width="60" height="30" />
      </th>
      <td>{'Mixed forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/leafless.png" width="60" height="30" />
      </th>
      <td>{'Leafless forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/unknown.png" width="60" height="30" />
      </th>
      <td>{'Unknown forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bare_rock.png" width="60" height="30" />
      </th>
      <td>{'Bare rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/scree.png" width="60" height="30" />
      </th>
      <td>{'Scree, shingle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/scrub.png" width="60" height="30" />
      </th>
      <td>{'Scrub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/heath.png" />
      </th>
      <td>{'Heath'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland.png" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_marsh_z12.png" />
      </th>
      <td>{'Marsh, wet meadow, saltmarsh, reed bed, fen (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_bog_z12.png" />
      </th>
      <td>{'Bog, string bog (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_mangrove_z12.png" />
      </th>
      <td>{'Mangrove (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_swamp_z12.png" />
      </th>
      <td>{'Swamp (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_marsh.png" />
      </th>
      <td>{'Marsh, wet meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_saltmarsh.png" />
      </th>
      <td>{'Saltmarsh'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_reedbed.png" />
      </th>
      <td>{'Reed bed'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_fen.png" />
      </th>
      <td>{'Fen'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_bog.png" />
      </th>
      <td>{'Bog, string bog'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_mangrove.png" />
      </th>
      <td>{'Mangrove'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_swamp.png" />
      </th>
      <td>{'Swamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_tidalflat_lower.png" />
      </th>
      <td>{'Tidal flat (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_tidalflat.png" />
      </th>
      <td>{'Tidal flat (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_saltern.png" width="60" height="30" />
      </th>
      <td>{'Salt pond'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sand.png" />
      </th>
      <td>{'Sand'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beach.png" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grass.png" />
      </th>
      <td>{'Grass, grassland, meadow, village green'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/vineyard.png" width="60" height="30" />
      </th>
      <td>{'Vineyard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/orchard.png" width="60" height="30" />
      </th>
      <td>{'Orchard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/plant_nursery.png" width="60" height="30" />
      </th>
      <td>{'Plant nursery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/garden.png" width="60" height="30" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/allotments.png" width="60" height="30" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_generic.png" width="60" height="30" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_christian.png" width="60" height="30" />
      </th>
      <td>{'Christian cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_jewish.png" width="60" height="30" />
      </th>
      <td>{'Jewish cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_muslim.png" width="60" height="30" />
      </th>
      <td>{'Muslim cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/quarry.png" width="60" height="30" />
      </th>
      <td>{'Quarry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dog_park.png" width="60" height="30" />
      </th>
      <td>{'Dog park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/farmland.png" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/park.png" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pitch.png" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recreation_ground.png" />
      </th>
      <td>{'Playground, recreation ground, sports centre, stadium, water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/golf_course.png" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/university.png" />
      </th>
      <td>{'Hospital, university, college, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/place_of_worship_area.png" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/residential_area.png" />
      </th>
      <td>{'Residential area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/retail_area.png" />
      </th>
      <td>{'Retail area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/commercial_area.png" />
      </th>
      <td>{'Commercial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/industrial_area.png" />
      </th>
      <td>{'Industrial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rest_area.svg" />
      </th>
      <td>{'Rest area, service area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/caravan_site.png" />
      </th>
      <td>{'Caravan site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/apron.png" />
      </th>
      <td>{'Airport apron'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/substation.png" />
      </th>
      <td>{'Substation, plant, generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridge.png" />
      </th>
      <td>{'Bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tree_row.png" width="70" />
      </th>
      <td>{'Tree row'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hedge.png" width="70" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/embankment.png" width="70" />
      </th>
      <td>{'Embankment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cliff.png" width="70" />
      </th>
      <td>{'Cliff'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/arete.png" width="70" />
      </th>
      <td>{'Arete'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/powerline_powertower.png" width="70" />
      </th>
      <td>{'Powerline with powertower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/powerline_powerpole.png" width="70" />
      </th>
      <td>{'Powerline with powerpole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fence.png" width="70" />
      </th>
      <td>{'Fence, wall, retaining wall, trench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/city_wall.png" width="70" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/guard_rail.png" width="70" />
      </th>
      <td>{'Guardrail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waterslide.png" width="70" />
      </th>
      <td>{'Waterslide'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river.png" />
      </th>
      <td>{'River'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent river'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river_tunnel.png" width="70" />
      </th>
      <td>{'River with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream.png" width="70" />
      </th>
      <td>{'Stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream_tunnel.png" width="70" />
      </th>
      <td>{'Stream with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal.png" width="70" />
      </th>
      <td>{'Canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal_tunnel.png" width="90" />
      </th>
      <td>{'Canal with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch.png" />
      </th>
      <td>{'Ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch_intermittent.png" width="70" />
      </th>
      <td>{'Intermittent ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch_tunnel.png" width="90" />
      </th>
      <td>{'Ditch, drain with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_station.png" width="70" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_request_station.png" width="70" />
      </th>
      <td>{'Train request station'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/peak.png" width="8" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/volcano.png" width="8" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/saddle.png" width="8" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cave.png" width="14" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/spring.png" width="12" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waterfall.png" width="14" />
      </th>
      <td>{'Waterfall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tree.png" width="16" />
      </th>
      <td>{'Tree'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/town_hall.png" width="14" />
      </th>
      <td>{'Town hall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/police.png" width="14" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/firestation.png" width="14" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/courthouse.png" width="14" />
      </th>
      <td>{'Court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/post_office.png" width="14" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/post_box.png" width="12" />
      </th>
      <td>{'Postbox'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bank.png" width="14" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/atm.png" width="14" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bureau_de_change.png" width="14" />
      </th>
      <td>{'Bureau de change'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/prison_icon.png" width="14" />
      </th>
      <td>{'Prison'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_wash.png" width="14" />
      </th>
      <td>{'Car wash'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_repair_station.png" width="14" />
      </th>
      <td>{'Bicycle repair station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rental_car.png" width="14" />
      </th>
      <td>{'Car rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rental_bicycle.png" width="14" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boat_rental.png" width="14" />
      </th>
      <td>{'Boat rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/library.png" width="14" />
      </th>
      <td>{'Library'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/veterinary.png" width="14" />
      </th>
      <td>{'Vet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/officce_point.png" width="6" />
      </th>
      <td>{'Different kinds of offices, institutions'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/place_of_worship_icon.png" width="14" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/christian.png" width="14" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/jewish.png" width="14" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/muslim.png" width="14" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hinduist.png" width="14" />
      </th>
      <td>{'Hindu place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sikhist.png" width="14" />
      </th>
      <td>{'Sikh place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shintoist.png" width="14" />
      </th>
      <td>{'Shinto place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/buddhist.png" width="14" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taoist.png" width="14" />
      </th>
      <td>{'Taoist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pharmacy.png" width="14" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hospital.png" width="14" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/doctors.png" width="14" />
      </th>
      <td>{'Doctor, clinic'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dentist_point.png" width="6" />
      </th>
      <td>{'Dentist (zoom 17)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dentist.png" width="14" />
      </th>
      <td>{'Dentist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/social_facility.png" width="14" />
      </th>
      <td>{'Social facility'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hotel.png" width="14" />
      </th>
      <td>{'Hotel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/guest_house.png" width="14" />
      </th>
      <td>{'Guest house'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hostel.png" width="14" />
      </th>
      <td>{'Hostel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motel.png" width="14" />
      </th>
      <td>{'Motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/chalet.png" width="14" />
      </th>
      <td>{'Chalet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/alpinehut.png" width="14" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wilderness_hut.png" width="14" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/camping.png" width="14" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/caravan_park.png" width="14" />
      </th>
      <td>{'Caravan park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shelter.png" width="14" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hunting_stand.png" width="14" />
      </th>
      <td>{'Hunting stand'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/museum.png" width="14" />
      </th>
      <td>{'Museum'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/theatre.png" width="14" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cinema.png" width="14" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gallery.png" width="14" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/arts_centre.png" width="14" />
      </th>
      <td>{'Arts centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/artwork.png" width="14" />
      </th>
      <td>{'Artwork'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/community_centre.png" width="14" />
      </th>
      <td>{'Community centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/casino.png" width="14" />
      </th>
      <td>{'Casino'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/nightclub.png" width="14" />
      </th>
      <td>{'Nightclub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/playground.png" width="14" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fitness.png" width="14" />
      </th>
      <td>{'Outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/golf.png" width="20" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/miniature_golf.png" width="20" />
      </th>
      <td>{'Minigolf'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water_park.png" width="14" />
      </th>
      <td>{'Swimming area, water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beach_resort.png" width="14" />
      </th>
      <td>{'Beach resort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fishing.png" width="14" />
      </th>
      <td>{'Fishing area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/picnic.png" width="14" />
      </th>
      <td>{'Picnic site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/firepit.png" width="14" />
      </th>
      <td>{'Fire pit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/viewpoint.png" width="14" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/office.png" width="14" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fort.png" width="14" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/memorial.png" width="14" />
      </th>
      <td>{'Memorial'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/monument.png" width="14" />
      </th>
      <td>{'Monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/obelisk.png" width="14" />
      </th>
      <td>{'Obelisk'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/plaque.png" width="12" />
      </th>
      <td>{'Plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/archaeological_site.png" width="14" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/slipway.png" width="14" />
      </th>
      <td>{'Slipway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/parking.png" width="12" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_parking.png" width="14" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_parking_private.png" width="14" />
      </th>
      <td>{'Bicycle private parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorcycle_parking.png" width="14" />
      </th>
      <td>{'Motorcycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bus_station.png" width="14" />
      </th>
      <td>{'Bus station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bus_stop.png" width="10" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taxi.png" width="14" />
      </th>
      <td>{'Taxi'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fuel.png" width="14" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/charging_station.png" width="14" />
      </th>
      <td>{'Charging station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/traffic_light.png" width="5" height="13" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/level_crossing.png" width="11" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mini_roundabout.png" width="9" />
      </th>
      <td>{'Mini roundabout'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/aerodrome.png" width="14" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/helipad.png" width="16" />
      </th>
      <td>{'Helipad'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ferry.png" width="14" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/entrance.png" width="10" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/elevator.png" width="12" />
      </th>
      <td>{'Elevator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toll_booth.png" width="14" />
      </th>
      <td>{'Tollbooth'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/food_point.png" width="6" />
      </th>
      <td>{'Amenities related to food or beverage (zoom 17)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bar.png" width="12" />
      </th>
      <td>{'Bar'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cafe.png" width="14" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/biergarten.png" width="14" />
      </th>
      <td>{'Beer garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pub.png" width="14" />
      </th>
      <td>{'Pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fast_food.png" width="14" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/restaurant.png" width="14" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ice_cream.png" width="14" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/supermarket.png" width="14" />
      </th>
      <td>{'Supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/point.png" width="6" />
      </th>
      <td>{'Shops (zoom 17, from zoom 18 for shops without icon)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/alcohol.png" width="14" />
      </th>
      <td>{'Alcohol shop, wine shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/art.png" width="14" />
      </th>
      <td>{'Art shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bakery.png" width="14" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beauty.png" width="14" />
      </th>
      <td>{'Beauty salon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle.png" width="14" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bookmaker.png" width="14" />
      </th>
      <td>{'Betting shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/books.png" width="14" />
      </th>
      <td>{'Bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/butcher.png" width="14" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car.png" width="14" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_parts.png" width="14" />
      </th>
      <td>{'Car parts shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_repair.png" width="14" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/charity.png" width="14" />
      </th>
      <td>{'Charity shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/chemist.png" width="14" />
      </th>
      <td>{'Chemist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/clothes.png" width="14" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/coffee.png" width="14" />
      </th>
      <td>{'Coffee shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/computer.png" width="14" />
      </th>
      <td>{'Computer store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/convenience.png" width="14" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/copyshop.png" width="14" />
      </th>
      <td>{'Copy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/deli.png" width="14" />
      </th>
      <td>{'Deli'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/department_store.png" width="14" />
      </th>
      <td>{'Department store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/diy.png" width="14" />
      </th>
      <td>{'Do-it-yourself store, hardware store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/electronics.png" width="14" />
      </th>
      <td>{'Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/seafood.png" width="14" />
      </th>
      <td>{'Fishmonger'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/florist.png" width="14" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/furniture.png" width="14" />
      </th>
      <td>{'Furniture shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/garden_centre.png" width="14" />
      </th>
      <td>{'Garden centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gift.png" width="14" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/greengrocer.png" width="14" />
      </th>
      <td>{'Greengrocery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hairdresser.png" width="14" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hifi.png" width="14" />
      </th>
      <td>{'Hi-fi shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/trade.png" width="14" />
      </th>
      <td>{'Home improvement store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/jewelry.png" width="14" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/laundry.png" width="14" />
      </th>
      <td>{'Laundry, dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/marketplace.png" width="14" />
      </th>
      <td>{'Marketplace'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mobile_phone.png" width="14" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorcycle.png" width="14" />
      </th>
      <td>{'Motorcycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/music.png" width="14" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/newsagent.png" width="14" />
      </th>
      <td>{'Newsagent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/optician.png" width="14" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/outdoor.png" width="14" />
      </th>
      <td>{'Outdoor shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/perfumery.png" width="14" />
      </th>
      <td>{'Perfumery, cosmetics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pet.png" width="14" />
      </th>
      <td>{'Pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/photo.png" width="14" />
      </th>
      <td>{'Photography shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shoes.png" width="14" />
      </th>
      <td>{'Shoe shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sports.png" width="14" />
      </th>
      <td>{'Sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stationery.png" width="14" />
      </th>
      <td>{'Stationery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tobacco.png" width="14" />
      </th>
      <td>{'Tobacconist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toys.png" width="14" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/travel_agency.png" width="14" />
      </th>
      <td>{'Travel agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tyres.png" width="14" />
      </th>
      <td>{'Tyre shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/variety_store.png" width="14" />
      </th>
      <td>{'Variety store'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toilets.png" width="14" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/drinking_water.png" width="14" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bbq.png" width="14" />
      </th>
      <td>{'Barbecue'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shower.png" width="14" />
      </th>
      <td>{'Shower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telephone.png" width="12" />
      </th>
      <td>{'Telephone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/emergency_phone.png" width="14" />
      </th>
      <td>{'Emergency phone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recycling.png" width="14" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waste_basket.png" width="8" />
      </th>
      <td>{'Wastebasket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waste_disposal.png" width="14" />
      </th>
      <td>{'Waste container'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bench.png" width="14" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gate.png" width="6" />
      </th>
      <td>{'Gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/lift_gate.png" width="6" />
      </th>
      <td>{'Lift gate, swing gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/kissing_gate.png" width="12" />
      </th>
      <td>{'Kissing gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stile.png" width="10" />
      </th>
      <td>{'Stile'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cattle_grid.png" width="10" />
      </th>
      <td>{'Cattle grid'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bollard.png" width="6" />
      </th>
      <td>{'Bollard, block'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cycle_barrier.png" width="10" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fountain.png" width="14" />
      </th>
      <td>{'Fountain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mast.png" width="14" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/communications_tower.png" width="14" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telescope_dish.png" width="14" />
      </th>
      <td>{'Radio telescope'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telescope_dome.png" width="14" />
      </th>
      <td>{'Observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water_tower.png" width="14" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/windmill.png" width="14" />
      </th>
      <td>{'Windmill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/lighthouse.png" width="14" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/crane.png" width="14" />
      </th>
      <td>{'Crane'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/generator_wind.png" width="14" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cross.png" width="7" />
      </th>
      <td>{'Cross, wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mine.png" width="14" />
      </th>
      <td>{'Mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bunker.png" width="14" />
      </th>
      <td>{'Bunker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
