import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  faArrowRightLong,
  faArrowRotateRight,
  faBolt,
  faClock,
  faGaugeHigh,
  faHeartbeat,
  faTemperatureThreeQuarters,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Paper, LoadingOverlay, useMantineColorScheme, DefaultMantineColor } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { ElevationIcon } from 'assets/icons/ElevationIcon'
import { Chart, ChartDataset } from 'chart.js'
import {
  ChartDataPoint,
  TrackChart,
  XAxisTooltipFormatter,
  YAxisTooltipFormatter,
} from 'components/UI/Chart/TrackChart'
import { BottomToggle } from 'components/UI/Toggles/BottomToggle'
import { PANEL_TOGGLE_ANIMATION_DURATION } from 'config/constants'
import { useTrackStats } from 'hooks/useTrackStats'
import useMeasure from 'react-use-measure'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { ChartXAxisType, ChartYAxisType } from 'types/app'
import { getSecondsAsHMS } from 'utils/helpers'
import { getElevationGrade } from 'utils/elevations'
import { formatDistance } from 'utils/formatters/formatDistance'
import { formatElevation } from 'utils/formatters/formatElevation'
import { formatSpeed } from 'utils/formatters/formatSpeed'
import { formatTemperature } from 'utils/formatters/formatTemperature'
import { CustomChartDataPoint } from 'utils/trackStats/prepareTrackStats'

import { ChartPanelMenu } from './Components/ChartPanelMenu'

// height of the chart panel in % of viewport height
const CHART_PANEL_HEIGHT = 20

const CHART_COLORS = {
  gridDark: 'rgba(255, 255, 255, 0.15)',
  gridLight: 'rgba(0, 0, 0, 0.1)',
  types: {
    elevation: { fill: 'rgba(54, 162, 235, 0.2)', stroke: 'rgba(54, 162, 235, 1)' },
    heartRate: { fill: 'rgba(244, 52, 138, 0.2)', stroke: 'rgba(244, 52, 138, 1)' },
    cadence: { fill: 'rgba(72, 110, 233, 0.2)', stroke: 'rgba(72, 110, 233, 1)' },
    power: { fill: 'rgba(171, 120, 255, 0.2)', stroke: 'rgba(171, 120, 255, 1)' },
    speed: { fill: 'rgba(236, 157, 20, 0.2)', stroke: 'rgba(236, 157, 20, 1)' },
    temperature: { fill: 'rgba(245, 71, 71, 0.2)', stroke: 'rgba(245, 71, 71, 1)' },
    time: { fill: 'rgba(54, 162, 235, 0.2)', stroke: 'rgba(54, 162, 235, 1)' },
  },
}

const COMMON_DATASET_PROPERTIES: Partial<ChartDataset<'line', ChartDataPoint[]>> = {
  pointBorderColor: 'rgba(0, 0, 0, 0)',
  pointBackgroundColor: 'rgba(0, 0, 0, 0)',
  pointHoverRadius: 10,
  pointHitRadius: 20,
  borderWidth: 2,
}

const loaderProps = { size: 30 }

export const ChartPanel = () => {
  const { state } = useRouting()
  const [xAxisType, setXAxisType] = useState<ChartXAxisType>('distance')
  const [yAxisType, setYAxisType] = useState<ChartYAxisType>('elevation')
  const {
    state: { units },
  } = useOptions()
  const [isOpen, setIsOpen] = useState(true)
  const [ref, { height, width }] = useMeasure()
  const { stats } = useTrackStats()
  const chartRef = useRef<Chart | null>(null)
  const [debounced] = useDebouncedValue(width, 80)
  const { colorScheme } = useMantineColorScheme()

  useEffect(() => {
    chartRef.current?.resize()
  }, [debounced, stats?.chartData])

  // Checking if totalTime is falsy (0 or nil)
  const validatedType = !stats?.totalTime ? 'distance' : xAxisType

  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsOpen((prev) => !prev)
  }

  const getXvalue = (dataPoint: CustomChartDataPoint) =>
    validatedType === 'distance' ? dataPoint.xAxis.distance : dataPoint.xAxis.time ?? 0

  const getChartData = (yAxisDatapointAccessor: keyof CustomChartDataPoint['yAxis']): ChartDataPoint[] | undefined =>
    stats?.chartData.map((dataPoint) => ({
      x: getXvalue(dataPoint),
      y: dataPoint.yAxis[yAxisDatapointAccessor],
    }))

  const mainChartData: ChartDataPoint[] | undefined = stats?.chartData.map((dataPoint) => ({
    x: getXvalue(dataPoint),
    y: dataPoint.yAxis.elevation,
  }))

  const getDataset = (datasetType: ChartYAxisType): ChartDataset<'line', ChartDataPoint[]> | undefined => {
    // place to style individual chart data, for example to use different colors for each
    if (datasetType === 'elevation') {
      return mainChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            fill: true,
            data: mainChartData,
            backgroundColor: CHART_COLORS.types.elevation.fill,
            borderColor: CHART_COLORS.types.elevation.stroke,
          }
        : undefined
    }

    if (datasetType === 'heartRate') {
      const cadenceChartData = getChartData('heartRate')
      return cadenceChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            data: cadenceChartData,
            spanGaps: false,
            backgroundColor: CHART_COLORS.types.heartRate.fill,
            borderColor: CHART_COLORS.types.heartRate.stroke,
          }
        : undefined
    }

    if (datasetType === 'cadence') {
      const heartRateChartData = getChartData('cadence')
      return heartRateChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            data: heartRateChartData,
            spanGaps: false,
            backgroundColor: CHART_COLORS.types.cadence.fill,
            borderColor: CHART_COLORS.types.cadence.stroke,
          }
        : undefined
    }

    if (datasetType === 'power') {
      const powerChartData = getChartData('power')
      return powerChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            data: powerChartData,
            spanGaps: false,
            backgroundColor: CHART_COLORS.types.power.fill,
            borderColor: CHART_COLORS.types.power.stroke,
          }
        : undefined
    }

    if (datasetType === 'speed') {
      const speedChartData = getChartData('speed')
      return speedChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            data: speedChartData,
            spanGaps: false,
            backgroundColor: CHART_COLORS.types.speed.fill,
            borderColor: CHART_COLORS.types.speed.stroke,
          }
        : undefined
    }

    if (datasetType === 'temperature') {
      const temperatureChartData = getChartData('temperature')
      return temperatureChartData
        ? {
            ...COMMON_DATASET_PROPERTIES,
            data: temperatureChartData,
            spanGaps: false,
            backgroundColor: CHART_COLORS.types.temperature.fill,
            borderColor: CHART_COLORS.types.temperature.stroke,
          }
        : undefined
    }

    // This is a leftover from previous development since time data
    // is not currently displayed on y axis
    // if (datasetType === 'time') {
    //   const timeChartData = getChartData('time')
    //   return timeChartData
    //     ? {
    //         ...COMMON_DATASET_PROPERTIES,
    //         data: timeChartData,
    //         spanGaps: false,
    //         backgroundColor: CHART_COLORS.types.time.fill,
    //         borderColor: CHART_COLORS.types.time.stroke,
    //       }
    //     : undefined
    // }
    return undefined
  }

  const currentDataset = getDataset(yAxisType)

  /** Formats labels on the bottom of the chart (horizontal axis) */
  const xAxisLabelFormatter = useCallback(
    (value) =>
      validatedType === 'distance' ? formatDistance(Number(value) * 1000, units, 0) : getSecondsAsHMS(value as number),
    [units, validatedType]
  )

  /** Formats labels on the left side of the chart (vertical axis) */
  const yAxisLabelFormatter = useCallback(
    (value) => {
      if (yAxisType === 'elevation') {
        return formatElevation(Number(value), units, 0)
      }
      if (yAxisType === 'speed') {
        return formatSpeed(Number(value), units, 0)
      }
      if (yAxisType === 'cadence') {
        return `${value} rpm`
      }
      if (yAxisType === 'heartRate') {
        return `${value} bpm`
      }
      if (yAxisType === 'power') {
        return `${value} W`
      }
      if (yAxisType === 'temperature') {
        return formatTemperature(Number(value), units, 0)
      }
      if (yAxisType === 'time') {
        return new Date(value).toLocaleTimeString()
      }
      return value
    },
    [units, yAxisType]
  )

  /** Formats tooltip yAxis values */
  const yAxisTooltipFormatter: YAxisTooltipFormatter = useCallback(
    ({ currentIndex, prevPoint, currentPoint }) => {
      if (yAxisType === 'elevation') {
        const elevationGrade =
          currentIndex && currentIndex > 0 && currentPoint && prevPoint
            ? getElevationGrade(currentPoint, prevPoint).toFixed(1)
            : null
        const elevationStr = formatElevation(currentPoint.y, units, 1) + (elevationGrade ? ` (${elevationGrade}%)` : '')

        return elevationStr
      }
      if (yAxisType === 'speed') {
        return formatSpeed(currentPoint.y, units, 0)
      }
      if (yAxisType === 'cadence') {
        return `${currentPoint.y} rpm`
      }
      if (yAxisType === 'heartRate') {
        return `${currentPoint.y} bpm`
      }
      if (yAxisType === 'power') {
        return `${currentPoint.y} W`
      }
      if (yAxisType === 'temperature') {
        return formatTemperature(currentPoint.y, units, 0)
      }
      if (yAxisType === 'time') {
        return new Date(currentPoint.y).toLocaleTimeString()
      }
      return String(currentPoint.y)
    },
    [units, yAxisType]
  )

  const xAxisTooltipFormatter: XAxisTooltipFormatter = useCallback(
    (value) => {
      if (validatedType === 'distance') return formatDistance(value * 1000, units, 3)
      return getSecondsAsHMS(value)
    },
    [units, validatedType]
  )

  /** Change icons for individual xAxis types */
  const getTooltipXAxisIcon = useCallback(() => {
    if (validatedType === 'distance') return <FontAwesomeIcon icon={faArrowRightLong} />
    return <FontAwesomeIcon icon={faClock} />
  }, [validatedType])

  /** Change icons for individual yAxis types */
  const getTooltipYAxisIcon = useCallback(
    (color?: DefaultMantineColor) => {
      const getColorSchemeByTheme = () => (colorScheme === 'light' ? '#0b0b0b' : '#b7b8bb')
      if (yAxisType === 'speed') {
        return color ? (
          <FontAwesomeIcon icon={faGaugeHigh} />
        ) : (
          <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faGaugeHigh} />
        )
      }
      if (yAxisType === 'cadence') {
        return color ? (
          <FontAwesomeIcon icon={faArrowRotateRight} />
        ) : (
          <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faArrowRotateRight} />
        )
      }
      if (yAxisType === 'heartRate') {
        return color ? (
          <FontAwesomeIcon icon={faHeartbeat} />
        ) : (
          <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faHeartbeat} />
        )
      }
      if (yAxisType === 'power') {
        return color ? (
          <FontAwesomeIcon icon={faBolt} />
        ) : (
          <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faBolt} />
        )
      }
      if (yAxisType === 'temperature') {
        return color ? (
          <FontAwesomeIcon icon={faTemperatureThreeQuarters} />
        ) : (
          <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faTemperatureThreeQuarters} />
        )
      }
      return color ? (
        <Box>
          <ElevationIcon />
        </Box>
      ) : (
        // <FontAwesomeIcon color={getColorSchemeByTheme()} icon={faChartArea} />
        <Box style={{ color: getColorSchemeByTheme() }}>
          <ElevationIcon />
        </Box>
      )
    },
    [yAxisType, colorScheme]
  )

  const getYAxis = useCallback(() => {
    if (stats?.chartData) {
      const keys = Object.entries(stats.populatedDatasets).map(([key, value]) => value === true && key)
      return keys as ChartYAxisType[]
    }
    return []
  }, [stats?.chartData, stats?.populatedDatasets])

  const { routing, isRouting, areElevationsLoading } = state

  useEffect(() => {
    const yAxisData = getYAxis().includes(yAxisType)
    if (!yAxisData) setYAxisType('elevation')
  }, [yAxisType, getYAxis])

  return useMemo(
    () =>
      currentDataset || isRouting || areElevationsLoading ? (
        <Box
          ref={ref}
          sx={{
            height: `${CHART_PANEL_HEIGHT}%`,
            width: '100%',
            position: 'relative',
            marginBottom: isOpen ? 0 : -height,
            transition: `margin ${PANEL_TOGGLE_ANIMATION_DURATION}s`,
          }}
        >
          <BottomToggle isOpen={isOpen} handleToggle={handleToggle} zIndex={100} />
          <LoadingOverlay
            zIndex={100}
            loaderProps={loaderProps}
            visible={isRouting || areElevationsLoading}
            overlayBlur={2}
          />
          <Paper
            radius={0}
            sx={{
              zIndex: 50,
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
            shadow="md"
          >
            <ChartPanelMenu
              routing={routing}
              xAxisType={xAxisType}
              yAxisType={yAxisType}
              setXAxisType={setXAxisType}
              setYAxisType={setYAxisType}
              getYAxis={getYAxis}
              getTooltipYAxisIcon={getTooltipYAxisIcon}
              disabledXAxisToggle={!stats?.totalTime}
            />
            {currentDataset && mainChartData && mainChartData.length > 0 && (
              <Box sx={{ flex: 1, minHeight: 0 }}>
                <TrackChart
                  unitType={units}
                  dataset={currentDataset}
                  chartRef={chartRef}
                  chartData={mainChartData}
                  xAxisLabelFormatter={xAxisLabelFormatter}
                  yAxisLabelFormatter={yAxisLabelFormatter}
                  yAxisTooltipFormatter={yAxisTooltipFormatter}
                  xAxisTooltipFormatter={xAxisTooltipFormatter}
                  chartTooltipProps={{ xAxisIcon: getTooltipXAxisIcon(), yAxisIcon: getTooltipYAxisIcon() }}
                />
              </Box>
            )}
          </Paper>
        </Box>
      ) : null,
    [
      currentDataset,
      mainChartData,
      ref,
      isOpen,
      height,
      routing,
      isRouting,
      areElevationsLoading,
      xAxisType,
      yAxisType,
      units,
      xAxisLabelFormatter,
      yAxisLabelFormatter,
      yAxisTooltipFormatter,
      xAxisTooltipFormatter,
      getTooltipXAxisIcon,
      getTooltipYAxisIcon,
      getYAxis,
      stats?.totalTime,
    ]
  )
}
