import { ActionIcon, ActionIconProps, Group, MantineTheme, Stack, Text, useMantineTheme } from '@mantine/core'
import { useMantineTextColorIndex } from 'hooks/useMantineTextColor'

import './ActionIconGroup.css'

interface ActionIconGroupProps {
  iconSize?: string | number
  items: { icon: JSX.Element; label: string; value: string }[]
  labelFontSize?: string | number
  onClick: (value: string) => void
  size?: ActionIconProps['size']
  value: string
}

const getHoveredButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors.gray[1] : theme.colors.dark[4]

const getHoveredSelectedButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors[theme.primaryColor]?.[8] : theme.colors[theme.primaryColor]?.[7]

export const ActionIconGroup = (props: ActionIconGroupProps) => {
  const { iconSize, items, labelFontSize, onClick, value: selectedItemValue, size } = props
  const theme = useMantineTheme()
  const textColor = useMantineTextColorIndex()

  return (
    <Group className="trkbk-action-button-group" grow noWrap>
      {items.map(({ icon, label, value }) => {
        const isSelected = value === selectedItemValue

        return (
          <ActionIcon
            key={label}
            onClick={() => {
              onClick(value)
            }}
            size={size ?? 60}
            variant={isSelected ? 'filled' : 'transparent'}
            color={isSelected ? theme.primaryColor : textColor}
            sx={{
              '&:hover': {
                backgroundColor: isSelected
                  ? getHoveredSelectedButtonBackground(theme)
                  : getHoveredButtonBackground(theme),
              },
            }}
          >
            <Stack spacing="xs" sx={{ fontSize: iconSize ?? '1.8rem' }}>
              {icon}
              <Text
                inline
                className="trkbk-action-button-label"
                sx={{
                  fontSize: labelFontSize ?? '0.8rem',
                  fontColor: theme.colorScheme === 'light' ? '#495057' : 'inherit',
                }}
                color={isSelected ? 'white' : textColor}
              >
                {label}
              </Text>
            </Stack>
          </ActionIcon>
        )
      })}
    </Group>
  )
}
