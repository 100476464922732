import { useEffect, useMemo, useRef } from "react";

import { Box, CloseButton, Group, Stack, useMantineTheme } from "@mantine/core";
import { TOP_MENU_ITEMS_MARGINS } from "config/constants";
import { useEditMode } from "hooks/useEditMode";
import { useGenericModals } from "hooks/useGenericModals";
import { useUserTracks } from "hooks/useUserTracks";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { setCesiumMode } from "stores/genericStore/actions";
import { useGenericDispatch } from "stores/genericStore/GenericContext";
import { setIsRouting } from "stores/routingStore/actions";
import { useRouting } from "stores/routingStore/RoutingContext";

import { AddWaypointIcon } from "./DefaultMode/AddWaypointIcon";
import { MeasurementModeIcon } from "./DefaultMode/MeasurementModeIcon";
import { CesiumMapToggleIcon } from "./EditTrackMode/CesiumMapToggleIcon";
import { CopyToClipboardIcon } from "./EditTrackMode/CopyToClipboardIcon";
import { ExportTrackIcon } from "./EditTrackMode/ExportTrackIcon";
import { HotlineIcon } from "./EditTrackMode/HotlineIcon";
import { SaveIndicatorIcon } from "./EditTrackMode/SaveIndicatorIcon";
import { TrackMeta } from "./EditTrackMode/TrackMeta";
import { WaypointMeta } from "./EditWaypointMode/WaypointMeta";
import { DeleteIcon } from "./Shared/DeleteIcon";
import { EditIcon } from "./Shared/EditIcon";
import { FitToScreenIcon } from "./Shared/FitToScreenIcon";
import { GalleryIcon } from "./Shared/GalleryIcon";
import { UndoRedoButtons } from "./UndoRedoButtons";
import { PremiumFeatureWrapper } from "../Wrappers/PremiumFeatureWrapper";
import { useTranslation } from "react-i18next";

export const TopEditMenuItems = () => {
  const { t } = useTranslation();
  const { openConfirmLosingTrackModal } = useGenericModals();
  const { selectedTrack, trackStatus, selectTrack } = useUserTracks();
  const { selectedWaypoint, selectWaypoint, isAddingWaypoint } =
    useUserWaypoints();
  const { editMode } = useEditMode();
  const genericDispatch = useGenericDispatch();
  const {
    state: { isRouting, trackWaypoints },
    dispatch,
  } = useRouting();
  const selectedTrackRef = useRef(selectedTrack);
  useEffect(() => {
    selectedTrackRef.current = selectedTrack;
  }, [selectedTrack]);
  const theme = useMantineTheme();

  const isTrackEditMode = editMode === "track";
  const isWaypointEditMode = editMode === "waypoint";
  const isDefaultEditMode = editMode === "default";
  const isMeasurementMode = editMode === "measurement";
  const haveSaveableTrackwaypoints = trackWaypoints.length > 1;

  const lockIconStyles = useMemo(
    () => ({
      opacity: 0.8,
      color: theme.colorScheme === "dark" ? "#bbb" : "#444",
      border: `2px solid ${
        theme.colorScheme === "dark"
          ? "rgba(24, 18, 33, 0.7)"
          : "rgba(238, 238, 238)"
      }`,
      borderRadius: "2px",
      backgroundColor:
        theme.colorScheme === "dark"
          ? "rgba(24,18,33, 0.7)"
          : "rgb(238, 238, 238)",
    }),
    [theme.colorScheme]
  );

  const handleCloseButtonClick = () => {
    if (editMode === "track") {
      const isSaved = !!selectedTrack; // needs improvement

      // This is a failsafe mechanism to prevent an infinite loader
      // in ChartPanel in case routing is in progress and the track
      // gets discarded
      if (isRouting) {
        dispatch(setIsRouting(false), false);
      }
      // TODO (Peter): Is saved needs more bulletproof logic (checking if the latest changes were saved)
      // checking if latest elevations were fetched
      if (!isSaved && haveSaveableTrackwaypoints) {
        return openConfirmLosingTrackModal({
          onConfirm: () => genericDispatch(setCesiumMode(false)),
        });
      }
      return selectTrack(null);
    }
    if (editMode === "waypoint") {
      return selectWaypoint(null);
    }
    return undefined;
  };

  return (
    <Stack
      spacing={0}
      pt={4}
      sx={{
        position: "relative",
        transition: "padding-right 0.3s ease-in-out",
      }}
      pl={6}
      pr={!isDefaultEditMode && !isMeasurementMode ? 18 : 6}
    >
      <Group spacing={0} mb={4} noWrap>
        <UndoRedoButtons isOpen={!isDefaultEditMode && !isMeasurementMode} />

        <AddWaypointIcon
          isOpen={isDefaultEditMode || isMeasurementMode}
          isDisabled={isMeasurementMode}
        />
        <MeasurementModeIcon
          isOpen={isDefaultEditMode || isMeasurementMode}
          isDisabled={isAddingWaypoint}
        />

        <EditIcon isOpen={isTrackEditMode || isWaypointEditMode} />
        <ExportTrackIcon isOpen={isTrackEditMode || isWaypointEditMode} />
        <PremiumFeatureWrapper
          featureName={t("editmode.hotline_button_tooltip")}
          isVisible={isTrackEditMode}
          lockIconStyles={lockIconStyles}
        >
          <HotlineIcon isOpen={isTrackEditMode} />
        </PremiumFeatureWrapper>
        <FitToScreenIcon isOpen={isTrackEditMode || isWaypointEditMode} />
        <PremiumFeatureWrapper
          featureName={t("editmode.toggle_cesium_map_button_tooltip")}
          isVisible={isTrackEditMode}
          lockIconStyles={lockIconStyles}
        >
          <CesiumMapToggleIcon isOpen={isTrackEditMode} />
        </PremiumFeatureWrapper>
        <DeleteIcon isOpen={isTrackEditMode || isWaypointEditMode} />
        <CopyToClipboardIcon isOpen={isTrackEditMode} />
        <GalleryIcon
          isOpen={isTrackEditMode || isWaypointEditMode}
          editMode={editMode}
        />
      </Group>

      {!isDefaultEditMode && !isMeasurementMode && (
        <Box
          sx={{ position: "relative", width: "100%", height: 32 }}
          mx={TOP_MENU_ITEMS_MARGINS}
        >
          <Box
            sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
          >
            <Group
              mb={3}
              sx={{ flexWrap: "nowrap", flexShrink: 1, minWidth: 0 }}
              spacing={TOP_MENU_ITEMS_MARGINS}
            >
              {isTrackEditMode && (
                <TrackMeta
                  trackName={selectedTrack?.name}
                  status={trackStatus}
                />
              )}
              {isWaypointEditMode && selectedWaypoint && <WaypointMeta />}
              {isTrackEditMode && <SaveIndicatorIcon />}
            </Group>
          </Box>
        </Box>
      )}
      {(isTrackEditMode || isWaypointEditMode) && (
        <CloseButton
          size="sm"
          variant="transparent"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: isDefaultEditMode ? 0 : 1,
            transition: "opacity 0.3s ease-in-out",
          }}
          onClick={handleCloseButtonClick}
        />
      )}
    </Stack>
  );
};
