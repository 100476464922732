import React from 'react'

import { Modal, Text, Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { EditMode } from 'types/app'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

type Props = {
  srcs: string[]
  isOpen: boolean
  onClose: () => void
  editMode: EditMode
}

export const LightboxGallery = ({ srcs: images, isOpen, onClose, editMode }: Props) => {
  const { t } = useTranslation()

  const getAnchorElement = (data: string) => (
    <a href={data} target="_blank" rel="noreferrer" style={{ display: 'block', textAlign: 'center', color: 'white' }}>
      {data}
    </a>
  )

  const formatImages = (data: string[]) =>
    data.map((item, index) => ({
      src: item,
      title: `${data.length - index}/${data.length}`,
      description: getAnchorElement(item),
    }))

  return images.length > 0 ? (
    <Lightbox open={isOpen} close={onClose} slides={formatImages(images).reverse()} plugins={[Captions, Thumbnails]} />
  ) : (
    <Modal
      centered
      opened
      onClose={onClose}
      closeOnClickOutside
      size="xs"
      title={t('lightbox_gallery_modal.text')}
      styles={{ title: { fontWeight: 'bold', fontSize: '1.125rem' } }}
      style={{ zIndex: 400 }}
    >
      <Text size="sm">
        {t('lightbox_gallery_modal.text', { context: editMode === 'track' ? 'track' : 'waypoint' })}
      </Text>
      <Button onClick={onClose} style={{ display: 'flex', marginLeft: 'auto', marginTop: '1rem' }}>
        {'OK'}
      </Button>
    </Modal>
  )
}
