import { useEffect, useState } from 'react'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, UnstyledButton } from '@mantine/core'
import { useResponsive } from 'hooks/useResponsive'

const TOGGLE_LENGTH = 56 // px
const TOGGLE_THICKNESS = 20 // px
const ICON_TOP_PADDING = 2 // px
const OPEN_CLOSE_ANIMATION_DURATION = 400 // ms

type Props = {
  handleToggle: (e: React.MouseEvent<HTMLElement>) => void
  isOpen: boolean
  zIndex?: number
}

export const BottomToggle = ({ handleToggle, isOpen, zIndex }: Props) => {
  const { isSm } = useResponsive()
  const [isFullyClosed, setIsFullyClosed] = useState(false)

  useEffect(() => {
    if (isOpen) return setIsFullyClosed(false)

    const timeout = setTimeout(() => {
      setIsFullyClosed(!isOpen)
    }, OPEN_CLOSE_ANIMATION_DURATION)

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen])

  return (
    <UnstyledButton
      onClick={handleToggle}
      ml={-(TOGGLE_LENGTH / 2)}
      pt={ICON_TOP_PADDING}
      sx={(theme) => ({
        width: TOGGLE_LENGTH,
        height: TOGGLE_THICKNESS + (isOpen ? 0 : 4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'absolute',
        top: -TOGGLE_THICKNESS,
        left: '50%',
        color: theme.colors.gray[6],
        backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[4],
        borderTopLeftRadius: isOpen ? 0 : theme.radius.md,
        borderTopRightRadius: isOpen ? 0 : theme.radius.md,
        borderBottomRightRadius: isOpen ? theme.radius.md : 0,
        borderBottomLeftRadius: isOpen ? theme.radius.md : 0,
        boxShadow: isOpen ? '' : theme.shadows.sm,
        transition: `transform ${
          isFullyClosed ? 100 : OPEN_CLOSE_ANIMATION_DURATION
        }ms, height ${OPEN_CLOSE_ANIMATION_DURATION}ms, border-radius ${OPEN_CLOSE_ANIMATION_DURATION}ms`,
        zIndex: zIndex ?? 0,
        transform: isOpen && !isFullyClosed ? 'translate3d(0,20px,0)' : 'translate3d(0,0,0)',
        '@media(hover: hover)': {
          '&:hover': {
            transform: isOpen ? undefined : 'translate3d(0,-4px, 0)',
          },
        },
      })}
    >
      {!isSm && (
        // touch thumb on small devices
        <Box
          onClick={handleToggle}
          sx={{
            position: 'absolute',
            top: -15,
            bottom: 0,
            right: -15,
            left: -15,
            borderRadius: 0,
          }}
        />
      )}
      <Stack
        sx={{
          transform: isOpen ? '' : 'rotate(180deg)',
          transition: 'transform 0.5s',
        }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </Stack>
    </UnstyledButton>
  )
}
