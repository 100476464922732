import { base64ToNum } from '../base64toNum/base64toNum'

/**
 *
 * @param dataBase64 Base64 encoded string
 * @returns Array of numbers [number, number, ...]
 */
export const decodeBase64string = (dataBase64: string): number[] => {
  const decodedData: number[] = []

  if (dataBase64.length > 1 && dataBase64.charCodeAt(0) === 254) {
    const base64s = dataBase64.substring(1).match(/.{1,4}/g)
    base64s?.forEach((it) => decodedData.push(base64ToNum(it)))
  } else {
    const split = dataBase64.split(',')
    split.forEach((it) => decodedData.push(parseFloat(it)))
  }

  return decodedData
}
