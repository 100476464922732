export const BubbleWrapLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorway_15z.png" />
      </th>
      <td>{'Motorway (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorway.png" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/primary.png" />
      </th>
      <td>{'Trunk road, primary road, secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tertiary.png" />
      </th>
      <td>{'Tertiary road, labelled tertiary road, unclassified road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/primary_ref.png" />
      </th>
      <td>{'Labelled primary road/trunk road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/secondary_ref.png" />
      </th>
      <td>{'Labelled secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/minor_road.png" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/service.png" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorway_tunnel.png" />
      </th>
      <td>{'Motorway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/primary_tunnel.png" />
      </th>
      <td>{'Primary, secondary, tertiary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/minor_road_tunnel.png" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/arrow.png" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/footway.png" />
      </th>
      <td>{'Footway, path, cycleway, bridleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pedestrian_line.png" />
      </th>
      <td>{'Pedestrian street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/track_major.png" />
      </th>
      <td>{'Track (grade 1, grade 2)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/track_minor.png" width="70" />
      </th>
      <td>{'Track (grade 3, grade 4, grade 5)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/steps.png" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/racetrack.png" width="70" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/runway.png" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/taxiway.png" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/rail.png" />
      </th>
      <td>{'Railway, tram, light rail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/rail_service.png" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/subway.png" />
      </th>
      <td>{'Subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/piste.png" />
      </th>
      <td>{'Piste'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/piste_easy.png" />
      </th>
      <td>{'Piste (difficulty: easy)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/piste_intermediate.png" />
      </th>
      <td>{'Piste (difficulty: intermediate)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/piste_advanced.png" />
      </th>
      <td>{'Piste (difficulty: advanced, expert)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/chairlift.png" width="70" />
      </th>
      <td>{'Chairlift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gondola.png" width="70" />
      </th>
      <td>{'Gondola lift, cable car'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/aerialway.png" />
      </th>
      <td>
        {
          'Goods cable lift, mixed lift, drag lift, t-bar lift, j-bar lift, platter lift, magic carpet, rope tow, zipline'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pier.png" width="70" />
      </th>
      <td>{'Pier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ferry.png" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/platform.png" />
      </th>
      <td>{'Platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water.png" width="60" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/forest.png" width="60" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/nature_reserve.png" width="60" />
      </th>
      <td>{'Nature reserve'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/national_park_usa.png" width="60" />
      </th>
      <td>{'National park, protected area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wetland.png" />
      </th>
      <td>{'Wetland, mud'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/beach.png" width="60" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/plant_nursery.png" width="60" />
      </th>
      <td>{'Orchard, plant nursery, retail area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/garden.png" width="60" />
      </th>
      <td>{'Garden, allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cemetery.png" width="60" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/park.png" width="60" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch.png" width="60" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/playground.png" width="60" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/recreation_ground.png" width="60" />
      </th>
      <td>{'Recreation ground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/sports_centre.png" width="60" />
      </th>
      <td>{'Sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/stadium.png" width="60" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/golf_course.png" width="60" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/winter_sports.png" width="60" />
      </th>
      <td>{'Winter sports'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hospital.png" width="60" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/university.png" width="60" />
      </th>
      <td>{'University, college'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/school.png" width="60" />
      </th>
      <td>{'School'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/airport.png" width="60" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/military_area.png" width="60" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/danger_area.png" width="60" />
      </th>
      <td>{'Danger area, range'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/place_of_worship.png" width="60" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/parking.png" width="60" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/urban_area_lower.png" width="60" />
      </th>
      <td>{'Urban area (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/urban_area_higher.png" width="60" />
      </th>
      <td>{'Urban area (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/commercial.png" width="60" />
      </th>
      <td>{'Commercial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/industrial.png" width="60" />
      </th>
      <td>{'Industrial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/apron.png" width="60" />
      </th>
      <td>{'Airport apron'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generator.png" width="60" />
      </th>
      <td>{'Substation, plant, generator, pier area, bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/railway.png" width="60" />
      </th>
      <td>{'Railway area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/works.png" width="60" />
      </th>
      <td>{'Wastewater plant, works, waterworks'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pedestrian.png" width="60" />
      </th>
      <td>{'Pedestrian area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/theme_park.png" width="60" />
      </th>
      <td>{'Theme park, maze'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/zoo.png" width="60" />
      </th>
      <td>{'Zoo, wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/petting_zoo.png" width="60" />
      </th>
      <td>{'Zoo enclosure, petting zoo, aviary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tree_row.png" />
      </th>
      <td>{'Tree row'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hedge.png" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/power_line.png" />
      </th>
      <td>{'Power line'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/minor_power_line.png" />
      </th>
      <td>{'Minor power line'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fence.png" />
      </th>
      <td>{'Fence, snow fence, guardrail, trench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/retaining_wall.png" />
      </th>
      <td>{'Wall, retaining wall, embankment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/city_wall.png" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/river.png" width="70" />
      </th>
      <td>{'River, stream, canal, ditch, drain'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/peak.png" width="20" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/volcano.png" width="20" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/saddle.png" width="20" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cave_entrance.png" width="20" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/spring.png" width="20" />
      </th>
      <td>{'Spring, hot spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/waterfall.png" width="20" />
      </th>
      <td>{'Waterfall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/geyser.png" width="20" />
      </th>
      <td>{'Geyser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/rock.png" width="20" />
      </th>
      <td>{'Rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/stone.png" width="20" />
      </th>
      <td>{'Stone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/sinkhole.png" width="20" />
      </th>
      <td>{'Sinkhole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tree.png" width="20" />
      </th>
      <td>{'Tree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wetland_icon.png" width="20" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/beach_icon.png" width="20" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dune.png" width="20" />
      </th>
      <td>{'Dune'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/townhall.png" width="20" />
      </th>
      <td>{'Town hall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/police.png" width="20" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fire_station.png" width="20" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/court.png" width="20" />
      </th>
      <td>{'Court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/post_office.png" width="20" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/post_box.png" width="20" />
      </th>
      <td>{'Postbox'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bank.png" width="16" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/atm.png" width="20" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bureau_de_change.png" width="20" />
      </th>
      <td>{'Bureau de change'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/money_transfer.png" width="20" />
      </th>
      <td>{'Money transfer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/prison.png" width="20" />
      </th>
      <td>{'Prison'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ranger_station.png" width="20" />
      </th>
      <td>{'Ranger station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car_wash.png" width="20" />
      </th>
      <td>{'Car wash'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bicycle_repair_station.png" width="20" />
      </th>
      <td>{'Bicycle repair station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car_rental.png" width="20" />
      </th>
      <td>{'Car rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car_sharing.png" width="20" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bicycle_rental.png" width="20" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/boat_rental.png" width="20" />
      </th>
      <td>{'Boat rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/boat_storage.png" width="20" />
      </th>
      <td>{'Boat storage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/university_icon.png" width="20" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/kindergarten.png" width="20" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/library.png" width="20" />
      </th>
      <td>{'Library'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/childcare.png" width="20" />
      </th>
      <td>{'Childcare, day care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/veterinary.png" width="20" />
      </th>
      <td>{'Vet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/place_of_worship_icon.png" width="20" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/christian.png" width="20" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/jewish.png" width="20" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/muslim.png" width="20" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hindu.png" width="20" />
      </th>
      <td>{'Hindu place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/sikh.png" width="20" />
      </th>
      <td>{'Sikh place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shinto.png" width="20" />
      </th>
      <td>{'Shinto place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/buddhist.png" width="20" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/taoist.png" width="20" />
      </th>
      <td>{'Taoist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pharmacy.png" width="20" />
      </th>
      <td>{'Pharmacy, chemist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hospital_icon.png" width="18" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/health_centre.png" width="20" />
      </th>
      <td>{'Health centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/doctor.png" width="20" />
      </th>
      <td>{'Doctor, clinic'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dentist.png" width="20" />
      </th>
      <td>{'Dentist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/blood_bank.png" width="20" />
      </th>
      <td>{'Blood bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/midwife.png" width="20" />
      </th>
      <td>{'Midwife'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/social_facility.png" width="20" />
      </th>
      <td>{'Social facility'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ambulatory_care.png" width="20" />
      </th>
      <td>{'Ambulatory care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/assisted_living.png" width="20" />
      </th>
      <td>{'Assisted living'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/food_bank.png" width="20" />
      </th>
      <td>{'Food bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/group_home.png" width="20" />
      </th>
      <td>{'Group home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/nursing_home.png" width="20" />
      </th>
      <td>{'Nursing home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/outreach.png" width="20" />
      </th>
      <td>{'Outreach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/soup_kitchen.png" width="20" />
      </th>
      <td>{'Soup kitchen'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/workshop.png" width="20" />
      </th>
      <td>{'Workshop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hotel.png" width="20" />
      </th>
      <td>{'Hotel, hostel, motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/guest_house.png" width="20" />
      </th>
      <td>{'Guest house'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/chalet.png" width="20" />
      </th>
      <td>{'Chalet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/alpine_hut.png" width="20" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wilderness_hut.png" width="20" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/camping.png" width="20" />
      </th>
      <td>{'Camping, summer camp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/caravan_park.png" width="20" />
      </th>
      <td>{'Caravan park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shelter.png" width="20" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hunting_stand.png" width="20" />
      </th>
      <td>{'Hunting stand'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/museum.png" width="20" />
      </th>
      <td>{'Museum, observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/theatre.png" width="20" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cinema.png" width="20" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gallery.png" width="20" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/arts_centre.png" width="20" />
      </th>
      <td>{'Arts centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/artwork.png" width="20" />
      </th>
      <td>{'Artwork'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/community_centre.png" width="20" />
      </th>
      <td>{'Community centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gambling.png" width="20" />
      </th>
      <td>{'Gambling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/nightclub.png" width="20" />
      </th>
      <td>{'Nightclub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/studio.png" width="20" />
      </th>
      <td>{'Studio'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/national_park_icon.png" width="20" />
      </th>
      <td>{'National park, protected area, park, battlefield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/forest_icon.png" width="20" />
      </th>
      <td>{'Nature reserve, forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/recreation_ground_icon.png" width="20" />
      </th>
      <td>{'Recreation ground, sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/playground_icon.png" width="20" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/garden_icon.png" width="20" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fitness.png" width="20" />
      </th>
      <td>{'Outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/recreation_track.png" width="20" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dog_park.png" width="20" />
      </th>
      <td>{'Dog park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/golf_course_icon.png" width="20" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/miniature_golf.png" width="20" />
      </th>
      <td>{'Minigolf'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_soccer.png" width="20" />
      </th>
      <td>{'Football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_american_football.png" width="20" />
      </th>
      <td>{'American football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_tennis.png" width="20" />
      </th>
      <td>{'Tennis court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_basketball.png" width="20" />
      </th>
      <td>{'Basketball court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_baseball.png" width="20" />
      </th>
      <td>{'Baseball field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_cricket.png" width="20" />
      </th>
      <td>{'Cricket field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pitch_golf.png" width="20" />
      </th>
      <td>{'Green, golf shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/swimming_area.png" width="20" />
      </th>
      <td>{'Swimming area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water_park.png" width="20" />
      </th>
      <td>{'Water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/beach_resort.png" width="20" />
      </th>
      <td>{'Beach resort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fishing.png" width="20" />
      </th>
      <td>{'Fishing area, fishing shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/picnic_site.png" width="20" />
      </th>
      <td>{'Picnic site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/firepit.png" width="20" />
      </th>
      <td>{'Fire pit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/viewpoint.png" width="20" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/information.png" width="14" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/memorial.png" width="20" />
      </th>
      <td>{'Memorial, monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/obelisk.png" width="20" />
      </th>
      <td>{'Obelisk'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/plaque.png" width="20" />
      </th>
      <td>{'Plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/archaeological_site.png" width="20" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/historical.png" width="20" />
      </th>
      <td>{'Historical, fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/stadium_icon.png" width="20" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/winter_sports_icon.png" width="20" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ski_rental.png" width="20" />
      </th>
      <td>{'Ski rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ski_school.png" width="20" />
      </th>
      <td>{'Ski school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/marina.png" width="20" />
      </th>
      <td>{'Marina'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dive_centre.png" width="20" />
      </th>
      <td>{'Dive centre, scuba diving equipment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/aquarium.png" width="20" />
      </th>
      <td>{'Aquarium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/horse_riding.png" width="20" />
      </th>
      <td>{'Horse riding'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/zoo_icon.png" width="20" />
      </th>
      <td>{'Zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/aviary.png" width="20" />
      </th>
      <td>{'Aviary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/petting_zoo_icon.png" width="20" />
      </th>
      <td>{'Petting zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wildlife_park.png" width="20" />
      </th>
      <td>{'Wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/theme_park_icon.png" width="20" />
      </th>
      <td>{'Theme park, amusement ride'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/attraction.png" width="20" />
      </th>
      <td>{'Attraction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/carousel.png" width="20" />
      </th>
      <td>{'Carousel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/roller_coaster.png" width="20" />
      </th>
      <td>{'Roller coaster'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/summer_toboggan.png" width="20" />
      </th>
      <td>{'Summer toboggan'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/maze.png" width="20" />
      </th>
      <td>{'Maze'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water_slide.png" width="20" />
      </th>
      <td>{'Water slide'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/slipway.png" width="20" />
      </th>
      <td>{'Slipway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/put_in.png" width="20" />
      </th>
      <td>{'Put in (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/egress.png" width="20" />
      </th>
      <td>{'Egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/put_in_egress.png" width="20" />
      </th>
      <td>{'Put in, egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/rapid.png" width="20" />
      </th>
      <td>{'Rapid (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hazard.png" width="20" />
      </th>
      <td>{'Hazard (whitewater)'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/parking_icon.png" width="16" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/parking_garage.png" width="20" />
      </th>
      <td>{'Parking garage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bicycle_parking.png" width="20" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorcycle_parking.png" width="20" />
      </th>
      <td>{'Motorcycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bus_station.png" width="20" />
      </th>
      <td>{'Bus station, bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/train_station.png" width="20" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/halt.png" width="20" />
      </th>
      <td>{'Train request station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tram_stop.png" width="20" />
      </th>
      <td>{'Tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/taxi.png" width="20" />
      </th>
      <td>{'Taxi'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fuel.png" width="20" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/charging_station.png" width="20" />
      </th>
      <td>{'Charging station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/traffic_lights.png" width="20" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/level_crossing.png" width="14" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/mini_roundabout.png" width="20" />
      </th>
      <td>{'Mini roundabout'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/airport_icon.png" width="20" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/helipad.png" width="20" />
      </th>
      <td>{'Helipad'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/heliport.png" width="20" />
      </th>
      <td>{'Heliport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ferry_terminal.png" width="20" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/aeroway_gate.png" width="20" />
      </th>
      <td>{'Aeroway gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/subway_entrance.png" width="20" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/elevator.png" width="20" />
      </th>
      <td>{'Elevator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tollboth.png" width="20" />
      </th>
      <td>{'Tollboth'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorway_junction.png" width="20" />
      </th>
      <td>{'Motorway junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/rest_area.png" width="20" />
      </th>
      <td>{'Rest area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/service_area.png" width="20" />
      </th>
      <td>{'Service area'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Offices'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/company.png" width="20" />
      </th>
      <td>{'Company, insurance, office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/association.png" width="20" />
      </th>
      <td>{'Association, foundation'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/government.png" width="20" />
      </th>
      <td>{'Government offices'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/employment_agency.png" width="20" />
      </th>
      <td>{'Employment agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/estate_agent.png" width="20" />
      </th>
      <td>{'Estate agent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/lawyer.png" width="20" />
      </th>
      <td>{'Lawyer office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/notary.png" width="20" />
      </th>
      <td>{'Notary public'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ngo.png" width="20" />
      </th>
      <td>{'NGO'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/consulting.png" width="20" />
      </th>
      <td>{'Consulting'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/it.png" width="20" />
      </th>
      <td>{'It office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/financial.png" width="20" />
      </th>
      <td>{'Financial company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/political_party.png" width="20" />
      </th>
      <td>{'Political party'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/telecommunication.png" width="20" />
      </th>
      <td>{'Telecommunication company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/religion.png" width="20" />
      </th>
      <td>{'Religious community'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/research.png" width="20" />
      </th>
      <td>{'Research'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/architect.png" width="20" />
      </th>
      <td>{'Architect'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/accountant.png" width="20" />
      </th>
      <td>{'Accountant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tax_advisor.png" width="20" />
      </th>
      <td>{'Tax advisor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/therapist.png" width="20" />
      </th>
      <td>{'Therapist'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bar.png" width="20" />
      </th>
      <td>{'Bar, pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cafe.png" width="20" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/biergarten.png" width="20" />
      </th>
      <td>{'Beer garden, brewery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fast_food.png" width="20" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/restaurant.png" width="20" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/ice_cream.png" width="20" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Craft'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/craft.png" width="20" />
      </th>
      <td>{'Unspecified craft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/carpenter.png" width="20" />
      </th>
      <td>{'Carpenter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/electrician.png" width="20" />
      </th>
      <td>{'Electrician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hvac.png" width="20" />
      </th>
      <td>{'HVAC'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/photographer.png" width="20" />
      </th>
      <td>{'Photographer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/confectionery.png" width="20" />
      </th>
      <td>{'Confectionery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/winery.png" width="20" />
      </th>
      <td>{'Winery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dressmaker.png" width="20" />
      </th>
      <td>{'Dressmaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tailor.png" width="20" />
      </th>
      <td>{'Tailor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gardener.png" width="20" />
      </th>
      <td>{'Gardener'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/stonemason.png" width="20" />
      </th>
      <td>{'Stonemason'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/plumber.png" width="20" />
      </th>
      <td>{'Plumber'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/handicraft.png" width="20" />
      </th>
      <td>{'Handicraft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shoemaker.png" width="20" />
      </th>
      <td>{'Shoemaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pottery.png" width="20" />
      </th>
      <td>{'Pottery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/metal_construction.png" width="20" />
      </th>
      <td>{'Metal construction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/sawmill.png" width="20" />
      </th>
      <td>{'Sawmill'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shop.png" width="20" />
      </th>
      <td>{'Shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/closed.png" width="14" />
      </th>
      <td>{'Closed shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/alcohol.png" width="20" />
      </th>
      <td>{'Alcohol shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/art.png" width="20" />
      </th>
      <td>{'Art shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bakery.png" width="20" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/beauty.png" width="20" />
      </th>
      <td>{'Beauty salon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bicycle.png" width="20" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/books.png" width="20" />
      </th>
      <td>{'Bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gas_canister.png" width="20" />
      </th>
      <td>{'Bottled gas shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/butcher.png" width="20" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car_parts.png" width="20" />
      </th>
      <td>{'Car parts shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car_repair.png" width="20" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/car.png" width="20" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/clothes.png" width="20" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/coffee.png" width="20" />
      </th>
      <td>{'Coffee shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/computer.png" width="20" />
      </th>
      <td>{'Computer store, Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/convenience.png" width="20" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/copy_shop.png" width="20" />
      </th>
      <td>{'Copy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cosmetics.png" width="20" />
      </th>
      <td>{'Cosmetics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/deli.png" width="20" />
      </th>
      <td>{'Deli'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/department_store.png" width="20" />
      </th>
      <td>{'Department store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/diy.png" width="20" />
      </th>
      <td>{'Do-it-yourself store, hardware store, painter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dry_cleaner.png" width="20" />
      </th>
      <td>{'Dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fishmonger.png" width="20" />
      </th>
      <td>{'Fishmonger'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/florist.png" width="20" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/funeral_directors.png" width="20" />
      </th>
      <td>{'Funeral home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/furniture.png" width="20" />
      </th>
      <td>{'Furniture shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/garden_centre.png" width="20" />
      </th>
      <td>{'Garden centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gift.png" width="20" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/greengrocer.png" width="20" />
      </th>
      <td>{'Greengrocery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hairdresser.png" width="20" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hifi.png" width="20" />
      </th>
      <td>{'Hi-fi shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/trade.png" width="20" />
      </th>
      <td>{'Home improvement store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hunting.png" width="20" />
      </th>
      <td>{'Hunting shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/jewelry.png" width="20" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/laundry.png" width="20" />
      </th>
      <td>{'Laundry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/mall.png" width="20" />
      </th>
      <td>{'Mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/marketplace.png" width="20" />
      </th>
      <td>{'Marketplace'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/mobile_phone.png" width="20" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/motorcycle.png" width="20" />
      </th>
      <td>{'Motorcycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/music.png" width="20" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/newsagent.png" width="20" />
      </th>
      <td>{'Newsagent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/newspaper.png" width="20" />
      </th>
      <td>{'Newspaper'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/optician.png" width="20" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/outdoor.png" width="20" />
      </th>
      <td>{'Outdoor shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/perfumery.png" width="20" />
      </th>
      <td>{'Perfumery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pet.png" width="20" />
      </th>
      <td>{'Pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/photo.png" width="20" />
      </th>
      <td>{'Photography shop, photographic laboratory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shoe.png" width="20" />
      </th>
      <td>{'Shoe shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/sports.png" width="20" />
      </th>
      <td>{'Sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/stationery.png" width="20" />
      </th>
      <td>{'Stationery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/supermarket.png" width="20" />
      </th>
      <td>{'Supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tobacco.png" width="20" />
      </th>
      <td>{'Tobacconist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/toy.png" width="20" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/travel_agency.png" width="20" />
      </th>
      <td>{'Travel agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/tyres.png" width="20" />
      </th>
      <td>{'Tyre shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/variety_store.png" width="20" />
      </th>
      <td>{'Variety store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wine.png" width="20" />
      </th>
      <td>{'Wine shop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/toilets.png" width="20" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/drinking_water.png" width="20" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water_point.png" width="20" />
      </th>
      <td>{'Water point'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/watering_place.png" width="20" />
      </th>
      <td>{'Watering place'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bbq.png" width="20" />
      </th>
      <td>{'Barbecue'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/shower.png" width="20" />
      </th>
      <td>{'Shower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/telephone.png" width="20" />
      </th>
      <td>{'Telephone, emergency phone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/recycling.png" width="20" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/waste_basket.png" width="20" />
      </th>
      <td>{'Wastebasket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/waste_disposal.png" width="20" />
      </th>
      <td>{'Waste container'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bench.png" width="20" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/street_lamp.png" width="20" />
      </th>
      <td>{'Street lamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/defibrillator.png" width="20" />
      </th>
      <td>{'Defibrillator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/fire_hydrant.png" width="20" />
      </th>
      <td>{'Fire hydrant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/gate.png" width="20" />
      </th>
      <td>{'Gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bollard.png" width="20" />
      </th>
      <td>{'Bollard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cycle_barrier.png" width="20" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/border_control.png" width="20" />
      </th>
      <td>{'Border control'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water_well.png" width="20" />
      </th>
      <td>{'Water well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/mast.png" width="20" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/communications_tower.png" width="20" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/telescope.png" width="20" />
      </th>
      <td>{'Radio telescope'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/water_tower.png" width="20" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/watermill.png" width="20" />
      </th>
      <td>{'Watermill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/windmill.png" width="20" />
      </th>
      <td>{'Windmill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/lighthouse.png" width="20" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cross.png" width="9" />
      </th>
      <td>{'Cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/wayside_cross.png" width="20" />
      </th>
      <td>{'Wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generator_icon.png" width="20" />
      </th>
      <td>{'Generator, plant, substation, wastewater plant, waterworks, works'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generator_nuclear.png" width="20" />
      </th>
      <td>{'Nuclear generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generator_solar.png" width="20" />
      </th>
      <td>{'Solar generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generator_wind.png" width="20" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/power_pole.png" width="20" />
      </th>
      <td>{'Power pole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/power_tower.png" width="20" />
      </th>
      <td>{'Transmission tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/pylon.png" width="20" />
      </th>
      <td>{'Pylon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/petroleum_well.png" width="20" />
      </th>
      <td>{'Petroleum well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/lifeguard_tower.png" width="20" />
      </th>
      <td>{'Lifeguard tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/offshore_platform.png" width="20" />
      </th>
      <td>{'Offshore platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/dam.png" width="20" />
      </th>
      <td>{'Dam'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/lock.png" width="20" />
      </th>
      <td>{'Lock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/cemetery_icon.png" width="20" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/quarry.png" width="20" />
      </th>
      <td>{'Quarry, mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/allotments.png" width="20" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/farm.png" width="20" />
      </th>
      <td>{'Farm'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/slaughterhouse.png" width="20" />
      </th>
      <td>{'Slaughterhouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bunker.png" width="20" />
      </th>
      <td>{'Bunker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/airfield.png" width="20" />
      </th>
      <td>{'Airfield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/military.png" width="20" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/danger.png" width="20" />
      </th>
      <td>{'Danger area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/trail_riding_station.png" width="20" />
      </th>
      <td>{'Trail riding station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/trailhead.png" width="20" />
      </th>
      <td>{'Trailhead'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/walking_junction.png" width="20" />
      </th>
      <td>{'Walking junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/generic.png" width="9" />
      </th>
      <td>{'Generic point of interest'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/bike_trail.png" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hike_red.png" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hike_blue.png" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/bubble-wrap/images/legend/hike_green.png" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
