import { useCallback, useEffect } from 'react'

import { User } from 'types/app'
import { useLocalStorage } from 'usehooks-ts'

export const useLocalUserData = () => {
  const [localUser, setLocalUser] = useLocalStorage<User | null>('user', null)
  const removeLocalUser = useCallback(() => {
    setLocalUser(null)
  }, [setLocalUser])

  useEffect(() => {}, [localUser])
  const user = typeof localUser === 'string' ? null : localUser
  return { localUser: user, setLocalUser, removeLocalUser }
}
