import * as React from 'react'
import { SVGProps } from 'react'

export const LegendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" version="1.1" id="svg4" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M 16,4 C 10.478,4 6,8.395 6,13.815 6,19.32 10.375,23.083 16,28 21.625,23.083 26,19.32 26,13.815 26,8.395 21.522,4 16,4 Z m 0,18 c -4.419,0 -8,-3.582 -8,-8 0,-4.418 3.581,-8 8,-8 4.419,0 8,3.582 8,8 0,4.418 -3.581,8 -8,8 z m -2.039,-4.994 c 0.564,-1.792 0.93,-2.343 1.118,-2.887 0.273,-0.788 -0.212,-1.138 -1.739,0.209 L 13,13.688 c 1.743,-1.901 5.334,-2.331 4.113,0.614 -0.764,1.839 -0.606,1.484 -0.918,2.442 -0.455,1.396 0.693,0.83 1.818,-0.211 0.154,0.251 0.203,0.332 0.357,0.62 -2.499,2.382 -5.271,2.592 -4.409,-0.147 z M 18,11.02 c -0.531,0.454 -1.32,0.444 -1.761,-0.022 -0.441,-0.466 -0.367,-1.21 0.164,-1.664 0.532,-0.454 1.319,-0.443 1.761,0.022 0.44,0.465 0.367,1.21 -0.164,1.664 z"
      style={{ fill: 'currentcolor' }}
    />
  </svg>
)
