import { Grid, Stack, Text } from '@mantine/core'
import { TrackStats } from 'hooks/useTrackStats'
import { useTranslation } from 'react-i18next'
import { UnitType } from 'types/app'
import { getSecondsAsHMS } from 'utils/helpers'
import { formatDistance } from 'utils/formatters/formatDistance'
import { formatElevation } from 'utils/formatters/formatElevation'

type StatsItemProps = {
  label: string
  value: string
}
const StatsItem = ({ label, value }: StatsItemProps) => (
  <Stack spacing={0}>
    <Text color="dimmed" size="sm">
      {label}
    </Text>
    <Text>{value}</Text>
  </Stack>
)

type TrackStatsDisplayProps = {
  stats: Partial<TrackStats>
  unitType: UnitType
}

export const TrackStatsDisplay = ({
  stats: { totalLengthKm, totalTime, totalClimb, totalDescend, distanceDownhill, distanceUphill },
  unitType,
}: TrackStatsDisplayProps) => {
  const { t } = useTranslation()
  return (
    <Grid columns={2} p="xs" sx={{ maxWidth: '100%' }}>
      <Grid.Col span={1}>
        <StatsItem
          label={t('track_stats.length_label')}
          value={formatDistance((totalLengthKm ?? 0) * 1000, unitType, 1)}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <StatsItem label={t('track_stats.duration_label')} value={totalTime ? getSecondsAsHMS(totalTime) : 'N/A'} />
      </Grid.Col>
      <Grid.Col span={1}>
        <StatsItem label={t('track_stats.total_climb_label')} value={formatElevation(totalClimb ?? 0, unitType, 1)} />
      </Grid.Col>
      <Grid.Col span={1}>
        <StatsItem
          label={t('track_stats.total_descend_label')}
          value={formatElevation(totalDescend ?? 0, unitType, 1)}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <StatsItem
          label={t('track_stats.distance_uphill_label')}
          value={formatDistance((distanceUphill ?? 0) * 1000, unitType, 1)}
        />
      </Grid.Col>
      <Grid.Col span={1}>
        <StatsItem
          label={t('track_stats.distance_downhill_label')}
          value={formatDistance((distanceDownhill ?? 0) * 1000, unitType, 1)}
        />
      </Grid.Col>
    </Grid>
  )
}
