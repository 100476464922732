import { numToBase64 } from '../numToBase64/numToBase64'

/**
 *
 * @param data Array of numeric data
 * @returns Base64 string of encoded data
 */
export const encodeBase64string = (data: number[]): string => {
  let encodedData = String.fromCharCode(254)

  for (let i = 0; i < data.length; i += 1) {
    encodedData += numToBase64(data[i]!.toFixed(2))
  }

  return encodedData
}
