import { ForwardedRef, useMemo } from 'react'

import { Box, useMantineColorScheme } from '@mantine/core'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
  Legend,
  Tooltip,
  LineController,
  ScatterDataPoint,
  ChartDataset,
} from 'chart.js'
import { Unit } from 'config/enums/units'
import { Chart } from 'react-chartjs-2'
import { rewriteChartTooltipData } from 'stores/genericStore/actions'
import { useGenericDispatch } from 'stores/genericStore/GenericContext'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { Coords, UnitType } from 'types/app'
import { isValidCoords } from 'utils/validators'

import { ChartTooltip, ChartTooltipProps } from './ChartTooltip'
import { VerticalBar } from './VerticalBar'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

ChartJS.register(LineElement, LineController, PointElement, CategoryScale, LinearScale, Filler, Legend, Tooltip)

const CHART_COLORS = {
  fill: 'rgba(54, 162, 235, 0.2)',
  stroke: 'rgba(54, 162, 235, 1)',
  gridDark: 'rgba(255, 255, 255, 0.15)',
  gridLight: 'rgba(0, 0, 0, 0.1)',
}

export type ChartDataPoint = {
  x: number
  y: number | null | undefined
}

export type YAxisTooltipFormatter = ({
  currentIndex,
  prevPoint,
  currentPoint,
}: {
  currentIndex: number
  prevPoint: ScatterDataPoint
  currentPoint: ScatterDataPoint
}) => string

export type XAxisTooltipFormatter = (value: number) => string

type Props = {
  unitType: UnitType
  chartData: ChartDataPoint[]
  dataset: ChartDataset<'line', ChartDataPoint[]>
  chartRef: ForwardedRef<ChartJSOrUndefined<"line", ChartDataPoint[], unknown>>
  xAxisLabelFormatter: (value: number | string) => string
  yAxisLabelFormatter: (value: number | string) => string
  yAxisTooltipFormatter: YAxisTooltipFormatter
  xAxisTooltipFormatter: XAxisTooltipFormatter
  chartTooltipProps: ChartTooltipProps
}

export const TrackChart = ({
  unitType,
  chartData,
  chartRef,
  dataset,
  xAxisLabelFormatter,
  yAxisLabelFormatter,
  yAxisTooltipFormatter,
  xAxisTooltipFormatter,
  chartTooltipProps,
}: Props) => {
  const { colorScheme } = useMantineColorScheme()
  const dispatch = useGenericDispatch()
  const { state: routingState } = useRouting()

  const memoized = useMemo(
    () => (
      <Chart
        ref={chartRef}
        type="line"
        data={{
          datasets: [dataset],
        }}
        options={{
          parsing: false,
          animation: false,
          responsive: false,
          maintainAspectRatio: false,
          interaction: {
            axis: 'x',
            intersect: false,
            mode: 'nearest',
          },
          layout: {
            padding: {
              top: 25,
              bottom: 5,
              left: 10,
              right: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: (context) => {
                const tooltipModel = context.tooltip

                // This could have been done better (first and third line conditionals)
                const index = tooltipModel.dataPoints[0]?.dataIndex || null
                const currentPoint = tooltipModel.dataPoints[0]?.parsed
                const prevPoint = index !== null && index > 0 && tooltipModel.dataPoints[0]?.dataset.data[index - 1]

                const formattedYAxisTooltipLabel = yAxisTooltipFormatter({
                  // @ts-expect-error The type for currentIndex doesn't take into consideration a nil value but that value is possible
                  currentIndex: index,
                  currentPoint,
                  // @ts-expect-error The type for prevPoint doesn't take into consideration a nil value but that value is possible
                  // Also, due to bad conditional prevPoint can also be 'false'
                  prevPoint,
                })

                const formattedXAxisTooltipLabel = xAxisTooltipFormatter(currentPoint.x)

                if (routingState.computedTrackpoints.length === chartData.length) {
                  const cursorPositionIndex = tooltipModel.dataPoints[0]?.dataIndex || 0
                  const cursorPositionCoords = routingState.computedTrackpoints[cursorPositionIndex]

                  if (cursorPositionCoords?.length === 2) {
                    const coords: Coords = [cursorPositionCoords[0], cursorPositionCoords[1]]
                    if (isValidCoords(coords)) {
                      dispatch(
                        rewriteChartTooltipData({
                          position: coords,
                          xAxisString: formattedXAxisTooltipLabel,
                          yAxisString: formattedYAxisTooltipLabel,
                          left: tooltipModel.caretX,
                          top: tooltipModel.caretY,
                          isHovered: tooltipModel.opacity === 1,
                        })
                      )
                    }
                  }
                }
              },
            },
          },
          scales: {
            x: {
              type: 'linear',
              max: chartData.at(-1)?.x,
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                maxTicksLimit: 15,
                callback: xAxisLabelFormatter,
                stepSize: unitType === Unit.imperial ? 1.609344 : 1.0,
              },
              title: {
                display: false,
              },
              grid: {
                color: colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.1)',
              },
            },
            y: {
              ticks: {
                maxTicksLimit: 5,
                callback: yAxisLabelFormatter,
              },
              type: 'linear',
              title: {
                display: false,
              },
              grid: {
                color: colorScheme === 'dark' ? CHART_COLORS.gridDark : CHART_COLORS.gridLight,
              },
            },
          },
        }}
      />
    ),
    [
      chartData,
      chartRef,
      colorScheme,
      dataset,
      dispatch,
      routingState.computedTrackpoints,
      unitType,
      xAxisLabelFormatter,
      xAxisTooltipFormatter,
      yAxisLabelFormatter,
      yAxisTooltipFormatter,
    ]
  )

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {memoized}
      <ChartTooltip {...chartTooltipProps} />
      <VerticalBar />
    </Box>
  )
}
