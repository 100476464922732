import { useCallback, useEffect, useMemo, useRef } from 'react'

import { FaLocationDotPlus } from 'assets/icons/FaLocationDotPlus'
import { Tooltip } from 'components/UI/Tooltip'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

export const AddWaypointIcon = ({ isOpen, isDisabled }: { isOpen: boolean; isDisabled: boolean }) => {
  const { isAddingWaypoint, setIsAddingWaypoint } = useUserWaypoints()
  const isAddingWaypointRef = useRef(isAddingWaypoint)
  const { t } = useTranslation()

  useEffect(() => {
    isAddingWaypointRef.current = isAddingWaypoint
  }, [isAddingWaypoint])

  const handleClick = useCallback(() => {
    setIsAddingWaypoint((prev) => !prev)
  }, [setIsAddingWaypoint])

  return useMemo(
    () => (
      <Tooltip label={t('editmode.add_waypoint_button_tooltip')}>
        <div>
          <CollapsibleTopMenuActionIcon
            active={isAddingWaypoint}
            isOpen={isOpen}
            disabled={isDisabled}
            onClick={handleClick}
          >
            <FaLocationDotPlus />
          </CollapsibleTopMenuActionIcon>
        </div>
      </Tooltip>
    ),
    [handleClick, isAddingWaypoint, isDisabled, isOpen, t]
  )
}
