import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react'

import { faPenToSquare, faRoute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Box,
  Center,
  Checkbox,
  Group,
  Image,
  MantineTheme,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'

const getSelectedBackgroundColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.violet[7], 0.4) : theme.fn.rgba(theme.colors.violet[5], 0.3)

const getHoverSelectedBackgroundColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark'
    ? theme.fn.rgba(theme.fn.lighten(theme.colors.violet[7], 0.2), 0.4)
    : theme.fn.rgba(theme.fn.lighten(theme.colors.violet[5], 0.3), 0.3)

const getHoverUnselectedBackgroundColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark' ? theme.fn.rgba('#FFF', 0.08) : theme.fn.rgba('#000', 0.08)

const getDimmedTextColor = (theme: MantineTheme) =>
  theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7]

const ImageIcon = ({ src }: { src: string }) => (
  <Image
    withPlaceholder
    radius="xs"
    placeholder={<Skeleton width={20} height={20} circle />}
    width={25}
    fit="contain"
    height={30}
    src={src}
  />
)

export type ItemProps = {
  id: number
  index: number
  name: string
  onClick?: (e: MouseEvent, index: number) => void
  onEditButtonClick?: (id: number) => void
  selected: boolean
  bottomInfo?: string
  leftImageSrc?: string
  leftIcon?: ReactNode
  isSelectMode?: boolean
  isMultiselected?: boolean
  trackIconColor?: string
}

export const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      id,
      index,
      name,
      onClick,
      onEditButtonClick,
      selected,
      bottomInfo,
      leftIcon,
      leftImageSrc,
      trackIconColor,
      isSelectMode,
      isMultiselected: isSelectModeSelected,
    },
    ref
  ) => {
    const theme = useMantineTheme()
    const handleEditActionIconClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onEditButtonClick?.(id)
      },
      [id, onEditButtonClick]
    )

    const handleClick = useCallback((e: MouseEvent) => onClick?.(e, index), [index, onClick])

    const LeftSideIcon = useMemo(
      () => (
        <Box sx={{ userSelect: 'none' }}>
          {leftIcon && leftIcon}
          {leftImageSrc && <ImageIcon src={leftImageSrc} />}
          {trackIconColor && <FontAwesomeIcon icon={faRoute} color={trackIconColor} fontSize={20} />}
        </Box>
      ),
      [leftIcon, leftImageSrc, trackIconColor]
    )

    return useMemo(
      () => (
        <Group
          ref={ref}
          onClick={handleClick}
          spacing="sm"
          px={13}
          py={8}
          key={id}
          sx={{
            position: 'relative',
            flexWrap: 'nowrap',
            cursor: 'pointer',
            backgroundColor: selected ? getSelectedBackgroundColor(theme) : 'transparent',
            boxShadow: selected ? 'inset 0 0 5px #00000017' : 'none',
            '&:hover': {
              backgroundColor: selected
                ? getHoverSelectedBackgroundColor(theme)
                : getHoverUnselectedBackgroundColor(theme),
            },
            userSelect: isSelectMode ? 'none' : 'initial',
          }}
        >
          {LeftSideIcon}
          <Stack sx={{ flexGrow: 1 }} spacing={3}>
            <Text
              sx={{
                fontSize: 14,
                flexGrow: 1,
                textOverflow: 'ellipsis',
                overflowX: 'hidden',
                overflowWrap: 'anywhere',
                lineHeight: 1.2,
              }}
              lineClamp={2}
            >
              {name}
            </Text>
            {bottomInfo && (
              <Text
                size="xs"
                color="dimmed"
                sx={{
                  color: selected ? getDimmedTextColor(theme) : undefined,
                  userSelect: isSelectMode ? 'none' : 'initial',
                }}
              >
                {bottomInfo}
              </Text>
            )}
          </Stack>
          {isSelectMode ? (
            <Center sx={{ width: 28, height: 28, flexShrink: 0 }}>
              <Checkbox checked={isSelectModeSelected} onChange={undefined} readOnly />
            </Center>
          ) : (
            <ActionIcon onClick={handleEditActionIconClick} color={theme.primaryColor} variant="transparent">
              <FontAwesomeIcon icon={faPenToSquare} />
            </ActionIcon>
          )}
        </Group>
      ),
      [
        ref,
        handleClick,
        id,
        selected,
        theme,
        isSelectMode,
        LeftSideIcon,
        name,
        bottomInfo,
        isSelectModeSelected,
        handleEditActionIconClick,
      ]
    )
  }
)
Item.displayName = 'Item'
