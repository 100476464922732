import * as React from 'react'
import { SVGProps } from 'react'

export const FaLocationDotPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" height="1em" {...props}>
    <path
      fill="currentcolor"
      d="M192 0C85.96 0 0 85.96 0 192c0 87.4 116.1 243 168.3 307.2 12.3 15.3 35.1 15.3 47.4 0a1824.985 1824.985 0 0 0 23.5-30.079h-55.22v-93.97l66.33.001c.034-21.545.048-43.09.05-64.634v-1.998h94.226v1.566C367.689 266.756 384 224.626 384 192 384 85.96 298 0 192 0zm0 128c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64z"
    />
    <path
      fill="currentcolor"
      d="M307.17 385.667v46.293h-47.544v30.084h47.543v47.727h30.268v-47.727h47.543V431.96h-47.543v-47.724h-30.268v1.431z"
      transform="matrix(1.39616 0 0 1.39616 -152.513 -201.95)"
      aria-label="+"
      style={{
        fontWeight: 700,
        fontSize: '186.53px',
        strokeWidth: 1.07614,
      }}
    />
  </svg>
)
