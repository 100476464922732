import React from 'react'

import { Box, Stack } from '@mantine/core'

import { PlanMenu } from '../../PlanMenu/PlanMenu'
import { SignInPanel } from './SignInPanel'

export const UnauthenticatedUserMenu = () => (
  <Stack style={{ width: '100%', flexGrow: 1, maxHeight: '100%', minHeight: 0 }}>
    <Box p="sm">
      <SignInPanel />
    </Box>
    <PlanMenu />
  </Stack>
)
