import { useMemo } from 'react'

import { Cartesian3, Color } from 'cesium'
import { Entity, PolylineGraphics } from 'resium'
import { hexToRgb } from 'utils/colors/hexToRGB'

const whiteColorMaterial = new Color(1, 1, 1, 0.8)

type Props = {
  positions: Cartesian3[]
  /** HEX color */
  color: string
}

export const TrackPolyline = ({ positions, color }: Props) => {
  const rgb = useMemo(() => hexToRgb(color), [color])
  const trackColor = useMemo(() => new Color((rgb?.r ?? 1) / 255, (rgb?.g ?? 1) / 255, (rgb?.b ?? 1) / 255, 1), [rgb])

  return (
    <>
      <Entity>
        <PolylineGraphics clampToGround={true} positions={positions} width={8} material={whiteColorMaterial} />
      </Entity>
      <Entity>
        <PolylineGraphics clampToGround={true} positions={positions} width={4} material={trackColor} zIndex={10} />
      </Entity>
    </>
  )
}
