import React, { useCallback, useContext, useEffect, useState } from "react";

import { Overlay } from "config/enums/overlays";
import { useAuth } from "hooks/useAuth";

import { OptionsActions, updateSaveableOptions } from "./actions";
import {
  initialOptionsState,
  NonSaveableOptions,
  SaveableOptions,
} from "./state";

type OptionsContextType = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  state: SaveableOptions & NonSaveableOptions;
  dispatch: React.Dispatch<OptionsActions>;
  toggleOverlay: (overlay: Overlay) => void;
  updateSaveable: (
    options: Partial<SaveableOptions>,
    saveToServer?: boolean
  ) => void;
};

export const OptionsContext = React.createContext<OptionsContextType>({
  loading: false,
  setLoading: () => null,
  state: initialOptionsState,
  dispatch: () => undefined,
  toggleOverlay: () => null,
  updateSaveable: () => null,
});

export const useOptions = () => useContext(OptionsContext);

export const OptionsProvider = (props: {
  children: React.ReactNode;
  reducerTuple: [
    SaveableOptions & NonSaveableOptions,
    React.Dispatch<OptionsActions>
  ];
}) => {
  const { children, reducerTuple } = props;
  const [state, dispatch] = reducerTuple;
  const { update, user } = useAuth();
  const [initialUserSettings] = useState(user?.settings);
  const [loading, setLoading] = useState(false);

  const updateSaveable = useCallback(
    (options: Partial<SaveableOptions>, saveToServer: boolean = true) => {
      const mergedOptions: Partial<SaveableOptions> = {
        ...options,
        autoSaveView: options.autoSaveView ?? state.autoSaveView,
        language: options.language ?? state.language,
        mapColor: options.mapColor ?? state.mapColor,
        mapOverlays: options.mapOverlays ?? state.mapOverlays,
        mapType: options.mapType ?? state.mapType,
        units: options.units ?? state.units,
        viewZoom: options.viewZoom ?? state.viewZoom,
        viewCenter: options.viewCenter ?? state.viewCenter,
        mapLabels: options.mapLabels ?? state.mapLabels,
        sortTracks: options.sortTracks ?? state.sortTracks,
        sortWaypoints: options.sortWaypoints ?? state.sortWaypoints,
        colorScheme: options.colorScheme ?? state.colorScheme,
        receiveNewsletter: options.receiveNewsletter ?? state.receiveNewsletter,
      };
      dispatch(updateSaveableOptions(mergedOptions));
      if (saveToServer && user) {
        update(mergedOptions);
      }
    },
    [state, update, user]
  );

  useEffect(() => {
    updateSaveable(
      {
        autoSaveView: initialUserSettings?.autoSaveView,
        language: initialUserSettings?.language,
        mapColor: initialUserSettings?.mapColor,
        mapLabels: initialUserSettings?.mapLabels,
        mapOverlays: initialUserSettings?.mapOverlays,
        mapType: initialUserSettings?.mapType,
        units: initialUserSettings?.units,
        viewCenter: initialUserSettings?.viewCenter,
        viewZoom: initialUserSettings?.viewZoom,
        sortTracks: initialUserSettings?.sortTracks,
        sortWaypoints: initialUserSettings?.sortWaypoints,
        colorScheme: initialUserSettings?.colorScheme,
      },
      false
    );
  }, [initialUserSettings]);

  const toggleOverlay = useCallback(
    (clickedOverlay: Overlay) => {
      const { mapOverlays: overlayType, ...rest } = state;
      if (overlayType.includes(clickedOverlay)) {
        const filteredOverlays = state.mapOverlays.filter(
          (overlay) => overlay !== clickedOverlay
        );
        return updateSaveable(
          { ...state, mapOverlays: filteredOverlays },
          true
        );
      }
      return updateSaveable(
        { ...rest, mapOverlays: [...overlayType, clickedOverlay] },
        true
      );
    },
    [state, updateSaveable]
  );

  const memoizedValue = React.useMemo(
    () => ({
      loading,
      setLoading,
      state,
      dispatch,
      updateSaveable,
      toggleOverlay,
    }),
    [state, toggleOverlay, updateSaveable, loading, setLoading]
  );

  return (
    <OptionsContext.Provider value={memoizedValue}>
      {children}
    </OptionsContext.Provider>
  );
};
