import { useCallback } from 'react'

import { REFILL_COLOR_PREVIEWS } from 'assets/refillColorPreviews'
import { MapLayerPickerPart } from 'components/LeafletMap/MapControls/MapLayerPicker'
import { getMapColors, MapColor } from 'config/enums/mapColors'
import { useImagePreload } from 'hooks/useImagePreload'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'

export const MapColorPicker = () => {
  const { state, updateSaveable } = useOptions()
  useImagePreload(Object.values(REFILL_COLOR_PREVIEWS))
  const { t } = useTranslation()
  const handleColorSelect = useCallback(
    (value: MapColor) => {
      updateSaveable({ mapColor: value })
    },
    [updateSaveable]
  )

  return (
    <MapLayerPickerPart title={t('map_layer_picker.color_type_heading')}>
      <MapLayerPickerPart.Grid>
        {getMapColors(t).map(({ label, value }, i) => {
          const imageSrc = REFILL_COLOR_PREVIEWS[value]
          return (
            <MapLayerPickerPart.Card
              key={i}
              label={label}
              value={value}
              imageSrc={imageSrc}
              onClick={handleColorSelect}
              selected={value === state.mapColor}
            />
          )
        })}
      </MapLayerPickerPart.Grid>
    </MapLayerPickerPart>
  )
}
