import { forwardRef } from 'react'

import { Group, Text } from '@mantine/core'
import { SelectOption } from 'utils/chart/yAxisOptions'
import './getSelectItem.css'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: SelectOption
  label: string
  icon: string
}

// eslint-disable-next-line react/display-name
export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, icon, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div className="top-selected-item">
        <span className="top-selected-item-icon">{icon}</span>
        <Text className="top-selected-item-text" size="sm">
          {label}
        </Text>
      </div>
    </Group>
  </div>
))
