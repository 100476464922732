import { useCallback, useState } from 'react'

import { faEnvelope, faMessage, faRightFromBracket, faSliders } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, MediaQuery, Menu, ScrollArea, useMantineTheme } from '@mantine/core'
import { UserButton } from 'components/UI/LeftMenu/UserButton'
import { FEEDBACK_EMAIL } from 'config/constants'
import { useAuth } from 'hooks/useAuth'
import { useGenericModals } from 'hooks/useGenericModals'
import { useTranslation } from 'react-i18next'
import { getMailToLink } from 'utils/helpers/getMailToLink'

import { DownloadTrackbook } from './MenuItems/DownloadTrackbook'
import { ImportGPXMenuItem } from './MenuItems/ImportGPXMenuItem'
import { TabMenu } from './TabMenu'

const ITEM_HEIGHT = 34
export const USER_MENU_ICON_WIDTH = 15

export const AuthenticatedUserMenu = () => {
  const { user, signOut } = useAuth()
  const theme = useMantineTheme()
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()
  const { openSettingsModal } = useGenericModals()

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSignOutClick = () => {
    signOut()
    close()
  }

  const handleContactUsClick = () => {
    window.location.href = getMailToLink(FEEDBACK_EMAIL, 'Trackbook feedback')
    close()
  }

  const handleSendFeedbackClick = () => {
    close()
    window.open('https://forms.gle/TruHuHm1f1JXBbxNA', '_blank')
  }

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ padding: 4 }}>
        <Box p="xs">
          <Menu
            closeOnItemClick={false}
            closeOnClickOutside
            onChange={setIsOpen}
            styles={{
              itemIcon: { color: theme.colors.gray[6], width: USER_MENU_ICON_WIDTH },
              item: { width: '100%', height: ITEM_HEIGHT },
              itemRightSection: { marginLeft: '0.5em' },
            }}
            transition="fade"
            position="bottom-end"
            opened={isOpen}
            shadow="lg"
          >
            <Menu.Target>
              <UserButton email={user?.email || ''} />
            </Menu.Target>

            <Menu.Dropdown>
              <ScrollArea
                sx={{ maxWidth: '100%' }}
                styles={{
                  thumb: {
                    '&:before': { minWidth: 0 },
                  },
                }}
              >
                <Box sx={{ overflow: 'hidden' }}>
                  <Menu.Item onClick={handleContactUsClick} icon={<FontAwesomeIcon icon={faEnvelope} />}>
                    {t('dropdown_menu.contact_us_label')}
                  </Menu.Item>

                  <Menu.Item onClick={handleSendFeedbackClick} icon={<FontAwesomeIcon icon={faMessage} />}>
                    {t('dropdown_menu.send_feedback_label')}
                  </Menu.Item>
                  <Menu.Divider />
                  <DownloadTrackbook iconWidth={USER_MENU_ICON_WIDTH} />
                  <Menu.Divider />
                  <ImportGPXMenuItem />
                  <Menu.Item component="div" onClick={openSettingsModal} icon={<FontAwesomeIcon icon={faSliders} />}>
                    {t('dropdown_menu.account_settings_button_label')}
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item onClick={handleSignOutClick} icon={<FontAwesomeIcon icon={faRightFromBracket} />}>
                    {t('dropdown_menu.logout_label')}
                  </Menu.Item>
                </Box>
              </ScrollArea>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </MediaQuery>

      <TabMenu />
    </>
  )
}
