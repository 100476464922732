import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'

import { EditMode } from 'types/app'

interface EditModeInterface {
  editMode: EditMode
  isSaved: boolean
  setEditMode: Dispatch<SetStateAction<EditMode>>
  setIsSaved: Dispatch<SetStateAction<boolean>>
}

const EditModeContext = React.createContext<EditModeInterface>({
  editMode: 'default',
  isSaved: false,
  setEditMode: () => null,
  setIsSaved: () => null,
})

export const useProvideEditMode = (): EditModeInterface => {
  const [editMode, setEditMode] = useState<EditMode>('default')
  const [isSaved, setIsSaved] = useState(false)

  return { editMode, isSaved, setEditMode, setIsSaved }
}

export const useEditMode = () => useContext(EditModeContext)

export const EditModeProvider = ({ children }: { children: ReactNode }) => {
  const editMode = useProvideEditMode()
  return <EditModeContext.Provider value={editMode}>{children}</EditModeContext.Provider>
}
