import * as React from 'react'
import { SVGProps } from 'react'

export const PUBicon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="100%" height="100%" viewBox="0 0 24.892 24.596" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      style={{
        display: 'inline',
      }}
    >
      <g
        style={{
          fontSize: 64,
          letterSpacing: '-2.64538px',
          wordSpacing: 0,
          whiteSpace: 'pre',
          display: 'inline',
          fill: 'currentcolor',
        }}
      >
        <path
          d="M32.574 58.898v12.414h5.62q3.12 0 4.825-1.615 1.703-1.616 1.703-4.603 0-2.966-1.703-4.58-1.704-1.616-4.824-1.616zm-4.47-3.673h10.09q5.555 0 8.387 2.523 2.856 2.5 2.856 7.346 0 4.89-2.856 7.39-2.832 2.501-8.386 2.501h-5.621v13.277h-4.47zm24.572 0h4.492v20.07q0 5.311 1.925 7.657 1.925 2.323 6.24 2.323 4.293 0 6.219-2.323 1.924-2.346 1.924-7.656V55.225h4.493v20.623q0 6.462-3.209 9.759-3.186 3.297-9.427 3.297-6.262 0-9.47-3.297-3.187-3.297-3.187-9.758zm35.902 17.26V84.59h7.169q3.607 0 5.333-1.483 1.747-1.504 1.747-4.58 0-3.098-1.747-4.559-1.726-1.482-5.333-1.482zm0-13.587v9.958h6.616q3.274 0 4.868-1.217 1.615-1.24 1.615-3.762 0-2.5-1.615-3.74-1.594-1.239-4.868-1.239zm-4.47-3.673h11.418q5.11 0 7.877 2.124 2.766 2.125 2.766 6.042 0 3.03-1.416 4.823-1.417 1.793-4.16 2.235 3.297.708 5.112 2.965 1.836 2.235 1.836 5.6 0 4.425-3.009 6.836-3.01 2.412-8.564 2.412h-11.86Z"
          style={{
            fontSize: '53.3333px',
            display: 'inline',
            fill: 'currentcolor',
            strokeWidth: 0.849728,
          }}
          transform="translate(-.516 -1.475) scale(.19112)"
          aria-label="PUB"
        />
      </g>
    </g>
  </svg>
)
