import { useState } from 'react'

import { Slider } from '@mantine/core'
import { MapLayerPickerPart } from 'components/LeafletMap/MapControls/MapLayerPicker'
import { useTranslation } from 'react-i18next'
import { useOptions } from 'stores/optionsStore/OptionsContext'

type Props = {
  setIgnoreClicks: (value: boolean) => void
}

export const LabelCountSlider = ({ setIgnoreClicks }: Props) => {
  const {
    updateSaveable,
    state: { mapLabels },
  } = useOptions()
  const [sliderValue, setSliderValue] = useState(mapLabels)
  const { t } = useTranslation()

  const handleChangeEnd = (value: number) => {
    updateSaveable({ mapLabels: value })
    setIgnoreClicks(false)
  }

  const handleChange = (value: number) => {
    setIgnoreClicks(true)
    setSliderValue(value)
  }

  return (
    <MapLayerPickerPart title={t('label_count_slider.title')}>
      <Slider
        mb="lg"
        thumbSize={22}
        onChange={handleChange}
        value={sliderValue}
        onChangeEnd={handleChangeEnd}
        size="sm"
        radius="sm"
        step={5}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '0%' },
          { value: 25, label: '25%' },
          { value: 50, label: '50%' },
          { value: 75, label: '75%' },
          { value: 100, label: '100%' },
        ]}
        label={(value) => `${value}%`}
        styles={(theme) => ({
          track: {
            '&:before': {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[5],
            },
          },
          mark: {
            border: 'none',
            width: '12px',
            height: '12px',
            position: 'relative',
            left: '-3px',
            top: '-3px',
          },
          markLabel: {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
          },
        })}
      />
    </MapLayerPickerPart>
  )
}
