import { useState } from 'react'

import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIconProps } from '@mantine/core'
import { LeafletControlWrapperProps } from 'components/LeafletMap/Utils/LeafletControlWrapper'
import { useMap, useMapEvents } from 'react-leaflet'

import { ControlActionIcon } from '../ControlActionIcon'
import { MergedControl } from '../MergedControl/MergedControl'

const ZoomActionIcon = ({ children, ...rest }: ActionIconProps & React.ComponentPropsWithoutRef<'button'>) => (
  <ControlActionIcon sx={{ '&:disabled': { cursor: 'default' } }} {...rest}>
    {children}
  </ControlActionIcon>
)

type Props = {
  position: LeafletControlWrapperProps['position']
}
export const CustomZoomControl = ({ position }: Props) => {
  const map = useMap()
  const [currentZoom, setCurrentZoom] = useState(map.getZoom())
  useMapEvents({
    zoomend: () => setCurrentZoom(map.getZoom()),
  })
  const isMaxZoom = currentZoom >= map.getMaxZoom()
  const isMinZoom = currentZoom <= map.getMinZoom()

  return (
    <MergedControl position={position}>
      <ZoomActionIcon onClick={() => map.zoomIn()} disabled={isMaxZoom}>
        <FontAwesomeIcon icon={faPlus} />
      </ZoomActionIcon>
      <ZoomActionIcon onClick={() => map.zoomOut()} disabled={isMinZoom}>
        <FontAwesomeIcon icon={faMinus} />
      </ZoomActionIcon>
    </MergedControl>
  )
}
