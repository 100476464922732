import { defaultIcon } from 'components/LeafletMap/Icons'
import L from 'leaflet'

import { getWaypointIconAnchor } from './getWaypointIconAnchor'
import { getWaypointIconSize } from './getWaypointIconSize'
import { getWaypointIconPopupAnchor } from './getWaypointsPopupAnchor'

export const getIcon = (name: string) => {
  if (name === 'map_icon_default') return defaultIcon

  return L.icon({
    iconUrl: `img/map_icons/${name}.png`,
    iconSize: getWaypointIconSize(name),
    iconAnchor: getWaypointIconAnchor(name),
    popupAnchor: getWaypointIconPopupAnchor(name),
  })
}
