import { useCallback } from 'react'

import { useMap } from 'react-leaflet'
import { useSearchParams } from 'react-router-dom'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { getClosestValidZoom } from 'utils/mapSettings/getClosestValidZoom'
import { isValidLat, isValidLng } from 'utils/validators'

const getLat = (searchParams: URLSearchParams) => {
  const rawLat = searchParams.get('lat')
  if (!rawLat) return null
  const lat = Number(rawLat)
  return isValidLat(lat) ? lat : null
}

const getLng = (searchParams: URLSearchParams) => {
  const rawLng = searchParams.get('lng')
  if (!rawLng) return null
  const lng = Number(rawLng)
  return isValidLng(lng) ? lng : null
}

const getZoom = (searchParams: URLSearchParams, minZoom: number, maxZoom: number) => {
  const rawZoom = searchParams.get('z')
  if (!rawZoom) return null
  return getClosestValidZoom(Number(rawZoom), minZoom, maxZoom)
}

export const useMapQueryParams = () => {
  const map = useMap()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    state: { mapType: mapLayerID },
  } = useOptions()

  const updateUrlParams = useCallback(() => {
    const zoom = map.getZoom()
    const { lat, lng } = map.getCenter()

    setSearchParams(
      {
        lat: lat.toFixed(6),
        lng: lng.toFixed(6),
        z: zoom.toFixed(2),
        basemap: String(mapLayerID),
      },
      { replace: true }
    )
  }, [map, mapLayerID, setSearchParams])

  return {
    lat: getLat(searchParams),
    lng: getLng(searchParams),
    zoom: getZoom(searchParams, map.getMinZoom(), map.getMaxZoom()),
    basemap: Number(searchParams.get('basemap')),
    updateParams: updateUrlParams,
  }
}
