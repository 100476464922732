import React from 'react'

import { Paper, Text } from '@mantine/core'
import { LeafletControlWrapper, LeafletControlWrapperProps } from 'components/LeafletMap/Utils/LeafletControlWrapper'

import { MouseCoordinateValueDisplay } from './MouseCoordinateValueDisplay'

const DARK_BG_COLOR = 'rgba(0, 0, 0, 0.8)'
const LIGHT_BG_COLOR = 'rgba(255,255,255,.8)'

type Props = {
  position: LeafletControlWrapperProps['position']
}
/**
 * Displays current mouse position coordinates
 */
export const MouseCoordinateControl = ({ position }: Props) => (
  <LeafletControlWrapper position={position}>
    <Paper
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? DARK_BG_COLOR : LIGHT_BG_COLOR,
        cursor: 'pointer',
      })}
    >
      <Text size="xs" py={3} px={4} mr={4}>
        <MouseCoordinateValueDisplay />
      </Text>
    </Paper>
  </LeafletControlWrapper>
)
