 
import { Coords, GridLayer as LeafletGridLayer, TileLayerOptions } from 'leaflet'

import {
  IndexedDbTileCache,
  DEFAULT_CRAWL_DELAY,
  DEFAULT_DATABASE_NAME,
  DEFAULT_DATABASE_VERSION,
  DEFAULT_MAX_AGE,
  DEFAULT_PROVIDER_NAME,
  DEFAULT_TILE_URL_SUB_DOMAINS,
  DEFAULT_TILE_URL,
} from '../indexed-db-tile-cache'

/**
 * Interface for the tile layer options. It is a mixin of the original Leaflet `TileLayerOptions` and the
 * `IndexedDbTileCacheOptions` of the YAGA Development Team.
 */
export interface ICachedTileLayerOptions extends TileLayerOptions {
  /**
   * Name of the database
   *
   * The default value is equal to the constance DEFAULT_DATABASE_NAME
   * @default "tile-cache-data"
   */
  databaseName: string
  /**
   * Version of the IndexedDB store. Should not be changed normally! But can provide an "upgradeneeded" event from
   * IndexedDB.
   *
   * The default value is equal to the constance DEFAULT_DATABASE_VERSION
   * @default 1
   */
  databaseVersion?: number
  /**
   * Name of the current tile provider. Should correspond with the name of the tile server
   *
   * The default value is equal to the constance DEFAULT_OBJECT_STORE_NAME
   * @default "OpenStreetMap";
   */
  provider: string
  /**
   * The delay in milliseconds used for not stressing the tile server while seeding.
   *
   * The default value is equal to the constance DEFAULT_CRAWL_DELAY
   * @default 500
   */
  crawlDelay?: number
  /**
   * The maximum age in milliseconds of a stored tile.
   *
   * The default value is equal to the constance DEFAULT_MAX_AGE
   * @default 1000 * 60 * 60 * 24 * 7
   */
  maxAge?: number
}

export const CachedLeafletTileLayer = LeafletGridLayer.extend({
  options: {
    databaseName: DEFAULT_DATABASE_NAME,
    databaseVersion: DEFAULT_DATABASE_VERSION,
    provider: DEFAULT_PROVIDER_NAME,
    crawlDelay: DEFAULT_CRAWL_DELAY,
    maxAge: DEFAULT_MAX_AGE,
    tileUrl: DEFAULT_TILE_URL,
    tileUrlSubDomains: DEFAULT_TILE_URL_SUB_DOMAINS,
  },
  initialize(options: ICachedTileLayerOptions) {
    L.setOptions(this, options)
  },
  createTile(coords: Coords, done: (error: string | null, tile: HTMLImageElement) => void) {
    const tile = document.createElement('img')

    if (this.options.crossOrigin) {
      tile.crossOrigin = 'anonymous'
    }

    tile.alt = ''
    tile.setAttribute('role', 'presentation')

    const tileCache = new IndexedDbTileCache({
      crawlDelay: this.options.crawlDelay,
      databaseName: this.options.databaseName,
      databaseVersion: this.options.databaseVersion,
      maxAge: this.options.maxAge,
      provider: this.options.provider,
      tileUrl: this.options.url,
      tileUrlSubDomains: this.options.subdomains as string[],
    })

    tileCache
      .getTileAsDataUrl({
        x: coords.x,
        y: coords.y,
        z: coords.z,
      })
      .then((dataUrl) => {
        tile.src = dataUrl
        done(null, tile)
      })
      .catch((error: Error | DOMException | null) => {
        tile.src = this.options.errorTileUrl ?? ''
        done(error?.toString() ?? 'Unknown tile error', tile)
      })

    return tile
  },
})
