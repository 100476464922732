import { useCallback, useEffect, useRef } from 'react'

import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { Waypoint } from 'types/app'

import { WaypointMarker } from '../../components/LeafletMap/WaypointMarker'

export const UserWaypointsContainer = () => {
  const { waypoints, selectedWaypoint, selectWaypoint: selectUserWaypoint, updateWaypoint } = useUserWaypoints()
  const theme = useMantineTheme()
  const { colorScheme } = theme
  const {
    state: { isLeftMenuOpen },
    dispatch,
  } = useOptions()
  const isSmallViewport = useMediaQuery('(max-width: 768px)')
  const closureBypassFn = useRef<() => void>()

  useEffect(() => {
    const popupWrappers = document.querySelectorAll<HTMLElement>('.leaflet-popup-content-wrapper')
    const popupTips = document.querySelectorAll<HTMLElement>('.leaflet-popup-tip')
    popupWrappers.forEach((popupWrapper) => {
      popupWrapper.style.backgroundColor = colorScheme === 'dark' ? theme.colors.dark[7] : 'white'
      popupWrapper.style.color = colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7]
    })
    popupTips.forEach((popupTip) => {
      popupTip.style.backgroundColor = colorScheme === 'dark' ? theme.colors.dark[7] : 'white'
      popupTip.style.color = colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7]
    })
  })

  // We need to create a function like this because if we would put it in a useCallback, that
  // would be a closure and it's context would stay the same from the first render
  closureBypassFn.current = () => {
    if (isLeftMenuOpen && isSmallViewport) {
      dispatch(updateNonSaveableOptions({ isLeftMenuOpen: false }))
    }
  }

  const selectWaypoint = useCallback(
    (waypointId: Waypoint['id'] | null) => {
      if (closureBypassFn.current) closureBypassFn.current()
      selectUserWaypoint(waypointId)
    },
    [closureBypassFn, selectUserWaypoint]
  )

  return (
    <>
      {waypoints &&
        waypoints.map((waypoint) => {
          const selected = waypoint.id === selectedWaypoint?.id
          return (
            <WaypointMarker
              key={waypoint.id}
              waypoint={waypoint}
              selected={selected}
              selectWaypoint={selectWaypoint}
              updateWaypoint={updateWaypoint}
            />
          )
        })}
    </>
  )
}
