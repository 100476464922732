import { DEFAULT_MAP_ID } from "config/constants";
import { Overlay } from "config/enums/overlays";
import { Routing } from "config/enums/routings";
import { SaveableOptions } from "stores/optionsStore/state";

export interface UserSettings extends SaveableOptions {
  routing: Routing;
}

export const parseSettingsToObj = (settingsString: string) => {
  if (!settingsString) return null;
  const parsedJson: UserSettings = JSON.parse(settingsString);
  const { mapType, mapOverlays, ...rest } = parsedJson;

  const validMapOverlays = mapOverlays
    ? mapOverlays.reduce<Overlay[]>((accumulator, overlay) => {
        if (Object.values(Overlay).includes(overlay))
          return [...accumulator, overlay];
        return accumulator;
      }, [])
    : undefined;

  const settings: Partial<UserSettings> = {
    mapType: typeof mapType === "number" ? mapType : DEFAULT_MAP_ID,
    mapOverlays: validMapOverlays,
    ...rest,
  };
  return settings;
};

export const parseSettingsToString = ({
  mapOverlays: overlay,
  viewCenter: viewBounds,
  viewZoom: zoom,
  ...rest
}: Partial<UserSettings>) => {
  const settings: Partial<UserSettings> = {
    viewCenter: viewBounds,
    viewZoom: zoom,
    mapOverlays: overlay,
    ...rest,
  };
  return JSON.stringify(settings);
};
