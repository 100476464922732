import { useMemo } from 'react'

import { HotlineControl } from 'components/LeafletMap/TrackpointControls/HotlineControl'
import { NonRoutingControl } from 'components/LeafletMap/TrackpointControls/NonRoutingControl'
import { RoutingControl } from 'components/LeafletMap/TrackpointControls/RoutingControl'
import { INTERACTION_SETTINGS } from 'config/constants'
import { Routing } from 'config/enums/routings'
import { useActionTimeout } from 'hooks/useActionTimeout'
import { useEditMode } from 'hooks/useEditMode'
import { useHotkeys } from 'react-hotkeys-hook'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'

export const RoutingContainer = () => {
  const actionTimeout = useActionTimeout(INTERACTION_SETTINGS.markerActionTimeout)
  const { state, history } = useRouting()
  const { editMode } = useEditMode()
  const {
    state: { hotlineType },
  } = useOptions()

  useHotkeys('ctrl+z', () => history.undo())
  useHotkeys('ctrl+y', () => history.redo())

  return useMemo(
    () =>
      editMode !== 'measurement' ? (
        <>
          {hotlineType !== 'none' && <HotlineControl />}
          {state.routing !== Routing.none ? (
            <RoutingControl actionTimeout={actionTimeout} />
          ) : (
            <NonRoutingControl actionTimeout={actionTimeout} />
          )}
        </>
      ) : null,
    [actionTimeout, editMode, hotlineType, state.routing]
  )
}
