import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTrackStats } from 'hooks/useTrackStats'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { Coords } from 'types/app'

import { HotlinePolyline } from '../Hotline'

const mergeDataForHotline = (
  trackpoints: Coords[],
  valuesControllingColor: (number | null)[]
) => {
  if (trackpoints.length === 0 || valuesControllingColor.length === 0) return null
  if (trackpoints.length !== valuesControllingColor.length) return null
  return trackpoints.map((coords, index) => {
    const value = valuesControllingColor[index]
    return [...coords, value] as HotlineDataPoint
  })
}

export type HotlineDataType = 'none' | 'elevation' | 'heartRate' | 'cadence' | 'power' | 'speed' | 'temperature'
type HotlineDataPoint = [...Coords, number | null]

type HotlineData = {
  datapoints: HotlineDataPoint[]
  min: number | null
  max: number | null
}

export const HotlineControl = () => {
  const {
    state: { computedTrackpoints, elevations },
  } = useRouting()
  const { stats } = useTrackStats()
  const [hotlineData, setHotlineData] = useState<HotlineData | null>(null)
  const {
    state: { hotlineType },
  } = useOptions()

  const getHotlineData = useCallback(() => {
    if (hotlineType === 'elevation') {
      const data = mergeDataForHotline(computedTrackpoints, elevations)
      return data
    }

    const chartData = stats?.chartData
    if (!chartData) return null

    if (hotlineType === 'none') return null

    if (hotlineType === 'heartRate') {
      const heartRates = chartData.map((dataPoint) => dataPoint.yAxis.heartRate)
      return mergeDataForHotline(computedTrackpoints, heartRates)
    }
    if (hotlineType === 'cadence') {
      const cadences = chartData.map((dataPoint) => dataPoint.yAxis.cadence)
      return mergeDataForHotline(computedTrackpoints, cadences)
    }
    if (hotlineType === 'power') {
      const powers = chartData.map((dataPoint) => dataPoint.yAxis.power)
      return mergeDataForHotline(computedTrackpoints, powers)
    }
    if (hotlineType === 'speed') {
      const speeds = chartData.map((dataPoint) => dataPoint.yAxis.speed)
      return mergeDataForHotline(computedTrackpoints, speeds)
    }
    if (hotlineType === 'temperature') {
      const temperatures = chartData.map((dataPoint) => dataPoint.yAxis.temperature)
      return mergeDataForHotline(computedTrackpoints, temperatures)
    }

    return null
  }, [computedTrackpoints, elevations, stats?.chartData, hotlineType])

  useEffect(() => {
    const data = getHotlineData()
    if (!data) return setHotlineData(null)
    if (data.every((datapoint) => datapoint[2] === null)) return setHotlineData(null)

    const max = Math.max(...data.map((datapoint) => datapoint[2] ?? Number.MIN_VALUE))
    const min = Math.min(...data.map((datapoint) => datapoint[2] ?? Number.MAX_VALUE))

    // IMPORTANT: min and max values CANNOT be the same (HotlinePolyline would throw an error)
    const validMax = max !== min ? max : max + 1

    return setHotlineData({ datapoints: data, min, max: validMax })
  }, [getHotlineData])

  return useMemo(
    () =>
      hotlineData ? (
        <HotlinePolyline
          positions={hotlineData.datapoints}
          weight={4}
          outlineWidth={0}
          interactive={false}
          min={hotlineData.min}
          max={hotlineData.max}
        />
      ) : null,
    [hotlineData]
  )
}
