import React, { ReactNode, useContext, useState } from 'react'

import { Map } from 'leaflet'

interface MapContextInterface {
  map: Map | null
  setMap: (map: Map | null) => void
}

const MapContext = React.createContext<MapContextInterface>({
  map: null,
  setMap: () => null,
})

export const useProvideMapContext = (): MapContextInterface => {
  const [map, setMap] = useState<Map | null>(null)
  return { map, setMap }
}

export const useMapContext = () => useContext(MapContext)

export const MapContextProvider = ({ children }: { children: ReactNode }) => {
  const mapcontext = useProvideMapContext()
  return <MapContext.Provider value={mapcontext}>{children}</MapContext.Provider>
}
