import { useState } from 'react'

import axios from 'axios'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { useAuth } from './useAuth'

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL ?? ''

export const useGPXupload = () => {
  const [isUploading, setIsUploading] = useState(false)
  const { user } = useAuth()
  const {
    state: { language },
  } = useOptions()

  const upload = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!user) throw new Error('Not signed in')
      const reader = new FileReader()
      reader.onload = async (e) => {
        const contents = e.target?.result
        if (!contents) throw new Error('File empty')
        try {
          const { data } = await axios.post(
            `${BASE_API_URL}/import-gpx`,
            {
              gpx: contents,
              agent: 'Trackbook',
              lng: language,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          )
          if (data.msg) {
            setIsUploading(false)
            resolve(data.msg as string)
          }
          reject(new Error('No data returned from server'))
        } catch (error) {
          setIsUploading(false)
          reject(error)
        }
      }
      reader.readAsText(file)
    })

  return { upload, isUploading }
}
