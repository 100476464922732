export enum Language {
  'ar' = 'ar',
  'cs' = 'cs',
  'en' = 'en',
  'fr' = 'fr',
  'es' = 'es',
  'bn' = 'bn',
  'de' = 'de',
  'gr' = 'gr',
  'hi' = 'hi',
  'hu' = 'hu',
  'id' = 'id',
  'it' = 'it',
  'ja' = 'ja',
  'ko' = 'ko',
  'nl' = 'nl',
  'pl' = 'pl',
  'pt' = 'pt',
  'pt_BR' = 'pt_BR',
  'ru' = 'ru',
  'sk' = 'sk',
  'tr' = 'tr',
  'uk' = 'uk',
  'vi' = 'vi',
  'zh_CN' = 'zh_CN',
  'zh_TW' = 'zh_TW',
}

export const LANGUAGES = [
  { label: 'Česky', value: Language.cs, flagSrc: 'https://dl.vecturagames.com/icons/flags/cz.png' },
  { label: 'Deutsch', value: Language.de, flagSrc: 'https://dl.vecturagames.com/icons/flags/de.png' },
  { label: 'Dutch', value: Language.nl, flagSrc: 'https://dl.vecturagames.com/icons/flags/nl.png' },
  { label: 'English', value: Language.en, flagSrc: 'https://dl.vecturagames.com/icons/flags/us.png' },
  { label: 'Français', value: Language.fr, flagSrc: 'https://dl.vecturagames.com/icons/flags/fr.png' },
  { label: 'Español', value: Language.es, flagSrc: 'https://dl.vecturagames.com/icons/flags/es.png' },
  { label: 'Italiano', value: Language.it, flagSrc: 'https://dl.vecturagames.com/icons/flags/it.png' },
  { label: 'Magyar', value: Language.hu, flagSrc: 'https://dl.vecturagames.com/icons/flags/hu.png' },
  { label: 'Polish', value: Language.pl, flagSrc: 'https://dl.vecturagames.com/icons/flags/pl.png' },
  { label: 'Português (Brasil)', value: Language.pt_BR, flagSrc: 'https://dl.vecturagames.com/icons/flags/br.png' },
  { label: 'Slovenčina', value: Language.sk, flagSrc: 'https://dl.vecturagames.com/icons/flags/sk.png' },
  { label: 'Русский', value: Language.ru, flagSrc: 'https://dl.vecturagames.com/icons/flags/ru.png' },
  { label: 'Türkçe', value: Language.tr, flagSrc: 'https://dl.vecturagames.com/icons/flags/tr.png' },
  { label: 'Український', value: Language.uk, flagSrc: 'https://dl.vecturagames.com/icons/flags/ua.png' },
]
