import { Box } from '@mantine/core'
import { useGenericState } from 'stores/genericStore/GenericContext'

export const VerticalBar = () => {
  const { chartTooltipData } = useGenericState()

  return chartTooltipData?.isHovered ? (
    <div style={{ position: 'absolute', left: chartTooltipData.left, top: 0, bottom: 0 }}>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 26,
          bottom: 34,
          borderLeftStyle: 'dashed',
          borderLeftWidth: 1,
          borderLeftColor: theme.colors.gray[7],
          pointerEvents: 'none',
        })}
      ></Box>
    </div>
  ) : null
}
