import { Polyline, PolylineProps } from 'react-leaflet'

export const MeasurementPolyline = ({ positions, ...rest }: PolylineProps) => (
  <>
    <Polyline
      interactive={false}
      positions={positions}
      pathOptions={{ color: 'white', opacity: 1, weight: 5 }}
      {...rest}
    />
    <Polyline interactive={false} positions={positions} pathOptions={{ color: '#F06595', opacity: 1, weight: 3 }} />
  </>
)
