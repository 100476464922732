import { faRuler } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mantine/core'
import { CollapsibleTopMenuActionIcon } from 'components/UI/TopMenu/CollapsibleActionIcon'
import { useEditMode } from 'hooks/useEditMode'
import { useTranslation } from 'react-i18next'

export const MeasurementModeIcon = ({ isOpen, isDisabled }: { isOpen: boolean; isDisabled: boolean }) => {
  const { editMode, setEditMode } = useEditMode()
  const { t } = useTranslation()

  const handleClick = () => {
    if (editMode === 'measurement') {
      setEditMode('default')
    } else {
      setEditMode('measurement')
    }
  }

  return (
    <Tooltip label={t('map_controls.measurement_button_tooltip')}>
      <div>
        <CollapsibleTopMenuActionIcon
          isOpen={isOpen}
          disabled={isDisabled}
          onClick={handleClick}
          active={editMode === 'measurement'}
        >
          <FontAwesomeIcon icon={faRuler} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  )
}
