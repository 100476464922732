import i18n from 'utils/i18n/i18n'

import { showSuccessNotification } from './customNotifications'

type Props = {
  name?: string
  count?: number
}
export const showTrackExportSuccessNotification = ({ name, count }: Props) => {
  if (!name && !count) throw new Error('Export success notification requires either count or name')
  const title = i18n.t('notifications.track.successful_export.title')
  const message = count
    ? i18n.t('notifications.track.successful_export.with_count.message', {
        tracksCount: count,
      })
    : i18n.t('notifications.track.successful_export.with_name.message', {
        name,
      })
  showSuccessNotification({
    message,
    title,
  })
}

export const showTrackDeletionSuccessNotification = ({ name, count }: Props) => {
  if (!name && !count) throw new Error('Delete success notification requires either count or name')
  const title = i18n.t('notifications.track.successful_delete.title')
  const message = count
    ? i18n.t('notifications.track.successful_delete.with_count.message', {
        tracksCount: count,
      })
    : i18n.t('notifications.track.successful_delete.with_name.message', {
        name,
      })
  showSuccessNotification({
    message,
    title,
  })
}
