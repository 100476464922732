import { Cartesian3 } from 'cesium'
import { useGenericState } from 'stores/genericStore/GenericContext'

import { CesiumMarker } from './CesiumMarker'

export const ChartMarkerCesium = () => {
  const { chartTooltipData: data } = useGenericState()
  const coords = data?.position

  const cartesian3Position = coords ? Cartesian3.fromDegrees(coords[1], coords[0], 0) : null
  return cartesian3Position && data?.isHovered ? (
    <CesiumMarker position={cartesian3Position} type="chartPosition" />
  ) : null
}
