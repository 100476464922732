type ResponseError = {
  response?: {
    data?: {
      msg: string;
    };
  };
};

// Type guard for our 'ResponseError'
function isResponseError(error: unknown): error is ResponseError {
  if (error) {
    if (Object.hasOwn(error, "response")) {
      // Sometimes all of this 'checking' stuff can really get in the way
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore We can safely ignore this since we have a check in place
      const response = error.response as ResponseError["response"];
      if (response && Object.hasOwn(response, "data")) {
        // @ts-ignore We can safely ignore this since we have a check in place
        const data = response.data as ResponseError["response"]["data"]
        if (data) {
          return true
        }
      }
    }
  }
  return false
}

export const getErrorMsg = (error: unknown) => {
  if (isResponseError(error)) return error.response?.data?.msg;
  if (error instanceof Error) return error.message;
  return null;
};
