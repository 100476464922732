import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, DefaultMantineColor, Menu, SegmentedControl, Select, useMantineTheme } from '@mantine/core'
import { Routing } from 'config/enums/routings'
import { useTranslation } from 'react-i18next'
import { ChartXAxisType, ChartYAxisType } from 'types/app'
import { getYAxisOptions } from 'utils/chart/yAxisOptions'
import { SelectItem } from 'utils/helpers/getSelectItem/getSelectItem'

type Props = {
  routing: Routing
  xAxisType: ChartXAxisType
  yAxisType: ChartYAxisType
  setXAxisType: (value: ChartXAxisType) => void
  setYAxisType: (value: ChartYAxisType) => void
  getYAxis: () => ChartYAxisType[] | []
  getTooltipYAxisIcon: (color?: DefaultMantineColor) => JSX.Element
  disabledXAxisToggle: boolean
}

export const ChartPanelMenu = ({
  xAxisType,
  yAxisType,
  setXAxisType,
  setYAxisType,
  getYAxis,
  getTooltipYAxisIcon,
  disabledXAxisToggle,
}: Props) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  return (
    <Menu
      shadow="md"
      position="top"
      withinPortal
      closeOnItemClick={false}
      styles={{
        item: {
          gap: 10,
          paddingTop: 5,
          paddingBottom: 5,
          '&[data-hovered]': { backgroundColor: 'transparent' },
          cursor: 'default',
        },
        itemLabel: { whiteSpace: 'nowrap' },
        itemRightSection: { width: 150, display: 'flex', justifyContent: 'flex-start' },
      }}
    >
      <Menu.Target>
        <ActionIcon
          variant="light"
          color={theme.primaryColor}
          sx={{ position: 'absolute', width: 20, height: 20, top: 24, right: 16, zIndex: 100 }}
        >
          {!yAxisType ? <FontAwesomeIcon icon={faChartLine} /> : getTooltipYAxisIcon(theme.primaryColor)}
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component="div"
          rightSection={
            <Select
              size="xs"
              icon={getTooltipYAxisIcon()}
              itemComponent={SelectItem}
              data={getYAxisOptions(t, getYAxis())}
              value={yAxisType}
              onChange={setYAxisType}
              styles={{ dropdown: { minWidth: 'max-content' } }}
            />
          }
        >
          {t('chart_options_menu.y_axis_type.label')}
        </Menu.Item>
        <Menu.Item
          component="div"
          rightSection={
            <SegmentedControl
              size="xs"
              data={[
                { label: t('chart_options_menu.x_axis_type.distance'), value: 'distance' },
                { label: t('chart_options_menu.x_axis_type.time'), value: 'time' },
              ]}
              onChange={(value: ChartXAxisType) => {
                if (!disabledXAxisToggle) setXAxisType(value)
              }}
              value={xAxisType}
              disabled={disabledXAxisToggle}
            />
          }
        >
          {t('chart_options_menu.x_axis_type.label')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
