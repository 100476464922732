import { Coords, Waypoint } from 'types/app'
import { parseWaypointForAPI } from 'utils/helpers/waypointParser/waypointParser'

import { encodeTracksToGPX } from './encodeTracksToGPX'
import { encodeWaypointsToGPX } from './encodeWaypointsToGPX'

type EncodeGPXparams = {
  waypoints?: Waypoint[] | null
  tracks?: {
    trackPoints?: Coords[]
    trackPointsEle?: number[]
    description?: string
    name?: string
    links?: string[]
  }[]
}

export const encodeGPX = ({ waypoints, tracks }: EncodeGPXparams) => {
  const result = ['<?xml version="1.0" encoding="UTF-8"?>']
  result.push(
    `<gpx version="1.1" creator="Trackbook - ${window.location.origin}" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">`
  )
  if (waypoints) {
    const parsed = waypoints.map((waypoint) => parseWaypointForAPI(waypoint))
    result.push(encodeWaypointsToGPX(parsed))
  }
  if (tracks) {
    tracks.forEach(({ trackPoints, trackPointsEle, description, links, name }) => {
      if (trackPoints && trackPointsEle && trackPoints.length > 0) {
        result.push(encodeTracksToGPX(trackPoints, trackPointsEle, name || 'Track', description, links))
      }
    })
  }
  result.push('</gpx>')

  return result.join('\n')
}
