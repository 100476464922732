import { useMemo } from 'react'

import { createStyles } from '@mantine/core'
import { LatLng } from 'leaflet'
import * as L from 'leaflet'
import { Marker } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { formatDistance } from 'utils/formatters/formatDistance'

export type SegmentData = {
  center: LatLng
  distance: number
  angleFromNorth: number
}

type Props = {
  segmentData: SegmentData[]
}

const useStyles = createStyles((theme) => ({
  centerPointIcon: {
    color: theme.colors.gray[9],
    display: 'flex!important',
    justifyContent: 'center!important',
    alignItems: 'center!important',
    '& > *': {
      textAlign: 'center',
      fontWeight: 600,
      textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;',
    },
  },
}))

export const SegmentMarkers = ({ segmentData }: Props) => {
  const { classes } = useStyles()
  const {
    state: { units },
  } = useOptions()
  return useMemo(
    () => (
      <>
        {segmentData.map((segment, index) => {
          const baseTextAngle = segment.angleFromNorth - 90
          const limitedTextAngle = baseTextAngle > 90 && baseTextAngle < 270 ? baseTextAngle - 180 : baseTextAngle

          const icon = L.divIcon({
            html: `<div style="transform:rotate(${limitedTextAngle}deg)">${formatDistance(
              segment.distance,
              units,
              3
            )}</div>`,
            className: classes.centerPointIcon,
            iconSize: [100, 20],
            iconAnchor: [50, 10],
          })
          return <Marker interactive={false} key={index} position={segment.center} icon={icon} />
        })}
      </>
    ),
    [classes.centerPointIcon, segmentData, units]
  )
}
