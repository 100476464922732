import { LANGUAGES } from 'config/enums/languages'

import { getBrowserLanguage } from './getBrowserLanguage'
import { getLocalStorageLanguage } from './getLocalStorageLanguage'

const isSupportedLanguage = (langCode: string) =>
  LANGUAGES.some((language) => language.value.toLowerCase() === langCode)

export const getAutomaticLanguage = () => {
  const localStorageLanguage = getLocalStorageLanguage()
  if (localStorageLanguage) return localStorageLanguage

  const browserLanguage = getBrowserLanguage()

  if (browserLanguage && isSupportedLanguage(browserLanguage)) {
    return browserLanguage
  }

  return null
}
