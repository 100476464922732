import { faGlobe, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Group, Stack, Text, TextInput } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type Props = {
  onEdit: (links: string[]) => void
  links: string[]
  disabled?: boolean
}
export const LinksEditor = ({ onEdit, links, disabled = false }: Props) => {
  const { t } = useTranslation()

  const handleEdit = (index: number, value: string) => {
    const updatedLinks = [...links]
    updatedLinks[index] = value
    onEdit(updatedLinks)
  }

  const handleDelete = (index: number) => {
    const updatedLinks = links.filter((_, i) => i !== index)
    onEdit(updatedLinks)
  }

  const handleAddEmpty = () => {
    onEdit([...links, ''])
  }

  const fixLink = (link: string) => {
    if (!link.startsWith('file:///') && !link.startsWith('http://') && !link.startsWith('https://')) {
      return `//${link}`
    }
    return link
  }

  return (
    <div>
      <Group mb={4} spacing={6}>
        <Text size="sm">{t('links_editor.label')}</Text>
        <ActionIcon
          size="xs"
          color="primaryColor"
          variant="filled"
          radius={30}
          onClick={handleAddEmpty}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlus} fontSize={14} />
        </ActionIcon>
      </Group>
      <Stack>
        {links.map((link, index) => (
          <Group key={index} spacing="xs">
            <TextInput
              sx={{ flexGrow: 1 }}
              value={link}
              onChange={(e) => handleEdit(index, e.currentTarget.value)}
              data-lpignore="true"
              placeholder="https://example.com"
              disabled={disabled}
            />
            <a href={fixLink(link)} target="blank">
              <ActionIcon tabIndex={1} variant="filled" color="primaryColor" disabled={disabled}>
                <FontAwesomeIcon icon={faGlobe} fontSize={14} />
              </ActionIcon>
            </a>
            <ActionIcon
              tabIndex={1}
              variant="filled"
              color="red"
              onClick={() => handleDelete(index)}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrashAlt} fontSize={14} />
            </ActionIcon>
          </Group>
        ))}
      </Stack>
    </div>
  )
}
