import { Coords } from 'types/app'

export const isValidLat = (lat: number) => {
  if (lat > 90 || lat < -90) return false
  return true
}

export const isValidLng = (lng: number) => {
  if (lng > 180 || lng < -180) return false
  return true
}
/**
 * Validates if coordinates are in bounds and in correct format
 * Lat (-90, 90)
 * Lng (-180, 180)
 *
 * @param coords Coords in [lat, lng] format
 * @returns
 */
export const isValidCoords = ([lat, lng]: Coords): boolean => isValidLat(lat) && isValidLng(lng)
