import * as React from 'react'
import { SVGProps } from 'react'

export const StartMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 182.415 253.984" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M104.369 20.239c-50.35 0-91.171 41.653-91.171 93.037 0 5.853.595 11.56 1.61 17.116a93.681 93.681 0 0 0 13.75 34.547l.814 1.174 74.73 108.098 75.604-108.573.34-.486a93.736 93.736 0 0 0 14.242-37.149c.747-4.808 1.252-9.707 1.252-14.727 0-51.384-40.82-93.037-91.171-93.037"
      fill="#05cb25"
      style={{
        strokeWidth: 6.07805,
      }}
      transform="translate(-13.125 -20.239)"
    />
    <path
      d="M104.296 20.251c-50.35 0-91.171 41.653-91.171 93.037 0 5.853.596 11.56 1.61 17.116a93.681 93.681 0 0 0 13.75 34.548l.814 1.173 74.73 108.098 75.61-108.572.335-.487a93.736 93.736 0 0 0 14.24-37.149c.754-4.808 1.253-9.706 1.253-14.727 0-51.384-40.82-93.037-91.171-93.037m0 6.51c46.68 0 84.661 38.814 84.661 86.527 0 4.164-.389 8.65-1.173 13.724-1.975 12.612-6.437 24.252-13.153 34.445l-.334.474-70.244 100.865L34.666 162.43l-.73-1.057a86.649 86.649 0 0 1-12.794-32.153 89.335 89.335 0 0 1-1.501-15.925c0-47.719 37.981-86.533 84.655-86.533"
      fill="#231f20"
      opacity={0.15}
      style={{
        strokeWidth: 6.07805,
      }}
      transform="translate(-13.125 -20.239)"
    />
  </svg>
)

export const MidMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 204.295 284.549" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M105.239 3.115C48.826 3.115 3.091 49.783 3.091 107.353c0 6.558.668 12.952 1.805 19.177A104.96 104.96 0 0 0 20.3 165.237l.912 1.314 83.727 121.113 84.708-121.644.381-.545a105.021 105.021 0 0 0 15.956-41.622c.837-5.387 1.402-10.875 1.402-16.5 0-57.57-45.734-104.239-102.147-104.239"
      fill="#682bcb"
      style={{
        strokeWidth: 6.80983,
      }}
      transform="translate(-3.091 -3.114)"
    />
    <path
      d="M105.157 3.128C48.745 3.128 3.01 49.796 3.01 107.367c0 6.557.667 12.952 1.804 19.176a104.96 104.96 0 0 0 15.404 38.707l.912 1.314 83.727 121.114 84.715-121.645.374-.545a105.021 105.021 0 0 0 15.956-41.621c.844-5.387 1.403-10.876 1.403-16.5 0-57.571-45.735-104.239-102.148-104.239m0 7.293c52.3 0 94.854 43.488 94.854 96.945 0 4.665-.435 9.69-1.314 15.377-2.213 14.13-7.212 27.171-14.737 38.591l-.374.532-78.702 113.009-77.74-112.451-.818-1.185a97.081 97.081 0 0 1-14.335-36.024 100.091 100.091 0 0 1-1.682-17.842c0-53.464 42.555-96.952 94.848-96.952"
      fill="#231f20"
      opacity={0.15}
      style={{
        strokeWidth: 6.80983,
      }}
      transform="translate(-3.091 -3.114)"
    />
  </svg>
)

export const EndMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 141.511 197.032" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M91.037 38.273c-39.06 0-70.727 32.313-70.727 72.174 0 4.541.462 8.969 1.25 13.278a72.674 72.674 0 0 0 10.665 26.801l.632.91 57.972 83.86 58.652-84.227.264-.378a72.717 72.717 0 0 0 11.048-28.819c.58-3.73.97-7.53.97-11.425 0-39.861-31.666-72.174-70.726-72.174"
      fill="#ef9227"
      style={{
        strokeWidth: 4.71513,
      }}
      transform="translate(-20.253 -38.273)"
    />
    <path
      d="M90.98 38.282c-39.06 0-70.727 32.313-70.727 72.175 0 4.54.462 8.968 1.25 13.278a72.674 72.674 0 0 0 10.665 26.8l.632.911 57.972 83.859 58.657-84.227.26-.378a72.717 72.717 0 0 0 11.047-28.818c.584-3.73.97-7.53.97-11.425 0-39.862-31.666-72.175-70.726-72.175m0 5.05c36.212 0 65.677 30.111 65.677 67.125 0 3.23-.302 6.71-.91 10.647-1.532 9.783-4.993 18.813-10.203 26.72l-.26.367-54.493 78.248-53.828-77.861-.565-.82a67.219 67.219 0 0 1-9.926-24.943 69.303 69.303 0 0 1-1.164-12.353c0-37.019 29.465-67.13 65.672-67.13"
      fill="#231f20"
      opacity={0.15}
      style={{
        strokeWidth: 4.71513,
      }}
      transform="translate(-20.253 -38.273)"
    />
  </svg>
)
