export enum AuthAPIErrors {
  EMAIL_NOT_VERIFIED = "login_error_email_not_verified",
  NO_ACCOUNT = "common_error_no_account",
  INCORRECT_PASSWORD = "login_error_password_incorrect",
}

export type ChartXAxisType = "distance" | "time";
export type ChartYAxisType =
  | "elevation"
  | "heartRate"
  | "cadence"
  | "power"
  | "speed"
  | "temperature"
  | "time";

declare module "*.jpg";
declare module "*.png";
declare module "*.jpeg";
declare module "*.gif";

import { BicycleType, Routing } from "config/enums/routings";
import { UserSettings } from "utils/helpers";

/**
 * Coords in [lat, lng] format
 */
export type Coords = [number, number];

export type UnitType = "metric" | "imperial";

export interface RoutingAPI {
  trip: {
    locations: {
      type: string;
      lat: number;
      lon: number;
      city: string;
      original_index: number;
    }[];
    legs: {
      maneuvers: {
        type: number;
        instruction: string;
        verbal_transition_alert_instruction: string;
        verbal_pre_transition_instruction: string;
        verbal_post_transition_instruction: string;
        street_names: string[];
        time: number;
        length: number;
        cost: number;
        begin_shape_index: number;
        end_shape_index: number;
        travel_mode: string;
        travel_type: string;
      }[];
      shape: string;
    }[];
  };
}

export type TrackRaw = {
  cmt: string;
  createdAt: string;
  number: string;
  src: string;
  trackPointsCadence: string;
  trackPointsHeartRate: string;
  trackPointsPower: string;
  trackPointsSpeed: string;
  trackPointsTemperature: string;
  trackPointsTime: string;
  type: string;
  updatedAt: string;
  userId: number;
  uuid: string;
  pub: boolean; // api returns boolean, but updating has to be done via 'true' | 'false' strings (api is designed this way)
  id: number;
  name: string;
  desc: string;
  links: string;
  controlPoints: string;
  trackPoints: string;
  trackPointsEle: string;
  color: string;
  routing: string;
};

export type TrackPointsTime = {
  startTimestamp: number;
  startPointIndex: number | undefined;
  datapoints: number[];
};

export type Track = {
  id: number;
  name: string;
  desc: string;
  links: string[];
  controlPoints: Coords[];
  trackPoints: Coords[];
  trackPointsEle: number[];
  trackPointsCadence: number[] | null;
  trackPointsHeartRate: number[] | null;
  trackPointsPower: number[] | null;
  trackPointsSpeed: number[] | undefined;
  trackPointsTemperature: number[] | null;
  trackPointsTime: TrackPointsTime | null;
  color: string;
  routing: Routing;
  pub: boolean;
  userId: number;
  uuid: string;
  cmt: string;
  type: string;
  number: string;
  src: string;
};

export type SavingStatus = "saved" | "saving" | "unsaved";
export type EditMode = "default" | "track" | "waypoint" | "measurement";

export type WaypointRaw = {
  id: number;
  userId: number;
  name: string;
  desc: string;
  links: string;
  point: string;
  ele: string;
  localTime: string;
  icon: string;
  createdAt: Date;
  updatedAt: Date;
  cmt: string;
  src: string;
  type: string;
};

export type Waypoint = {
  id: number;
  userId: number;
  name: string;
  desc: string;
  links: string[];
  point: Coords;
  ele: number;
  localTime: string;
  icon: string | null;
  createdAt: Date;
  updatedAt: Date;
  cmt: string;
  src: string;
  type: string;
};

export interface TrackWithMetadata extends Track {
  meta?: { length?: number };
}

export type User = {
  email: string;
  settings: Partial<UserSettings> | null;
  id: number | string;
};

export interface BicycleCostingOptions {
  [key: string]: string;
  bicycleType: BicycleType;
  useHills: "0" | "0.5" | "1";
}

export interface RoutingCostingOptions {
  // Possibility to add other costing options later
  [Routing.bicycle]: BicycleCostingOptions;
}

// Source https://www.geodev.me/blog/deeppartial-in-typescript - useful for payloads that allow partial object merging
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
