import { MapID } from 'config/enums/mapIDs'

import Basic from './basic.png'
import BubbleWrap from './bubbleWrap.png'
import Cinnabar from './cinnabar.png'
import Refill from './refill.png'
import Street from './street.png'
import Topo from './topo.png'
import Tron from './tron.png'
import Walkabout from './walkabout.png'

export const BIKETRAILS_OVERLAY_PREVIEWS = {
  [MapID.basic]: Basic,
  [MapID.bubbleWrap]: BubbleWrap,
  [MapID.cinnabar]: Cinnabar,
  [MapID.refill]: Refill,
  [MapID.street]: Street,
  [MapID.topo]: Topo,
  [MapID.tron]: Tron,
  [MapID.walkabout]: Walkabout,
}
