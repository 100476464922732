import { useEffect, useState } from 'react'

import { LeafletMouseEvent } from 'leaflet'
import { useMap } from 'react-leaflet'
import { Coords } from 'types/app'
import { formatCoords } from 'utils/formatters/formatCoords'

export const MouseCoordinateValueDisplay = () => {
  const [coords, setCoords] = useState<Coords>([0, 0])
  const map = useMap()

  useEffect(() => {
    if (!map) return undefined

    map.addEventListener('mousemove', (e: LeafletMouseEvent) => {
      setCoords([e.latlng.lat, e.latlng.lng])
    })

    return () => {
      map.removeEventListener('mousemove')
    }
  }, [map])

  return <>{formatCoords(coords)}</>
}
