/**
 * @param coordinate Single coordinate value (either Lat or Lng)
 * @param factor
 * @returns Encoded coordinate value
 */
export const encodeSingleCoord = (coordinate: number, factor: number): string => {
  const roundedCoord = Math.round(coordinate * factor)
  let shiftedCoord = roundedCoord << 1
  if (shiftedCoord < 0) {
    shiftedCoord = ~shiftedCoord
  }
  let output = ''
  while (shiftedCoord >= 0x20) {
    output += String.fromCharCode((0x20 | (shiftedCoord & 0x1f)) + 63)
    shiftedCoord >>= 5
  }
  output += String.fromCharCode(shiftedCoord + 63)
  return output
}
