import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import { TRACK_COLORS } from 'config/constants'
import { Routing } from 'config/enums/routings'
import equal from 'fast-deep-equal'
import { useTranslation } from 'react-i18next'
import { setCesiumMode } from 'stores/genericStore/actions'
import { useGenericDispatch } from 'stores/genericStore/GenericContext'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { rewriteComputedTrackpoints, rewriteTrackmeta, rewriteTrackWaypoints } from 'stores/routingStore/actions'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { SavingStatus, TrackWithMetadata, TrackRaw, Track } from 'types/app'
import { getUrlsIfAreValidImages } from 'utils/api/checkIfUrlIsImage'
import { parseTrackForAPI, parseTrackFromAPI } from 'utils/helpers/trackParser/trackParser'
import { showTrackDeletionSuccessNotification } from 'utils/notifications/trackNotifications'
import { getTracksWithMetadata } from 'utils/tracks/getTracksWithMetadata'

import { useAuth } from './useAuth'
import { useEditMode } from './useEditMode'
import { useGenericModals } from './useGenericModals'
import { useMapContext } from './useMapContext'

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL

const DEFAULT_NEW_TRACK_DATA: Partial<Track> = {
  id: -1,
  name: 'Track',
  desc: '',
  links: [],
  routing: Routing.auto,
  pub: false,
  trackPointsEle: [],
}

export interface CreateTrackInterface extends Omit<Partial<Track>, 'id' | 'userId' | 'uuid'> {}
export interface UpdateTrackInterface extends Partial<CreateTrackInterface> {}

interface UserTrackData {
  tracks: TrackWithMetadata[] | null
  selectedTrack: TrackWithMetadata | null
  selectTrack: (id: number | null) => void
  updateTrack: (id: number, updatedData: UpdateTrackInterface) => Promise<unknown>
  deleteTrack: (id: number | number[]) => Promise<unknown>
  // TODO (peter): add elevation data to create track
  createTrack: (data: CreateTrackInterface) => Promise<unknown>
  isFetching: boolean
  fetchTracks: () => Promise<unknown>
  trackStatus: SavingStatus
  getImagesFromLinks: () => Promise<string[]>
  getSelectedTrackImages: () => Promise<string[]>
}

const UserTrackContext = React.createContext<UserTrackData>({
  tracks: null,
  selectedTrack: null,
  selectTrack: () => null,
  updateTrack: async () => null,
  deleteTrack: async () => null,
  createTrack: async () => null,
  isFetching: false,
  fetchTracks: async () => null,
  trackStatus: 'unsaved',
  getImagesFromLinks: async () => [],
  getSelectedTrackImages: async () => [],
})

export const useProvideUserTrackData = (): UserTrackData => {
  const [isFetching, setIsFetching] = useState(false)
  const [tracks, setTracks] = useState<TrackWithMetadata[] | null>(null)
  const [selectedTrackId, setSelectedTrackId] = useState<number | null>(null)
  const [trackStatus, setTrackStatus] = useState<SavingStatus>('unsaved')
  const { openConfirmLosingTrackModal, openErrorModal } = useGenericModals()
  const genericDispatch = useGenericDispatch()
  const { editMode, setEditMode } = useEditMode()
  const shouldSelectLastTrack = useRef(false)
  const {
    dispatch,
    history: { clearHistory },
    setEditedTrack,
  } = useRouting()
  const {
    state: { language },
  } = useOptions()
  const { map } = useMapContext()
  const { user } = useAuth()
  const { t } = useTranslation()
  const tracksRef = useRef(tracks)
  const selectedTrackIdRef = useRef(selectedTrackId)
  const editModeRef = useRef(editMode)
  useEffect(() => {
    tracksRef.current = tracks
  }, [tracks])
  useEffect(() => {
    selectedTrackIdRef.current = selectedTrackId
  }, [selectedTrackId])
  useEffect(() => {
    editModeRef.current = editMode
  }, [editMode])

  const reset = () => {
    shouldSelectLastTrack.current = false
    setIsFetching(false)
    setTracks(null)
    setSelectedTrackId(null)
    tracksRef.current = null
    selectedTrackIdRef.current = null
    setTrackStatus('unsaved')
  }

  useEffect(() => {
    if (!user) reset()
  }, [user])

  const fetchTracks = useCallback(async () => {
    if (!user) return Promise.reject(new Error('not_signed_in'))
    try {
      const { data } = await axios.post(
        `${BASE_API_URL}/get-tracks`,
        {
          agent: 'Trackbook',
          lng: language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      if (!equal(tracksRef.current, data.tracks)) {
        const rawTracks = data.tracks as TrackRaw[]
        const decodedTracks = rawTracks.map((track) => parseTrackFromAPI(track))
        const tracksWithMeta = getTracksWithMetadata(decodedTracks)
        setTracks(tracksWithMeta)
      }
      return data.tracks
    } catch (error) {
      setTracks(null)
      return console.error('error getting tracks')
    }
  }, [language, user])

  const selectTrack = useCallback(
    (id: number | null) => {
      genericDispatch(setCesiumMode(false))
      if (id === null) {
        setSelectedTrackId(null)
        setEditedTrack(null)
        return
      }
      if (!tracksRef.current) throw new Error('Tracks empty')
      const foundTrack = tracksRef.current.find((track) => track.id === id)
      if (!foundTrack) throw new Error('Track not found')

      const select = () => {
        dispatch(
          rewriteTrackmeta({
            totalDistance: undefined,
            trackPointsSpeed: undefined,
            trackPointsTime: undefined,
            totalTime: undefined,
          })
        )
        setSelectedTrackId(foundTrack.id)
        setEditedTrack(foundTrack, map)
        setEditMode('track')
        setTrackStatus('saved')
      }

      if (editModeRef.current === 'track' && !selectedTrackIdRef.current) {
        openConfirmLosingTrackModal({ onConfirm: select })
        return
      }
      select()
    },
    [dispatch, genericDispatch, map, openConfirmLosingTrackModal, setEditMode, setEditedTrack]
  )

  const updateTrack = useCallback(
    async (id: number, newData: UpdateTrackInterface) => {
      if (!user) return new Error('not_signed_in')
      setTrackStatus('saving')
      const originalTrackData =
        id === -1 ? DEFAULT_NEW_TRACK_DATA : { ...tracksRef.current?.find((value) => value.id === id) }

      const updatedTrackData = { ...originalTrackData, ...newData }
      const parsedForAPI = parseTrackForAPI(updatedTrackData)

      try {
        await axios.post(
          `${BASE_API_URL}/update-track`,
          {
            ...parsedForAPI,
            agent: 'Trackbook',
            lng: language,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        )
        await fetchTracks()
        setTrackStatus('saved')
        return null
      } catch (error) {
        setTrackStatus('unsaved')
        return error
      }
    },
    [fetchTracks, language, user]
  )

  const deleteTrack = useCallback(
    async (id: number | number[]) => {
      try {
        let singleDeletedTrackName
        if (typeof id === 'number' || id.length === 1) {
          const currentId = typeof id === 'number' ? id : id[0]
          const waypointToBeDeleted = tracks?.find((track) => track.id === currentId)
          singleDeletedTrackName = waypointToBeDeleted?.name
        }

        await axios.post(
          `${BASE_API_URL}/delete-track`,
          {
            id: typeof id === 'number' ? id : undefined,
            ids: Array.isArray(id) ? id : undefined,
            agent: 'Trackbook',
            lng: language,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        )
        clearHistory()
        dispatch([rewriteTrackWaypoints([]), rewriteComputedTrackpoints([])], false)
        setSelectedTrackId(null)

        if (singleDeletedTrackName) {
          showTrackDeletionSuccessNotification({ name: singleDeletedTrackName })
        } else if (Array.isArray(id)) {
          showTrackDeletionSuccessNotification({ count: id.length })
        }
        fetchTracks()
      } catch (error) {
        openErrorModal({
          title: t('generic.error_title'),
          text: t('errors.error_deleting_track.text'),
        })
      }
    },
    [clearHistory, dispatch, fetchTracks, language, openErrorModal, t, tracks]
  )

  const createTrack = useCallback(
    async ({ color, routing, ...rest }: CreateTrackInterface) => {
      const fallbackColor = routing === Routing.none ? TRACK_COLORS.nonRoutable : TRACK_COLORS.routable
      const newData = {
        routing,
        color: color ?? fallbackColor,
        src: 'Trackbook',
        ...rest,
      }
      shouldSelectLastTrack.current = true
      await updateTrack(-1, newData)
      // API doesn't return anything when creating new track, so there's no simple way of selecting the new track based on the ID or sth
      // As a not so safe workaround, let's select the last one in the list
    },
    [updateTrack]
  )

  useEffect(() => {
    const lastTrack = tracks?.[tracks.length - 1]
    if (lastTrack && shouldSelectLastTrack.current) {
      setSelectedTrackId(lastTrack.id)
      shouldSelectLastTrack.current = false
    }
  }, [tracks])

  useEffect(() => {
    if (!user) return
    const fetch = async () => {
      setIsFetching(true)
      await fetchTracks()
      setIsFetching(false)
    }
    fetch()
  }, [user, fetchTracks])

  const selectedTrack = useMemo(() => {
    const foundTrack = tracks?.find((value) => value.id === selectedTrackId)
    // return a new copy to prevent accident original array mutation
    return foundTrack ? { ...foundTrack } : null
  }, [selectedTrackId, tracks])

  /**
   * Returns a list of images that are present in links in all waypoints
   */
  const getImagesFromLinks = async () => {
    const allLinks = tracks?.reduce<string[]>((links, waypoint) => [...links, ...waypoint.links], [])
    if (!allLinks || allLinks.length === 0) return []
    const imageUrls = await getUrlsIfAreValidImages(allLinks)
    return imageUrls
  }

  const getSelectedTrackImages = useCallback(async () => {
    if (!selectedTrack || selectedTrack.links.length < 1) return []
    const images = await getUrlsIfAreValidImages(selectedTrack.links)
    return images
  }, [selectedTrack])

  return {
    tracks,
    fetchTracks,
    selectedTrack,
    selectTrack,
    updateTrack,
    deleteTrack,
    createTrack,
    isFetching,
    trackStatus,
    getImagesFromLinks,
    getSelectedTrackImages,
  }
}

export const useUserTracks = () => useContext(UserTrackContext)

export const UserTracksDataProvider = ({ children }: { children: ReactNode }) => {
  const userTrackData = useProvideUserTrackData()
  return <UserTrackContext.Provider value={userTrackData}>{children}</UserTrackContext.Provider>
}
