import { Fragment, useMemo } from 'react'

import { useMantineTheme } from '@mantine/core'
import { CircleMarker } from 'react-leaflet'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { Coords } from 'types/app'
import { formatDistance } from 'utils/formatters/formatDistance'

import { LabelMarker } from './LabelMarker'

export type PointData = {
  distanceFromPrevious: number | null
  totalDistance: number
  point: Coords
}

type Props = {
  pointsData: PointData[]
}

export const GuideMarkers = ({ pointsData }: Props) => {
  const theme = useMantineTheme()
  const MEASUREMENT_MAIN_COLOR = theme.colors.pink[5]
  const {
    state: { units },
  } = useOptions()

  return useMemo(
    () => (
      <>
        {pointsData.map((pointData, index) => {
          const distance = pointData.totalDistance ? formatDistance(pointData.totalDistance, units, 3) : null

          return (
            <Fragment key={index}>
              <CircleMarker
                interactive={false}
                radius={9}
                center={pointData.point}
                pathOptions={{ fillColor: MEASUREMENT_MAIN_COLOR, color: 'white', weight: 1, fillOpacity: 1 }}
              />
              {distance && <LabelMarker interactive={false} position={pointData.point} text={distance} />}
            </Fragment>
          )
        })}
      </>
    ),
    [MEASUREMENT_MAIN_COLOR, pointsData, units]
  )
}
