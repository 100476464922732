import { BASE64_CHAR_SET } from 'config/constants'

export const numToBase64 = (num: number | string) => {
  let n = num
  if (typeof n !== 'number') {
    n = parseFloat(n)
  }

  n = Math.floor(n * 100) + 2000000

  let base64 = ''

  for (let i = 0; i < 4; i += 1) {
    const remainder = n % 64
    n = Math.floor(n / 64)

    base64 = BASE64_CHAR_SET.charAt(remainder) + base64
  }

  while (base64.length < 4) {
    base64 = `A${base64}`
  }

  return base64
}
