import { MapColor } from 'config/enums/mapColors'

import Black from './black.png'
import BlueGray from './blue-gray.png'
import Blue from './blue.png'
import BrownOrange from './brown-orange.png'
import GrayGold from './gray-gold.png'
import Gray from './gray.png'
import HighContrast from './high-contrast.png'
import InvertedDark from './inverted-dark.png'
import Inverted from './inverted.png'
import PinkYellow from './pink-yellow.png'
import Pink from './pink.png'
import PurpleGreen from './purple-green.png'
import Sepia from './sepia.png'
import Zinc from './zinc.png'

export const REFILL_COLOR_PREVIEWS = {
  [MapColor.black]: Black,
  [MapColor.blue]: Blue,
  [MapColor.blueGray]: BlueGray,
  [MapColor.brownOrange]: BrownOrange,
  [MapColor.grayGold]: GrayGold,
  [MapColor.gray]: Gray,
  [MapColor.highContrast]: HighContrast,
  [MapColor.invertedDark]: InvertedDark,
  [MapColor.inverted]: Inverted,
  [MapColor.pinkYellow]: PinkYellow,
  [MapColor.pink]: Pink,
  [MapColor.purpleGreen]: PurpleGreen,
  [MapColor.sepia]: Sepia,
  [MapColor.zinc]: Zinc,
}
