import React, { ReactNode } from 'react'

import { Divider, Stack } from '@mantine/core'
import { LeafletControlWrapper, LeafletControlWrapperProps } from 'components/LeafletMap/Utils/LeafletControlWrapper'

import { MapControlPaper } from '../MapControlPaper'

type Props = {
  position: LeafletControlWrapperProps['position']
  children: ReactNode[]
}

export const MergedControl = ({ children, position }: Props) => (
  <LeafletControlWrapper position={position}>
    <MapControlPaper>
      {React.Children.map(children, (child, index) => (
        <Stack key={index} spacing={0}>
          {child}
          {index < children.length - 1 && <Divider />}
        </Stack>
      ))}
    </MapControlPaper>
  </LeafletControlWrapper>
)
