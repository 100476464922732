import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, UnstyledButton } from '@mantine/core'
import { useResponsive } from 'hooks/useResponsive'

const TOGGLE_HEIGHT = 56 // px
const TOGGLE_WIDTH = 17 // px
const ICON_RIGHT_PADDING = 3 // px

type Props = {
  handleToggle: (e: React.MouseEvent<HTMLElement>) => void
  isOpen: boolean
}

export const LeftToggle = ({ handleToggle, isOpen }: Props) => {
  const { isSm } = useResponsive()

  return (
    <UnstyledButton
      onClick={handleToggle}
      mt={-(TOGGLE_HEIGHT / 2)}
      pr={ICON_RIGHT_PADDING}
      sx={(theme) => ({
        height: TOGGLE_HEIGHT,
        width: TOGGLE_WIDTH + 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        position: 'absolute',
        right: -TOGGLE_WIDTH,
        top: '50%',
        color: theme.colors.gray[6],
        backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[4],
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.sm,
        transition: 'transform 0.1s',
        '@media(hover: hover)': {
          '&:hover': {
            transform: 'translate3d(4px,0,0)',
          },
        },
      })}
    >
      {!isSm && (
        // touch thumb on small devices
        <Box
          onClick={handleToggle}
          sx={{
            position: 'absolute',
            top: -15,
            bottom: -15,
            right: -15,
            left: 0,
            borderRadius: 15,
          }}
        />
      )}
      <Stack
        sx={{
          transform: isOpen ? '' : 'rotate(180deg)',
          transition: 'transform 0.5s',
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Stack>
    </UnstyledButton>
  )
}
