import * as React from 'react'
import { SVGProps } from 'react'

export const ElevationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="13" height="13" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      style={{
        display: 'inline',
      }}
    >
      <g
        style={{
          fontSize: 64,
          letterSpacing: '-2.64538px',
          wordSpacing: 0,
          whiteSpace: 'pre',
          display: 'inline',
          fill: '#fff',
        }}
      >
        <path
          d="m 65.810562,14.09749 47.856458,76.781879 21.74755,-33.747712 45.1214,68.764213 c -0.0836,27.9724 -0.0111,21.18642 -0.0258,45.43941 L 0,170.44019 V 141.28577 C 24.249825,96.119089 41.163533,58.080863 65.810562,14.09749 Z"
          style={{
            fontSize: '53.3333px',
            display: 'inline',
            fill: 'currentcolor',
            strokeWidth: 0.849728,
          }}
          transform="translate(-.516 -1.475) scale(.19112)"
          aria-label="Elevation"
        />
      </g>
    </g>
  </svg>
)
