import { Coords, DeepPartial, RoutingCostingOptions } from 'types/app'

import { ActionType, RoutingActions } from './actions'
import { RoutingState } from './state'

const insert = <T>(arr: T[], index: number, newItem: T) => [...arr.slice(0, index), newItem, ...arr.slice(index)]
const mergePojo = <T extends Record<string, unknown>>(to: T, from: Partial<T>): T => ({ ...to, ...from })

const getMergedCostingOptions = (
  routing: keyof RoutingCostingOptions,
  costingOptions: RoutingCostingOptions,
  updatedCostingOptions: DeepPartial<RoutingCostingOptions>
) => ({
  ...costingOptions,
  [routing]: mergePojo(costingOptions[routing], updatedCostingOptions[routing] ?? {}),
})

export const RoutingReducer = (state: RoutingState, action: RoutingActions): RoutingState => {
  switch (action.type) {
    case ActionType.ADD_TRACK_WAYPOINT:
      if (action.payload.index !== undefined) {
        return {
          ...state,
          trackWaypoints: insert<Coords>(state.trackWaypoints, action.payload.index, action.payload.coords),
        }
      }
      return { ...state, trackWaypoints: [...state.trackWaypoints, action.payload.coords] }
    case ActionType.ADD_TRACK_WAYPOINTS:
      return { ...state, trackWaypoints: [...state.trackWaypoints, ...action.payload] }
    case ActionType.DELETE_TRACK_WAYPOINT:
      return { ...state, trackWaypoints: state.trackWaypoints.filter((_x, i: number) => i !== action.payload) }
    case ActionType.UPDATE_TRACK_WAYPOINT: {
      const trackpointsCopy = [...state.trackWaypoints]
      trackpointsCopy[action.payload.index] = action.payload.coords
      return { ...state, trackWaypoints: trackpointsCopy }
    }

    case ActionType.REWRITE_TRACK_WAYPOINTS:
      return { ...state, trackWaypoints: action.payload }
    case ActionType.CLEAR_TRACK_WAYPOINTS:
      return { ...state, trackWaypoints: [] }
    case ActionType.UPDATE_NON_ROUTABLE_TRACK_METADATA:
      return { ...state, nonRoutableTrackMetadata: action.payload }
    case ActionType.CHANGE_ROUTING_TYPE:
      return { ...state, routing: action.payload }
    case ActionType.UPDATE_ROUTING_COSTING_OPTIONS:
      return {
        ...state,
        costingOptions: getMergedCostingOptions(
          state.routing as keyof RoutingCostingOptions,
          state.costingOptions,
          action.payload
        ),
      }
    case ActionType.REWRITE_COMPUTED_TRACKPOINTS:
      return { ...state, computedTrackpoints: action.payload }
    case ActionType.REWRITE_COMPUTED_TRACKPOINTS_WITH_ATTRIBUTES:
      return { ...state, computedTrackpointsWithAttributes: action.payload }
    case ActionType.REWRITE_ELEVATIONS:
      return { ...state, elevations: action.payload }
    case ActionType.CLEAR_ELEVATIONS:
      return { ...state, elevations: [] }
    case ActionType.REWRITE_TRACK_META:
      return { ...state, trackMeta: action.payload }
    case ActionType.SHOW_PLACEHOLDER_TRACK:
      return { ...state, showPlaceholderTrack: action.payload }
    case ActionType.CHANGE_TRACK_COLOR:
      return { ...state, trackColor: action.payload }

    case ActionType.SET_ARE_ELEVATIONS_LOADING:
      return { ...state, areElevationsLoading: action.payload }
    case ActionType.SET_IS_ROUTING:
      return { ...state, isRouting: action.payload }
    default:
      throw new Error('Invalid action at trackPointsReducer')
  }
}
