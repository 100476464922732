import { useState, useEffect } from 'react'

import { ColorScheme } from '@mantine/core'

const getMode = () => {
  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
  return matchMedia.matches ? 'dark' : 'light'
}

export const useDetectMode = () => {
  const [mode, setMode] = useState<ColorScheme>(getMode())

  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
    // setMode(matchMedia.matches ? 'dark' : 'light')
    matchMedia.addEventListener('change', (event) => {
      const colorScheme = event.matches ? 'dark' : 'light'
      setMode(colorScheme)
    })
  }, [])

  return { mode, setMode }
}
