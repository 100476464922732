// NOTE: original function accepted additional unit argument
// m --> meters
// K --> kilometers
// N --> nautical miles
// delete note when all original uses of this function are implemented

// source https://www.geodatasource.com/developers/javascript

import { Coords } from 'types/app'

/**
 *
 * @param coords1 First coordinates [lat, lng]
 * @param coords2 Second coordinates [lat, lng]
 * @returns Distance in meters
 */
export const getDistanceBetweenCoords = (coords1: Coords, coords2: Coords): number => {
  const [lat1, lng1] = coords1
  const [lat2, lng2] = coords2

  if (lat1 === lat2 && lng1 === lng2) {
    return 0
  }

  const radlat1 = (Math.PI * lat1) / 180
  const radlat2 = (Math.PI * lat2) / 180
  const theta = lng1 - lng2
  const radtheta = (Math.PI * theta) / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist *= 1609.344

  return dist
}
