import { ReactNode } from 'react'

import { Button, Center, MantineColor, Text } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'

type ErrorModalProps = {
  title: string
  text: string
  icon: ReactNode
  buttonText: string
  buttonColor: MantineColor
}

export const ModalWithTopIcon = ({ context, id, innerProps }: ContextModalProps<ErrorModalProps>) => (
  <>
    {innerProps.icon && <Center mb="xs">{innerProps.icon}</Center>}
    {innerProps.title && (
      <Text size="xl" weight={700} mb="xs" align="center">
        {innerProps.title}
      </Text>
    )}
    {innerProps.text && (
      <Text align="center" mb="sm">
        {innerProps.text}
      </Text>
    )}
    <Button color={innerProps.buttonColor || 'red'} fullWidth onClick={() => context.closeModal(id)}>
      {innerProps.buttonText}
    </Button>
  </>
)
