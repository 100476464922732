import React, { ReactNode, useContext, useState } from 'react'

import { Viewer as CesiumViewer } from 'cesium'

interface CesiumContextInterface {
  cesium: CesiumViewer | null
  setCesium: (cesium: CesiumViewer | null) => void
}

const CesiumContext = React.createContext<CesiumContextInterface>({
  cesium: null,
  setCesium: () => null,
})

export const useProvideCesiumContext = (): CesiumContextInterface => {
  const [cesium, setCesium] = useState<CesiumViewer | null>(null)
  return { cesium, setCesium }
}

export const useCesiumContext = () => useContext(CesiumContext)

export const CesiumContextProvider = ({ children }: { children: ReactNode }) => {
  const mapcontext = useProvideCesiumContext()
  return <CesiumContext.Provider value={mapcontext}>{children}</CesiumContext.Provider>
}
