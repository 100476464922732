import { useEffect, useState } from 'react'

import { ActionIcon, Container, Grid, Group, MantineTheme, Stack, Text, useMantineTheme } from '@mantine/core'
import { BicycleType, Routing } from 'config/enums/routings'
import { BICYCLE_ICONS, ROUTING_ICONS } from 'config/icons'
import { useAuth } from 'hooks/useAuth'
import { useMantineTextColorIndex } from 'hooks/useMantineTextColor'
import { TFunction, useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { BicycleCostingOptions } from 'types/app'

import { ActionIconGroup } from './ActionIconGroup'
import { SliderWithLabel } from './SliderWithLabel'
import './RoutingPicker.css'

const getRoutingItems = (t: TFunction) => [
  {
    icon: ROUTING_ICONS.bicycle,
    label: t('general.routing.bike'),
    value: Routing.bicycle,
  },
  {
    icon: ROUTING_ICONS.auto,
    label: t('general.routing.car'),
    value: Routing.auto,
  },
  {
    icon: ROUTING_ICONS.pedestrian,
    label: t('general.routing.walk'),
    value: Routing.pedestrian,
  },
  {
    icon: ROUTING_ICONS.none,
    label: t('general.routing.none'),
    value: Routing.none,
  },
]

const getBicycleTypeItems = (t: TFunction) => [
  {
    icon: BICYCLE_ICONS.Hybrid,
    label: t('general.bicycle_costing_options.hybrid_icon_label'),
    value: BicycleType.hybrid,
  },
  { icon: BICYCLE_ICONS.Road, label: t('general.bicycle_costing_options.road_icon_label'), value: BicycleType.road },
  {
    icon: BICYCLE_ICONS.Cross,
    label: t('general.bicycle_costing_options.crossCountry_icon_label'),
    value: BicycleType.crossCountry,
  },
  {
    icon: BICYCLE_ICONS.Mountain,
    label: t('general.bicycle_costing_options.mountain_icon_label'),
    value: BicycleType.mountain,
  },
]

const getHoveredButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors.gray[1] : theme.colors.dark[4]

const getHoveredSelectedButtonBackground = (theme: MantineTheme) =>
  theme.colorScheme === 'light' ? theme.colors[theme.primaryColor]?.[8] : theme.colors[theme.primaryColor]?.[7]

export const RoutingPicker = () => {
  const { state, changeRouting, updateCostingOptions } = useRouting()
  const { user, update } = useAuth()
  const { t } = useTranslation()
  const [width, setWindowWidth] = useState(0)
  const [isOptionsPanelOpen, setOptionsPanelOpen] = useState(false)
  const textColor = useMantineTextColorIndex()
  const theme = useMantineTheme()

  const updateDimensions = () => {
    const { innerWidth } = window
    setWindowWidth(innerWidth)
  }

  useEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    // This handles use cases when routing is switched outside of Routing picker (e.g. from Routes tab)
    setOptionsPanelOpen((value) => {
      if (!value && state.routing === Routing.bicycle) {
        return true
      }
      if (value && state.routing !== Routing.bicycle) return false
      return value
    })
  }, [state.routing])

  return (
    <>
      <Group p="xs" className="trkbk-routing-panel" spacing="xs">
        {getRoutingItems(t).map(({ value, icon, label }) => {
          const isSelected = value === state.routing

          return (
            <ActionIcon
              key={label}
              onClick={() => {
                if (value === Routing.bicycle && state.routing !== Routing.none) {
                  setOptionsPanelOpen((value_) => !value_)
                } else if (isOptionsPanelOpen) {
                  setOptionsPanelOpen(false)
                }
                changeRouting(value)
                if (user) update({ routing: value })
              }}
              variant={isSelected ? 'filled' : 'transparent'}
              size={width > 768 ? 72 : 52}
              color={isSelected ? theme.primaryColor : textColor}
              sx={{
                fontSize: width > 768 ? 26 : 16,
                '&:hover': {
                  backgroundColor: isSelected
                    ? getHoveredSelectedButtonBackground(theme)
                    : getHoveredButtonBackground(theme),
                },
              }}
            >
              <Stack spacing="xs">
                {value === Routing.bicycle ? BICYCLE_ICONS[state.costingOptions[Routing.bicycle].bicycleType] : icon}
                <Text
                  inline
                  sx={{ fontSize: width > 768 ? '0.9rem' : '0.8rem', textAlign: 'center' }}
                  color={isSelected ? 'white' : ''}
                >
                  {label}
                </Text>
              </Stack>
            </ActionIcon>
          )
        })}
      </Group>
      {isOptionsPanelOpen ? (
        <Container
          className="trkbk-routing-options-panel"
          p={width > 768 ? 15 : 12}
          m={0}
          mt={5}
          style={{ borderColor: theme.colorScheme === 'light' ? '#dee2e6' : '#373a40' }}
        >
          <Grid align="start" justify="start" gutter={5} grow>
            <Grid.Col span={2}>
              <ActionIconGroup
                iconSize={width > 768 ? '1.8rem' : '1.0rem'}
                items={getBicycleTypeItems(t)}
                labelFontSize={width > 768 ? '0.8rem' : '0.5rem'}
                onClick={(value) =>
                  updateCostingOptions({
                    [Routing.bicycle]: { bicycleType: value as BicycleCostingOptions['bicycleType'] },
                  })
                }
                size={width > 768 ? 60 : 40}
                value={state.costingOptions[Routing.bicycle].bicycleType}
              />
              <div className="slider-container">
                <SliderWithLabel
                  label={t('general.bicycle_costing_options.use_hills_slider_label')}
                  labelFontSize={width > 768 ? '0.8em' : '0.7em'}
                  onChange={(value) =>
                    updateCostingOptions({
                      [Routing.bicycle]: { useHills: String(value) as BicycleCostingOptions['useHills'] },
                    })
                  }
                  markLabelFontSize={width > 768 ? '0.7em' : '0.5em'}
                  markTooltip={null}
                  marks={[
                    { value: 0, label: '0%' },
                    { value: 0.5, label: '50%' },
                    { value: 1, label: '100%' },
                  ]}
                  min={0}
                  max={1}
                  step={0.5}
                  value={Number(state.costingOptions[Routing.bicycle].useHills)}
                  thumbSize={width > 768 ? 18 : 16}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Container>
      ) : null}
    </>
  )
}
