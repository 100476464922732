import { Coords } from 'types/app'

import { getDistanceBetweenCoords } from '../getDistanceBetweenCoords/getDistanceBetweenCoords'

// WARN: refactored to return meters as a standard, instead of km (used in legacy code)
// WARN: meters are rounded to 2 decimals (centimeters)
// TO DO: handle unit conversion further down (in UI)

/**
 *
 * @param trackPoints Array of Coords
 * @returns Length in meters --> rounded to 2 decimals (centimeters)
 */
export const getTrackPointsDistance = (trackPoints: Coords[]) => {
  if (trackPoints.length < 2) return 0

  let length = 0
  for (let i = 1; i < trackPoints.length; i += 1) {
    const fromCoords: Coords = trackPoints[i - 1]!
    const toCoords: Coords = trackPoints[i]!
    length += getDistanceBetweenCoords(fromCoords, toCoords)
  }

  return Math.round(length * 100) / 100
}
