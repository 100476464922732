import { useRef } from 'react'

export type ActionTimeout = {
  /** Sets the time at which the action took place */
  setTime: () => void
  /** Returns true if the timeout has passed since last setTime() call */
  timeoutHasPassed: () => boolean
}

/**
 * A hook to prevent fast consecutive actions, often misclicks or double clicks.
 */
export const useActionTimeout = (timeout = 100): ActionTimeout => {
  const prevActionTime = useRef(0)

  const setActionTimestamp = () => {
    prevActionTime.current = Date.now()
  }

  const timeoutHasPassed = () => prevActionTime.current + timeout < Date.now()

  return { setTime: setActionTimestamp, timeoutHasPassed }
}
