/**
 *
 * @param seconds Time in seconds
 * @returns Time string in format 12h:12m:12s
 */
export const getSecondsAsHMS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor((seconds % 3600) % 60)

  const hDisplay = hours > 0 ? `${hours}h` : ''
  const mDisplay = minutes > 0 ? `${hDisplay !== '' ? `:${minutes}` : minutes}m:` : ''
  const sDisplay = `${secs}s`

  return hDisplay + mDisplay + sDisplay
}
