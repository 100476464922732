import { useState, useEffect, useCallback } from 'react'

import {
  faArrowRotateRight,
  faBolt,
  faGaugeHigh,
  faHeartbeat,
  faPenFancy,
  faTemperatureThreeQuarters,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, DefaultMantineColor, Popover, Select, useMantineColorScheme } from '@mantine/core'
import { ElevationIcon } from 'assets/icons/ElevationIcon'
import { HotlineDataType } from 'components/LeafletMap/TrackpointControls/HotlineControl'
import { Tooltip } from 'components/UI/Tooltip'
import { useTrackStats } from 'hooks/useTrackStats'
import { useTranslation } from 'react-i18next'
import { useGenericState } from 'stores/genericStore/GenericContext'
import { updateNonSaveableOptions } from 'stores/optionsStore/actions'
import { useOptions } from 'stores/optionsStore/OptionsContext'
import { ChartYAxisType } from 'types/app'
import { getYAxisOptions, SelectOption } from 'utils/chart/yAxisOptions'
import { cleanObject } from 'utils/helpers/cleanObject'
import { SelectItem } from 'utils/helpers/getSelectItem/getSelectItem'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

interface HotlineSelectOption {
  value: SelectOption['value'] | 'none'
  label: string
}

export const HotlineIcon = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation()
  const {
    dispatch,
    state: { hotlineType },
  } = useOptions()
  const { showCesiumMap } = useGenericState()
  const { stats } = useTrackStats()

  const [selectData, setSelectData] = useState<HotlineSelectOption[]>([
    { label: t('y_axis_options.none'), value: 'none' },
  ])
  const { colorScheme } = useMantineColorScheme()
  const [filteredYAxixData, setFilteredYAxixData] = useState<string[]>()

  const handleSelect = (value: string) => {
    dispatch(updateNonSaveableOptions({ hotlineType: value as HotlineDataType }))
  }

  const setHotlineType = useCallback(
    (data: string[]) => {
      if (!data.includes(hotlineType)) dispatch(updateNonSaveableOptions({ hotlineType: 'none' as HotlineDataType }))
    },
    [dispatch, hotlineType]
  )

  useEffect(() => {
    if (stats?.chartData) {
      const yAxisData = stats?.chartData.map((item) => item.yAxis)
      const cleanedData = yAxisData.map((item) => cleanObject(item))
      const keys = Object.keys(cleanedData[cleanedData.length - 1])
      setHotlineType(keys)
      setFilteredYAxixData(keys)
      const yAxisOptions = getYAxisOptions(t, keys as ChartYAxisType[])
      setSelectData([{ label: t('y_axis_options.none'), value: 'none' }, ...yAxisOptions])
    }
  }, [stats, t, setHotlineType])

  /** Change icons for individual hotline types */
  const getHotlineIcon = useCallback(
    (color?: DefaultMantineColor) => {
      const getColorSchemeByTheme = () => (colorScheme === 'light' ? '#0b0b0b' : '#b7b8bb')
      if (hotlineType === 'elevation')
        return (
          <Box sx={{ color: color ?? getColorSchemeByTheme() }}>
            <ElevationIcon />
          </Box>
        )
      if (hotlineType === 'speed')
        return <FontAwesomeIcon color={color ?? getColorSchemeByTheme()} icon={faGaugeHigh} />
      if (hotlineType === 'cadence')
        return <FontAwesomeIcon color={color ?? getColorSchemeByTheme()} icon={faArrowRotateRight} />
      if (hotlineType === 'heartRate')
        return <FontAwesomeIcon color={color ?? getColorSchemeByTheme()} icon={faHeartbeat} />
      if (hotlineType === 'power') return <FontAwesomeIcon color={color ?? getColorSchemeByTheme()} icon={faBolt} />
      if (hotlineType === 'temperature')
        return <FontAwesomeIcon color={color ?? getColorSchemeByTheme()} icon={faTemperatureThreeQuarters} />
      return ''
    },
    [hotlineType, colorScheme]
  )

  return (
    <Popover position="bottom" withinPortal trapFocus>
      <Tooltip label={t('editmode.hotline_button_tooltip')}>
        <Popover.Target>
          <div>
            <CollapsibleTopMenuActionIcon
              isOpen={isOpen}
              active={hotlineType !== 'none' && filteredYAxixData?.includes(hotlineType)}
              disabled={showCesiumMap}
            >
              {hotlineType !== 'none' && filteredYAxixData?.includes(hotlineType) ? (
                getHotlineIcon('')
              ) : (
                <FontAwesomeIcon icon={faPenFancy} />
              )}
            </CollapsibleTopMenuActionIcon>
          </div>
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown>
        <Select
          data-autofocus
          size="xs"
          icon={getHotlineIcon()}
          itemComponent={SelectItem}
          data={selectData}
          value={hotlineType}
          onChange={handleSelect}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
