import { MouseEvent, useCallback, useMemo } from 'react'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Center, Image, Overlay, Paper, Text, useMantineTheme } from '@mantine/core'

type Props<T> = {
  label: string
  value: T
  imageSrc: string
  onClick?: (value: T) => void
  selected?: boolean
}

export const MapLayerPickerCard = <T,>({ label, value, imageSrc, onClick, selected = false }: Props<T>) => {
  const theme = useMantineTheme()

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      onClick?.(value)
    },
    [onClick, value]
  )

  return useMemo(
    () => (
      <Paper
        onClick={handleClick}
        onContextMenu={(e: MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          cursor: 'pointer',
          width: 130,
          userSelect: 'none',
          outlineWidth: 2,
          outlineStyle: selected ? 'solid' : 'none',
          outlineColor: theme.colors[theme.primaryColor]?.[5],
          '&:hover': {
            outlineStyle: 'solid',
          },
        }}
        shadow="md"
      >
        <Box sx={{ position: 'relative' }}>
          <Image src={imageSrc} width={130} height={65} />
          {selected && (
            <Overlay opacity={1} color="#ffffff7d">
              <Center sx={{ height: '100%' }}>
                <FontAwesomeIcon icon={faCheck} color={theme.colors[theme.primaryColor]?.[5]} fontSize={46} />
              </Center>
            </Overlay>
          )}
        </Box>
        <Text
          color={selected ? theme.primaryColor : undefined}
          weight={selected ? 'bold' : 'normal'}
          size="sm"
          align="center"
          px="xs"
          sx={{ whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {label}
        </Text>
      </Paper>
    ),
    [handleClick, imageSrc, label, selected, theme.colors, theme.primaryColor]
  )
}
