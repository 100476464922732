import i18n from 'utils/i18n/i18n'

export enum Overlay {
  transit = 'transit',
  bikePaths = 'bikePaths',
  bikeTrails = 'bikeTrails',
  walkPaths = 'walkPaths',
  hikeWaymarked = 'hikeWaymarked',
}

const getAllOverlayLabels = () => [
  { label: i18n.t('general.overlay.transit'), value: Overlay.transit },
  { label: i18n.t('general.overlay.bikePaths'), value: Overlay.bikePaths },
  { label: i18n.t('general.overlay.bikeTrails'), value: Overlay.bikeTrails },
  { label: i18n.t('general.overlay.walkPaths'), value: Overlay.walkPaths },
  { label: i18n.t('general.overlay.hikeWaymarked'), value: Overlay.hikeWaymarked },
]

// generic overlays show up/work for all tangram maps
const GENERIC_OVERLAYS = [Overlay.transit, Overlay.bikeTrails, Overlay.hikeWaymarked]

export const getOverlayLabels = (value: Overlay[]) =>
  getAllOverlayLabels().filter((label) => value.includes(label.value))

export const getGenericOverlayLabels = () => getOverlayLabels(GENERIC_OVERLAYS)
