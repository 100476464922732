import { useCallback } from 'react'

import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@mantine/core'
import { useAuth } from 'hooks/useAuth'
import { useGenericModals } from 'hooks/useGenericModals'
import { useTranslation } from 'react-i18next'

export const ImportGPXMenuItem = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { openInfoModal, openImportGPXmodal } = useGenericModals()

  const handleClick = useCallback(() => {
    if (!user) {
      openInfoModal('Please sign in', 'Importing GPX file is possible after sign-in.')
      return
    }
    openImportGPXmodal()
  }, [openImportGPXmodal, openInfoModal, user])

  return (
    <Menu.Item icon={<FontAwesomeIcon icon={faUpload} />} onClick={handleClick}>
      {t('dropdown_menu.import_gpx_label')}
    </Menu.Item>
  )
}
