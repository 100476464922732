import { NonSaveableOptions, SaveableOptions } from "./state";

export enum ActionType {
  UPDATE_SAVEABLE = "update_saveable",
  UPDATE_NON_SAVEABLE = "update_non_saveable",
  RESET = "reset",
}

interface UpdateSaveableOptions {
  type: ActionType.UPDATE_SAVEABLE;
  payload: Partial<SaveableOptions>;
}
interface UpdateNonSaveableOptions {
  type: ActionType.UPDATE_NON_SAVEABLE;
  payload: Partial<NonSaveableOptions>;
}

interface ResetOptions {
  type: ActionType.RESET;
}

export type OptionsActions =
  | UpdateSaveableOptions
  | UpdateNonSaveableOptions
  | ResetOptions;

export const updateSaveableOptions = (
  newOptions: Partial<SaveableOptions>
): UpdateSaveableOptions => ({
  type: ActionType.UPDATE_SAVEABLE,
  payload: newOptions,
});
export const updateNonSaveableOptions = (
  newOptions: Partial<NonSaveableOptions>
): UpdateNonSaveableOptions => ({
  type: ActionType.UPDATE_NON_SAVEABLE,
  payload: newOptions,
});

export const resetOptions = (): ResetOptions => ({
  type: ActionType.RESET,
});
