import React, { ReactNode, useContext, useState } from 'react'

import { ModalsContextProps } from '@mantine/modals/lib/context'

const useModals = () => {
  const [modals, setModals] = useState<ModalsContextProps | null>(null)
  return { modals, setModals }
}

interface ConfirmModalContextInterface {
  modals: ModalsContextProps | null
  setModals: (modals: ModalsContextProps) => void
}

const MapContext = React.createContext<ConfirmModalContextInterface>({
  modals: null,
  setModals: () => null,
})

export const useLiftedModalContext = () => useContext(MapContext)

export const LiftedModalContextProvider = ({ children }: { children: ReactNode }) => {
  const mapcontext = useModals()
  return <MapContext.Provider value={mapcontext}>{children}</MapContext.Provider>
}
