import { forwardRef, useMemo } from 'react'

import { Group, Image, Text } from '@mantine/core'
import { normalizeIconName } from 'utils/helpers/waypointIcons/normalizeUniqueName'

interface SelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  iconName: string
  onMouseDown: () => void
  onMouseEnter: () => void
}

export const SelectIconDisplayItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, iconName, onMouseDown, onMouseEnter, ...rest }: SelectItemProps, ref) => {
    const memoizedOnMouseDown = useMemo(() => onMouseDown, [])
    const memoizedOnMouseEnter = useMemo(() => onMouseEnter, [])

    const isHovered = rest['data-hovered' as keyof typeof rest]

    return useMemo(
      () => (
        <div ref={ref} onMouseDown={memoizedOnMouseDown} onMouseEnter={memoizedOnMouseEnter} {...rest}>
          <Group noWrap>
            <Image width={25} src={`img/map_icons/${normalizeIconName(iconName)}.png`} />
            <div>
              <Text size="sm">{label}</Text>
            </div>
          </Group>
        </div>
      ),
      [isHovered]
    )
  }
)
SelectIconDisplayItem.displayName = 'SelectIconDisplayItem'
