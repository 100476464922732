import { BicycleType, Routing } from 'config/enums/routings'
import { BicycleCostingOptions, Coords, RoutingCostingOptions } from 'types/app'

export interface TrackMeta {
  totalDistance: number | undefined
  /** Smoothed avg speed data for all trackpoints */
  trackPointsSpeed: number[] | undefined
  trackPointsTime:
    | {
        datapoints: number[]
        startPointIndex: number | undefined
        startTimestamp: number
      }
    | undefined
  /** Total time it takes to traverse the track, only applicable for routable tracks */
  totalTime: number | undefined
}

export interface NonRoutableTrackMetadata {
  trackPointsEle: number[]
  trackPointsCadence: number[] | null
  trackPointsHeartRate: number[] | null
  trackPointsPower: number[] | null
  trackPointsSpeed: number[] | undefined
  trackPointsTemperature: number[] | null
  trackPointsTimeDatapoints: number[] | undefined
}

export type ComputedTrackPointsWithAttributes = {
  coords: Coords
  /** Seconds since start of the track */
  time: number
  speed: number
}

export interface RoutingState {
  /** user added waypoints */
  trackWaypoints: Coords[]
  /**
   * Final waypoints from which elevation data is calculated
   * (equals trackWaypoints if routing is none / equals result of routing if routing is used)
   */
  computedTrackpoints: Coords[]
  /** Computed trackpoints together with various attributes like time or speed.
   * Required for chart and only applicable for routable tracks
   */
  computedTrackpointsWithAttributes: ComputedTrackPointsWithAttributes[]
  /** When a track is selected in the menu, a placeholder track is selected till a new one is generated by routing control */
  showPlaceholderTrack: boolean
  /** Controls currently active routing type */
  routing: Routing
  /** Elevations are calculated after computedTrackpoints are calculated, both arrays should have the same length
   * Possible improvement: Pair elevations to computedTrackpoints so that one knows if a track already has a calculated elevations or not
   * Maybe it could all be encapsulated in a Track class?
   */
  costingOptions: RoutingCostingOptions
  elevations: number[]
  /** Metadata for the track */
  trackMeta: TrackMeta
  /** Color as HEX */
  trackColor: string | null
  /** Is true since new computedTrackpoints were received, until new elevations are received  */
  areElevationsLoading: boolean
  /** Is true since routing control started routing, till new computed trackpoints are received */
  isRouting: boolean
  /** Track metadata only for non-routable tracks - these tracks are imported from various devices and often contain
   * additional data, like heart rate, cadence, power, etc. This was added after Peter left and should be refactored
   * along with all other track-related things in the app.
   */
  nonRoutableTrackMetadata: NonRoutableTrackMetadata
}

export const initialRoutingState: RoutingState = {
  trackWaypoints: [],
  computedTrackpoints: [],
  computedTrackpointsWithAttributes: [],
  showPlaceholderTrack: false,
  routing: Routing.bicycle,
  costingOptions: {
    [Routing.bicycle]: {
      bicycleType: BicycleType.hybrid,
      useHills: '0' as BicycleCostingOptions['useHills'],
    },
  },
  elevations: [],
  trackMeta: {
    totalDistance: undefined,
    trackPointsSpeed: undefined,
    trackPointsTime: undefined,
    totalTime: undefined,
  },
  nonRoutableTrackMetadata: {
    // We also interpolate elevations from the track data, but elevations are re-computed and this value
    // is not really used, only as a placeholder in case fetching elevations fails for some reason
    trackPointsEle: [],
    trackPointsCadence: null,
    trackPointsHeartRate: null,
    trackPointsPower: null,
    trackPointsSpeed: undefined,
    trackPointsTemperature: null,
    // Unfortunately, the trackPointsTime attribute naming and format are inconsistent with the rest of the
    // attributes, which is confusing and also makes life harder when we need to code something that iterates
    // over the attributes in similar fashion (like interpolation), so we just store datapoints here and the
    // rest of the time data is left on the track object
    trackPointsTimeDatapoints: undefined,
  },
  trackColor: null,
  areElevationsLoading: false,
  isRouting: false,
}
