import {
  Image,
  List,
  Stack,
  Tabs,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { faAppStore, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  qrCodeGPXViewerGoogle,
  qrCodeGPXViewerProGoogle,
  qrCodeGPXViewerProApple,
} from "assets/gpxViewerAppsModal";

import "./GPXViewerAppsModal.css";

export const GPXViewerAppsModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <TypographyStylesProvider>
        <Stack p="sm">
          <div className="trkbk-md-paragraph">
            <ReactMarkdown skipHtml>
              {t("gpx_viewer_apps_modal.intro_paragraph")}
            </ReactMarkdown>
          </div>
          <Tabs defaultValue="googlePlay">
            <Tabs.List grow position="center">
              <Tabs.Tab
                value="googlePlay"
                icon={<FontAwesomeIcon icon={faGooglePlay} />}
              >
                Google Play
              </Tabs.Tab>
              <Tabs.Tab
                value="appStore"
                icon={<FontAwesomeIcon icon={faAppStore} />}
              >
                App Store
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="googlePlay" pt="xs">
              <div className="trkbk-tabs-panel-container">
                <div className="trkbk-paragraph-column left">
                  <div className="trkbk-bottom-margin">
                    <Image src={qrCodeGPXViewerGoogle} />
                    <Text size="md" weight={500} align="center">
                      GPX Viewer
                    </Text>
                  </div>
                  <div>
                    <Image src={qrCodeGPXViewerProGoogle} />
                    <Text size="md" weight={500} align="center">
                      GPX Viewer PRO
                    </Text>
                  </div>
                </div>
                <div className="trkbk-paragraph-column">
                  <List spacing="sm">
                    <List.Item>
                      {t("gpx_viewer_apps_modal.google_step_1")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.google_step_2")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.google_step_3")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.google_step_4")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.google_step_5")}
                    </List.Item>
                  </List>
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="appStore" pt="xs">
              <div className="trkbk-tabs-panel-container">
                <div className="trkbk-paragraph-column left">
                  <div>
                    <Image src={qrCodeGPXViewerProApple} />
                    <Text size="md" weight={500} align="center">
                      GPX Viewer PRO
                    </Text>
                  </div>
                </div>
                <div className="trkbk-paragraph-column">
                  <List spacing="sm">
                    <List.Item>
                      {t("gpx_viewer_apps_modal.apple_step_1")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.apple_step_2")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.apple_step_3")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.apple_step_4")}
                    </List.Item>
                    <List.Item>
                      {t("gpx_viewer_apps_modal.apple_step_5")}
                    </List.Item>
                  </List>
                </div>
              </div>
            </Tabs.Panel>
          </Tabs>
          <div className="trkbk-bottom-offset-medium"></div>
        </Stack>
      </TypographyStylesProvider>
    </>
  );
};
