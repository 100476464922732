import { useEffect, useMemo } from 'react'

import axios, { AxiosError, AxiosResponse } from 'axios'
import { useAuth } from 'hooks/useAuth'
import { debounce } from 'lodash'

type Props = {
  children: JSX.Element
}

// Axios intercepor is mountend in a special component, so that we can use hooks (like useAuth()) in the interceptor
export const AxiosInterceptor = ({ children }: Props) => {
  const { signOut } = useAuth()
  const signOutDebounced = useMemo(() => debounce(signOut, 200), [signOut])

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => response
    const errInterceptor = (error: AxiosError) => {
      if (error?.response?.status === 401) {
        signOutDebounced()
      }
      return error
    }
    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor)

    return () => axios.interceptors.response.eject(interceptor)
  }, [signOutDebounced])
  return children
}
