import React, { ReactNode, useCallback, useContext, useState } from 'react'

import { TrackMeta } from 'stores/routingStore/state'
import { Coords, Track } from 'types/app'
import { getDistanceBetweenCoords } from 'utils/coords'
import { CustomChartDataPoint, getPreparedChartData } from 'utils/trackStats/prepareTrackStats'

export type TrackStats = {
  /** Value in meters */
  minElevation: number
  /** Value in meters */
  maxElevation: number
  /** Value in meters */
  totalClimb: number
  /** Value in meters */
  totalDescend: number
  /** Value in kilometers */
  totalLengthKm: number
  /** Time in seconds */
  totalTime?: number
  /** Distance spent climbing in km */
  distanceUphill: number
  /** Distance spent descending in km */
  distanceDownhill: number
  /** data to be displayed on chart */
  chartData: CustomChartDataPoint[]
  populatedDatasets: {
    elevation: boolean
    heartRate: boolean
    cadence: boolean
    power: boolean
    speed: boolean
    temperature: boolean
    time: boolean
  }
}

interface TrackStatsContextType {
  clearTrackStats: () => void
  stats: TrackStats | null
  setTrackStats: ({
    track,
    trackpoints,
    elevations,
  }: {
    track: Pick<
      Track,
      | 'trackPointsCadence'
      | 'trackPointsEle'
      | 'trackPointsHeartRate'
      | 'trackPointsPower'
      | 'trackPointsSpeed'
      | 'trackPointsTemperature'
    > & { trackPointsTimeDatapoints: number[] | undefined }
    trackpoints: Coords[]
    elevations: number[]
  }) => void
  updateTrackStatsWithTrackMeta: (trackMeta: TrackMeta) => void
  updateTrackStatsWithElevations: (elevations: number[], trackpoints: Coords[]) => void
}

const TrackStatsContext = React.createContext<TrackStatsContextType>({
  clearTrackStats: () => null,
  stats: null,
  setTrackStats: () => null,
  updateTrackStatsWithTrackMeta: () => {},
  updateTrackStatsWithElevations: () => {},
})

const useProvideTrackStats = (): TrackStatsContextType => {
  const [stats, setStats] = useState<TrackStats | null>(null)

  const clearTrackStats = useCallback(() => {
    setStats(null)
  }, [])

  const updateTrackStatsWithTrackMeta = useCallback((trackMeta: TrackMeta) => {
    setStats((prevStats) => {
      if (prevStats) {
        let computedTotalTime = 0
        let populatedSpeed = false
        let populatedTime = false

        const updatedChartData = prevStats.chartData.map((stat, index) => {
          const time = trackMeta.trackPointsTime?.datapoints[index] ?? stat.xAxis.time
          const speed = trackMeta.trackPointsSpeed![index] ?? stat.yAxis.speed

          computedTotalTime += time ?? 0
          if (populatedTime === false && time !== null) populatedTime = true
          if (populatedSpeed === false && speed !== null) populatedSpeed = true

          return {
            xAxis: {
              ...stat.xAxis,
              time,
            },
            yAxis: {
              ...stat.yAxis,
              speed,
            },
          }
        })

        return {
          ...prevStats,
          chartData: updatedChartData,
          totalTime: computedTotalTime,
          populatedDatasets: {
            ...prevStats.populatedDatasets,
            speed: populatedSpeed,
            time: populatedTime,
          },
        }
      }
      return null
    })
  }, [])

  const updateTrackStatsWithElevations = useCallback((elevations: number[], trackpoints: Coords[]) => {
    setStats((prevStats) => {
      let minElevation = Number.MAX_VALUE
      let maxElevation = Number.MIN_VALUE
      let totalClimb = 0
      let totalDescend = 0
      let computedTotalDistance = 0 // km
      let distanceUphill = 0 // km
      let distanceDownhill = 0 // km
      let populatedElevation = false

      const updatedChartData = trackpoints.map((point, index) => {
        const prevPoint = trackpoints[index - 1]
        const distanceBetweenCurrentPrevPoint = prevPoint ? getDistanceBetweenCoords(prevPoint, point) / 1000 : 0
        const currentDistanceFromStart = computedTotalDistance + distanceBetweenCurrentPrevPoint
        computedTotalDistance = currentDistanceFromStart

        const prevElevation = elevations[index - 1] || 0
        const elevation = Number(elevations[index]) || 0
        if (elevation < minElevation) {
          minElevation = elevation
        }
        if (elevation > maxElevation) {
          maxElevation = elevation
        }

        if (elevation > prevElevation) {
          distanceUphill += distanceBetweenCurrentPrevPoint
        } else {
          distanceDownhill += distanceBetweenCurrentPrevPoint
        }

        if (index > 0) {
          const elevationDiff = elevation - prevElevation

          if (elevationDiff > 0) {
            totalClimb += elevationDiff
          } else if (elevationDiff < 0) {
            totalDescend += Math.abs(elevationDiff)
          }
        }

        const roundedElevation = Number(elevations[index]?.toFixed(1))
        if (populatedElevation === false && !isNaN(roundedElevation)) populatedElevation = true

        return {
          xAxis: {
            ...(prevStats?.chartData[index]?.xAxis ?? {
              time: null,
            }),
            distance: index > 0 ? currentDistanceFromStart : 0,
          },
          yAxis: {
            ...(prevStats?.chartData[index]?.yAxis ?? {
              heartRate: null,
              cadence: null,
              power: null,
              speed: null,
              temperature: null,
            }),
            elevation: roundedElevation,
          },
        }
      })

      return {
        ...(prevStats ?? {}),
        chartData: updatedChartData,
        minElevation,
        maxElevation,
        totalClimb,
        totalDescend,
        totalLengthKm: computedTotalDistance,
        distanceUphill,
        distanceDownhill,
        populatedDatasets: {
          ...(prevStats
            ? prevStats.populatedDatasets
            : {
                heartRate: false,
                cadence: false,
                power: false,
                speed: false,
                temperature: false,
                time: false,
              }),
          elevation: populatedElevation,
        },
      }
    })
  }, [])

  // This method runs on the initial load (when user clicks on a track) and subsequent track updates that happen
  // after something with the track changes (trackpoints, elevations, ...)
  const setTrackStats = useCallback(
    ({
      track,
      trackpoints,
      elevations,
    }: {
      track: Pick<
        Track,
        | 'trackPointsCadence'
        | 'trackPointsEle'
        | 'trackPointsHeartRate'
        | 'trackPointsPower'
        | 'trackPointsSpeed'
        | 'trackPointsTemperature'
      > & { trackPointsTimeDatapoints: number[] | undefined }
      trackpoints: Coords[]
      elevations: number[]
    }) => {
      const { preparedChartData, computedTotalDistance, computedTotalTime, ...rest } = getPreparedChartData({
        trackpoints,
        elevations,
        // I don't know why we have these default nil values here since all of the attributes can be
        // either null or undefined...
        trackPointsHeartRate: track.trackPointsHeartRate ?? null,
        trackPointsCadence: track.trackPointsCadence ?? null,
        trackPointsPower: track.trackPointsPower ?? null,
        trackPointsSpeed: track.trackPointsSpeed ?? undefined,
        trackPointsTemperature: track.trackPointsTemperature ?? null,
        trackPointsTimeDatapoints: track.trackPointsTimeDatapoints ?? undefined,
      })

      setStats({
        chartData: preparedChartData,
        totalLengthKm: computedTotalDistance,
        totalTime: computedTotalTime,
        ...rest,
      })
    },
    []
  )

  return {
    clearTrackStats,
    stats,
    setTrackStats,
    updateTrackStatsWithElevations,
    updateTrackStatsWithTrackMeta,
  }
}

export const useTrackStats = () => useContext(TrackStatsContext)

export const TrackStatsProvider = ({ children }: { children: ReactNode }) => {
  const stats = useProvideTrackStats()
  return <TrackStatsContext.Provider value={stats}>{children}</TrackStatsContext.Provider>
}
