import { LeafletControlWrapperProps } from 'components/LeafletMap/Utils/LeafletControlWrapper'
import { MapLayersPickerOverlay } from 'containers/Map/Controls/MapLayerPicker/MapLayersPickerOverlay'

import { HelpControl } from '../HelpControl/HelpControl'
import { LegendControl } from '../LegendControl/LegendControl'
import { MergedControl } from '../MergedControl/MergedControl'
import { PublicTracksControl } from '../PublicTracksControl/PublicTracksControl'

type Props = {
  position: LeafletControlWrapperProps['position']
}

export const MergedMapPublicLegendHelpControl = ({ position }: Props) => (
  <MergedControl position={position}>
    <MapLayersPickerOverlay />
    <PublicTracksControl />
    <LegendControl />
    <HelpControl />
  </MergedControl>
)
