import { Map, Polyline } from 'leaflet'
import { Coords } from 'types/app'

export const fitPolylineToScreen = (polyline: Polyline, map: Map, maxZoom?: number) => {
  map.fitBounds(polyline.getBounds().pad(0.6), { maxZoom, animate: false })
}

export const fitTrackToScreen = (trackpoints: Coords[], map: Map) => {
  fitPolylineToScreen(L.polyline(trackpoints), map)
}

export const fitCoordsToScreen = (coords: Coords, map: Map, maxZoom?: number) => {
  fitPolylineToScreen(L.polyline([coords]), map, maxZoom)
}

export const fitWaypointToScreen = (coords: Coords, map: Map) => {
  fitCoordsToScreen(coords, map, 14)
}
