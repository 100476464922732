import { Button, Divider, Text, useMantineTheme } from '@mantine/core'
import { BicycleType } from 'config/enums/routings'
import { BICYCLE_ICONS } from 'config/icons'
import { TFunction, useTranslation } from 'react-i18next'
import { BicycleCostingOptions } from 'types/app'

import { ActionIconSelect } from './ActionIconSelect'
import { Slider } from './Slider'
import './styles.css'

const getBicycleTypeItems = (t: TFunction) => [
  {
    icon: BICYCLE_ICONS.Hybrid,
    label: t('general.bicycle_costing_options.hybrid_icon_label'),
    value: BicycleType.hybrid,
  },
  { icon: BICYCLE_ICONS.Road, label: t('general.bicycle_costing_options.road_icon_label'), value: BicycleType.road },
  {
    icon: BICYCLE_ICONS.Cross,
    label: t('general.bicycle_costing_options.crossCountry_icon_label'),
    value: BicycleType.crossCountry,
  },
  {
    icon: BICYCLE_ICONS.Mountain,
    label: t('general.bicycle_costing_options.mountain_icon_label'),
    value: BicycleType.mountain,
  },
]

export const BicycleOptionsSubMenu = ({
  handlers,
  onButtonClick,
  position,
  visible,
  values,
}: {
  handlers: {
    bicycleType: (value: BicycleType) => void
    useHills: (value: BicycleCostingOptions['useHills']) => void
  }
  onButtonClick: () => void
  position: { left: number; top: number }
  visible: boolean
  values: {
    bicycleType: BicycleType
    useHills: BicycleCostingOptions['useHills']
  }
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  return visible ? (
    <div
      className="trkbk-context-menu sub-menu"
      style={{
        ...position,
        backgroundColor: theme.colorScheme === 'light' ? '#fff' : '#1A1B1E',
        border: theme.colorScheme === 'light' ? '1px solid #ced4da' : 'none',
      }}
    >
      <>
        <Text size="xs" px="xs" py={4} color="dimmed">
          {t('general.routing.routing_options')}
        </Text>
        <Divider />
        <div className="container">
          <ActionIconSelect<BicycleType>
            items={getBicycleTypeItems(t)}
            label={t('general.bicycle_costing_options.bicycle_type_label')}
            onItemClick={(value) => {
              handlers.bicycleType(value)
            }}
            value={values.bicycleType}
          />
          <Slider<number>
            items={[
              { value: 0, label: '0%' },
              { value: 0.5, label: '50%' },
              { value: 1, label: '100%' },
            ]}
            label={t('general.bicycle_costing_options.use_hills_slider_label')}
            onChange={(value) => {
              handlers.useHills(String(value) as BicycleCostingOptions['useHills'])
            }}
            value={Number(values.useHills)}
          />
          <div className="button">
            <Button onClick={onButtonClick} size="xs" fullWidth>
              {t('generic.ok')}
            </Button>
          </div>
        </div>
      </>
    </div>
  ) : null
}
